import React from "react";
import styles from "./FunnelStageMenu.module.scss";
import { MdHomeFilled } from "react-icons/md";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
}

const FunnelStageMenu: React.FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={classNames(styles["container"])}>
      <button
        className={styles["home-icon"]}
        onClick={() => {
          navigate(ROUTE_DEFINITIONS.HOME.path);
        }}
      >
        <svg
          width="36"
          height="30"
          viewBox="0 0 36 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.6471 4.74706L26.4706 12.6882V26.4706H22.9412V15.8824H12.3529V26.4706H8.82353V12.6882L17.6471 4.74706ZM17.6471 0L0 15.8824H5.29412V30H15.8824V19.4118H19.4118V30H30V15.8824H35.2941L17.6471 0Z"
            fill="white"
          />
        </svg>
      </button>
      <button
        className={styles["campaign-icon"]}
        onClick={(e) => {
          dispatch(setSelectedCampaign(''));
          navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
        }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8571 0C10.5072 0 8.57143 1.93576 8.57143 4.28571H2.85714C1.29562 4.28571 0 5.58134 0 7.14286V15.7143H2.85714C3.66433 15.7143 4.28571 16.3357 4.28571 17.1429C4.28571 17.95 3.66433 18.5714 2.85714 18.5714H0V27.1429C0 28.7044 1.29562 30 2.85714 30H11.4286V27.1429C11.4286 26.3357 12.05 25.7143 12.8571 25.7143C13.6643 25.7143 14.2857 26.3357 14.2857 27.1429V30H22.8571C24.4187 30 25.7143 28.7044 25.7143 27.1429V21.4286C28.0642 21.4286 30 19.4928 30 17.1429C30 14.7929 28.0642 12.8571 25.7143 12.8571V7.14286C25.7143 5.58134 24.4187 4.28571 22.8571 4.28571H17.1429C17.1429 1.93576 15.2071 0 12.8571 0ZM12.8571 2.85714C13.6643 2.85714 14.2857 3.47853 14.2857 4.28571V7.14286H22.8571V15.7143H25.7143C26.5215 15.7143 27.1429 16.3357 27.1429 17.1429C27.1429 17.95 26.5215 18.5714 25.7143 18.5714H22.8571V27.1429H17.1429C17.1429 24.7929 15.2071 22.8571 12.8571 22.8571C10.5072 22.8571 8.57143 24.7929 8.57143 27.1429H2.85714V21.4286C5.2071 21.4286 7.14286 19.4928 7.14286 17.1429C7.14286 14.7929 5.2071 12.8571 2.85714 12.8571V7.14286H11.4286V4.28571C11.4286 3.47853 12.05 2.85714 12.8571 2.85714Z"
            fill="white"
          />
        </svg>
        <div className={styles["new-campaign-container"]}>
          <TextElement>New Campaign Plan</TextElement>
        </div>
        <svg
          width="8"
          height="10"
          viewBox="0 0 8 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles["arrow-icon"]}
        >
          <path d="M8 5L0.730061 9.33013L0.730062 0.669872L8 5Z" fill="white" />
        </svg>
      </button>
    </div>
  );
};

export default FunnelStageMenu;
