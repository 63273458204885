import React from "react";

const Marketo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0043 0.5V20.5C13.0667 20.4546 13.1111 20.4246 13.1511 20.3902C14.7479 19.12 16.3451 17.8504 17.9426 16.5815C18.0667 16.4834 18.1096 16.3835 18.1093 16.2287C18.1054 11.9782 18.1054 7.72796 18.1093 3.47797C18.1093 3.31438 18.0597 3.2291 17.9162 3.15225C16.5186 2.40248 15.1226 1.64783 13.7283 0.888303C13.4933 0.761309 13.2576 0.636144 13.0043 0.5ZM6.4326 17.936C6.48914 17.9177 6.53539 17.9063 6.57945 17.888C7.85634 17.3339 9.13213 16.7773 10.4112 16.2283C10.5416 16.1723 10.554 16.0903 10.554 15.9721C10.5518 11.8612 10.5518 7.75004 10.554 3.63863C10.554 3.48968 10.5096 3.426 10.3672 3.38464C9.11891 3.01866 7.87543 2.64793 6.62938 2.27975C6.56917 2.26182 6.50566 2.25487 6.43186 2.24059L6.4326 17.936ZM0.888885 4.31679V15.6665C0.962312 15.6501 1.02289 15.6387 1.0809 15.6237C1.98404 15.3891 2.88572 15.1498 3.79071 14.9225C3.95555 14.8811 4.01319 14.8189 4.01099 14.6421C4.00585 11.4398 4.00585 8.23764 4.01099 5.03557V4.81013L0.888885 4.31679Z"
        fill="white"
      />
    </svg>
  );
};

export default Marketo;
