import React, { useState } from "react";

import styles from "./MarketingSentiments.module.scss";
import TextArea from "../../../../componenets/MarketingBriefFormCard/components/TextArea/TextArea";
import { TextElement } from "../../../../componenets";
import Input from "../../../../componenets/MarketingBriefFormCard/components/Input/Input";
import { PlusIcon } from "../../../../icons";
import { AiOutlineMinus } from "react-icons/ai";
import { TiMinus } from "react-icons/ti";
interface MarketingSentimentsProps {
  type: "Marketing Sentiments" | "Marketing Sentiments Generation";
}

const MarketingSentiments: React.FC<MarketingSentimentsProps> = (props) => {
  const { type } = props;
  const [relevantHashtags, setRelevantHashtags] = useState<
    { id: number; socialNetworkURI: string; tags: string }[]
  >([]);

  const [pgRagQuestions, setPgRagQuestions] = useState<
    {
      id: number;
      question: string;
    }[]
  >([]);

  const [promts, setPromts] = useState<
    {
      id: number;
      prompt: string;
    }[]
  >([]);

  return type === "Marketing Sentiments" ? (
    <div className={styles["container"]}>
      <TextElement className={styles["header-text"]}>
        Information sources
      </TextElement>
      <div className={styles["equal-columns"]}>
        <TextArea
          label="News Websites*"
          value=""
          placeholder="list of news websites uri separated by a comma"
          onChange={() => {}}
        />
        <TextArea
          label="Reports Websites*"
          value=""
          placeholder="list of reports websites uri separated by a comma"
          onChange={() => {}}
        />
        <TextArea
          label="Opinion Leaders Social Network Accounts*"
          value=""
          placeholder="List of Opinion Leaders Social Networks Accounts separated by a comma"
          onChange={() => {}}
        />
      </div>
      <TextElement className={styles["sub-header-text"]}>
        Relevant Hashtags
      </TextElement>
      {relevantHashtags.map((hashtag) => (
        <div className={styles["hashtag-input-row"]} key={hashtag.id}>
          <Input
            className={styles["hashtag-input"]}
            label="Social Network URI*"
            value={hashtag.socialNetworkURI}
            onChange={() => {}}
            placeholder="social network uri"
          />
          <Input
            className={styles["hashtag-input"]}
            label="List of Tags*"
            value={hashtag.tags}
            onChange={() => {}}
            placeholder="hashtags separated by a comma"
          />
          {/* <span
            className={styles["remove-icon"]}
            onClick={() => {
              setRelevantHashtags(
                relevantHashtags.slice(0, relevantHashtags.length - 1)
              );
            }}
          >
            <svg
              width="20"
              height="4"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 3.42855H11.4286H8.57143H0V0.571411H8.57143H11.4286H20V3.42855Z"
                fill="white"
              />
            </svg>
          </span> */}
        </div>
      ))}
      <div className={styles["hashtag-input-row"]}>
        <Input
          className={styles["hashtag-input"]}
          label="Social Network URI*"
          value=""
          onChange={() => {}}
          placeholder="social network uri"
        />
        <Input
          className={styles["hashtag-input"]}
          label="List of Tags*"
          value=""
          placeholder="hashtags separated by a comma"
          onChange={() => {}}
        />
        <span
          className={styles["add-icon"]}
          onClick={() => {
            setRelevantHashtags([
              ...relevantHashtags,
              {
                socialNetworkURI: "",
                tags: "",
                id: relevantHashtags.length + 1,
              },
            ]);
          }}
        >
          <PlusIcon />
        </span>
        <span
          className={styles["remove-icon"]}
          onClick={() => {
            setRelevantHashtags(
              relevantHashtags.slice(0, relevantHashtags.length - 1)
            );
          }}
        >
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 3.42855H11.4286H8.57143H0V0.571411H8.57143H11.4286H20V3.42855Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </div>
  ) : (
    <div className={styles["container"]}>
      <TextElement className={styles["header-text"]}>LLM Model</TextElement>
      <div className={styles["unequal-columns"]}>
        <Input
          label="LLM Model*"
          value=""
          onChange={() => {}}
          placeholder="Example: Llama 3 8b, Gemma 2 9b"
        />
        <Input
          label="Temperature*"
          value=""
          onChange={() => {}}
          placeholder="Temperature"
        />
        <Input
          label="Frequency Penalty*"
          value=""
          onChange={() => {}}
          placeholder="Frequency Penalty"
        />
        <Input
          label="Embedding Model*"
          value=""
          onChange={() => {}}
          placeholder="Example: BAAI/bge-base-en-v1.5"
        />
      </div>
      <TextElement className={styles["header-text"]}>Metrics</TextElement>
      <div className={styles["equal-columns"]}>
        <Input
          label="Rouge metric exact name*"
          value=""
          onChange={() => {}}
          placeholder="model metric exact name"
        />
        <Input
          label="Rouge threshold*"
          value=""
          onChange={() => {}}
          placeholder="rouge threshold"
        />
        <Input
          label="BERT metric exact name*"
          value=""
          onChange={() => {}}
          placeholder="model metric exact name"
        />
        <Input
          label="BERT threshold*"
          value=""
          onChange={() => {}}
          placeholder="bert threshold"
        />
      </div>
      <TextElement className={styles["header-text"]}>
        Prompt Templates
      </TextElement>
      <div className={styles["equal-columns"]}>
        <TextArea
          label="Title extraction*"
          value=""
          onChange={() => {}}
          placeholder="Title extraction"
        />
        <TextArea
          label="Facts extractions*"
          value=""
          onChange={() => {}}
          placeholder="Facts extractions"
        />
      </div>
      <div className={styles["equal-columns"]}>
        <TextArea
          label="Mind map generation*"
          value=""
          onChange={() => {}}
          placeholder="Mind map generation"
        />
        <TextArea
          label="Query answering*"
          value=""
          onChange={() => {}}
          placeholder="Query answering"
        />
      </div>
      <TextElement className={styles["header-text"]}>
        Neo database connection
      </TextElement>
      <div className={styles["unequal-columns"]}>
        <Input
          label="URI*"
          value=""
          onChange={() => {}}
          placeholder="database connection uri"
        />
        <Input
          label="Login*"
          value=""
          onChange={() => {}}
          placeholder="login"
        />
        <Input
          label="Password*"
          value=""
          onChange={() => {}}
          placeholder="password"
        />
      </div>
      <TextElement className={styles["header-text"]}>
        Report Generation
      </TextElement>
      <div className={styles["unequal-columns"]}>
        <Input
          label="Top facts Count*"
          value=""
          onChange={() => {}}
          placeholder="top facts count"
        />
      </div>
      {pgRagQuestions.map((entry) => (
        <div className={styles["one-dynamic-input-row"]} key={entry.id}>
          <Input
            className={styles["hashtag-input"]}
            label="Questions for facts extractions with PG-RAG*"
            value={entry.question}
            onChange={() => {}}
            placeholder="one line questions"
          />

          <span
            className={styles["remove-icon"]}
            onClick={() => {
              setPgRagQuestions(
                pgRagQuestions.filter((question) => question.id !== entry.id)
              );
            }}
          >
            <TiMinus fill="#fff" />
          </span>
        </div>
      ))}
      <div className={styles["one-dynamic-input-row"]}>
        <Input
          className={styles["hashtag-input"]}
          label="Questions for facts extractions with PG-RAG*"
          value=""
          onChange={() => {}}
          placeholder="one line questions"
        />
        <span
          className={styles["add-icon"]}
          onClick={() => {
            setPgRagQuestions([
              ...pgRagQuestions,
              {
                question: "",
                id: pgRagQuestions.length + 1,
              },
            ]);
          }}
        >
          <PlusIcon />
        </span>
      </div>
      {promts.map((entry) => (
        <div className={styles["one-dynamic-input-row"]} key={entry.id}>
          <Input
            className={styles["hashtag-input"]}
            label="Prompt for Report Generation*"
            value={entry.prompt}
            onChange={() => {}}
            placeholder="prompt for report generation"
          />

          <span
            className={styles["remove-icon"]}
            onClick={() => {
              setPromts(promts.filter((prompt) => prompt.id !== entry.id));
            }}
          >
            <TiMinus fill="#fff" />
          </span>
        </div>
      ))}
      <div className={styles["one-dynamic-input-row"]}>
        <Input
          className={styles["hashtag-input"]}
          label="Prompt for Report Generation*"
          value=""
          onChange={() => {}}
          placeholder="prompt for report generation"
        />
        <span
          className={styles["add-icon"]}
          onClick={() => {
            setPromts([
              ...promts,
              {
                prompt: "",
                id: promts.length + 1,
              },
            ]);
          }}
        >
          <PlusIcon />
        </span>
      </div>
    </div>
  );
};

export default MarketingSentiments;
