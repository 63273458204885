import React from "react";

const DriveConsumptionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="122"
      height="135"
      viewBox="0 0 122 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.25">
        <path
          d="M55.8762 135.001C78.5087 135.001 96.856 133.59 96.856 131.85C96.856 130.111 78.5087 128.7 55.8762 128.7C33.2436 128.7 14.8963 130.111 14.8963 131.85C14.8963 133.59 33.2436 135.001 55.8762 135.001Z"
          fill="#EB2701"
        />
      </g>
      <path
        d="M100.542 72.1532C100.307 71.9574 100.089 71.7659 99.8995 71.5872C98.743 70.508 89.435 61.0835 85.2783 58.4701C83.6638 57.4556 78.2078 55.5392 77.8993 56.5415C77.5909 57.5438 81.4631 73.3115 81.4631 73.3115L90.3019 82.8521L90.3783 82.7936L90.3019 82.7266C90.3019 82.7266 96.4093 78.2653 100.542 72.1532Z"
        fill="#02285E"
      />
      <path
        d="M100.65 71.9941C100.615 72.0474 100.578 72.0998 100.542 72.1531C100.578 72.0998 100.615 72.0474 100.65 71.9941Z"
        fill="#3E4049"
      />
      <path
        d="M106.915 79.195C105.964 78.7583 105.217 79.1049 105.217 79.1049C105.217 79.1049 106.49 77.407 105.988 76.1369C105.485 74.8667 103.328 74.287 103.328 74.287C103.328 74.287 101.738 73.1551 100.54 72.1523C96.6767 77.8688 91.0881 82.249 90.3759 82.7951C91.2216 83.5342 98.952 90.2576 100.591 90.476C102.326 90.7071 102.21 88.1248 102.21 88.1248C103.945 88.0861 104.484 85.8137 104.484 85.8137C106.607 85.8911 107.183 83.5399 107.183 83.5399C108.725 83.2701 109.069 81.458 109.069 81.3425C109.069 81.2269 107.502 79.4648 106.915 79.195Z"
        fill="#EDBC9A"
      />
      <path
        d="M100.542 72.1523C96.4094 78.2645 90.3015 82.7272 90.3015 82.7272L90.3779 82.7942C91.0901 82.249 96.6787 77.8692 100.542 72.1523Z"
        fill="#3E4049"
      />
      <g opacity="0.25">
        <path
          d="M100.285 85.0669C100.285 85.0669 101.197 86.2621 102.174 86.596C103.151 86.9299 103.831 85.6966 103.831 85.6966C103.831 85.6966 103.532 85.4254 103.553 85.3362C103.574 85.2471 102.322 84.3707 102.254 84.3099C102.186 84.2491 102.065 84.0363 102.058 83.7873C102.05 83.5383 102.096 83.0006 102.096 83.0006C102.096 83.0006 103.459 84.0778 104.396 84.2575C105.334 84.4372 106.365 83.1911 106.365 83.1911C106.365 83.1911 104.755 81.9814 104.589 81.9648C104.422 81.9483 104.259 82.0229 104.191 81.7927C104.123 81.5625 103.908 81.1503 103.908 81.0989C103.908 81.0475 104.234 80.4971 104.234 80.4971C104.234 80.4971 106.259 82.2681 107.184 81.9592C108.11 81.6503 108.458 80.6357 108.458 80.6357C108.616 80.7014 108.754 80.8091 108.856 80.9473C108.958 81.0854 109.02 81.2487 109.036 81.4196C109.1 82.0233 108.635 82.7402 108.187 83.0496C107.738 83.359 107.107 83.5765 107.107 83.5765C107.107 83.5765 107.095 84.5938 106.08 85.2494C105.065 85.905 104.486 86.0328 104.486 86.0328C104.486 86.0328 104.181 87.1488 103.467 87.6124C102.752 88.076 101.968 87.9732 101.968 87.9732C101.968 87.9732 100.619 87.2115 100.285 86.3696C99.951 85.5277 100.285 85.0669 100.285 85.0669Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M93.6591 89.9434C92.4564 91.1857 91.7254 91.9167 91.7254 91.9167C81.1132 103.833 67.5443 103.84 65.0771 103.429C62.6099 103.018 58.7098 102.35 56.6482 103.371C54.5866 104.393 54.5281 107.168 56.0124 107.688C57.4967 108.209 57.766 107.149 57.1883 106.397C56.6105 105.645 56.7638 104.759 58.6721 104.393C61.9868 104.046 63.2499 104.894 67.6037 105.202C80.6402 105.742 88.7955 99.8061 95.4208 93.2529C95.6745 93.0015 95.9396 92.7341 96.2188 92.4511C94.9086 92.2148 93.6591 89.9434 93.6591 89.9434Z"
        fill="#A54E2B"
      />
      <path
        d="M110.21 74.9885C109.444 74.1612 108.324 73.8363 108.152 73.79C103.089 80.0569 96.7791 86.7194 93.6591 89.9417C93.6591 89.9417 94.9085 92.2151 96.2216 92.4509C99.9896 88.6254 105.789 82.1944 110.705 75.713C110.696 75.6941 110.685 75.6776 110.676 75.6587L110.668 75.6691C110.549 75.421 110.395 75.1917 110.21 74.9885Z"
        fill="#97979E"
      />
      <path
        d="M119.076 35.3943C119.847 35.2014 119.847 35.5679 120.753 35.7603C121.658 35.9528 122.198 35.0283 121.292 34.1223C120.386 33.2163 117.534 33.4285 117.052 35.5873C116.436 38.5554 117.785 40.367 118.761 45.9688C119.738 51.5706 119.249 57.5582 113.082 67.1176C111.77 69.1514 110.048 71.4431 108.152 73.7896C108.326 73.8334 109.452 74.1433 110.21 74.9885C110.396 75.1887 110.553 75.4144 110.676 75.6587C114.809 70.2083 118.342 64.7551 119.583 60.8729C122.41 52.0333 121.356 47.4078 119.814 42.7823C118.273 38.1568 118.305 35.5873 119.076 35.3943Z"
        fill="#A54E2B"
      />
      <path
        d="M110.21 74.9893C110.395 75.1925 110.549 75.4218 110.668 75.6698L110.676 75.6595C110.553 75.4151 110.396 75.1894 110.21 74.9893Z"
        fill="#B2856F"
      />
      <g opacity="0.25">
        <path
          d="M64.6432 104.713C64.6432 104.713 73.1957 104.918 81.3566 101.577C89.5176 98.2368 98.8459 88.1123 104.486 81.2493C110.127 74.3863 121.499 61.2985 121.312 49.7256C121.312 49.7256 122.625 59.7246 112.701 72.9153C102.777 86.1059 94.7614 93.8971 94.7614 93.8971C94.7614 93.8971 87.7692 101.212 79.2639 103.758C70.7586 106.303 64.6432 104.713 64.6432 104.713Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M62.6382 104.73C62.5493 104.73 62.4628 104.701 62.3914 104.648C62.3199 104.595 62.2673 104.521 62.2411 104.436L62.0104 103.688L60.7304 104.646C60.6855 104.68 60.6342 104.704 60.5798 104.717C60.5253 104.731 60.4687 104.733 60.4134 104.724C60.3581 104.715 60.3052 104.694 60.2579 104.664C60.2105 104.634 60.1698 104.595 60.138 104.549L59.1225 103.067C59.07 102.991 59.0446 102.899 59.0505 102.806C59.0564 102.713 59.0932 102.625 59.1551 102.556L116.66 37.8456C116.699 37.8014 116.747 37.7659 116.8 37.7415C116.854 37.7171 116.912 37.7044 116.971 37.7041H118.651C118.732 37.7042 118.811 37.7278 118.878 37.7719C118.946 37.8161 118.999 37.879 119.031 37.9528C119.064 38.0267 119.074 38.1085 119.061 38.1881C119.048 38.2677 119.012 38.3417 118.957 38.4012L117.899 39.553L119.139 40.41C119.186 40.4401 119.227 40.4793 119.258 40.5254C119.29 40.5714 119.311 40.6233 119.322 40.678C119.333 40.7327 119.333 40.789 119.322 40.8437C119.311 40.8983 119.289 40.9502 119.257 40.9961C119.226 41.0421 119.185 41.0812 119.138 41.1111C119.091 41.1411 119.038 41.1613 118.983 41.1705C118.928 41.1797 118.872 41.1777 118.818 41.1647C118.763 41.1517 118.712 41.1279 118.668 41.0948L117.034 39.9704C116.985 39.9366 116.944 39.8927 116.913 39.8416C116.883 39.7906 116.864 39.7336 116.857 39.6744C116.85 39.6153 116.856 39.5554 116.875 39.4988C116.893 39.4422 116.924 39.3902 116.964 39.3464L117.705 38.5399H117.158L59.9941 102.867L60.5804 103.722L61.9864 102.669C62.0397 102.629 62.1019 102.603 62.1677 102.592C62.2335 102.581 62.3009 102.586 62.3643 102.607C62.4278 102.627 62.4853 102.663 62.5321 102.71C62.5788 102.758 62.6134 102.816 62.633 102.88L63.0377 104.193C63.0698 104.298 63.0589 104.412 63.0074 104.509C62.9558 104.606 62.8679 104.679 62.7627 104.712C62.7223 104.724 62.6804 104.73 62.6382 104.73Z"
        fill="#232328"
      />
      <path
        d="M47.5426 75.4639L48.1661 72.1826L52.0879 79.8564L43.8986 76.885L47.0676 75.9568C47.0676 75.9568 47.4921 75.7158 47.5426 75.4639Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M51.8624 79.4182L45.1371 76.5029L44.0556 76.8354L44.0712 76.9939L51.8893 79.6932L51.8624 79.4182Z"
          fill="white"
        />
      </g>
      <path
        d="M12.4256 45.7827L8.4732 45.5605L-1.52588e-05 38.6084L5.38766 39.6531L12.4256 45.7827Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M9.40665 45.5352C9.40665 45.5352 3.43319 40.7182 3.35301 40.7069L2.63468 40.6055C2.63468 40.6055 2.87617 40.9686 2.91154 40.9974C2.94691 41.0262 8.52843 45.5621 8.52843 45.5621L9.40665 45.5352Z"
          fill="white"
        />
      </g>
      <path
        d="M13.0384 45.1158L12.155 41.4728L4.24402 33.9287L6.05091 38.9707L13.0384 45.1158Z"
        fill="#A7A7B7"
      />
      <path
        d="M6.05095 38.9707L47.5426 75.4638C47.5426 75.4638 47.4586 75.9948 47.0676 75.9552L5.38828 39.6527C5.38828 39.6527 5.51846 39.1216 6.05095 38.9707Z"
        fill="#B2856F"
      />
      <path
        d="M11.7936 44.2621C11.6356 43.8287 11.4993 43.3877 11.3692 42.9453C11.2673 42.5411 11.0621 42.0076 11.0781 41.5822C11.0784 41.5785 11.0797 41.5749 11.082 41.5719C11.0842 41.5689 11.0873 41.5667 11.0909 41.5655C11.0944 41.5643 11.0982 41.5641 11.1018 41.5651C11.1054 41.5661 11.1087 41.5681 11.1112 41.5709C11.3838 41.8954 11.5503 42.4453 11.7168 42.8264C11.8847 43.2561 12.0469 43.6881 12.1884 44.1277C12.2695 44.3814 11.887 44.513 11.7951 44.2621H11.7936Z"
        fill="#76767C"
      />
      <path
        d="M9.52026 38.8869C9.55941 38.8548 9.53865 38.7732 9.55233 38.8397C9.5844 38.984 9.66411 39.2642 9.7042 39.4099C9.79853 39.7462 9.98295 40.4259 10.0702 40.764C10.1375 40.9938 10.1713 41.2321 10.1707 41.4715C10.1704 41.4752 10.169 41.4787 10.1667 41.4816C10.1644 41.4846 10.1613 41.4867 10.1578 41.4879C10.1542 41.489 10.1504 41.4891 10.1469 41.4882C10.1433 41.4872 10.1401 41.4852 10.1376 41.4824C9.99298 41.2913 9.87772 41.0797 9.7957 40.8546C9.57118 40.3095 9.3744 39.7533 9.20614 39.1883C9.1901 39.1208 9.14765 38.9821 9.13916 38.9128C9.08209 38.7218 9.22972 38.5025 9.43772 38.6072C9.46181 38.6203 9.48307 38.638 9.50028 38.6593C9.51748 38.6806 9.53029 38.7052 9.53797 38.7315C9.54564 38.7578 9.54803 38.7854 9.54499 38.8126C9.54195 38.8398 9.53354 38.8662 9.52026 38.8902V38.8869Z"
        fill="#76767C"
      />
      <path
        d="M8.82554 41.6516C8.58311 40.9795 8.3638 40.2164 8.17231 39.524C8.09886 39.2829 8.05949 39.0327 8.05534 38.7807C8.05542 38.7769 8.05665 38.7733 8.05886 38.7703C8.06107 38.7672 8.06414 38.7649 8.06768 38.7637C8.07122 38.7625 8.07505 38.7624 8.07866 38.7634C8.08227 38.7644 8.08549 38.7665 8.08788 38.7693C8.37087 39.1221 8.5468 39.7126 8.72084 40.1211C8.8977 40.5828 9.06939 41.0469 9.21937 41.5181C9.30002 41.7728 8.9161 41.9035 8.82554 41.6516Z"
        fill="#76767C"
      />
      <path
        d="M6.05884 35.4029C6.26448 35.9505 6.44843 36.5056 6.62483 37.0622C6.76868 37.5659 7.03327 38.2413 7.05922 38.7691C7.05955 38.773 7.05858 38.777 7.05645 38.7803C7.05433 38.7836 7.05117 38.7861 7.04746 38.7875C7.04376 38.7888 7.03971 38.7889 7.03595 38.7877C7.03219 38.7865 7.02893 38.7841 7.02667 38.7809C6.85773 38.5374 6.71825 38.2748 6.61115 37.9984C6.28665 37.2122 5.93669 36.3443 5.66502 35.5458C5.57917 35.2926 5.96168 35.1544 6.05649 35.4043L6.05884 35.4029Z"
        fill="#76767C"
      />
      <path
        d="M11.3889 45.1103C10.923 45.0551 10.4607 44.9778 9.99899 44.8948C9.58535 44.8033 9.00852 44.7453 8.6279 44.5387C8.62471 44.5368 8.62216 44.534 8.62055 44.5307C8.61894 44.5273 8.61835 44.5236 8.61885 44.5199C8.61935 44.5162 8.62093 44.5128 8.62338 44.51C8.62582 44.5072 8.62904 44.5052 8.63262 44.5043C9.05144 44.4076 9.62733 44.5076 10.0476 44.5311C10.5145 44.5755 10.981 44.6255 11.4441 44.6981C11.4988 44.7056 11.5482 44.7345 11.5816 44.7785C11.6149 44.8224 11.6294 44.8778 11.6219 44.9325C11.6144 44.9872 11.5855 45.0366 11.5416 45.07C11.4976 45.1033 11.4422 45.1178 11.3875 45.1103H11.3889Z"
        fill="#76767C"
      />
      <path
        d="M5.75902 43.1134C6.20473 43.1347 6.64903 43.1785 7.09238 43.2281C7.49187 43.2894 8.0437 43.3059 8.4163 43.4846C8.41973 43.4862 8.42261 43.4887 8.42454 43.492C8.42648 43.4952 8.42737 43.4989 8.42712 43.5027C8.42686 43.5064 8.42546 43.51 8.42311 43.5129C8.42075 43.5159 8.41755 43.518 8.41394 43.5191C8.02295 43.6455 7.47064 43.5879 7.06785 43.5945C6.62167 43.5842 6.17549 43.5676 5.73072 43.5285C5.46424 43.5016 5.49065 43.104 5.75902 43.1134Z"
        fill="#76767C"
      />
      <path
        d="M8.52955 42.618C7.82207 42.5402 7.04007 42.402 6.33496 42.2694C6.12366 42.2175 5.76897 42.1709 5.60909 42.0237C5.85812 41.9487 6.1246 41.9718 6.37222 41.9831C7.08865 42.035 7.87631 42.0996 8.58285 42.2058C8.84744 42.2487 8.79745 42.6439 8.52955 42.618Z"
        fill="#76767C"
      />
      <path
        d="M2.42055 40.3648C3.21858 40.4629 4.0968 40.6242 4.89059 40.7771C5.15424 40.8431 5.49005 40.8789 5.70796 41.0435C5.4278 41.1138 5.12877 41.0803 4.8505 41.0629C4.04397 40.9902 3.15774 40.903 2.36254 40.7766C2.09794 40.7294 2.15265 40.3356 2.42055 40.3648Z"
        fill="#76767C"
      />
      <g opacity="0.25">
        <path
          d="M12.3709 42.3636L6.47529 37.1797L7.20682 39.9874L6.05222 38.9719C6.05222 38.9719 4.11137 33.8041 4.24485 33.93C4.37833 34.0559 12.1733 41.55 12.1733 41.55L12.3709 42.3636Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M11.231 45.6437L5.11977 40.416L1.16969 39.5689L0.0938568 38.6279L5.38767 39.6542L12.1285 45.6178L12.0441 45.7626L11.231 45.6437Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M50.8441 78.5253L48.4326 73.8848C48.4326 73.8848 48.2736 75.9657 48.2774 76.0039C48.2812 76.0421 50.8441 78.5253 50.8441 78.5253Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M52.0854 80.1657C52.0492 80.1657 52.0133 80.1594 51.9793 80.1469L43.7896 77.1755C43.7277 77.1531 43.6745 77.1118 43.6376 77.0574C43.6007 77.003 43.5819 76.9383 43.5839 76.8726C43.586 76.8069 43.6087 76.7435 43.649 76.6915C43.6892 76.6395 43.7449 76.6015 43.808 76.5831L46.9411 75.6657C47.0755 75.5846 47.2156 75.4601 47.2345 75.403L47.8571 72.1236C47.869 72.0596 47.9008 72.0009 47.9478 71.9559C47.9948 71.9108 48.0547 71.8816 48.1192 71.8723C48.1837 71.863 48.2494 71.8742 48.3072 71.9042C48.365 71.9342 48.4119 71.9816 48.4415 72.0397L52.3632 79.7134C52.3875 79.761 52.3992 79.814 52.3972 79.8673C52.3952 79.9207 52.3794 79.9726 52.3516 80.0182C52.3237 80.0637 52.2846 80.1013 52.238 80.1274C52.1914 80.1535 52.1388 80.1672 52.0854 80.1671V80.1657ZM44.8975 76.9113L51.4473 79.2889L48.3 73.1311L47.8467 75.5181C47.7764 75.8864 47.3467 76.1515 47.217 76.2256C47.1967 76.2368 47.1752 76.2458 47.1529 76.2524L44.8975 76.9113Z"
        fill="#0F0F14"
      />
      <path
        d="M51.1493 68.2309L51.9577 64.9902L55.4376 72.8743L47.4299 69.4412L50.647 68.6946C50.647 68.6946 51.0866 68.4838 51.1493 68.2309Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M55.2392 72.4244L48.6904 69.1318L47.5919 69.4021L47.5985 69.5615L55.2506 72.7003L55.2392 72.4244Z"
          fill="white"
        />
      </g>
      <path
        d="M17.7761 36.6064L13.843 36.1602L5.77773 28.7383L11.0979 30.0872L17.7761 36.6064Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M14.776 36.186C14.776 36.186 9.08599 31.0374 9.00864 31.0214L8.29739 30.8799C8.29739 30.8799 8.51812 31.2572 8.55161 31.2874C8.58509 31.3176 13.8973 36.1647 13.8973 36.1647L14.776 36.186Z"
          fill="white"
        />
      </g>
      <path
        d="M18.4257 35.9758L17.7508 32.2889L10.2808 24.3076L11.7985 29.4439L18.4257 35.9758Z"
        fill="#A7A7B7"
      />
      <path
        d="M11.7985 29.4434L51.1493 68.231C51.1493 68.231 51.0351 68.7564 50.647 68.6946L11.0981 30.0867C11.0981 30.0867 11.257 29.5636 11.7985 29.4434Z"
        fill="#B2856F"
      />
      <path
        d="M17.2319 35.0518C17.0984 34.6099 16.9871 34.1618 16.8819 33.7128C16.8027 33.3029 16.6282 32.7591 16.6687 32.3351C16.6691 32.3314 16.6705 32.3279 16.6729 32.325C16.6752 32.3222 16.6784 32.3201 16.682 32.3191C16.6856 32.318 16.6894 32.3181 16.6929 32.3193C16.6964 32.3205 16.6995 32.3227 16.7017 32.3257C16.9555 32.6653 17.0909 33.2218 17.2352 33.6138C17.3767 34.0524 17.5182 34.4929 17.6323 34.9396C17.696 35.1999 17.313 35.3079 17.2319 35.0518Z"
        fill="#76767C"
      />
      <path
        d="M15.2669 29.5563C15.308 29.5261 15.2914 29.4436 15.3018 29.5091C15.3259 29.6549 15.3895 29.9397 15.4211 30.0869C15.4966 30.4284 15.6414 31.117 15.7093 31.4599C15.7636 31.6928 15.784 31.9324 15.7697 32.1711C15.7694 32.1749 15.7679 32.1785 15.7655 32.1814C15.7631 32.1843 15.7598 32.1864 15.7561 32.1874C15.7524 32.1884 15.7486 32.1882 15.745 32.1869C15.7414 32.1856 15.7384 32.1832 15.7362 32.1801C15.6027 31.9812 15.4995 31.7636 15.4301 31.5344C15.237 30.9771 15.0722 30.4104 14.9363 29.8364C14.924 29.7685 14.8891 29.6275 14.8854 29.5577C14.8382 29.3639 14.9985 29.1535 15.2004 29.2695C15.2236 29.2839 15.2437 29.3028 15.2596 29.325C15.2755 29.3472 15.2869 29.3723 15.2931 29.3989C15.2993 29.4255 15.3001 29.453 15.2956 29.4799C15.2912 29.5069 15.2814 29.5327 15.2669 29.5558V29.5563Z"
        fill="#76767C"
      />
      <path
        d="M14.4166 32.2767C14.2123 31.5918 14.0369 30.8174 13.885 30.1151C13.8511 29.8996 13.7563 29.5538 13.8298 29.3496C13.9959 29.5501 14.0779 29.8057 14.1638 30.0382C14.3939 30.7202 14.6401 31.4725 14.817 32.1658C14.8802 32.4267 14.4963 32.5337 14.4166 32.2767Z"
        fill="#76767C"
      />
      <path
        d="M12.0089 25.8817C12.1829 26.4397 12.3352 27.0042 12.4805 27.5702C12.5956 28.081 12.8215 28.771 12.8177 29.2993C12.8176 29.3031 12.8163 29.3069 12.8139 29.3099C12.8115 29.313 12.8083 29.3152 12.8046 29.3163C12.8009 29.3174 12.7969 29.3173 12.7933 29.316C12.7896 29.3148 12.7865 29.3124 12.7842 29.3092C12.6295 29.0564 12.5052 28.7861 12.414 28.5041C12.1348 27.7023 11.8348 26.8146 11.6089 25.9991C11.5405 25.7393 11.9235 25.6251 12.0074 25.8817H12.0089Z"
        fill="#76767C"
      />
      <path
        d="M16.7796 35.8758C16.3174 35.7943 15.8599 35.691 15.4038 35.5815C14.9987 35.4679 14.4223 35.3759 14.0554 35.1481C14.0521 35.1461 14.0495 35.1433 14.048 35.1398C14.0465 35.1363 14.046 35.1325 14.0467 35.1288C14.0475 35.1251 14.0493 35.1217 14.052 35.1191C14.0548 35.1164 14.0582 35.1147 14.062 35.1141C14.4864 35.0415 15.0553 35.1736 15.4746 35.2212C15.9382 35.2919 16.4009 35.3688 16.8593 35.4674C16.9135 35.478 16.9612 35.5096 16.9921 35.5554C17.0229 35.6012 17.0343 35.6573 17.0237 35.7115C17.0131 35.7656 16.9815 35.8134 16.9357 35.8442C16.8899 35.875 16.8338 35.8864 16.7796 35.8758Z"
        fill="#76767C"
      />
      <path
        d="M11.2725 33.5635C11.7163 33.6107 12.1573 33.6791 12.5973 33.7522C12.9926 33.8361 13.543 33.8838 13.9048 34.0823C13.908 34.0842 13.9105 34.087 13.9122 34.0903C13.9138 34.0936 13.9144 34.0973 13.914 34.101C13.9135 34.1046 13.912 34.1081 13.9096 34.1109C13.9072 34.1137 13.9041 34.1157 13.9005 34.1168C13.5024 34.221 12.9539 34.1319 12.5516 34.1168C12.1068 34.0809 11.6625 34.0389 11.2211 33.9753C10.9565 33.9328 11.005 33.5385 11.2725 33.5626V33.5635Z"
        fill="#76767C"
      />
      <path
        d="M14.0658 33.2253C13.3621 33.1074 12.591 32.9249 11.8962 32.7537C11.6675 32.682 11.3623 32.6343 11.1854 32.4674C11.4382 32.4065 11.7043 32.4447 11.9495 32.4674C12.6617 32.5617 13.4446 32.6692 14.1441 32.815C14.4059 32.873 14.3328 33.2649 14.0672 33.2239L14.0658 33.2253Z"
        fill="#76767C"
      />
      <path
        d="M8.09518 30.6285C8.88614 30.77 9.75398 30.9827 10.5379 31.1803C10.7968 31.2614 11.1303 31.3162 11.3397 31.4926C11.0567 31.5473 10.7595 31.4968 10.4827 31.4638C9.68087 31.3454 8.80219 31.2077 8.01547 31.0365C7.75371 30.9761 7.83059 30.5842 8.0966 30.6285H8.09518Z"
        fill="#76767C"
      />
      <g opacity="0.25">
        <path
          d="M17.9155 33.1888L12.3222 27.6781L12.8934 30.5226L11.7982 29.4435C11.7982 29.4435 10.1555 24.1737 10.2819 24.3072C10.4083 24.4407 17.7646 32.3653 17.7646 32.3653L17.9155 33.1888Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M16.5914 36.3977L10.7873 30.8313L6.89096 29.7616L5.87031 28.7607L11.0981 30.0861L17.4894 36.4227L17.3965 36.5623L16.5914 36.3977Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M54.2737 71.4763L52.1295 66.7061C52.1295 66.7061 51.8527 68.7747 51.8541 68.8134C51.8555 68.8521 54.2737 71.4763 54.2737 71.4763Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M55.4374 73.186C55.3951 73.1861 55.3532 73.1776 55.3143 73.1611L47.3066 69.7279C47.2464 69.7018 47.1959 69.6574 47.1623 69.601C47.1288 69.5445 47.1139 69.479 47.1197 69.4136C47.1256 69.3482 47.1519 69.2863 47.1949 69.2367C47.238 69.1871 47.2955 69.1524 47.3595 69.1374L50.5393 68.3993C50.6775 68.3262 50.8223 68.2106 50.8468 68.1535L51.6548 64.9147C51.6704 64.8515 51.7054 64.7948 51.7549 64.7525C51.8044 64.7102 51.8659 64.6844 51.9308 64.6789C51.9957 64.6733 52.0606 64.6882 52.1166 64.7214C52.1726 64.7547 52.2168 64.8046 52.2429 64.8642L55.7228 72.7484C55.7436 72.7956 55.7524 72.8474 55.7484 72.8989C55.7443 72.9504 55.7275 73.0002 55.6994 73.0436C55.6713 73.087 55.6329 73.1227 55.5876 73.1476C55.5423 73.1725 55.4915 73.1857 55.4398 73.186H55.4374ZM48.4277 69.5298L54.8323 72.2753L52.0396 65.949L51.4515 68.3073C51.3609 68.6714 50.9166 68.9115 50.7832 68.9766C50.7619 68.9864 50.7398 68.9941 50.7171 68.9997L48.4277 69.5298Z"
        fill="#0F0F14"
      />
      <path
        d="M55.0224 77.855L55.8309 74.6143L59.3107 82.4984L51.3035 79.0652L54.5201 78.3191C54.5201 78.3191 54.9597 78.1049 55.0224 77.855Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M59.1131 82.0499L52.5637 78.7578L51.4652 79.0281L51.4718 79.1875L59.1239 82.3263L59.1131 82.0499Z"
          fill="white"
        />
      </g>
      <path
        d="M21.6494 46.2309L17.7163 45.7842L9.65153 38.3623L14.9713 39.7112L21.6494 46.2309Z"
        fill="#A7A7B7"
      />
      <g opacity="0.4">
        <path
          d="M18.6491 45.8115C18.6491 45.8115 12.9591 40.6629 12.8799 40.6474L12.1681 40.5059C12.1681 40.5059 12.3889 40.8832 12.4224 40.9129L17.7709 45.7907L18.6491 45.8115Z"
          fill="white"
        />
      </g>
      <path
        d="M22.2989 45.6003L21.624 41.9129L14.154 33.9316L15.6717 39.0679L22.2989 45.6003Z"
        fill="#A7A7B7"
      />
      <path
        d="M15.6717 39.0684L55.0225 77.856C55.0225 77.856 54.9083 78.3814 54.5202 78.3201L14.9713 39.7136C14.9713 39.7136 15.1302 39.1886 15.6717 39.0684Z"
        fill="#B2856F"
      />
      <path
        d="M21.105 44.6771C20.972 44.2352 20.8607 43.7876 20.755 43.3381C20.6758 42.9287 20.5017 42.3844 20.5418 41.9609C20.5423 41.9572 20.5438 41.9537 20.5462 41.9509C20.5486 41.9481 20.5518 41.9461 20.5554 41.945C20.559 41.944 20.5628 41.9441 20.5663 41.9452C20.5699 41.9464 20.573 41.9485 20.5753 41.9515C20.8286 42.2906 20.9639 42.8476 21.1083 43.2391C21.2498 43.6777 21.3913 44.1182 21.5054 44.5649C21.5691 44.8252 21.1861 44.9332 21.105 44.6771Z"
        fill="#76767C"
      />
      <path
        d="M19.1401 39.1813C19.1816 39.1516 19.1651 39.0681 19.175 39.1341C19.199 39.2799 19.2627 39.5647 19.2943 39.7124C19.3698 40.0534 19.5151 40.7424 19.583 41.0849C19.6369 41.318 19.657 41.5577 19.6429 41.7966C19.6425 41.8003 19.641 41.8037 19.6386 41.8065C19.6362 41.8093 19.633 41.8113 19.6294 41.8123C19.6259 41.8133 19.6221 41.8131 19.6186 41.8119C19.6151 41.8107 19.6121 41.8085 19.6099 41.8055C19.4759 41.6068 19.3727 41.389 19.3038 41.1594C19.1103 40.6022 18.9453 40.0354 18.8095 39.4614C18.7972 39.3935 18.7623 39.2525 18.7585 39.1832C18.7114 38.9888 18.8722 38.7785 19.0741 38.8945C19.1208 38.9239 19.1539 38.9705 19.1663 39.0243C19.1787 39.078 19.1693 39.1345 19.1401 39.1813Z"
        fill="#76767C"
      />
      <path
        d="M18.2897 41.9017C18.0859 41.2168 17.9124 40.4429 17.7581 39.7406C17.7247 39.5269 17.6294 39.1746 17.703 38.9746C17.869 39.1755 17.951 39.4307 18.0369 39.6637C18.2675 40.3452 18.5133 41.0975 18.6906 41.7913C18.7538 42.0517 18.3699 42.1587 18.2897 41.9017Z"
        fill="#76767C"
      />
      <path
        d="M15.8824 35.5065C16.0564 36.065 16.2088 36.6295 16.354 37.1955C16.4691 37.7063 16.695 38.3963 16.6908 38.9241C16.6908 38.928 16.6896 38.9318 16.6873 38.935C16.685 38.9381 16.6818 38.9404 16.678 38.9415C16.6743 38.9426 16.6703 38.9425 16.6666 38.9412C16.663 38.9398 16.6599 38.9373 16.6578 38.934C16.3682 38.4954 16.1814 37.7889 16.0017 37.2993C15.8173 36.7446 15.639 36.1876 15.4829 35.6244C15.4145 35.3646 15.7975 35.25 15.8819 35.5065H15.8824Z"
        fill="#76767C"
      />
      <path
        d="M20.6527 45.5006C20.1905 45.4194 19.7335 45.3157 19.2769 45.2062C18.8694 45.0916 18.2954 45.0006 17.9285 44.7733C17.9252 44.7713 17.9226 44.7684 17.9211 44.7649C17.9196 44.7614 17.9192 44.7575 17.92 44.7538C17.9208 44.7501 17.9227 44.7467 17.9255 44.7441C17.9282 44.7415 17.9318 44.7398 17.9355 44.7393C18.36 44.6662 18.9284 44.7987 19.3477 44.8464C19.8113 44.9171 20.274 44.9935 20.7324 45.0926C20.7848 45.1049 20.8303 45.137 20.8595 45.1822C20.8887 45.2274 20.8992 45.2821 20.8889 45.3349C20.8786 45.3877 20.8482 45.4345 20.8042 45.4653C20.7602 45.4962 20.7059 45.5088 20.6527 45.5006Z"
        fill="#76767C"
      />
      <path
        d="M15.1449 43.1875C15.5887 43.2347 16.0297 43.3031 16.4697 43.3762C16.865 43.4602 17.4154 43.5078 17.7771 43.7064C17.7803 43.7082 17.7829 43.711 17.7846 43.7143C17.7862 43.7176 17.7868 43.7213 17.7864 43.725C17.7859 43.7286 17.7844 43.7321 17.782 43.7349C17.7796 43.7377 17.7765 43.7398 17.7729 43.7408C17.3753 43.845 16.8263 43.7559 16.4245 43.7408C15.9792 43.7049 15.5349 43.663 15.0935 43.5993C14.8289 43.5573 14.8774 43.1625 15.1449 43.1866V43.1875Z"
        fill="#76767C"
      />
      <path
        d="M17.9388 42.8501C17.2356 42.7327 16.4639 42.5501 15.7692 42.3785C15.5395 42.3072 15.2344 42.2601 15.057 42.0912C15.3098 42.0304 15.5758 42.0686 15.8211 42.0941C16.5338 42.1884 17.3162 42.2959 18.0157 42.4417C18.2774 42.4997 18.2043 42.8911 17.9388 42.8501Z"
        fill="#76767C"
      />
      <path
        d="M11.9682 40.2536C12.7592 40.3951 13.627 40.6073 14.4109 40.8049C14.6703 40.8865 15.0033 40.9408 15.2127 41.1176C14.9297 41.1723 14.6321 41.1219 14.3553 41.0884C13.5535 40.9705 12.6748 40.8323 11.8881 40.6611C11.6263 40.6007 11.7032 40.2088 11.9692 40.2536H11.9682Z"
        fill="#76767C"
      />
      <g opacity="0.25">
        <path
          d="M21.7904 42.8134L16.1957 37.3031L16.7668 40.1476L15.6717 39.068C15.6717 39.068 14.0289 33.7987 14.1539 33.9322C14.2789 34.0657 21.638 41.9899 21.638 41.9899L21.7904 42.8134Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M20.4646 46.0237L14.6605 40.4577L10.7646 39.3876L9.74397 38.3867L14.9713 39.7121L21.3626 46.0492L21.2697 46.1883L20.4646 46.0237Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M58.1465 81.1013L56.0024 76.3311C56.0024 76.3311 55.7255 78.3997 55.7269 78.4379C55.7283 78.4761 58.1465 81.1013 58.1465 81.1013Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M59.311 82.8108C59.2687 82.8108 59.2268 82.8021 59.1879 82.7854L51.1802 79.3522C51.1197 79.3264 51.0689 79.2821 51.0351 79.2256C51.0014 79.1692 50.9863 79.1035 50.9921 79.038C50.9979 78.9724 51.0243 78.9104 51.0675 78.8608C51.1107 78.8112 51.1685 78.7765 51.2326 78.7617L54.4124 78.024C54.5511 77.9505 54.6954 77.8354 54.72 77.7778L55.5284 74.539C55.5441 74.476 55.5791 74.4194 55.6286 74.3773C55.678 74.3351 55.7394 74.3094 55.8042 74.3039C55.8689 74.2983 55.9338 74.3131 55.9897 74.3462C56.0456 74.3793 56.0898 74.4291 56.116 74.4885L59.5964 82.3727C59.6172 82.42 59.626 82.4717 59.6219 82.5233C59.6178 82.5748 59.601 82.6246 59.5729 82.668C59.5449 82.7114 59.5065 82.7472 59.4612 82.7722C59.4159 82.7971 59.3651 82.8104 59.3134 82.8108H59.311ZM52.3013 79.1541L58.7054 81.9001L55.9128 75.5733L55.3246 77.9316C55.2341 78.2957 54.7902 78.5358 54.6563 78.6009C54.6353 78.6109 54.6133 78.6186 54.5907 78.624L52.3013 79.1541Z"
        fill="#0F0F14"
      />
      <path
        d="M19.0618 53.6601L19.0956 53.6201L19.0618 53.6601Z"
        fill="#4B7762"
      />
      <path
        d="M26.303 40.5718C26.303 40.5718 26.303 39.2356 25.4805 39.03C24.6579 38.8243 14.5014 50.7741 14.5014 50.7741C14.5014 50.7741 15.1117 51.6863 16.3578 51.3651L19.0934 53.62L28.615 42.2886L26.303 40.5718Z"
        fill="#A0ABAF"
      />
      <path
        d="M28.6151 42.2891L19.0948 53.6195L28.6703 42.3296L28.6151 42.2891Z"
        fill="#4B7762"
      />
      <path
        d="M28.6543 42.2422L28.6151 42.2894L28.6703 42.3304L19.0958 53.6203L39.1278 70.1243C39.1278 70.1243 49.4829 67.3416 49.8682 58.0689L28.6543 42.2422Z"
        fill="#A54E2B"
      />
      <g opacity="0.25">
        <path
          d="M19.3259 45.2256C19.3259 45.2256 19.0406 47.074 19.9094 47.5103L19.5952 47.8513L44.9012 67.1692C44.9012 67.1692 43.5688 68.1596 42.6467 68.6313C41.7246 69.1029 40.6148 69.5977 40.6148 69.5977L17.2771 50.3671C17.2771 50.3671 15.5871 50.3364 15.3815 49.7199L19.3259 45.2256Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M42.8114 92.9008C36.8799 90.3397 34.3834 81.2793 34.3834 81.2793C33.0968 86.1746 30.7517 95.5595 29.6881 104.338C28.5684 113.586 28.7656 122.113 28.7656 122.113C28.7656 122.113 40.8073 122.477 46.5416 123.036C46.5416 123.036 47.3713 112.581 48.3028 109.201C49.0725 106.406 56.1845 92.5569 56.1845 92.5569C56.1845 92.5569 48.7424 95.4623 42.8114 92.9008Z"
        fill="#02285E"
      />
      <path
        d="M30.0212 122.113C30.0212 122.113 24.734 125.961 23.6067 127.396C22.4795 128.831 22.3488 131.924 22.3488 131.924L45.3654 132.05C45.9106 130.343 45.4494 122.952 45.4494 122.952C39.7132 122.393 30.0212 122.113 30.0212 122.113Z"
        fill="#EDBC9A"
      />
      <path
        d="M73.3094 92.9006C79.6766 91.8035 81.8401 82.2852 81.8401 82.2852C83.0381 86.8432 85.0101 96.9488 85.5294 104.673C86.1552 113.985 85.6968 122.281 85.6968 122.281C85.6968 122.281 75.4148 122.225 69.6819 122.784C69.6819 122.784 68.8376 112.083 67.7533 108.698C66.869 105.937 61.0875 93.0596 61.0875 93.0596C61.0875 93.0596 68.0047 93.8142 73.3094 92.9006Z"
        fill="#02285E"
      />
      <path
        d="M84.8078 122.222C84.8078 122.222 89.6427 125.332 90.7699 126.767C91.8972 128.202 92.5306 131.546 92.5306 131.546L68.7594 131.924C68.2146 130.217 70.1711 122.629 70.1711 122.629C75.9059 122.069 84.8078 122.222 84.8078 122.222Z"
        fill="#EDBC9A"
      />
      <g opacity="0.4">
        <path
          d="M19.1043 33.5977C19.1557 33.5133 19.2057 33.4293 19.2585 33.3444C19.2585 33.3444 19.2585 33.3057 19.2557 33.2383C19.2033 33.3581 19.1534 33.4779 19.1043 33.5977Z"
          fill="white"
        />
      </g>
      <path
        d="M15.8121 86.4235C15.7796 86.2481 15.7541 86.0693 15.7348 85.8887C15.1547 87.1069 17.2214 89.3501 17.2214 89.3501C17.2214 89.3501 17.2771 89.4336 17.4667 89.5251C16.9474 89.044 16.1711 88.3342 15.8121 86.4235Z"
        fill="#EDBC9A"
      />
      <path
        d="M18.8789 89.7209L18.8874 89.754C20.0849 89.7068 21.7484 89.0125 22.0474 88.0838C22.3663 87.0934 20.9443 86.8104 21.1211 86.5114C21.298 86.2123 21.989 85.5369 22.2531 84.9257C22.2531 84.9257 18.6214 84.7134 16.7697 85.2337C16.7857 85.6178 16.8359 85.9998 16.9197 86.375C17.346 88.198 18.8789 89.7209 18.8789 89.7209Z"
        fill="#EDBC9A"
      />
      <path
        d="M15.7348 85.8876C15.7541 86.0683 15.7796 86.247 15.8121 86.4225C16.1711 88.3331 16.9474 89.043 17.4667 89.524C17.6719 89.6231 18.0327 89.7316 18.6595 89.7547C18.7331 89.7547 18.8095 89.7547 18.8873 89.7547L18.8788 89.7217C18.8788 89.7217 17.346 88.1987 16.9201 86.3758C16.8363 86.0005 16.7861 85.6185 16.7701 85.2344C16.3036 85.365 15.948 85.5419 15.7942 85.781C15.7721 85.8152 15.7522 85.8508 15.7348 85.8876Z"
        fill="#EDBC9A"
      />
      <path
        d="M17.8951 89.8401C17.8951 89.8401 18.3899 92.3767 19.8916 93.27C21.3933 94.1633 22.5508 92.5186 22.5508 92.5186C24.9821 93.3068 25.4024 91.4937 25.4024 91.4937C27.8262 92.546 28.4473 90.3443 28.4473 90.3443C31.2834 89.8486 30.3085 86.3089 30.3085 86.3089C31.2329 83.7492 29.9967 81.1014 29.9967 81.1014C23.0125 77.6659 16.8127 79.2728 16.8103 79.2733C13.7809 88.0521 17.8951 89.8401 17.8951 89.8401Z"
        fill="#EDBC9A"
      />
      <path
        d="M78.4441 56.2074C78.4441 56.2074 71.9151 60.1787 55.8804 58.4057C39.8456 56.6328 40.1918 52.6582 40.1918 52.6582C16.3786 55.1735 16.6347 79.914 16.714 79.9913C18.5704 81.8341 29.2075 83.2618 30.8008 82.8425C30.4305 79.8475 33.6246 70.5479 34.49 70.6003C31.5974 84.6871 31.3625 88.4042 31.8658 92.7641C32.2535 96.1246 53.5881 93.4348 57.9429 93.4348C65.8246 93.4348 83.6007 93.7499 83.6007 90.1648C83.6007 82.6198 78.4441 56.2074 78.4441 56.2074Z"
        fill="#02285E"
      />
      <path
        d="M57.7948 63.4676C57.7948 63.4676 59.4838 62.4531 61.3147 61.1434C63.1457 59.8336 63.3037 59.2733 63.3037 59.2733L63.1273 58.9969C63.1273 58.9969 59.2918 58.6766 55.8803 58.4068C52.4689 58.137 51.4091 57.9248 51.4091 57.9248L51.1959 58.2611C51.1959 58.2611 54.7078 62.1258 55.0705 62.5121C55.4332 62.8984 56.5869 63.919 56.5869 63.919H56.9642L57.7948 63.4676Z"
        fill="#EDBC9A"
      />
      <path
        d="M82.5947 78.3439C82.5947 78.3439 72.9942 81.1738 56.5869 81.4822C40.1796 81.7907 33.2911 77.2119 33.2911 77.2119C33.2911 77.2119 31.48 81.62 32.0337 85.1357C32.0337 85.1357 38.7915 89.1654 56.5869 88.6259C74.3823 88.0863 83.4748 85.7648 83.4748 85.7648C83.4748 85.7648 82.7975 80.7347 82.5947 78.3439Z"
        fill="#EB2701"
      />
      <path
        d="M67.3301 80.7842C67.158 80.6762 50.0352 69.8235 42.7571 56.5479C42.6189 56.2966 47.6901 59.342 47.9656 59.4222C51.9067 60.5683 53.6541 60.7692 53.8909 60.9315C54.422 61.2946 76.7235 79.4112 76.8938 79.5178C77.1367 79.6706 73.7073 80.2305 72.282 80.3984C72.0966 80.4201 67.416 80.8408 67.3301 80.7842Z"
        fill="#01234C"
      />
      <path
        d="M57.9151 65.6838C57.7892 65.4258 56.1006 63.7726 56.3586 63.6462C56.4058 63.6236 61.0445 61.3351 63.1032 58.6939C63.2801 58.4675 65.8506 59.915 66.0746 60.0914C66.2987 60.2678 69.6592 60.2003 69.4847 60.4267C67.2778 63.2566 60.712 67.5406 60.5129 67.6378C60.4408 67.676 58.0052 65.8682 57.9151 65.6838Z"
        fill="#01234C"
      />
      <path
        d="M60.2838 88.6924C60.2838 88.6924 63.9462 98.6329 63.8618 101.836L58.7076 101.434L55.0683 89.2357"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M57.1169 89.0225L60.1171 99.0804L63.546 99.3488C63.7554 100.349 63.8795 101.219 63.863 101.837L58.7088 101.434L55.0681 89.2366L57.1169 89.0225Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M55.4317 82.1416C55.4317 82.1416 56.6476 79.1084 60.6335 80.0267C64.6195 80.945 63.429 87.1415 61.1953 88.8937C58.9615 90.6459 56.5212 88.8503 56.5212 88.8503C56.5212 88.8503 53.2513 90.2441 50.2865 88.1367C47.3217 86.0294 47.1137 80.945 49.8063 80.3639C52.499 79.7828 54.3247 81.1997 55.4317 82.1416Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M47.8645 82.7705C47.8645 82.7705 50.385 86.2136 53.4465 86.5876C56.508 86.9616 57.1466 85.6254 57.1466 85.6254L57.1598 85.117C57.1598 85.117 59.9562 87.113 61.2387 86.5701C62.5211 86.0273 62.7885 85.1806 62.7885 85.1806L63.2267 84.4581C63.2267 84.4581 62.9871 88.6011 60.5684 89.2958C58.1498 89.9905 56.9433 89.1071 56.9433 89.1071L56.0345 88.7973C56.0345 88.7973 52.4692 90.1056 49.8124 87.754C47.1556 85.4023 47.8645 82.7705 47.8645 82.7705Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M50.9934 88.5498C50.9934 88.5498 46.8123 98.2837 46.7278 101.487L51.8962 101.356L56.1736 89.3653"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M54.14 89.0479L50.614 98.9341L47.1761 99.0214C46.9139 100.009 46.7445 100.872 46.728 101.49L51.8964 101.359L56.1738 89.3662L54.14 89.0479Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M53.916 82.5893C54.7867 83.2911 55.1225 84.1646 54.8933 84.4047C54.664 84.6447 53.958 84.1604 53.0873 83.4585C52.2166 82.7567 51.5111 82.103 51.7398 81.8629C51.9686 81.6229 53.0453 81.8875 53.916 82.5893Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M60.4624 81.3359C61.2991 81.666 61.7453 82.3381 61.6123 82.6338C61.4793 82.9296 60.818 82.7376 59.9813 82.407C59.1446 82.0764 58.4489 81.7311 58.5819 81.4354C58.7149 81.1397 59.6257 81.0048 60.4624 81.3359Z"
          fill="white"
        />
      </g>
      <path
        d="M100.65 71.9941C100.615 72.0474 100.578 72.0998 100.542 72.1531C100.578 72.0998 100.615 72.0474 100.65 71.9941Z"
        fill="#3E4049"
      />
      <path
        d="M104.35 74.6174C103.944 74.4136 103.522 74.2446 103.087 74.1123C103.087 74.1123 103.064 74.1166 103.022 74.1269L103.343 74.4028C103.685 74.4402 104.023 74.5121 104.35 74.6174Z"
        fill="#EDBC9A"
      />
      <path
        d="M107.615 79.4732C107.338 79.2259 107.008 79.0452 106.651 78.945C106.294 78.8448 105.918 78.8277 105.553 78.895C105.762 78.4329 105.913 77.9469 106.004 77.448C105.898 77.7092 105.766 77.9593 105.611 78.1946C105.611 78.1946 105.643 77.2183 104.975 76.5113C104.306 75.8043 102.675 75.4449 102.109 75.1364C102.052 75.1053 101.891 74.9803 101.65 74.7812C101.335 75.008 101.058 75.2841 100.831 75.5991C99.9638 76.8136 98.9851 81.0094 98.9851 81.0094C100.801 80.5028 101.815 79.0912 101.815 79.0912C101.815 79.0912 102.189 79.61 102.109 79.9161C102.029 80.2222 103.297 81.0651 103.297 81.0651V81.5938C103.04 81.7094 102.796 81.8532 102.571 82.0225C102.236 82.2829 102.16 83.4625 102.057 83.7888C101.955 84.1152 100.888 83.9251 100.136 85.1566C99.3837 86.3881 101.601 87.8922 101.601 87.8922C101.601 87.8922 102.951 88.2224 103.452 87.7979C103.953 87.3734 104.358 86.0636 104.358 86.0636C104.358 86.0636 105.495 85.9476 105.965 85.3835C106.434 84.8194 107.017 83.7506 107.017 83.7506C107.017 83.7506 107.923 83.5002 108.308 83.0955C108.694 82.6908 109.04 81.524 109.04 81.524C109.04 81.524 108.56 80.3222 107.615 79.4732Z"
        fill="#EDBC9A"
      />
      <g opacity="0.4">
        <path
          d="M105.835 75.8417C106.099 76.3058 106.097 76.9043 106.004 77.4491C106.24 76.868 106.404 76.0695 105.874 75.5106C105.438 75.0493 104.851 74.7791 104.351 74.6201C104.923 74.9069 105.536 75.3163 105.835 75.8417Z"
          fill="white"
        />
      </g>
      <path
        d="M104.974 76.5097C105.643 77.2172 105.611 78.1931 105.611 78.1931C105.766 77.9578 105.898 77.7077 106.004 77.4464C106.098 76.9017 106.098 76.3031 105.835 75.839C105.536 75.316 104.923 74.9066 104.35 74.6175C104.023 74.5121 103.685 74.4402 103.343 74.4029L103.022 74.127C102.529 74.2584 102.063 74.4797 101.65 74.7797C101.891 74.9788 102.052 75.1037 102.109 75.1349C102.675 75.4433 104.307 75.8032 104.974 76.5097Z"
        fill="#EDBC9A"
      />
      <path
        d="M90.641 23.4553C90.641 23.4553 89.9689 28.6703 95.6688 31.0786C101.369 33.4868 106.237 30.9172 106.237 30.9172L107.102 23.0727C107.102 23.0727 102.273 24.8141 99.6024 24.3523C96.9314 23.8906 91.6475 18.9873 91.6475 18.9873"
        fill="#EB2701"
      />
      <path
        d="M53.1032 60.7957C61.3396 61.7296 74.8595 60.5509 78.9681 58.0073C84.426 54.6279 89.1977 48.504 90.7777 44.1488C92.2983 39.9572 96.7512 24.0305 86.2107 11.0493C76.3603 -1.08206 51.7566 -7.54651 35.9181 14.9748C31.8586 21.9935 30.8512 39.1597 35.3663 47.9432C38.2981 53.6446 45.1068 59.8897 53.1032 60.7957Z"
        fill="#02285E"
      />
      <path
        d="M78.2786 26.8776C81.1557 27.4389 83.2687 29.7434 83.4932 32.528C83.8365 36.7804 82.314 43.1166 78.6616 45.8564C73.6526 49.6145 67.9862 42.4605 57.7514 42.7379C47.1557 43.0251 46.4893 45.9965 40.4418 43.7712C36.7856 42.4251 35.4895 27.7351 39.0896 25.9056C44.4919 23.1672 47.718 28.4214 57.9424 28.5652C64.77 28.661 73.5116 25.9471 78.2786 26.8776Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M91.3843 19.445C93.3492 15.1662 97.3271 15.5417 100.53 15.6534C103.732 15.7652 106.1 11.7793 106.1 11.7793C108.051 13.6824 107.523 21.6722 107.523 21.6722C107.523 21.6722 99.7476 21.1652 97.8464 21.5113C95.9452 21.8575 92.3753 24.6044 92.3753 24.6044L92.0574 24.5507"
        fill="#EB2701"
      />
      <path
        d="M33.5268 22.744C33.5268 22.744 66.7159 28.5052 93.0713 24.6447C93.0713 24.6447 94.9971 24.4089 94.4221 21.0163C93.8472 17.6237 89.732 18.1477 89.732 18.1477C89.732 18.1477 67.4357 19.6207 59.3945 19.3127C51.3533 19.0047 35.4761 16.9719 34.9682 17.0667C33.8645 17.2723 33.307 20.5942 33.5268 22.744Z"
        fill="#EB2701"
      />
      <path
        d="M59.0602 32.8224C61.6453 35.5391 65.1586 37.0381 68.7866 36.973C72.049 36.7164 73.5432 35.7684 75.1402 32.7753C76.4179 29.4435 76.15 28.6035 76.15 28.6035L59.0602 32.8224Z"
        fill="#EB2701"
      />
      <path
        d="M42.8259 33.9784C44.3653 35.9315 46.8424 36.7564 49.1431 36.0824C51.6377 35.5636 53.921 34.2741 55.7019 32.3748L41.7981 26.8701C41.7981 26.8701 42.0047 32.7375 42.8259 33.9784Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default DriveConsumptionIcon;
