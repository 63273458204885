import React from "react";

const SelectLeadsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="146"
      height="170"
      viewBox="0 0 146 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <g opacity="0.2">
        <path
          d="M58.5086 169.958C90.4354 169.958 116.317 167.233 116.317 163.871C116.317 160.509 90.4354 157.784 58.5086 157.784C26.5819 157.784 0.700195 160.509 0.700195 163.871C0.700195 167.233 26.5819 169.958 58.5086 169.958Z"
          fill="#EB2701"
        />
      </g>
      <path
        d="M84.6115 112.294C84.6115 112.294 84.9797 118.46 85.029 118.548C85.3221 119.082 86.8577 128.169 87.4258 129.909C89.0938 135.016 90.3444 142.417 92.8462 150.651C93.5908 153.103 95.0237 159.639 94.0968 160.658C89.8818 165.286 69.7587 164.855 67.4135 162.221C65.0682 159.587 71.8958 151.902 71.8958 151.902C71.8958 151.902 68.1153 139.57 60.7425 132.515C60.7425 132.515 62.7019 133.78 55.3227 132.307C55.3227 132.307 53.0361 140.988 49.7985 153.778C49.7985 153.778 57.3941 160.065 56.2608 164.201C55.1275 168.338 26.5568 165.035 26.7632 163.055C27.713 154.031 29.221 140.952 30.6199 130.743C31.7468 122.516 31.9632 112.615 32.183 106.874L32.7822 98.2229L25.8468 97.2848C25.8468 97.2848 19.316 94.6253 18.0191 88.1631C17.3014 84.5796 20.4622 74.7279 30.6979 69.3034C37.7986 65.537 48.3058 63.9059 63.2841 67.9737C71.7211 70.2667 81.177 76.7454 88.9133 84.6617C90.9148 86.7052 92.8026 88.857 94.5682 91.1075C99.2715 97.0948 102.757 103.449 104.06 109.187C104.062 109.187 94.6409 111.596 84.6115 112.294Z"
        fill="#02285E"
      />
      <path
        d="M31.6733 113.572C31.6733 113.572 35.567 123.201 84.9291 117.363L84.6882 113.52C84.6882 113.52 59.208 122.232 32.038 107.709L31.6733 113.572Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M84.9904 118.236C35.6282 124.072 31.6953 113.58 31.6953 113.58L31.938 110.453C35.1873 112.51 40.8499 121.178 84.8186 116.082L84.9904 118.236Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M32.0085 107.811C34.3362 108.853 41.1912 111.984 41.9452 112.015C62.6623 100.175 82.6823 86.0064 86.5911 82.2476L79.8486 76.6455C59.7677 94.427 39.2283 104.882 32.0085 107.811Z"
        fill="#012551"
      />
      <path
        d="M61.1871 113.805C61.1871 113.805 64.7477 122.717 70.1053 128.468C70.5589 128.94 71.1506 129.256 71.7953 129.369C72.4399 129.483 73.104 129.389 73.6918 129.101C74.6973 128.6 75.9156 127.847 76.9991 126.778C77.5569 126.236 77.8765 125.494 77.8875 124.716C77.8984 123.937 77.6 123.187 77.0577 122.629C74.5917 120.108 69.7489 115.522 64.7236 112.539L61.1853 113.805H61.1871Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M76.9996 126.779C75.9149 127.847 74.6966 128.6 73.6911 129.101C73.1034 129.389 72.4394 129.483 71.7948 129.369C71.1502 129.256 70.5584 128.94 70.1047 128.469C64.7464 122.718 61.1858 113.805 61.1858 113.805L63.5146 112.97C65.0771 115.96 67.3537 119.74 70.1047 122.691C70.5581 123.163 71.1498 123.479 71.7943 123.593C72.4388 123.708 73.103 123.614 73.6911 123.327C74.5732 122.89 75.4057 122.359 76.1741 121.744C76.4948 122.062 76.7897 122.359 77.0582 122.631C77.3269 122.908 77.5385 123.234 77.681 123.592C77.8234 123.95 77.894 124.332 77.8885 124.717C77.8831 125.103 77.8018 125.483 77.6492 125.837C77.4967 126.19 77.276 126.511 76.9996 126.779Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M57.8451 112.539C57.8451 112.539 50.0092 115.72 43.8858 121.411C43.5184 121.757 43.2455 122.191 43.0932 122.672C42.9409 123.153 42.9143 123.665 43.0157 124.16C43.2655 125.377 43.9503 127.025 45.7315 128.502C46.2927 128.968 47.0095 129.205 47.7381 129.165C48.4667 129.125 49.1531 128.811 49.6597 128.285C52.2647 125.573 57.2817 120.036 59.6504 115.227L57.8463 112.539H57.8451Z"
        fill="#EB2701"
      />
      <g opacity="0.2">
        <path
          d="M59.6471 115.226C57.279 120.033 52.2597 125.573 49.6571 128.284C49.1503 128.809 48.4639 129.123 47.7354 129.163C47.0069 129.204 46.2902 128.967 45.7288 128.501C43.9471 127.024 43.2628 125.376 43.0137 124.158C42.9118 123.664 42.9383 123.152 43.0909 122.67C43.2434 122.189 43.5168 121.755 43.8849 121.41C43.9963 121.308 44.1042 121.207 44.2156 121.109C44.6459 121.712 45.1543 122.256 45.7277 122.726C46.289 123.192 47.0057 123.429 47.7341 123.389C48.4626 123.349 49.149 123.035 49.6559 122.511C51.7132 120.368 55.2768 116.464 57.8477 112.547L59.6471 115.226Z"
          fill="#39005E"
        />
      </g>
      <path
        d="M59.8355 112.66C59.8355 112.66 61.184 109.238 65.6141 110.275C70.0442 111.312 68.7215 118.304 66.2379 120.28C63.7543 122.257 61.0456 120.231 61.0456 120.231C61.0456 120.231 57.4105 121.804 54.1196 119.426C50.8287 117.049 50.593 111.31 53.5831 110.655C56.5733 109.999 58.606 111.598 59.8355 112.66Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M51.4322 113.369C51.4322 113.369 54.2306 117.254 57.6312 117.677C61.0317 118.099 61.7423 116.591 61.7423 116.591L61.7588 116.015C61.7588 116.015 64.8662 118.268 66.2903 117.657C67.7144 117.046 68.0123 116.089 68.0123 116.089L68.4983 115.273C68.4983 115.273 68.2327 119.949 65.5451 120.733C62.8575 121.516 61.5172 120.52 61.5172 120.52L60.5105 120.168C60.5105 120.168 56.5494 121.644 53.5986 118.99C50.6477 116.336 51.4322 113.369 51.4322 113.369Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M58.1527 113.164C59.1201 113.957 59.4924 114.942 59.2379 115.213C58.9835 115.484 58.199 114.939 57.2322 114.145C56.2654 113.352 55.4809 112.616 55.7348 112.345C55.9886 112.074 57.1847 112.372 58.1527 113.164Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M65.4281 111.754C66.3574 112.127 66.8534 112.885 66.7056 113.219C66.5579 113.554 65.8262 113.337 64.8934 112.964C63.9606 112.591 63.1931 112.202 63.3373 111.865C63.4816 111.528 64.4965 111.382 65.4281 111.754Z"
          fill="white"
        />
      </g>
      <path
        d="M48.43 96.9836L83.4206 103.322L119.778 111.765C119.957 111.806 120.121 111.894 120.255 112.019C120.39 112.144 120.489 112.302 120.543 112.477C120.597 112.652 120.604 112.838 120.564 113.017C120.523 113.196 120.436 113.361 120.312 113.496L117.702 116.33C117.601 116.44 117.478 116.527 117.341 116.585C117.204 116.643 117.056 116.672 116.907 116.668L81.5585 115.881L46.8493 102.07C46.5357 101.945 46.252 101.755 46.0168 101.513C45.7815 101.271 45.6001 100.982 45.4842 100.665C45.3684 100.348 45.3208 100.01 45.3446 99.6736C45.3683 99.3369 45.4629 99.009 45.6222 98.7114L45.8883 98.2137C46.1273 97.7661 46.5021 97.4058 46.9588 97.1848C47.4156 96.9637 47.9307 96.8933 48.43 96.9836Z"
        fill="#A6A6A6"
      />
      <path
        d="M145.128 87.3602C145.121 87.9819 144.872 88.5764 144.433 89.0171C138.43 95.093 124.86 108.823 119.168 114.585C118.351 115.411 117.371 116.056 116.289 116.48C115.207 116.903 114.049 117.094 112.889 117.041C104.884 116.671 86.2965 115.816 82.3201 115.634C81.8172 115.612 81.321 115.51 80.8497 115.333C76.3785 113.656 52.1085 104.567 45.132 101.953C44.629 101.764 44.2187 101.388 43.9873 100.903C43.7558 100.418 43.7213 99.8624 43.8908 99.3525C44.0608 98.8447 44.2426 98.2971 44.405 97.7999C44.481 97.5732 44.6013 97.3639 44.759 97.1841C44.9167 97.0043 45.1085 96.8578 45.3234 96.7529C45.5383 96.648 45.7719 96.5869 46.0106 96.5732C46.2493 96.5595 46.4884 96.5935 46.7139 96.673L78.7531 108.032C78.7531 108.032 100.77 85.9531 108.058 78.6459C108.76 77.9409 109.635 77.4325 110.596 77.1716C111.556 76.9107 112.568 76.9063 113.531 77.1591C120.242 78.918 135.895 83.0221 143.326 84.9704C143.737 85.0784 144.112 85.2923 144.413 85.5907C144.715 85.8891 144.933 86.2615 145.046 86.6707C145.109 86.8948 145.137 87.1275 145.128 87.3602Z"
        fill="#A6A6A6"
      />
      <g opacity="0.6">
        <path
          d="M145.126 87.3598C145.123 87.6668 145.061 87.9703 144.943 88.2539C144.681 88.0514 144.382 87.9022 144.063 87.8148C136.632 85.8659 120.979 81.7624 114.268 80.0034C113.305 79.7507 112.293 79.755 111.333 80.0159C110.372 80.2769 109.497 80.7852 108.795 81.4903L80.583 109.777C80.2558 110.105 79.8419 110.334 79.3895 110.437C78.9372 110.54 78.465 110.512 78.0279 110.356L47.4492 99.5151C47.2238 99.4356 46.9848 99.4017 46.7461 99.4154C46.5075 99.4292 46.2739 99.4903 46.0591 99.5952C45.8443 99.7 45.6525 99.8466 45.4948 100.026C45.3372 100.206 45.2169 100.415 45.1409 100.642C45.0237 101.005 44.8953 101.392 44.7675 101.772C44.3695 101.527 44.066 101.154 43.9064 100.715C43.7467 100.275 43.7403 99.795 43.888 99.3515C44.058 98.8438 44.2398 98.2961 44.4022 97.799C44.4782 97.5722 44.5985 97.3629 44.7562 97.1831C44.9138 97.0034 45.1057 96.8568 45.3206 96.7519C45.5355 96.647 45.7691 96.586 46.0078 96.5722C46.2465 96.5585 46.4856 96.5925 46.7111 96.6721L78.7503 108.031C78.7503 108.031 100.767 85.9521 108.055 78.645C108.757 77.9399 109.632 77.4315 110.593 77.1706C111.553 76.9097 112.565 76.9054 113.528 77.1581C120.239 78.917 135.892 83.0211 143.324 84.9694C143.734 85.0774 144.109 85.2913 144.411 85.5897C144.712 85.8881 144.93 86.2605 145.043 86.6697C145.107 86.894 145.135 87.1268 145.126 87.3598Z"
          fill="#E9E8F7"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M145.127 87.3581C145.12 87.9798 144.871 88.5743 144.432 89.015C138.429 95.0908 124.859 108.821 119.166 114.583C118.35 115.409 117.369 116.054 116.288 116.477C115.206 116.901 114.048 117.092 112.888 117.039C104.883 116.669 86.2952 115.814 82.3189 115.632C81.816 115.609 81.3198 115.508 80.8485 115.331C76.3773 113.654 52.1072 104.565 45.1308 101.951C44.6278 101.762 44.2175 101.386 43.986 100.901C43.7546 100.416 43.7201 99.8602 43.8896 99.3503C44.0596 98.8426 76.9196 111.226 76.9196 111.226L79.3493 111.801C80.2988 112.026 81.2905 111.999 82.2266 111.724C83.1628 111.449 84.0111 110.935 84.6882 110.232L112.826 81.0342C112.826 81.0342 131.563 85.5868 139.419 87.6964C142.398 88.4955 144.128 88.1255 145.127 87.3581Z"
          fill="#BAC4D3"
        />
      </g>
      <path
        d="M117.782 100.978C119.477 99.7199 120.207 97.8325 119.413 96.7622C118.619 95.692 116.601 95.8441 114.906 97.1021C113.211 98.3601 112.48 100.248 113.275 101.318C114.069 102.388 116.087 102.236 117.782 100.978Z"
        fill="white"
      />
      <path
        d="M108.683 111.749C108.683 111.749 112.168 107.42 116.15 108.176C120.132 108.933 118.856 123.961 105.782 124.337C92.7081 124.714 92.1957 110.845 96.559 108.172C101.344 105.241 108.684 111.745 108.684 111.745L108.683 111.749Z"
        fill="#F4D2B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.2219 95.2754C64.4609 97.8006 62.4957 92.7467 62.4957 92.7467C62.4957 92.7467 56.0399 99.1491 45.5098 93.7023C45.5098 93.7023 45.4213 93.7838 45.2442 93.9216C43.8588 95.0157 37.0659 99.7354 25.8446 97.2823C25.8446 97.2823 17.797 94.8357 17.719 87.253C17.6017 76.0675 26.0052 76.0739 26.0052 76.0739C26.0052 76.0739 41.16 72.9002 51.4116 76.6209C51.9392 76.8144 52.4839 76.8555 52.9752 77.09C61.9674 81.394 69.8356 92.8768 67.2219 95.2754Z"
        fill="#012551"
      />
      <path
        d="M53.4456 71.0693L63.5857 74.4095L70.1746 84.8276L62.9044 90.6126L53.4456 71.0693Z"
        fill="#012551"
      />
      <path
        d="M67.2206 95.2745C64.4597 97.7998 62.4944 92.7458 62.4944 92.7458C62.4944 92.7458 56.0386 99.1483 45.5085 93.7015C45.5085 93.7015 45.42 93.783 45.2429 93.9208C44.8073 91.412 43.3826 80.3578 51.2995 76.7514C51.2995 76.7514 54.7428 76.5228 58.4266 77.6127C67.9875 80.441 69.8343 92.876 67.2206 95.2745Z"
        fill="#F4D2B0"
      />
      <path
        d="M68.8862 83.4493C68.8862 83.4493 66.7515 80.1572 65.5971 78.0981C64.4426 76.039 63.5854 74.4096 63.5854 74.4096L63.8698 73.7207C63.8698 73.7207 69.8308 75.2504 71.9878 75.7317C74.1448 76.2131 79.6156 76.9331 79.6156 76.9331L79.5218 77.4326C78.0753 78.8842 76.5406 80.2451 74.9263 81.5074C72.3607 83.4973 70.0864 84.4976 70.0864 84.4976L69.3242 84.1194L68.8862 83.4493Z"
        fill="#F4D2B0"
      />
      <path
        d="M40.077 22.8165C40.077 22.8165 39.7041 29.3426 32.1888 30.9796C24.6736 32.6165 19.313 28.3635 19.313 28.3635L20.0517 18.581C20.0517 18.581 25.5519 21.8139 28.9219 21.8614C32.292 21.9089 39.87 17.127 39.87 17.127"
        fill="#EB2701"
      />
      <path
        d="M77.3962 77.0341C67.1195 76.2895 50.8701 71.7544 46.4323 67.706C40.537 62.3278 36.1087 53.7519 35.1753 48.07C34.2771 42.6004 32.4824 22.1214 48.3331 8.67331C63.1455 -3.89471 94.684 -6.16195 108.883 24.982C112.236 34.4865 109.537 55.6914 102.01 65.3889C97.123 71.6846 87.374 77.757 77.3962 77.0341Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M29.8545 47.2586C29.9133 47.1727 29.972 47.0875 30.0308 47.0017L30.0373 46.8906C29.9732 47.0117 29.9133 47.134 29.8545 47.2586Z"
          fill="white"
        />
      </g>
      <path
        d="M54.4001 29.8284C50.7579 29.856 47.647 32.1877 46.7353 35.539C45.3423 40.6557 45.7527 48.7461 49.5877 52.93C54.8474 58.6681 63.4086 51.2232 75.85 53.9056C88.7305 56.6823 88.866 60.4652 96.7612 59.1307C101.538 58.3228 106.487 40.6674 102.503 37.6133C96.5296 33.0302 91.3847 38.7115 78.8589 36.5469C70.4971 35.0999 60.4373 29.7844 54.4001 29.8284Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.0903 17.7464C38.6691 12.0693 33.7206 11.6172 29.7842 11.0221C25.8477 10.427 23.8625 5.01367 23.8625 5.01367C21.0453 6.89102 19.8616 16.7738 19.8616 16.7738C19.8616 16.7738 29.477 17.9341 31.7213 18.7924C33.9657 19.6508 37.6964 23.8235 37.6964 23.8235L38.0968 23.8305"
        fill="#EB2701"
      />
      <path
        d="M110.025 35.0219C110.025 35.0219 68.1556 34.4637 36.837 23.7132C36.837 23.7132 34.5386 22.9845 36.0161 18.9712C37.4936 14.958 42.4045 16.5398 42.4045 16.5398C42.4045 16.5398 69.3118 23.443 79.2051 24.9081C89.0984 26.3733 108.965 27.523 109.564 27.7552C110.866 28.26 110.786 32.4451 110.025 35.0219Z"
        fill="#EB2701"
      />
      <path
        d="M76.5239 41.4893C72.7434 44.2173 68.1075 45.2416 63.6909 44.3317C59.7626 43.2711 58.155 41.7707 56.8897 37.7457C56.0918 33.383 56.6106 32.418 56.6106 32.418L76.5239 41.4893Z"
        fill="#EB2701"
      />
      <path
        d="M96.0909 46.6194C93.7627 48.6538 90.5468 49.0947 87.8902 47.7445C84.9587 46.5408 82.4669 44.4412 80.7256 41.7126L98.9749 38.1689C98.9749 38.1689 97.3807 45.2914 96.0909 46.6194Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default SelectLeadsIcon;
