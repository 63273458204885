import React from "react";

const QualifyLeadsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="143"
      height="167"
      viewBox="0 0 143 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="64.8356"
        cy="156.043"
        rx="64.8356"
        ry="10.6692"
        fill="#EB2701"
        fillOpacity="0.2"
      />
      <path
        d="M83.2667 70.1455C83.2667 70.1455 101.949 74.9889 107.593 83.5127C113.238 92.0365 93.0469 104.586 93.0469 104.586C93.0469 104.586 81.1655 85.2996 83.2667 70.1455Z"
        fill="#02285E"
      />
      <path
        d="M90.6992 94.868C90.2839 94.2763 90.2012 93.6752 90.5041 93.1138C90.8332 92.6985 91.0668 92.2401 91.3429 91.8022C92.4363 90.0549 93.7936 88.3827 95.5844 87.3029C96.4832 86.706 97.5504 86.2831 98.6491 86.5732C98.1289 87.6105 97.4065 88.0741 96.5869 88.7322C95.8621 89.3026 95.1708 89.9144 94.5166 90.5645C93.676 91.3688 92.9494 92.2843 92.3571 93.2856C92.2313 93.4819 92.1754 93.7166 92.0891 93.9309C91.8253 94.4767 91.3674 94.6776 90.6992 94.868Z"
        fill="#022C51"
      />
      <path
        d="M31.0952 141.827C28.3078 148.384 29.9797 154.13 29.9797 154.13L64.6207 155.295C64.5817 155.173 61.8327 150.707 60.5045 149.004C59.1496 147.268 55.0671 144.138 55.0671 144.138C43.9763 144.685 31.8053 142.428 31.0952 141.827Z"
        fill="#02285E"
      />
      <path
        d="M29.943 154.14C29.5859 155.531 29.6843 157.625 29.6843 157.625C41.434 159.155 65.0331 158.7 65.0331 158.7C65.2661 156.642 64.8496 155.955 64.6323 155.283C64.6323 155.283 29.9971 154.143 29.943 154.14Z"
        fill="#02285E"
      />
      <path
        d="M91.8903 144.182C93.0298 135.45 93.3228 132.464 93.5476 128.306C93.6752 125.942 93.8581 117.298 93.2022 101.937C92.488 85.2151 86.9726 75.4636 86.9726 75.4636C78.7006 68.7617 42.8712 66.4826 40.2305 70.3383C36.9101 75.1852 30.7253 106.15 30.2884 110.357C29.8515 114.565 31.2752 142.664 31.2752 142.664C31.9853 143.264 44.1044 144.866 55.1982 144.32C58.0975 137.383 63.483 127.339 63.483 127.339C63.483 127.339 67.1937 126.865 67.5217 127.029C67.8497 127.192 70.8719 144.455 70.8719 144.455C70.8719 144.455 82.5634 145.457 91.8903 144.182Z"
        fill="#02285E"
      />
      <path
        d="M104.523 153.004C104.523 153.004 104.017 152.383 102.542 151.126C101.067 149.869 91.8816 144.034 91.8816 144.034C82.5553 145.309 70.8976 144.352 70.8976 144.352C69.8589 148.176 69.0538 154.716 69.0078 154.822L105.067 154.006L104.523 153.004Z"
        fill="#02285E"
      />
      <path
        d="M69.0911 154.664C68.5044 156.01 69.246 158.573 69.246 158.573L104.955 157.987C105.214 156.485 105.275 154.75 105.05 153.983L69.0911 154.664Z"
        fill="#02285E"
      />
      <path
        d="M82.9229 81.9288C82.9229 81.9288 77.5991 78.1168 71.5081 74.9397C65.4171 71.7625 59.4863 68.9768 59.4863 68.9768L59.1875 68.2074C59.1875 68.2074 67.7543 67.1264 74.2396 67.5085C80.725 67.8906 85.7587 69.7375 85.7587 69.7375L86.6022 70.5243C86.6022 70.5243 84.7381 76.7726 84.0973 81.5473C84.0973 81.5438 84.028 82.5298 82.9229 81.9288Z"
        fill="#F2C5A7"
      />
      <path
        d="M93.5488 110.357C93.5488 110.357 85.433 123.584 32.5679 98.0869L31.0137 105.646C31.0137 105.646 49.4369 117.575 72.5636 119.32C95.7345 121.067 93.6863 117.223 93.6863 117.223"
        fill="#EB2701"
      />
      <path
        d="M83.9192 83.2938C83.8839 83.2937 83.8486 83.2908 83.8138 83.2851C83.7255 83.2714 83.6408 83.2404 83.5646 83.194C83.4883 83.1475 83.4219 83.0865 83.3692 83.0144C83.3165 82.9423 83.2785 82.8606 83.2575 82.7738C83.2364 82.687 83.2326 82.5969 83.2464 82.5087C83.2569 82.4411 84.3264 75.6442 86.0239 70.0827C86.0493 69.9965 86.0915 69.9161 86.1481 69.8463C86.2047 69.7765 86.2746 69.7186 86.3537 69.676C86.4328 69.6334 86.5196 69.6069 86.6091 69.598C86.6985 69.5892 86.7888 69.5981 86.8748 69.6244C86.9607 69.6506 87.0406 69.6937 87.1099 69.751C87.1791 69.8084 87.2362 69.8788 87.278 69.9584C87.3198 70.038 87.3454 70.125 87.3533 70.2145C87.3612 70.304 87.3513 70.3942 87.3241 70.4799C85.6558 75.9471 84.6008 82.6526 84.5903 82.7184C84.5653 82.8785 84.484 83.0244 84.361 83.1299C84.238 83.2354 84.0813 83.2935 83.9192 83.2938Z"
        fill="#0F0F14"
      />
      <path
        d="M88.0373 86.2736H88.0152C87.926 86.2711 87.8382 86.251 87.7567 86.2144C87.6753 86.1779 87.6019 86.1257 87.5406 86.0608C87.4794 85.9959 87.4315 85.9196 87.3998 85.8362C87.3681 85.7528 87.3531 85.6639 87.3558 85.5747C87.3622 85.3563 87.5305 80.1413 89.1354 73.769C89.1794 73.5941 89.291 73.4439 89.4457 73.3513C89.6005 73.2588 89.7857 73.2314 89.9606 73.2754C90.1355 73.3193 90.2857 73.4309 90.3783 73.5857C90.4709 73.7404 90.4982 73.9255 90.4543 74.1004C88.8896 80.3218 88.7166 85.5631 88.7154 85.6155C88.7097 85.7917 88.6358 85.9587 88.5093 86.0815C88.3828 86.2042 88.2136 86.2731 88.0373 86.2736Z"
        fill="#0F0F14"
      />
      <path
        d="M30.2552 35.8874C30.2552 35.8874 32.1566 42.096 25.7172 46.2109C19.2779 50.3257 12.8164 48.2086 12.8164 48.2086L10.1367 38.8397C10.1367 38.8397 16.3763 39.9568 19.5336 38.8397C22.6909 37.7226 28.1021 30.6572 28.1021 30.6572"
        fill="#EB2701"
      />
      <path
        d="M83.714 73.5497C73.8803 76.396 57.1743 77.7688 51.6443 75.5253C44.2974 72.545 37.2172 66.0812 34.3873 61.1079C31.6657 56.3192 22.937 37.8564 33.0742 19.8676C42.5486 3.05479 71.161 -9.92336 95.1212 14.2036C101.521 21.9044 106.313 42.5915 102.639 54.2225C100.255 61.7696 93.2612 70.7855 83.714 73.5497Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M29.1504 62.1772C29.1755 62.0775 29.1994 61.9783 29.2251 61.8786L29.193 61.7725C29.1766 61.9066 29.1632 62.0425 29.1504 62.1772Z"
          fill="white"
        />
      </g>
      <path
        d="M46.019 37.4862C42.6345 38.7675 40.5391 42.011 40.8437 45.4479C41.3098 50.695 44.4788 58.0918 49.4944 60.669C56.373 64.2031 61.7859 54.3177 74.3046 52.5308C87.2648 50.681 88.6932 54.1616 95.5922 50.1953C99.766 47.7974 98.2945 29.6421 93.5288 28.1668C86.3829 25.9536 83.5459 33.0207 71.1274 35.319C62.8362 36.8531 51.6294 35.3644 46.019 37.4862Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.519 31.1619C25.2387 26.3616 20.4712 27.6447 16.5956 28.4455C12.72 29.2464 9.01103 24.8857 9.01103 24.8857C7.03041 27.608 9.33259 37.2238 9.33259 37.2238C9.33259 37.2238 18.6928 34.9925 21.0812 35.0193C23.4696 35.0461 28.3832 37.6484 28.3832 37.6484L28.7596 37.5168"
        fill="#EB2701"
      />
      <path
        d="M99.646 23.1646C99.646 23.1646 60.4343 37.0688 27.5467 37.8417C27.5467 37.8417 25.1531 37.9541 25.1484 33.7065C25.1438 29.4589 30.2608 29.237 30.2608 29.237C30.2608 29.237 57.7145 26.4 67.4387 24.3563C77.163 22.3125 96.0738 16.5413 96.7123 16.5518C98.0976 16.5745 99.4665 20.5006 99.646 23.1646Z"
        fill="#EB2701"
      />
      <path
        d="M70.6543 40.7353C68.0708 44.5793 64.1049 47.1333 59.6741 47.8048C55.6488 48.1694 53.6327 47.3254 51.066 44.012C48.8186 40.2216 48.9689 39.1436 48.9689 39.1436L70.6543 40.7353Z"
        fill="#EB2701"
      />
      <path
        d="M90.6563 38.769C89.1871 41.4662 86.3455 42.9852 83.4014 42.6421C80.2557 42.5297 77.2091 41.433 74.6465 39.4906L90.4332 29.9033C90.4332 29.9033 91.3984 37.0875 90.6563 38.769Z"
        fill="#EB2701"
      />
      <path
        d="M103.61 65.9912C102.572 64.7967 94.7848 65.9912 94.7848 65.9912C94.7848 65.9912 93.8283 61.054 91.779 60.7809C89.7296 60.5077 88.1445 72.8009 88.1445 72.8009C88.1445 72.8009 90.4415 79.3538 93.858 81.9893C93.858 81.9893 98.4385 81.8436 101.093 79.7126C103.747 77.5815 104.785 73.9466 102.708 69.7409C102.708 69.7414 104.649 67.1864 103.61 65.9912Z"
        fill="#F2C5A7"
      />
      <path
        d="M94.5583 81.6955L93.9609 81.1123L94.5478 81.7177L94.5583 81.6955Z"
        fill="#3E4049"
      />
      <path
        d="M88.2489 71.9229C88.2489 71.9229 83.535 74.1396 81.8637 74.5857C78.6738 75.436 76.8935 75.2159 68.7118 73.3946C60.5301 71.5734 51.4868 73.1256 49.5528 76.9154C47.758 80.4309 49.3454 83.5416 49.8307 85.2109C50.7418 88.3449 53.1028 92.0252 55.2483 93.0678C60.9961 95.8634 72.4564 94.1319 79.9478 91.5144C87.2586 88.9599 91.0451 86.7257 95.0063 81.8852L93.9653 81.1118C90.5452 78.4763 88.2489 71.9229 88.2489 71.9229Z"
        fill="#012A56"
      />
      <path
        d="M73.3197 74.8177C74.9695 75.187 76.66 75.6197 78.3738 75.7118C81.4408 75.9145 84.6803 75.2359 87.2365 73.4456C87.5207 73.2417 88.3911 72.4118 88.4015 72.3261C88.2361 72.0897 88.3561 71.2358 88.4598 70.6045C88.3433 70.7856 87.0355 71.5667 86.9615 71.6173C86.4501 71.9703 81.474 74.2697 78.4658 74.0926C76.8347 74.0082 75.2712 73.6022 73.6628 73.251C72.0276 72.8905 70.3797 72.5399 68.7113 72.2609C62.1875 71.0815 54.0716 71.1129 49.5063 76.7712C49.4423 76.8487 49.4096 76.9473 49.4146 77.0477C49.4195 77.148 49.4618 77.2429 49.5332 77.3137C49.6045 77.3845 49.6997 77.4261 49.8001 77.4304C49.9005 77.4346 49.9989 77.4012 50.076 77.3367C52.5774 75.2528 55.5233 73.9167 58.7324 73.4491C63.5518 72.7303 68.5598 73.7589 73.3197 74.8177Z"
        fill="#012A56"
      />
      <path
        d="M94.2309 81.374C91.7621 86.7463 83.1324 89.3742 77.0461 91.0213C68.8236 93.1763 62.2881 93.0406 56.2706 92.0557C55.4305 91.62 54.6977 93.0056 55.5861 93.2869C57.9745 94.0441 70.0236 94.5351 77.201 92.3673C87.2294 89.3387 91.3893 87.2851 93.4864 84.4719C94.0311 83.7416 94.6981 82.734 95.1239 81.8965C94.7756 81.8286 94.4607 81.6443 94.2309 81.374Z"
        fill="#012A56"
      />
      <g opacity="0.25">
        <path
          d="M103.404 70.2803C103.404 70.2803 103.404 70.2845 103.404 70.2864L103.41 70.3022L103.404 70.2803Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M102.685 77.2363C103.146 76.6789 103.434 76.0132 103.605 75.3213C103.398 76.0036 103.087 76.6499 102.684 77.2375L102.685 77.2363Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M71.6281 112.562C71.6281 112.562 69.7699 110.568 67.2009 110.787C64.6319 111.005 63.9218 113.956 63.9218 113.956C63.9218 113.956 60.5244 114.566 57.938 117.589C55.3515 120.613 54.5447 124.292 47.7891 125.785C47.7891 125.785 48.7567 132.017 52.5455 132.561C52.5455 132.561 57.5803 130.979 59.7106 127.125C61.841 123.272 63.7301 120.43 65.5546 119.811C65.5546 119.811 69.5625 123.563 72.9138 120.576C72.9138 120.576 75.2824 120.977 78.0145 118.391C78.0145 118.391 78.5609 120.121 75.5008 124.337C72.4408 128.554 68.7242 131.031 68.7242 131.031C68.7242 131.031 73.0233 132.342 76.0473 132.706C76.0473 132.706 87.0502 124.066 80.125 113.777C80.125 113.777 81.2901 111.37 78.6687 109.58C76.0473 107.79 72.4571 110.778 71.6281 112.562Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M50.0662 124.948C50.0662 124.948 50.9767 127.826 52.7983 128.555C54.6198 129.283 56.9884 125.934 58.8461 122.508C60.7038 119.082 63.8058 117.663 63.8058 117.663L64.1227 117.504C64.1227 117.504 63.6922 116.006 63.8058 114.722C63.9194 113.438 64.4117 112.563 64.4117 112.563C64.4117 112.563 65.067 116.306 68.5372 118.055C72.0074 119.803 73.5417 118.109 73.5417 118.109C73.5417 118.109 73.7165 117.434 73.3874 116.282C73.3874 116.282 74.9859 116.853 76.8715 116.334C78.7572 115.814 80.0137 113.274 80.0137 113.274L80.358 114.14L81.2965 115.857C81.2965 115.857 84.0472 120.888 81.106 126.263C78.1647 131.638 75.915 132.835 75.915 132.835L68.9828 130.847L71.1324 129.365C71.1324 129.365 74.0293 129.502 76.2971 128.354C78.5649 127.207 80.6988 122.826 80.4914 120.577C80.284 118.328 78.0995 119.812 78.0995 119.812L77.4162 119.257C77.4162 119.257 76.9193 119.832 75.213 120.212C73.5068 120.593 72.686 120.763 72.686 120.763C72.686 120.763 70.6972 121.975 69.0696 121.641C67.442 121.306 65.5482 120.022 65.5482 120.022L65.2022 119.812C65.2022 119.812 63.867 120.349 62.2079 122.991C60.5489 125.632 58.6411 129.519 55.9614 130.903C53.2818 132.287 52.4132 132.54 52.4132 132.54C52.4132 132.54 50.4 131.961 49.151 129.803C47.902 127.645 47.7832 125.677 47.7832 125.677L50.0662 124.948Z"
          fill="#CE1B02"
        />
      </g>
      <path
        d="M126.403 70.5405C135.127 70.5405 142.2 63.4692 142.2 54.7463C142.2 46.0234 135.127 38.9521 126.403 38.9521C117.678 38.9521 110.605 46.0234 110.605 54.7463C110.605 63.4692 117.678 70.5405 126.403 70.5405Z"
        fill="#EB2701"
      />
      <path
        d="M117.861 55.4648C118.763 55.5931 122.076 59.7429 123.848 60.0505C125.136 60.2751 134.309 49.5323 135.049 48.9547C136.013 48.2035 139.782 51.5216 138.785 52.2258C135.929 54.2361 126.809 65.7588 124.233 66.0797C121.45 66.4262 116.121 60.2751 115.091 58.447C114.509 57.4122 116.681 55.297 117.861 55.4648Z"
        fill="white"
      />
    </svg>
  );
};

export default QualifyLeadsIcon;
