import React, { useState } from "react";
import styles from "./CheckBoxSelect.module.scss";

interface Props {
  selectedDays: string[];
  onChange: (days: string[]) => void;
}

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const CheckBoxSelect: React.FC<Props> = (props) => {
  const { selectedDays, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (day: string) => {
    if (selectedDays.includes(day)) {
      onChange(selectedDays.filter((d) => d !== day)); // Remove if already selected
    } else {
      onChange([...selectedDays, day]); // Add if not selected
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textInput} onClick={toggleDropdown}>
        {selectedDays.length > 0
          ? selectedDays.join(", ")
          : "Select days of the week"}
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          {daysOfWeek.map((day) => (
            <div key={day} className={styles.checkboxItem}>
              <label className={styles.customCheckbox}>
                <input
                  type="checkbox"
                  checked={selectedDays.includes(day)}
                  onChange={() => handleCheckboxChange(day)}
                />
                <span className={styles.checkboxMark}></span>
                {day}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckBoxSelect;
