import React from "react";

const AddCommentIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 2C0.5 0.9 1.4 0 2.5 0H18.5C19.6 0 20.5 0.9 20.5 2V14C20.5 15.1 19.6 16 18.5 16H4.5L0.5 20V2ZM2.5 15.17L3.67 14H18.5V2H2.5V15.17ZM9.5 3H11.5V7H15.5V9H11.5V13H9.5V9H5.5V7H9.5V3Z"
        fill="#EB2701"
      />
    </svg>
  );
}; 

export default AddCommentIcon;
