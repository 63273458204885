import React, { useEffect, useState } from "react";
import styles from "./PostSchedulesCalendarListView.module.scss";

import classNames from "classnames";
import usePostSchedulesCalendarListView, {
  PostSchedule,
} from "./usePostSchedulesCalendarListView";

import Switch from "react-switch";
import TextElement from "../TextElement/TextElement";
import Select from "../Select/Select";
import ListViewSchedules from "./ListViewSchedules/ListViewSchedules";
import UpdatePostContentModal from "../UpdatePostContentModal/UpdatePostContentModal";
import PostCard from "../PostCard/PostCard";
import CustomCalendar from "./CalendarViewSchedules/CalendarViewSchedules";

interface PostSchedulesCalendarListViewProps {
  className?: string;
}

const generateMonths = (start: Date, end: Date) => {
  const months: string[] = [];
  let current = new Date(start);

  while (current <= end) {
    months.push(
      current.toLocaleString("default", { month: "long", year: "numeric" })
    );
    current.setMonth(current.getMonth() + 1);
  }

  return months;
};

const generateDays = (month: Date) => {
  const days: number[] = [];
  const start = new Date(month.getFullYear(), month.getMonth(), 1);
  const end = new Date(month.getFullYear(), month.getMonth() + 1, 0);

  for (let i = start.getDate(); i <= end.getDate(); i++) {
    days.push(i);
  }

  return days;
};

const PostSchedulesCalendarListView: React.FC<
  PostSchedulesCalendarListViewProps
> = (props) => {
  const { className } = props;
  const {
    dateRange,
    postSchedulesWithData,
    filterPostsByDate,
    groupPostsByDayInMonth,
  } = usePostSchedulesCalendarListView();
  const start = new Date(dateRange?.startDateRange as Date);
  const end = new Date(dateRange?.endDateRange as Date);
  const months = generateMonths(start, end);

  const [currentMonth, setCurrentMonth] = useState(
    new Date(dateRange?.startDateRange as Date)
  );

  const [isCalendarView, setIsCalendarView] = useState(true);

  const [startDateRange, setStartDateRange] = useState<Date | null>(null);
  const [endDateRange, setEndDateRange] = useState<Date | null>(null);

  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  

  useEffect(() => {
    if (dateRange?.startDateRange && dateRange?.endDateRange) {
      if (
        currentMonth <=
        new Date(
          dateRange?.startDateRange.getFullYear(),
          dateRange?.startDateRange.getMonth()
        )
      ) {
        setIsPrevDisabled(true);
      } else {
        setIsPrevDisabled(false);
      }
      if (
        currentMonth >=
        new Date(
          dateRange?.endDateRange.getFullYear(),
          dateRange?.endDateRange.getMonth()
        )
      ) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
      setCurrentMonth(new Date(dateRange?.startDateRange as Date));
      setStartDateRange(dateRange?.startDateRange);
      setEndDateRange(dateRange?.endDateRange);
    }
  }, [dateRange]);

  const handlePreviousMonth = () => {
    if (startDateRange === null || endDateRange === null) return null;
    const updatedDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() - 1)
    );
    setCurrentMonth(updatedDate);
    if (
      updatedDate <=
      new Date(startDateRange.getFullYear(), startDateRange.getMonth())
    ) {
      setIsPrevDisabled(true);
    } else {
      setIsPrevDisabled(false);
    }
    if (
      updatedDate >=
      new Date(endDateRange.getFullYear(), endDateRange.getMonth())
    ) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  };

  const handleNextMonth = () => {
    if (startDateRange === null || endDateRange === null) return null;
    const updatedDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() + 1)
    );
    setCurrentMonth(updatedDate);
    if (
      updatedDate <=
      new Date(startDateRange.getFullYear(), startDateRange.getMonth())
    ) {
      setIsPrevDisabled(true);
    } else {
      setIsPrevDisabled(false);
    }
    if (
      updatedDate >=
      new Date(endDateRange.getFullYear(), endDateRange.getMonth())
    ) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  };

  return (
    <div className={classNames(styles.calendarView, className)}>
     
      <div className={styles.header}>
        <div className={styles["back-forward-buttons"]}>
          <button
            className={styles["prev-button"]}
            onClick={handlePreviousMonth}
            disabled={isPrevDisabled}
          >
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.1665 4.33333H12.8332M1.1665 4.33333L4.49984 7.66667M1.1665 4.33333L4.49984 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <TextElement className={styles["current-month"]}>
            {currentMonth.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </TextElement>
          <button
            className={styles["next-button"]}
            onClick={handleNextMonth}
            disabled={isNextDisabled}
          >
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 4.33333H12.6667H1Z" fill="#D9D9D9" />
              <path
                d="M9.33333 7.66667L12.6667 4.33333L9.33333 7.66667Z"
                fill="#D9D9D9"
              />
              <path d="M9.33333 1L12.6667 4.33333L9.33333 1Z" fill="#D9D9D9" />
              <path
                d="M1 4.33333H12.6667M12.6667 4.33333L9.33333 7.66667M12.6667 4.33333L9.33333 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className={styles["filters"]}>
          <div className={styles["switch-filter"]}>
            <TextElement>Calendar</TextElement>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={isCalendarView}
              onChange={(value) => {
                setIsCalendarView(value);
              }}
              onColor="#EB2701"
            />
            <TextElement>List</TextElement>
          </div>
          <div className={styles["group-filter"]}>
            <TextElement className={styles["label"]}>Group by</TextElement>
            <Select
              options={[
                { value: "text", label: "Text" },
                { value: "single-media-post", label: "Single Media Post" },
              ]}
              value={""}
              placeholderText="Post Type"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
      {!isCalendarView ? (
        <CustomCalendar
          startDate={dateRange?.startDateRange as Date}
          endDate={dateRange?.endDateRange as Date}
          activeDate={currentMonth}
          posts={postSchedulesWithData}
        />
      ) : (
        <ListViewSchedules
          groupedPosts={groupPostsByDayInMonth(
            postSchedulesWithData,
            currentMonth.getMonth(),
            currentMonth.getFullYear()
          )}
        />
      )}
    </div>
  );
};

export default PostSchedulesCalendarListView;
