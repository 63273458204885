import React from "react";
import styles from "./Table.module.scss";

type RowData = {
  id: number | string;
  [key: string]: any;
};

type InternalRowData = {
  id: number | string;
  [key: string]: any;
  checked: boolean;
};

type Column = {
  name: string;
  key: string;
};

type TableComponentProps = {
  columns: Column[];
  rows: RowData[];
  onRowSelect?: (row: RowData) => void;
  onRowSelectAll?: (rows: RowData[]) => void;
  rowClassName?: string;
};

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  rows,
  rowClassName,
  onRowSelect,
  onRowSelectAll,
}) => {
  // const [selectedRows, setSelectedRows] = React.useState<RowData[]>([]);
  const [internalRows, setInternalRows] = React.useState<InternalRowData[]>([]);

  React.useEffect(() => {
    setInternalRows((prevRows) => {
      return rows.map((row) => {
        const checked = prevRows.find((r) => r.id === row.id)?.checked || false;
        return {
          ...row,
          checked,
        };
      });
    });
  }, [rows]);

  return (
    <table className={styles["table"]}>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  onRowSelectAll && onRowSelectAll(rows);
                  setInternalRows(
                    internalRows.map((row) => {
                      return {
                        ...row,
                        checked: true,
                      };
                    })
                  );
                } else {
                  onRowSelectAll && onRowSelectAll([]);
                  setInternalRows(
                    internalRows.map((row) => {
                      return {
                        ...row,
                        checked: false,
                      };
                    })
                  );
                }
              }}
            />
          </th>
          {columns.map((column, index) => (
            <th key={index}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {internalRows.map((row, rowIndex) => {
          return (
            <tr key={rowIndex} className={rowClassName}>
              <td>
                <input
                  type="checkbox"
                  checked={row.checked}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setInternalRows(
                      internalRows.map((internalRow) => {
                        if (internalRow.id === row.id) {
                          return {
                            ...internalRow,
                            checked,
                          };
                        }
                        return internalRow;
                      })
                    );
                    onRowSelect && onRowSelect(row);
                  }}
                />
              </td>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column.key]}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableComponent;
