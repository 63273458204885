import React from "react";

import styles from "./MarketValueSteps.module.scss";
import DisplayResponse from "../../componenets/DisplayResponse/DisplayResponse";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import SuggestedPrompt from "./components/SuggestedPrompt/SuggestedPrompt";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import { TextElement } from "../../componenets";

const IdentifyValueStep: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCampaignNavigation = () => {
    // dispatch(clearConversation());
    // dispatch(setSelectedCampaign(-99));
    // navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
  };

  return (
    <MarkerterViewLayout
      className={styles["container-wrapper"]}
    >
      <div className={styles["container"]}>
        <DisplayResponse
          customInsights={
            <TextElement className={styles["custom-insights"]}>
              As of the moment, you have <span>23456 Known Contacts</span> ready
              for nurturing and <span>346 Marketing Qualified Leads</span>. How
              would you like to proceed/
            </TextElement>
          }
          query=""
          modelResponseId={-1}
          direction="left"
          showEllipses={false}
          iconClassName={styles["ninja-icon"]}
        />

        <div className={styles["suggested-actions"]}>
          <div className={styles["actions"]}>
            <SuggestedPrompt
              title="Start a Brand New Campaign"
              description={
                "I need assistance in starting new campaigns to attract new leads, from brainstorming to execution."
              }
              onClick={()=>{
                navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path)
              }}
            />
            <SuggestedPrompt
              title="Execute Campaign"
              description="I have a campaign strategy and content plan ready to get implemented."
              onClick={()=>{
                navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGINS_LIST.path)
              
              }}
            />
            <SuggestedPrompt
              title="Nurture My Known Contacts"
              description={
                "I want to know about my known contacts and create a strategy to nurture them"
              }
              onClick={()=>{
                navigate(ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path)
              }}
            />
            <SuggestedPrompt
              title="Shortlist the MQLs"
              description="I want to see how many MQLs we got and see if they qualify to progress to sales"
              onClick={()=>{
                navigate(ROUTE_DEFINITIONS.MARKETING_SHORTLIST_STAGE.path)
              }}
            />
          </div>
        </div>
      </div>
    </MarkerterViewLayout>
  );
};

export default IdentifyValueStep;
