import React, { ReactNode } from "react";

import classNames from "classnames";

import styles from "./DisplayResponseContainer.module.scss";

import DisplayResponse from "../DisplayResponse/DisplayResponse";
import { FastNinja, NinjaIcon, UserIcon } from "../../../../icons";

type IconType = "ninja" | "user" | "fast-ninja";
type IconPlacement = "left" | "right" | "none";

type DisplayResponseContainerProps = {
  children: ReactNode;
  className?: string;
  iconType?: IconType;
  contentClass?: string;
  iconPlacement?: IconPlacement;
};

const DisplayResponseContainer = (props: DisplayResponseContainerProps) => {
  const {
    children,
    className,
    iconType = "ninja",
    contentClass,
    iconPlacement,
  } = props;

  const getIcon = () => {
    if (iconType === "ninja") {
      return <NinjaIcon className={styles["ninja-icon"]} />;
    } else if (iconType === "user") {
      return <UserIcon className={styles["user-icon"]} />;
    } else if (iconType === "fast-ninja") {
      return <FastNinja className={styles["fast-ninja-icon"]} />;
    }
  };

  const getDirection = (): "left" | "right" => {
    if (iconPlacement === "left") {
      return "right";
    } else if (iconPlacement === "right") {
      return "left";
    } else {
      return "right";
    }
  };

  return (
    <div
      className={classNames(styles["container"], className, {
        [styles["icon-right"]]: iconPlacement === "right",
        [styles["icon-left"]]: iconPlacement === "left",
        [styles["icon-none"]]: iconPlacement === "none",
      })}
    >
      <DisplayResponse
        backgroundColor={iconType === "fast-ninja" ? "theme" : "white"}
        direction={getDirection()}
      >
        {children}
      </DisplayResponse>
      {getIcon()}
    </div>
  );
};

export default DisplayResponseContainer;
