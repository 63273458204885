import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { updateUserData, UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

interface ProtectedRoleRouteProps {
  children: React.ReactNode;
  requiredRole: UserRoleType[];
  className?: string;
}

const ProtectedRoleRoute: React.FC<ProtectedRoleRouteProps> = ({
  children,
  requiredRole,
  className,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate(ROUTE_DEFINITIONS.LOGIN.path);
    } else {
      const userData = JSON.parse(user);
      if (!requiredRole.includes(userData?.role)) {
        navigate(ROUTE_DEFINITIONS.LOGIN.path);
      }
      dispatch(updateUserData(userData));
    }
  }, []);

  return <div className={className}>{children}</div>;
};

export default ProtectedRoleRoute;
