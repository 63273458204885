import React, { ReactNode } from "react";


import styles from "./SuggestedPrompt.module.scss";
import classNames from "classnames";
import { TextElement } from "../../../../componenets";

interface SuggestedPromptProps {
  title?: string;
  description?: string;
  className?: string;
  content?: ReactNode;
  onClick?: () => void;
}

const SuggestedPrompt: React.FC<SuggestedPromptProps> = ({
  title,
  description,
  className,
  onClick,
  content,
}) => {
  return (
    <button className={classNames(styles["suggested-prompt"], className)} onClick={onClick}>
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles["icon"]}
      >
        <path
          d="M10 0L8.2375 1.7625L15.2125 8.75H0V11.25H15.2125L8.2375 18.2375L10 20L20 10L10 0Z"
          fill="#EB2701"
        />
      </svg>
      {content ? (
        content
      ) : (
        <div className={styles["body"]}>
          <TextElement className={styles["title"]}>{title}</TextElement>
          <TextElement className={styles["description"]}>
            {description}
          </TextElement>
        </div>
      )}
    </button>
  );
};

export default SuggestedPrompt;
