import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";
import { UserRoleType } from "./authSlice";
import { TypePredicateKind } from "typescript";

type TeamMemeberData = {
  firstName: string;
  email: string;
  role: UserRoleType;
  id: number;
  dateJoined: string;
};

interface AddTeamMemberResponse {
  message: string;
  success: boolean;
  data: TeamMemeberData[];
}

interface AddTeamMemberInput {
  name: string;
  email: string;
  role: UserRoleType;
}

interface TeamMemeberState {
  teamMembers: TeamMemeberData[];
  loading: boolean;
}

interface FetchTeamMemberResponse {
  data: TeamMemeberData[];
  success: boolean;
  message: string;
}

const initialState: TeamMemeberState = {
  teamMembers: [],
  loading: false,
};

const httpService = new HttpService();

export const addTeamMember = createAsyncThunk(
  "teamMember/addTeamMember",
  async (
    teamMemberData: AddTeamMemberInput
  ): Promise<AddTeamMemberResponse> => {
    const response = await httpService.post(
      "/api/auth/add_team_member/",
      teamMemberData
    );
    return response.data as AddTeamMemberResponse;
  }
);

export const fetchTeamMembers = createAsyncThunk<FetchTeamMemberResponse, void>(
  "teamMember/fetchTeamMembers",
  async (): Promise<FetchTeamMemberResponse> => {
    const response = await httpService.get("/api/auth/get_team_member/");
    return response.data as FetchTeamMemberResponse;
  }
);

const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addTeamMember.rejected, (state, action) => {
      state.loading = false;
    }),
      builder.addCase(addTeamMember.pending, (state, action) => {
        state.loading = true;
      });
    builder.addCase(fetchTeamMembers.fulfilled, (state, action) => {
      state.teamMembers = action.payload.data;
    });
  },
});

export const {} = teamMemberSlice.actions;

export default teamMemberSlice.reducer;
