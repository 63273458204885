import React, { useState } from "react";
import styles from "./ColorPicker.module.scss";
import TextElement from "../TextElement/TextElement";
import { MuiColorInput } from "mui-color-input";
import styled from "styled-components";

interface ColorPickerProps {
  // width?: string;
  value: string;
  onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  const { value, onChange } = props;
  // const { width } = props;

  const MuiColorInputStyled = styled(MuiColorInput)`
    &.MuiColorInput-TextField {
      background-color: #fff;
      border-radius: 0px;
      // height: 120px;
      position: relative;
      div {
        border-radius: 0px !important;
        border: none !important;
        button {
          height: 100%;
          width: 100%;
          // border-radius: 50%;
        }
      }
      fieldset {
        border: none !important;
      }
    }
  `;
  return (
    <div
      style={{
        position: "relative",
      }}
      className={styles["color-picker-container"]}
    >
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "30px",
          left: "40%"
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9167 39.7498C18.9167 40.8957 19.8542 41.8332 21 41.8332C22.1458 41.8332 23.0833 40.8957 23.0833 39.7498V23.0832H39.75C40.8958 23.0832 41.8333 22.1457 41.8333 20.9998C41.8333 19.854 40.8958 18.9165 39.75 18.9165H23.0833V2.24984C23.0833 1.104 22.1458 0.166504 21 0.166504C19.8542 0.166504 18.9167 1.104 18.9167 2.24984V18.9165H2.24999C1.10416 18.9165 0.166656 19.854 0.166656 20.9998C0.166656 22.1457 1.10416 23.0832 2.24999 23.0832H18.9167V39.7498Z"
          fill="#778BA8"
        />
      </svg>
      <MuiColorInputStyled
        className={styles["color-picker"]}
        format="hex"
        value={value}
        onChange={onChange}
        component={"div"}
      />
    </div>
  );
};

export default ColorPicker;
