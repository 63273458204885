import React, { useEffect } from "react";

import styles from "./CampaignQueryChat.module.scss";
import Button from "../Button/Button";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import TextElement from "../TextElement/TextElement";

interface GenerateContentButtonProps {}

const GenerateContentButton: React.FC<GenerateContentButtonProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const navigate = useNavigate();
  useEffect(() => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          activeRecommendationAnalysisPhase: true,
        })
      );
  }, []);

  return (
    <>
      <Button
        className={classNames(styles["card"], styles["continue-button"])}
        onClick={() => {
          navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_CONTENT_BUILDER.path);
          selectedCampaign &&
            dispatch(
              updateCampaign({
                ...selectedCampaign,
                activeContentStep: true,
              })
            );
        }}
      >
        <TextElement>Generate content?</TextElement>
      </Button>
    </>
  );
};

export default GenerateContentButton;
