import React, { ReactNode } from "react";

import styles from "./DisplayResponse.module.scss";
import classNames from "classnames";

type BackgroundColor = "white" | "theme";
type DisplayDirection = "left" | "right";

type DisplayResponseProps = {
  children: ReactNode | string;
  backgroundColor?: BackgroundColor;
  direction?: DisplayDirection;
  className?: string;
};

const DisplayResponse: React.FC<DisplayResponseProps> = ({
  children,
  backgroundColor = "white",
  direction = "right",
  className,
}) => {
  const bgColor =
    backgroundColor === "theme"
      ? styles["theme-background"]
      : styles["white-background"];
  const directionClass =
    direction === "left" ? styles["left-direction"] : styles["right-direction"];

  return (
    <div className={styles["container"]}>
      <div
        className={classNames(
          styles["response-content"],
          directionClass,
          className
        )}
      >
        <div
          className={classNames(
            styles["content-container"],
            bgColor,
            directionClass
          )}
        >
          <div>{children}</div>
        </div>
        <svg
          width="88"
          height="71"
          viewBox="0 0 88 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(
            styles["svg"],
            backgroundColor === "white"
              ? styles["white-svg"]
              : styles["theme-svg"],
            directionClass === "left" ? styles["left-svg"] : styles["right-svg"]
          )}
          style={
            direction === "left"
              ? { transform: "scale(-1, 1)" }
              : { transform: "scale(1, 1)" }
          }
        >
          <path
            d="M87.9031 37.1328L78.15 39.2448C46.1107 46.1827 13.5697 29.8415 0 0V68.6328C25.8927 74.297 52.9401 67.4138 72.9753 50.0615L87.9031 37.1328Z"
            fill={
              bgColor === styles["theme-background"] ? "#EB2701" : "#FFFFFF"
            }
          />
        </svg>
      </div>
      <div
        className={classNames(
          styles["response-content-shadow"],
          directionClass,
          className
        )}
      >
        <div
          className={classNames(
            styles["content-container"],
            bgColor,
            directionClass
          )}
        ></div>
        <svg
          width="88"
          height="71"
          viewBox="0 0 88 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(
            styles["svg"],
            backgroundColor === "white"
              ? styles["white-svg"]
              : styles["theme-svg"],
            directionClass === "left" ? styles["left-svg"] : styles["right-svg"]
          )}
          style={
            direction === "left"
              ? { transform: "scale(-1, 1)" }
              : { transform: "scale(1, 1)" }
          }
        >
          <path
            d="M87.9031 37.1328L78.15 39.2448C46.1107 46.1827 13.5697 29.8415 0 0V68.6328C25.8927 74.297 52.9401 67.4138 72.9753 50.0615L87.9031 37.1328Z"
            fill={
              bgColor === styles["theme-background"] ? "#C82202" : "#F2F2F2"
            }
          />
        </svg>
      </div>
    </div>
  );
};

export default DisplayResponse;
