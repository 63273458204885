import React from "react";
import styles from "./ListViewSchedules.module.scss";
import { PostSchedule } from "../usePostSchedulesCalendarListView";
import TextElement from "../../TextElement/TextElement";
import UpdatePostContentModal from "../../UpdatePostContentModal/UpdatePostContentModal";

interface Props {
  groupedPosts: Record<string, PostSchedule[]> | null;
}

const ListViewSchedules: React.FC<Props> = (props) => {
  const { groupedPosts } = props;
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState<
    PostSchedule | undefined
  >(undefined);

  if (groupedPosts === null) {
    return <div>No posts</div>;
  }

  return (
    <div
      className={styles['container']}
    >
      <div className={styles["list-view-schedules"]}>
        <UpdatePostContentModal
          isOpen={isEditModalOpen}
          closeModal={() => setIsEditModalOpen(false)}
          post={selectedPost as PostSchedule}
          onOk={(text) => {}}
        />
        {Object.entries(groupedPosts).map(([key, posts], index) => {
          return (
            <div key={index} className={styles["schedules"]}>
              <TextElement className={styles["date"]}>{key}</TextElement>
              <div className={styles["posts"]}>
                {posts.map((post, index) => {
                  return (
                    <div className={styles["post-container"]} key={index}>
                      <div key={index} className={styles["post"]}>
                        <img src="infotech-social.png" />
                        <span className={styles["value-proposition"]}>
                          LinkedIn
                        </span>
                        <TextElement className={styles["time"]}>
                          {new Date(post.published_time).toLocaleTimeString()}
                        </TextElement>
                        <TextElement className={styles["text"]}>
                          {post?.data?.text as string}
                        </TextElement>
                      </div>
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles["edit-icon"]}
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedPost(post);
                        }}
                      >
                        <path
                          d="M4.06836 4.25005H3.23503C2.793 4.25005 2.36908 4.42564 2.05651 4.7382C1.74395 5.05076 1.56836 5.47468 1.56836 5.91671V13.4167C1.56836 13.8587 1.74395 14.2827 2.05651 14.5952C2.36908 14.9078 2.793 15.0834 3.23503 15.0834H10.735C11.1771 15.0834 11.601 14.9078 11.9135 14.5952C12.2261 14.2827 12.4017 13.8587 12.4017 13.4167V12.5834M11.5684 2.58338L14.0684 5.08338M15.2225 3.9042C15.5507 3.57599 15.7351 3.13085 15.7351 2.6667C15.7351 2.20255 15.5507 1.75741 15.2225 1.4292C14.8943 1.10099 14.4492 0.916611 13.985 0.916611C13.5209 0.916611 13.0757 1.10099 12.7475 1.4292L5.73503 8.4167V10.9167H8.23503L15.2225 3.9042Z"
                          stroke="#02285E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListViewSchedules;
