import React from "react";
import styles from "./CMOHome.module.scss";

import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import { TextElement } from "../../componenets";
import MarketingReport from "../../componenets/CampaignReports/MarketingReport";

const CMOHome: React.FC = () => {
  return (
    <CMOViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          My Campaigns
        </TextElement>
      }
    >
      <MarketingReport className={styles["campaign-report"]} />
    </CMOViewLayout>
  );
};

export default CMOHome;
