import React from "react";

import styles from "./AuthLayout.module.scss";
import Card from "../Card/Card";
import { NinjaIcon } from "../../icons";
import ErikoText from "../../icons/ErikoText";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <NinjaIcon className={styles["ninja-icon"]} />
          <ErikoText className={styles["eriko-text"]} />
        </div>
        {props.children}
      </div>
      <img className={styles["sideNinja"]} src="sideNinja.svg" />
    </div>
  );
};

export default AuthLayout;
