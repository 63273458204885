import React from "react";

const LogoutIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5556 5.55556L13.9889 7.12222L15.7444 8.88889H6.66667V11.1111H15.7444L13.9889 12.8667L15.5556 14.4444L20 10L15.5556 5.55556ZM2.22222 2.22222H10V0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H10V17.7778H2.22222V2.22222Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default LogoutIcon;
