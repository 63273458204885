import React, { useEffect } from "react";

import styles from "./MarketingBrief.module.scss";
import { TextElement } from "../../../../componenets";
import classNames from "classnames";
import { useAppSelector } from "../../../../redux/hooks";

interface MarketingBriefProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MarketingBrief: React.FC<MarketingBriefProps> = ({
  className,
  onClick,
}) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className={styles["row-content"]}>
        <TextElement className={styles["title"]}>Product</TextElement>
        <TextElement className={styles["content"]}>
          {selectedCampaign?.productName}
        </TextElement>
      </div>
      <div className={styles["row-content"]}>
        <TextElement className={styles["title"]}>Campaign Overview</TextElement>
        <TextElement className={styles["content"]}>
          {selectedCampaign?.campaignOverview}
        </TextElement>
      </div>
      <div className={styles["row-content"]}>
        <TextElement className={styles["title"]}>
          Campaign Objective
        </TextElement>
        <TextElement className={styles["content"]}>
          {selectedCampaign?.campaignObjective}
        </TextElement>
      </div>
      <div className={styles["col-content"]}>
        <div className={styles["content-container"]}>
          <TextElement className={styles["title"]}>Campaign Budget</TextElement>
          <TextElement className={styles["content"]}>
            ${selectedCampaign?.campaignBudget}
          </TextElement>
        </div>
        <div className={styles["content-container"]}>
          <TextElement className={styles["title"]}>
            Target Start Date
          </TextElement>
          <TextElement className={styles["content"]}>
            {selectedCampaign?.campaignStartDate &&
              new Date(selectedCampaign?.campaignStartDate).toDateString()}
          </TextElement>
        </div>
      </div>
    </div>
  );
};

export default MarketingBrief;
