import React from "react";

const ErikoText: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="158"
      height="27"
      viewBox="0 0 158 27"
      fill="#02285E"
      xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
        d="M7.70977 9.92267H16.0485V15.8221H7.70977V20.0291H17.2148V26.1865H0V0.384766H16.8523V6.23608H7.70977V9.92267Z"
        fill="#02285E"
      />
      <path
        d="M35.3251 20.0686C37.1947 19.4083 38.8589 18.3193 40.5232 17.2348L40.0864 16.452C42.8645 14.7552 43.8081 12.4287 43.8517 9.48116C43.8517 4.02343 39.6408 0.371826 33.2721 0.371826H22.1901V26.1736H29.9436V18.0788H32.9008L34.2331 20.7421C34.5427 20.4393 34.9157 20.2092 35.3251 20.0686ZM29.8082 12.5774V6.1969H33.1978C33.5859 6.17075 33.9753 6.22139 34.3438 6.34592C34.7123 6.47045 35.0528 6.66643 35.3456 6.92267C35.6385 7.17892 35.8781 7.4904 36.0507 7.83935C36.2233 8.18829 36.3256 8.56786 36.3516 8.95637C36.3604 9.08159 36.3604 9.20726 36.3516 9.33247C36.3516 11.2873 35.098 12.5774 33.2153 12.5774H29.8082Z"
        fill="#02285E"
      />
      <path
        d="M44.9263 21.3848C43.5678 21.6996 42.4496 21.4941 41.8162 19.9853C41.1086 18.3848 40.5931 17.0816 39.8506 15.9839C39.3881 16.2032 38.8918 16.3422 38.3829 16.395C35.976 16.7142 33.6871 17.6107 31.2497 17.8731C31.0742 17.9206 30.894 17.9485 30.7124 17.9562C31.1832 17.9186 31.657 17.9675 32.1102 18.1005C33.3071 18.5378 33.888 19.9985 35.098 22.9504C36.308 25.9023 39.3919 28.0495 45.5816 25.8585L44.9263 21.3848Z"
        fill="#02285E"
      />
      <path
        d="M57.1222 0.371826H48.9974C48.9974 0.559873 49.4343 24.35 49.4692 26.1736H57.5721C57.4935 24.3456 57.1222 2.16046 57.1222 0.371826Z"
        fill="#02285E"
      />
      <path
        d="M69.1259 10.4956C69.9296 10.4956 71.6812 10.5699 72.5767 10.0933C73.6949 9.50289 74.0531 7.95041 74.8175 4.84545C75.582 1.7405 78.3121 -0.83968 84.7682 0.419796V4.95915C83.3791 4.84982 82.3045 5.21717 81.8983 6.80464C81.0247 9.90085 80.1074 12.0787 78.7183 13.6662C81.1382 17.5014 83.4665 21.3717 85.9781 25.207V26.2041H77.1938L71.904 16.2288C70.9812 16.3243 70.0533 16.3608 69.1259 16.3382V26.1866H61.368V0.38481H69.1259V10.4956Z"
        fill="#02285E"
      />
      <path
        d="M100.065 26.5539C91.8183 26.5539 86.3013 21.2449 86.3013 13.277C86.3013 5.30904 91.8183 0 100.065 0C108.312 0 113.738 5.31341 113.738 13.277C113.738 21.2405 108.269 26.5539 100.065 26.5539ZM100.065 20.2478C103.608 20.2478 105.936 17.4096 105.936 13.277C105.936 9.14432 103.608 6.34548 100.065 6.34548C96.4354 6.34548 94.0155 9.14869 94.0155 13.277C94.0155 17.4052 96.4354 20.2741 100.065 20.2741V20.2478Z"
        fill="#02285E"
      />
      <path
        d="M139.317 26.8252L137.915 23.5803H128.891L127.489 26.8252H119.281V26.3879L131.481 0.32373H135.382L147.433 26.3879V26.8252H139.317ZM135.692 18.2844C134.67 15.8916 133.896 13.4005 133.381 10.85C132.795 13.3767 132.037 15.8606 131.114 18.2844H135.692Z"
        fill="#02285E"
      />
      <path
        d="M157.581 0.122559H149.963C149.963 0.319352 150.399 25.089 150.399 27.0001H158C157.93 25.0759 157.581 1.98991 157.581 0.122559Z"
        fill="#02285E"
      />
    </svg>
  );
};

export default ErikoText;
