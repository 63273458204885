import React from "react";

const CreateLeadsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="157"
      height="161"
      viewBox="0 0 157 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <path
          d="M84.826 160.233C113.106 160.233 136.032 157.47 136.032 154.06C136.032 150.651 113.106 147.888 84.826 147.888C56.5455 147.888 33.6196 150.651 33.6196 154.06C33.6196 157.47 56.5455 160.233 84.826 160.233Z"
          fill="#EB2701"
        />
      </g>
      <path
        d="M116.795 94.2197C116.345 97.4342 114.519 100.926 109.241 102.711L104.218 92.3348L98.2669 80.0426L86.501 59.1611C86.501 59.1611 107.16 61.9389 115.744 86.4165C115.844 86.6943 115.94 86.9787 116.036 87.2665C116.034 87.2665 117.309 90.5609 116.795 94.2197Z"
        fill="#01254F"
      />
      <path
        d="M109.244 102.71L104.22 92.3343C114.303 90.1243 115.745 86.416 115.745 86.416C115.845 86.6938 115.94 86.9782 116.037 87.266C116.037 87.266 120.532 98.892 109.244 102.71Z"
        fill="#F2C5A7"
      />
      <path
        d="M124.387 152.84C117.663 153.634 98.7061 156.055 97.4224 153.729C94.3718 148.198 102.737 139.902 102.737 139.902C93.191 109.482 70.2615 116.939 70.2615 116.939C71.7478 121.456 73.92 131.6 74.8373 135.457C75.625 138.766 79.1583 150.758 79.2657 152.593C79.315 153.433 67.7517 155.161 67.7517 155.161C63.7174 155.901 44.97 158.321 47.2335 152.494C48.9285 148.13 53.5806 141.828 53.5806 141.828C53.5806 141.828 47.295 121.05 46.4951 116.05C45.6952 111.05 44.1829 104.643 44.1829 104.643C80.8838 104.94 103.817 93.1113 103.817 93.1113C114.576 105.431 122.892 129.59 123.993 138.42C124.655 143.715 126.06 152.643 124.387 152.84Z"
        fill="#02285E"
      />
      <path
        d="M102.15 90.1247C102.15 90.1247 93.6036 98.1075 72.9293 101.879L72.8967 101.885C52.1997 105.656 42.9071 101.581 42.9071 101.581C38.9425 82.3387 43.1036 71.1621 43.1036 71.1621C43.1036 71.1621 45.1772 66.1622 65.7574 62.4111C86.3376 58.66 86.3548 58.125 86.3548 58.125C86.3548 58.125 91.6623 65.2361 93.9817 69.6805C96.3188 74.156 97.8201 80.742 102.15 90.1247Z"
        fill="#02285E"
      />
      <path
        d="M59.6746 71.8648L68.4778 77.8614L74.0654 69.2998L59.6746 71.8648Z"
        fill="#000072"
      />
      <path
        d="M72.6073 98.6174C89.5041 95.5396 100.28 86.2725 100.28 86.2725C100.28 86.2725 102.346 88.9391 103.918 93.1119C103.918 93.1119 94.0383 102.278 73.9314 105.94C53.8245 109.601 44.284 105.532 44.284 105.532C41.6601 101.518 42.2175 97.2352 42.2175 97.2352C42.2175 97.2352 55.7077 101.695 72.6073 98.6174Z"
        fill="#EB2701"
      />
      <g opacity="0.3">
        <path
          d="M102.315 89.5742C102.315 89.5742 93.2968 98.723 73.1905 102.386C53.0841 106.049 42.7093 101.087 42.7093 101.087C42.2665 100.409 42.7874 103.587 44.1857 105.729C44.1857 105.729 53.7263 109.602 73.8332 105.939C93.9401 102.276 103.909 93.1114 103.909 93.1114C103.834 92.6853 102.623 90.1548 102.315 89.5742Z"
          fill="#000072"
        />
      </g>
      <path
        d="M64.1011 109.861C64.1011 109.861 67.4108 119.372 69.8686 128.488C69.8686 128.488 73.1728 127.432 75.1113 124.285C75.1113 124.285 70.9519 111.822 68.6347 107.941L64.1011 109.861Z"
        fill="#EB2701"
      />
      <g opacity="0.3">
        <path
          d="M64.0994 109.863L64.2195 110.212L66.2483 109.353C68.5655 113.234 72.7249 125.696 72.7249 125.696C72.0986 126.7 71.2962 127.583 70.3568 128.3C71.3482 127.877 73.6278 126.694 75.1102 124.287C75.1102 124.287 70.9508 111.824 68.6336 107.943L64.0994 109.863Z"
          fill="#000072"
        />
      </g>
      <path
        d="M73.6845 106.105C73.6845 106.105 82.2043 111.433 90.5271 115.839C90.5271 115.839 88.7767 118.844 85.291 120.05C85.291 120.05 74.0881 113.244 70.8237 110.124L73.6845 106.105Z"
        fill="#EB2701"
      />
      <g opacity="0.3">
        <path
          d="M73.6865 106.104L73.9993 106.299L72.7206 108.097C75.9866 111.217 87.1878 118.023 87.1878 118.023C88.3016 117.63 89.3353 117.038 90.239 116.276C89.6102 117.154 87.9606 119.127 85.2946 120.049C85.2946 120.049 74.0917 113.243 70.8274 110.123L73.6865 106.104Z"
          fill="#000072"
        />
      </g>
      <path
        d="M72.7739 98.919C74.9698 99.2801 76.692 101.054 76.9483 103.272C77.2699 106.06 76.2325 109.78 68.8591 110.647C63.9828 111.219 61.5151 109.936 60.3083 108.194C57.6318 104.333 61.2189 99.1101 65.7265 100.399C66.0154 100.482 66.2976 100.587 66.5707 100.712C66.5707 100.712 68.8945 98.2818 72.7739 98.919Z"
        fill="#EB2701"
      />
      <g opacity="0.3">
        <path
          d="M68.3806 106.51C63.5043 107.083 61.0366 105.8 59.8298 104.058C59.7623 103.961 59.7014 103.862 59.6416 103.763C59.1921 105.162 59.325 106.772 60.3125 108.197C61.5193 109.938 63.9871 111.222 68.8633 110.649C76.2367 109.782 77.2741 106.062 76.9525 103.275C76.8771 102.628 76.674 102.002 76.3552 101.435C75.8205 103.666 73.8354 105.869 68.3806 106.51Z"
          fill="#000072"
        />
      </g>
      <g opacity="0.11">
        <path
          d="M65.9541 102.328C66.243 102.411 66.5252 102.516 66.7983 102.641C66.7983 102.641 69.1199 100.211 73.0015 100.848C73.9266 100.999 74.7911 101.407 75.4973 102.026C76.2034 102.644 76.7231 103.449 76.9976 104.348C77.0081 103.988 76.9924 103.629 76.9506 103.271C76.6943 101.053 74.9722 99.2791 72.7762 98.918C68.8946 98.2808 66.573 100.711 66.573 100.711C66.2999 100.586 66.0177 100.481 65.7288 100.398C61.7333 99.2558 58.4645 103.231 59.6397 106.836C59.8761 103.907 62.6704 101.389 65.9541 102.328Z"
          fill="white"
        />
      </g>
      <path
        d="M77.2079 80.3244C76.6886 80.324 76.1719 80.2517 75.6723 80.1094C74.4222 79.7548 73.3332 78.9761 72.5909 77.906C71.8487 76.836 71.4991 75.5407 71.6018 74.241C71.7045 72.9412 72.253 71.7174 73.154 70.7781C74.0549 69.8387 75.2525 69.242 76.5428 69.0895C77.833 68.937 79.136 69.2382 80.2297 69.9418C81.3235 70.6454 82.1403 71.7078 82.541 72.948C82.9418 74.1883 82.9016 75.5296 82.4273 76.7434C81.9531 77.9573 81.0742 78.9686 79.9403 79.6049C79.106 80.0759 78.1651 80.3236 77.2079 80.3244ZM77.1946 71.2728C76.6752 71.2736 76.1628 71.3938 75.6968 71.6241C75.2308 71.8545 74.8236 72.189 74.5063 72.6018C74.1891 73.0147 73.9704 73.495 73.8669 74.0059C73.7634 74.5168 73.7778 75.0447 73.9092 75.5491C74.0405 76.0535 74.2852 76.521 74.6245 76.9157C74.9639 77.3105 75.3888 77.622 75.8667 77.8263C76.3446 78.0306 76.8628 78.1224 77.3815 78.0945C77.9003 78.0666 78.4057 77.9199 78.8592 77.6655C79.5157 77.2961 80.0317 76.7186 80.3263 76.0235C80.6208 75.3284 80.6774 74.5548 80.4871 73.8239C80.2968 73.0931 79.8704 72.4462 79.2746 71.9846C78.6788 71.5229 77.9473 71.2726 77.1946 71.2728Z"
        fill="#000072"
      />
      <path
        d="M94.9563 82.8842C90.2333 82.6469 85.8657 80.0375 83.7467 75.6553C80.5837 69.1126 83.6128 61.0588 90.5001 57.7021L104.076 51.085L106.418 55.9277L92.8417 62.5454C88.7658 64.5321 86.974 69.2981 88.8455 73.1703C89.7523 75.0459 91.3736 76.4348 93.4113 77.0803C95.4489 77.7259 97.6582 77.552 99.6327 76.5892L113.012 70.0715L115.354 74.9148L101.974 81.4325C99.7941 82.5019 97.3801 83.0012 94.9563 82.8842Z"
        fill="#FF735D"
      />
      <path
        d="M106.533 73.227L108.891 78.0619L115.354 74.9136L113.012 70.0703L106.533 73.227Z"
        fill="white"
      />
      <path
        d="M99.6955 59.2043L106.418 55.9277L104.076 51.085L97.3379 54.3694L99.6955 59.2043Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M94.9582 82.8822C90.2353 82.645 85.8677 80.0356 83.7487 75.6534C80.5856 69.1106 83.6147 61.0568 90.5021 57.7002L104.078 51.083L106.42 55.9257L92.8436 62.5435C88.7678 64.5301 86.9759 69.2962 88.8475 73.1684C89.7542 75.0439 91.3756 76.4328 93.4132 77.0784C95.4509 77.7239 97.6601 77.55 99.6347 76.5873L113.014 70.0695L115.356 74.9128L101.976 81.4305C99.7961 82.4999 97.382 82.9993 94.9582 82.8822Z"
          fill="#6A0400"
        />
      </g>
      <path
        d="M93.944 80.809C89.221 80.5723 84.8535 77.9623 82.735 73.5801C79.5714 67.038 82.601 58.9841 89.4884 55.6269L103.064 49.0098L105.406 53.8531L91.8294 60.4697C87.7536 62.4563 85.9617 67.223 87.8327 71.0946C88.74 72.9707 90.3614 74.359 92.399 75.0051C94.4367 75.6512 96.6459 75.4762 98.6199 74.514L111.999 67.9963L114.341 72.839L100.961 79.3584C98.7815 80.4277 96.3676 80.9266 93.944 80.809Z"
        fill="#FF2200"
      />
      <path
        d="M105.523 71.1298L107.88 75.9648L114.35 72.8132L112.011 67.9688L105.523 71.1298Z"
        fill="white"
      />
      <path
        d="M98.6852 57.1297L105.408 53.8531L103.066 49.0098L96.3276 52.2942L98.6852 57.1297Z"
        fill="white"
      />
      <path
        d="M87.6467 74.044C87.6605 78.4995 85.0588 82.78 80.7521 84.7217C79.0028 85.5106 77.201 86.2661 75.377 86.9911C64.4581 91.33 52.6839 94.5022 46.163 96.1133C43.6046 96.7489 40.9256 96.7068 38.3883 95.991C36.8257 95.5526 35.3515 94.8438 34.0318 93.8966C33.2327 93.3221 32.4994 92.6606 31.8452 91.9244C24.8289 84.03 35.4256 68.1979 35.4256 68.1979C35.4256 68.1979 44.7215 66.4885 50.3844 66.7163L49.8923 72.6423C57.2751 69.3396 66.5749 67.6907 66.5749 67.6907C69.4423 61.5297 74.7399 65.5463 74.7399 65.5463C74.7399 65.5463 82.9243 61.7963 86.42 68.9407C86.4947 69.0879 86.5639 69.2379 86.6303 69.3851C86.6298 69.3879 86.6298 69.3907 86.6303 69.3935C87.2945 70.8536 87.641 72.439 87.6467 74.044Z"
        fill="#01254F"
      />
      <path
        d="M80.7538 84.7231C79.0045 85.512 77.2027 86.2675 75.3787 86.9925C75.4839 73.0815 66.5771 67.6927 66.5771 67.6927C69.4446 61.5316 74.7421 65.5482 74.7421 65.5482C74.7421 65.5482 82.9265 61.7983 86.4222 68.9427C86.4969 69.0899 86.5661 69.2399 86.6326 69.3871C89.2725 75.2121 86.5717 82.1009 80.7538 84.7231Z"
        fill="#F2C5A7"
      />
      <path
        d="M21.3206 33.7027C21.3206 33.7027 23.1274 39.6249 17.0083 43.5499C10.8893 47.4748 4.74925 45.4554 4.74925 45.4554L2.20288 36.5188C2.20288 36.5188 8.13204 37.5844 11.1323 36.5188C14.1326 35.4533 19.2746 28.7139 19.2746 28.7139"
        fill="#EB2701"
      />
      <path
        d="M72.1203 69.6268C62.7757 72.3417 46.9007 73.6512 41.6458 71.5112C34.6643 68.6684 27.9363 62.5029 25.2472 57.7591C22.6609 53.1913 14.3664 35.5804 23.9994 18.4216C33.0025 2.38452 60.1916 -9.99483 82.96 13.0189C89.0414 20.3644 93.595 40.097 90.1043 51.1914C87.838 58.3902 81.1925 66.9901 72.1203 69.6268Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M20.2715 58.784C20.2953 58.689 20.318 58.5946 20.3423 58.4996L20.3119 58.3984C20.2964 58.5262 20.2837 58.6557 20.2715 58.784Z"
          fill="white"
        />
      </g>
      <path
        d="M36.3004 35.229C33.0842 36.4512 31.0931 39.5451 31.3826 42.8234C31.8254 47.8284 34.8368 54.8839 39.6029 57.3422C46.1393 60.7133 51.283 51.2839 63.179 49.5795C75.4945 47.815 76.8519 51.135 83.4076 47.3517C87.3739 45.0645 85.9756 27.7469 81.4469 26.3396C74.6564 24.2286 71.9606 30.9696 60.1599 33.1618C52.2811 34.6251 41.6317 33.2051 36.3004 35.229Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6714 29.1937C16.5544 24.6148 12.024 25.8387 8.34121 26.6026C4.65839 27.3664 1.13388 23.207 1.13388 23.207C-0.748218 25.8037 1.43944 34.9758 1.43944 34.9758C1.43944 34.9758 10.334 32.8475 12.6036 32.8731C14.8732 32.8986 19.5425 35.3808 19.5425 35.3808L19.9001 35.2553"
        fill="#EB2701"
      />
      <path
        d="M87.2613 21.5665C87.2613 21.5665 50.0002 34.8292 18.7485 35.5664C18.7485 35.5664 16.4739 35.6736 16.4695 31.622C16.4651 27.5704 21.3275 27.3587 21.3275 27.3587C21.3275 27.3587 47.4156 24.6526 56.6562 22.7032C65.8967 20.7538 83.8669 15.2488 84.4736 15.2588C85.7899 15.2805 87.0908 19.0254 87.2613 21.5665Z"
        fill="#EB2701"
      />
      <path
        d="M59.711 38.3259C57.256 41.9926 53.4874 44.4287 49.277 45.0692C45.4519 45.417 43.5361 44.612 41.0971 41.4515C38.9615 37.8359 39.1043 36.8076 39.1043 36.8076L59.711 38.3259Z"
        fill="#EB2701"
      />
      <path
        d="M78.7195 36.4537C77.3235 39.0264 74.6232 40.4753 71.8255 40.1481C68.8363 40.0409 65.9412 38.9948 63.5061 37.142L78.5075 27.9971C78.5075 27.9971 79.4248 34.8498 78.7195 36.4537Z"
        fill="#EB2701"
      />
      <path
        d="M114.455 36.7241C118.545 36.7241 121.862 33.3959 121.862 29.2903C121.862 25.1847 118.545 21.8564 114.455 21.8564C110.364 21.8564 107.047 25.1847 107.047 29.2903C107.047 33.3959 110.364 36.7241 114.455 36.7241Z"
        fill="#00235F"
      />
      <path
        d="M115.261 29.5177C116.025 29.2065 116.574 28.426 116.59 27.4993C116.608 26.2855 115.704 25.2866 114.569 25.2688C113.435 25.251 112.501 26.2205 112.483 27.4354C112.469 28.371 113.004 29.1727 113.769 29.5027C110.603 29.9471 110.708 33.3915 110.708 33.3915L118.181 33.5093C118.181 33.5093 118.39 30.0265 115.261 29.5177Z"
        fill="white"
      />
      <path
        d="M142.071 52.79C150.03 52.79 156.483 46.3146 156.483 38.3267C156.483 30.3388 150.03 23.8633 142.071 23.8633C134.112 23.8633 127.66 30.3388 127.66 38.3267C127.66 46.3146 134.112 52.79 142.071 52.79Z"
        fill="#00235F"
      />
      <path
        d="M143.636 38.7705C145.122 38.1639 146.19 36.6457 146.218 34.8423C146.254 32.4808 144.491 30.5371 142.287 30.5024C140.083 30.4678 138.262 32.3543 138.229 34.7166C138.201 36.5365 139.243 38.0963 140.733 38.7385C134.573 39.599 134.775 46.3099 134.775 46.3099L149.315 46.5387C149.315 46.5387 149.722 39.7585 143.636 38.7705Z"
        fill="white"
      />
      <path
        d="M117.79 61.1031C121.871 61.1031 125.179 57.7831 125.179 53.6878C125.179 49.5924 121.871 46.2725 117.79 46.2725C113.709 46.2725 110.401 49.5924 110.401 53.6878C110.401 57.7831 113.709 61.1031 117.79 61.1031Z"
        fill="#00235F"
      />
      <path
        d="M118.591 53.9125C119.352 53.6012 119.9 52.8229 119.915 51.8947C119.933 50.6841 119.031 49.6874 117.9 49.6702C116.768 49.6529 115.836 50.6192 115.818 51.8321C115.803 52.7646 116.337 53.5616 117.1 53.8938C113.941 54.3348 114.047 57.7758 114.047 57.7758L121.502 57.8933C121.502 57.8933 121.711 54.4206 118.591 53.9125Z"
        fill="white"
      />
      <path
        d="M138.069 74.4607C142.097 74.4607 145.362 71.184 145.362 67.142C145.362 63.0999 142.097 59.8232 138.069 59.8232C134.042 59.8232 130.777 63.0999 130.777 67.142C130.777 71.184 134.042 74.4607 138.069 74.4607Z"
        fill="#00235F"
      />
      <path
        d="M138.86 67.3621C139.248 67.1955 139.579 66.9184 139.811 66.5652C140.043 66.2121 140.167 65.7983 140.168 65.3751C140.185 64.18 139.29 63.1966 138.178 63.179C137.066 63.1613 136.141 64.1158 136.124 65.3108C136.111 65.7376 136.224 66.1588 136.449 66.521C136.675 66.8832 137.002 67.1702 137.39 67.3458C134.273 67.7815 134.379 71.1777 134.379 71.1777L141.735 71.2935C141.735 71.2935 141.94 67.8646 138.86 67.3621Z"
        fill="white"
      />
    </svg>
  );
};

export default CreateLeadsIcon;
