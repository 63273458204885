import React from "react";
import "./App";
import "./styles/index.scss";
import AppRoutes from "./pages/AppRoutes";


function App() {
  return <AppRoutes />;
}

export default App;
