import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isHttpResponseError } from "../lib/helpers";
import HttpService from "../lib/api";
import { toast } from "react-toastify";

type CommentPointType = "content" | "analysis";

interface CreateCommentInput {
  text: string;
  profileImage: string;
  user: string;
  commentPoint: string;
  campaignPlan: string;
}

interface CreateCommentResponse {
  success: boolean;
  message?: string;
  data?: Comment;
}

interface CreateCommentPointInput {
  campaignPlan: string;
  comments: {
    text: string;
    profileImage: string;
    user: string;
  }[];
  type: CommentPointType;
  style?: React.CSSProperties;
}

interface CreateCommentPointResponse {
  success: boolean;
  message?: string;
  data?: CommentPoint;
}

export interface Comment {
  id: string;
  text: string;
  profileImage: string;
  timeStamp: string;
  commentPoint: string;
  createdAt: string;
  userName: string;
}

export interface CommentPoint {
  id: string;
  style?: React.CSSProperties;
  comments: Comment[];
  type: CommentPointType;
  campaignPlan: string;
}

interface CampaignCommentsState {
  campaignComments: CommentPoint[];
  loading?: boolean;
  errorMessage?: string;
}

interface FetchCommentPointsResponse {
  success: boolean;
  data?: CommentPoint[];
}

const initialState: CampaignCommentsState = {
  campaignComments: [],
  loading: false,
  errorMessage: "",
};

const httpService = new HttpService();

export const createCommentPoint = createAsyncThunk<
  CreateCommentPointResponse,
  CreateCommentPointInput
>("campaignComments/addCommentPoint", async (input) => {
  try {
    const response = await httpService.post<
      CommentPoint,
      CreateCommentPointInput
    >("/api/comment-points/", input);
    return {
      success: true,
      data: response.data,
      message: "Comment point added successfully",
    };
  } catch (error) {
    if (isHttpResponseError(error)) {
      return {
        success: false,
        message: error.data.message,
      };
    }
    return {
      success: false,
      message: "Failed to send OTP",
    };
  }
});

export const addComment = createAsyncThunk<
  CreateCommentResponse,
  CreateCommentInput
>("campaignComments/addComment", async (comment) => {
  try {
    const response = await httpService.post<Comment, CreateCommentInput>(
      "/api/comments/",
      comment
    );
    return {
      success: true,
      data: response.data,
      message: "Comment added successfully",
    };
  } catch (error) {
    if (isHttpResponseError(error)) {
      throw new Error(
        JSON.stringify({
          success: false,
          message: error.data.message,
        })
      );
    }
    throw new Error(
      JSON.stringify({
        success: false,
        message: "Failed to Create Comment",
      })
    );
  }
});

export const fetchCommentPoints = createAsyncThunk<
  FetchCommentPointsResponse,
  { campaign_plan_id?: string }
>(
  "campaignComments/fetchCommentPoints",
  async ({ campaign_plan_id }: { campaign_plan_id?: string }) => {
    let url = "/api/comment-points/";
    if (campaign_plan_id) {
      url = `/api/comment-points/?campaign_plan_id=${campaign_plan_id}`;
    }
    const response = await httpService.get<FetchCommentPointsResponse>(url);
    return response.data;
  }
);

const campaignCommentsSlice = createSlice({
  name: "campaignComments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchCommentPoints
    builder.addCase(fetchCommentPoints.fulfilled, (state, action) => {
      state.campaignComments = action.payload?.data || [];
      state.loading = false;
    });
    builder.addCase(fetchCommentPoints.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message || "";
      throw new Error(state.errorMessage);
    });
    builder.addCase(fetchCommentPoints.pending, (state, action) => {
      state.loading = true;
    });

    // create comment point
    builder.addCase(createCommentPoint.fulfilled, (state, action) => {
      state.campaignComments.push(action.payload.data as CommentPoint);
      state.loading = false;
    });
    builder.addCase(createCommentPoint.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message || "";
    });
    builder.addCase(createCommentPoint.pending, (state, action) => {
      state.loading = true;
    });

    // add comment
    builder.addCase(addComment.fulfilled, (state, action) => {
      const comment = action.payload.data as Comment;
      const commentPoint = state.campaignComments.find(
        (cp) => cp.id === comment.commentPoint
      );
      if (commentPoint) {
        commentPoint.comments.push(comment);
      }
      state.loading = false;
    });

    builder.addCase(addComment.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message || "";
      toast.error(state.errorMessage);
    });
    builder.addCase(addComment.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const {} = campaignCommentsSlice.actions;

export default campaignCommentsSlice.reducer;
