import React, { ReactNode } from "react";

import styles from "./ContainerBox.module.scss";
import classNames from "classnames";

type ContainerBoxProps = {
  children: ReactNode;
  className?: string;
};

const ContainerBox = (props: ContainerBoxProps) => {
  const { children, className } = props;
  return (
    <div className={classNames(styles["container"], className)}>{children}</div>
  );
};

export default ContainerBox;
