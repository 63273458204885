import React from "react";

const Propensity: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 12.8571H5.71429V7.14286H0V12.8571ZM0 20H5.71429V14.2857H0V20ZM0 5.71429H5.71429V0H0V5.71429ZM7.14286 12.8571H25.7143V7.14286H7.14286V12.8571ZM7.14286 20H25.7143V14.2857H7.14286V20ZM7.14286 0V5.71429H25.7143V0H7.14286Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default Propensity;
