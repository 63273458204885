import React from "react";
import Button from "../../Button/Button";
import TextElement from "../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useCampaignQueryChat from "../useCampaignQueryChat";
import { updateCampaign } from "../../../redux/campaignPinningSlice";

interface PinAllAndContinueSegmentsButtonProps {
  className?: string;
}

const PinAllAndContinueSegmentsButton: React.FC<
  PinAllAndContinueSegmentsButtonProps
> = ({ className }) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { audienceSegments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );
  const dispatch = useAppDispatch();

  const pinWholeSegmentSection = () => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            audienceSegmentation: audienceSegments.map((segment,index) => index),
          },
        })
      );
  };

  return (
    <Button
      className={className}
      onClick={() => {
        pinWholeSegmentSection();
      }}
    >
      <TextElement>Pin all Segments 👍🏻</TextElement>
    </Button>
  );
};

export default PinAllAndContinueSegmentsButton;
