import React from "react";
import styles from "./CampaignQueryChatSideBar.module.scss";
import { FaHome, FaUser, FaCog } from "react-icons/fa";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateChatScrolPosition } from "../../../../redux/CampaignQueryChatScrollControllerSlice";

interface CampaignQueryChatSideBarProps {
  className?: string;
}

const CampaignQueryChatSideBar: React.FC<CampaignQueryChatSideBarProps> = (
  props
) => {
  const { className } = props;
  const campaignQueryChatScrollController = useAppSelector(
    (state) => state.campaignQueryChatScrollController
  );
  const dispatch = useAppDispatch();

  const handleScrollAndUpdateState = (
    id: keyof typeof campaignQueryChatScrollController
  ) => {
    dispatch(
      updateChatScrolPosition({ id, isActive: true, isAvailable: true })
    );
  };

  return (
    <div className={classNames(styles["container"], className)}>
      <div
        className={classNames(
          classNames(styles["icon-container"]),
          campaignQueryChatScrollController?.marketingBrief?.isActive
            ? styles["active"]
            : ""
        )}
        id="marketingBrief"
        onClick={() => {
          handleScrollAndUpdateState("marketingBrief");
        }}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5667 3.08333C15.5667 2.72435 15.2757 2.43333 14.9167 2.43333H12.25C11.891 2.43333 11.6 2.72435 11.6 3.08333V3.91667C11.6 4.27565 11.891 4.56667 12.25 4.56667H14.9167C15.2757 4.56667 15.5667 4.27565 15.5667 3.91667V3.08333ZM6.4 3.08333C6.4 2.72435 6.10898 2.43333 5.75 2.43333H3.08333C2.72435 2.43333 2.43333 2.72435 2.43333 3.08333V7.58333C2.43333 7.94232 2.72435 8.23333 3.08333 8.23333H5.75C6.10899 8.23333 6.4 7.94232 6.4 7.58333V3.08333ZM15.5667 10.4167C15.5667 10.0577 15.2757 9.76667 14.9167 9.76667H12.25C11.891 9.76667 11.6 10.0577 11.6 10.4167V14.9167C11.6 15.2757 11.891 15.5667 12.25 15.5667H14.9167C15.2757 15.5667 15.5667 15.2757 15.5667 14.9167V10.4167ZM6.4 14.0833C6.4 13.7243 6.10898 13.4333 5.75 13.4333H3.08333C2.72435 13.4333 2.43333 13.7243 2.43333 14.0833V14.9167C2.43333 15.2757 2.72435 15.5667 3.08333 15.5667H5.75C6.10899 15.5667 6.4 15.2757 6.4 14.9167V14.0833ZM15.25 0.9C16.2717 0.9 17.1 1.72827 17.1 2.75V4.25C17.1 5.27173 16.2717 6.1 15.25 6.1H11.9167C10.8949 6.1 10.0667 5.27173 10.0667 4.25V2.75C10.0667 1.72827 10.8949 0.9 11.9167 0.9H15.25ZM6.08333 0.9C7.10506 0.9 7.93333 1.72827 7.93333 2.75V7.91667C7.93333 8.93839 7.10506 9.76667 6.08333 9.76667H2.75C1.72827 9.76667 0.9 8.93839 0.9 7.91667V2.75C0.9 1.72827 1.72827 0.9 2.75 0.9H6.08333ZM15.25 8.23333C16.2717 8.23333 17.1 9.06161 17.1 10.0833V15.25C17.1 16.2717 16.2717 17.1 15.25 17.1H11.9167C10.8949 17.1 10.0667 16.2717 10.0667 15.25V10.0833C10.0667 9.06161 10.8949 8.23333 11.9167 8.23333H15.25ZM6.08333 11.9C7.10506 11.9 7.93333 12.7283 7.93333 13.75V15.25C7.93333 16.2717 7.10506 17.1 6.08333 17.1H2.75C1.72827 17.1 0.9 16.2717 0.9 15.25V13.75C0.9 12.7283 1.72827 11.9 2.75 11.9H6.08333Z"
            fill="white"
            stroke="white"
            stroke-width="0.3"
          />
        </svg>
      </div>
      {campaignQueryChatScrollController?.pastCampaignAnalysis?.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.pastCampaignAnalysis?.isActive
              ? styles["active"]
              : ""
          )}
          id="pastCampaignAnalysis"
          onClick={() => {
            handleScrollAndUpdateState("pastCampaignAnalysis");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3688_3370)">
              <path
                d="M1.6665 16.6583L7.9165 10.4L11.2498 13.7333L17.1582 7.09166L18.3332 8.26666L11.2498 16.2333L7.9165 12.9L2.9165 17.9083L1.6665 16.6583ZM2.9165 12.9083L7.9165 7.89999L11.2498 11.2333L18.3332 3.26666L17.1582 2.09166L11.2498 8.73333L7.9165 5.39999L1.6665 11.6583L2.9165 12.9083Z"
                fill="#EB2701"
              />
            </g>
            <defs>
              <clipPath id="clip0_3688_3370">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {campaignQueryChatScrollController?.audienceSegmentation?.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.audienceSegmentation?.isActive
              ? styles["active"]
              : ""
          )}
          id="audienceSegmentation"
          onClick={() => {
            handleScrollAndUpdateState("audienceSegmentation");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3688_3374)">
              <path
                d="M9.99154 1.66666C5.39154 1.66666 1.6582 5.39999 1.6582 9.99999C1.6582 14.6 5.39154 18.3333 9.99154 18.3333C14.5915 18.3333 18.3249 14.6 18.3249 9.99999C18.3249 5.39999 14.5915 1.66666 9.99154 1.66666ZM12.9999 6.94999C13.8915 6.94999 14.6082 7.66666 14.6082 8.55832C14.6082 9.44999 13.8915 10.1667 12.9999 10.1667C12.1082 10.1667 11.3915 9.44999 11.3915 8.55832C11.3832 7.66666 12.1082 6.94999 12.9999 6.94999ZM7.99987 5.63332C9.0832 5.63332 9.96654 6.51666 9.96654 7.59999C9.96654 8.68332 9.0832 9.56666 7.99987 9.56666C6.91654 9.56666 6.0332 8.68332 6.0332 7.59999C6.0332 6.50832 6.9082 5.63332 7.99987 5.63332ZM7.99987 13.2417V16.3667C5.99987 15.7417 4.41654 14.2 3.71654 12.2333C4.59154 11.3 6.77487 10.825 7.99987 10.825C8.44154 10.825 8.99987 10.8917 9.5832 11.0083C8.21654 11.7333 7.99987 12.6917 7.99987 13.2417ZM9.99154 16.6667C9.76654 16.6667 9.54987 16.6583 9.3332 16.6333V13.2417C9.3332 12.0583 11.7832 11.4667 12.9999 11.4667C13.8915 11.4667 15.4332 11.7917 16.1999 12.425C15.2249 14.9 12.8165 16.6667 9.99154 16.6667Z"
                fill="#EB2701"
              />
            </g>
            <defs>
              <clipPath id="clip0_3688_3374">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {campaignQueryChatScrollController?.marketingSentiments?.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.marketingSentiments?.isActive
              ? styles["active"]
              : ""
          )}
          id="market-sentiments"
          onClick={() => {
            handleScrollAndUpdateState("marketingSentiments");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3688_3406)">
              <path
                d="M9.99163 1.66666C5.39163 1.66666 1.66663 5.39999 1.66663 9.99999C1.66663 14.6 5.39163 18.3333 9.99163 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.39999 14.6 1.66666 9.99163 1.66666ZM7.08329 6.66666C7.77496 6.66666 8.33329 7.22499 8.33329 7.91666C8.33329 8.60832 7.77496 9.16666 7.08329 9.16666C6.39163 9.16666 5.83329 8.60832 5.83329 7.91666C5.83329 7.22499 6.39163 6.66666 7.08329 6.66666ZM9.99996 15C8.09996 15 6.48329 13.6167 5.83329 11.6667H14.1666C13.5166 13.6167 11.9 15 9.99996 15ZM12.9166 9.16666C12.225 9.16666 11.6666 8.60832 11.6666 7.91666C11.6666 7.22499 12.225 6.66666 12.9166 6.66666C13.6083 6.66666 14.1666 7.22499 14.1666 7.91666C14.1666 8.60832 13.6083 9.16666 12.9166 9.16666Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_3688_3406">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      {campaignQueryChatScrollController?.competitorAnalysis.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.competitorAnalysis?.isActive
              ? styles["active"]
              : ""
          )}
          id="competitor-analysis"
          onClick={() => {
            handleScrollAndUpdateState("competitorAnalysis");
          }}
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 3.83333V0.5H0.666672V15.5H17.3333V3.83333H9ZM4.00001 13.8333H2.33334V12.1667H4.00001V13.8333ZM4.00001 10.5H2.33334V8.83333H4.00001V10.5ZM4.00001 7.16667H2.33334V5.5H4.00001V7.16667ZM4.00001 3.83333H2.33334V2.16667H4.00001V3.83333ZM7.33334 13.8333H5.66667V12.1667H7.33334V13.8333ZM7.33334 10.5H5.66667V8.83333H7.33334V10.5ZM7.33334 7.16667H5.66667V5.5H7.33334V7.16667ZM7.33334 3.83333H5.66667V2.16667H7.33334V3.83333ZM15.6667 13.8333H9V12.1667H10.6667V10.5H9V8.83333H10.6667V7.16667H9V5.5H15.6667V13.8333ZM14 7.16667H12.3333V8.83333H14V7.16667ZM14 10.5H12.3333V12.1667H14V10.5Z"
              fill="white"
            />
          </svg>
        </div>
      )}

      {campaignQueryChatScrollController?.recommendations?.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.recommendations?.isActive
              ? styles["active"]
              : ""
          )}
          id="recommendation"
          onClick={() => {
            handleScrollAndUpdateState("recommendations");
          }}
        >
          <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.76005 17.7778L6.96005 11.6667L0.800049 8.88889L6.96005 6.11111L9.76005 0L12.56 6.11111L18.72 8.88889L12.56 11.6667L9.76005 17.7778ZM18.72 20L17.32 16.9444L14.24 15.5556L17.32 14.1667L18.72 11.1111L20.12 14.1667L23.2 15.5556L20.12 16.9444L18.72 20Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      {campaignQueryChatScrollController?.content?.isAvailable && (
        <div
        className={classNames(
            classNames(styles["icon-container"]),
            campaignQueryChatScrollController?.content?.isActive
              ? styles["active"]
              : ""
          )}
          id="content"
          onClick={() => {
            handleScrollAndUpdateState("content");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3688_3502)">
              <path
                d="M17.0083 7.00833L12.9833 2.98333C12.675 2.675 12.25 2.5 11.8083 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V8.19167C17.5 7.75 17.325 7.325 17.0083 7.00833ZM5.83333 5.83333H11.6667V7.5H5.83333V5.83333ZM14.1667 14.1667H5.83333V12.5H14.1667V14.1667ZM14.1667 10.8333H5.83333V9.16667H14.1667V10.8333Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_3688_3502">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default CampaignQueryChatSideBar;
