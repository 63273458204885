import React from "react";
import styles from "./Table.module.scss";

type Column = {
  header: string;
  accessor: string;
};

type Row = {
  [key: string]: any;
};

type TableProps = {
  className?: string;
  rows: Row[];
  columns: Column[];
};

const Table: React.FC<TableProps> = ({ className, rows, columns }) => {
  return (
    <table className={`${styles.table} ${className}`}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>{row[column.accessor]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
