import React from "react";

const Pin: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 7.2L9.6 8.8V10.4H5.6V15.2L4.8 16L4 15.2V10.4H0V8.8L1.6 7.2V1.6H0.8V0H8.8V1.6H8V7.2Z"
        fill="#B2BBCA"
      />
    </svg>
  );
};

export default Pin;
