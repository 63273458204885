import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import default styles
import styles from "./CalendarViewSchedules.module.scss"; // Import custom styles
import usePostSchedulesCalendarListView, {
  PostSchedule,
} from "../usePostSchedulesCalendarListView";
import TextElement from "../../TextElement/TextElement";
import PostCard from "../../PostCard/PostCard";
import UpdatePostContentModal from "../../UpdatePostContentModal/UpdatePostContentModal";

interface Props {
  posts: PostSchedule[];
  startDate: Date;
  endDate: Date;
  activeDate: Date;
}

const CustomCalendar: React.FC<Props> = (props) => {
  const { startDate, endDate, activeDate, posts } = props;

  const { filterPostsByDate } = usePostSchedulesCalendarListView();

  const renderTileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      const filteredPosts = filterPostsByDate(posts, date);
      if (filteredPosts.length > 0) {
        return (
          <div className={styles.customTileContent}
            onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span
              style={{
                color: "black",
              }}
            >
              {date.getDate()}
            </span>
            {filteredPosts.map((post, index) => (
              <PostCard
                key={index}
                post={post}
                className={styles.customPostCard}
              />
            ))}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={styles.customCalendarContainer}>
      <Calendar
        className={styles.customCalendar}
        tileContent={renderTileContent}
        activeStartDate={activeDate}
        minDate={startDate}
        maxDate={endDate}
        view="month"
        showNavigation={false}
        // tileDisabled={}
      />
    </div>
  );
};

export default CustomCalendar;
