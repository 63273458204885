import React, { useEffect } from "react";

import CampaignHeader from "../CampaignHeader/CampaignHeader";
import SideBar from "../SideBar/SideBar";

import styles from "./InterestStageLayout.module.scss";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";

import InsightsFromKnownContacts from "../CampaignReports/InsightsFromKnownContacts";
import NurtureStrategyBuilder from "../NurtureStrategyBuilder/NurtureStrategyBuilder";
import NurtureStrategyQueryChat from "../NurtureStrategyQueryChat/NurtureStrategyQueryChat";

interface Props {
  children?: React.ReactNode;
  operation: "report" | "content" | "strategy";
}

const InterestStageLayout: React.FC<Props> = (props) => {
  const { operation } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );
 
  useEffect(() => {
    // if (selectedCampaign?.id === campaigns.length - 1) {
    // } else {
    // }
    dispatch(setSelectedCampaign(''));
  }, []);

  const getContentJsx = () => {
    switch (operation) {
      case "content":
        return;
      case "report":
        return (
          <div className={styles["content"]}>
            <SideBar className={styles["side-bar"]} />
            <InsightsFromKnownContacts
              className={styles["insights-from-known-contacts"]}
            />
          </div>
        );
      case "strategy":
        return (
          <div className={styles["content"]}>
            <SideBar className={styles["side-bar"]} />
            <NurtureStrategyQueryChat
              className={styles["nurture-strategy-chat"]}
            />
            <NurtureStrategyBuilder
              open={open}
              onClose={() => setOpen(!open)}
            />
          </div>
        );
    }
  };

  const getContainerClassName = () => {
    switch (operation) {
      case "content":
        return styles["container-content"];
      case "report":
        return styles["container"];
      case "strategy":
        return styles["container-strategy"];
    }
  };

  return (
    <ProtectedRoleRoute
      requiredRole={[UserRoleType.MARKETING]}
      className={styles["campaign-builder-layout"]}
    >
      <div className={getContainerClassName()}>
        <CampaignHeader className={styles["header"]} />
        {getContentJsx()}
      </div>
    </ProtectedRoleRoute>
  );
};

export default InterestStageLayout;
