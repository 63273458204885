import React, { useState, useRef, useEffect, useCallback } from "react";

import styles from "./CampaignQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";
import MarketingBriefFormCard from "../MarketingBriefFormCard/MarketingBriefFormCard";
import PerformanceMetricsCard from "../PerformanceMetricsCard/PerformanceMetricsCard";
import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import useCampaignQueryChat from "./useCampaignQueryChat";
import MarketSentimentCard from "../MarketSentimentCard/MarketSentimentCard";
import CompetitorAnalysisCard from "../CompetitorAnalysisCard/CompetitorAnalysisCard";
import CompetitorStrategies from "../CompetitorStrategies/CompetitorStrategies";
import RecommendedMarketingChannels from "../RecommendedMarketingChannels/RecommendedMarketingChannels";
import RecommendedProductOfferingsCard from "../RecommendedProductOfferingsCard/RecommendedProductOfferingsCard";
import TargetAudienceAndEstimatedNumbersCard from "../TargetAudienceAndEstimatedNumbersCard/TargetAudienceAndEstimatedNumbersCard";
import ProjectedROICard from "../ProjectedROICard/ProjectedROICard";
import BudgetAllocationAndBreakdown from "../BudgetAllocationAndBreakdown/BudgetAllocationAndBreakdown";

import GenerateContentButton from "./GenerateContentButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useCampaignAnalysisQueryChat } from "../../context/CampaignAnalysisQueryChatContext";
import { SyncLoader } from "react-spinners";

import PinAllAndContinueCompetitorButton from "./componenets/PinAllAndContinueCompetitorButton";
import { toast } from "react-toastify";
import PinAllAndContinueSegmentsButton from "./componenets/PinAllAndContinueSegmentsButton";
import PinAllPerformanceInsights from "./componenets/PinAllPerformanceInsights";
import PinAllMarketSentiments from "./componenets/PinAllMarketSentiments";
import AudienceSegmentation from "../AudienceSegmentationCards/AudienceSegmentation";
import {
  fetchCompetitors,
  fetchMarketingContent,
  fetchMarketSentiments,
  FetchMarketSentimentsResponse,
  updateLoadedStateForAnalysis,
} from "../../redux/campaignPlanAnalysisQueryChat";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import SocialMediaContentCard from "../SocialMediaContentCard/SocialMediaContentCard";
import EmailMarketingContentCard from "../EmailMarketingContentCard/EmailMarketingContentCard";
import BlogPostContentCard from "../BlogPostContentCard/BlogPostContentCard";
import GoogleAdsCard from "../GoogleAdsCard/GoogleAdsCard";
import { updateChatScrolPosition } from "../../redux/CampaignQueryChatScrollControllerSlice";
import {
  fetchErikoAiAssistantResponse,
  updateErikoChatHistory,
} from "../../redux/erikoAiChatAsistantSlice";
import HttpService from "../../lib/api";
import CompetitorAnalysisList from "../CompetitorAnalysisCard/CompetitorAnalysisList";
import ContentDeliveryPlans from "../ContentDelieveryPlans/ContentDeliveryPlans";
import PostSchedulesCalendarListViewModal from "../PostSchedulesCalendarListViewModal/PostSchedulesCalendarListViewModal";
import { fetchPosts } from "../../redux/contentGenerationSlice";
import ApprovalCampaignModal from "../ApprovalCampaignModal/ApprovalCampaignModal";
import ApprovalSubmittedCampaignModal from "../ApprovalSubmittedCampaignModal/ApprovalSubmittedCampaignModal";
import CompetitorAnalysisModal from "../CompetitorAnalysisModal/CompetitorAnalysisModal";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CampaignQueryChatProps {
  className?: string;
}

const CampaignQueryChat: React.FC<CampaignQueryChatProps> = (props) => {
  const [userInput, setUserInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState(false);
  const [activeAnalysisPhase, setActiveAnalysisPhase] = useState(0);

  const { marketSentiments, competitors } = useCampaignQueryChat();

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const selectedCampaignRef = useRef(selectedCampaign);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [competitorAnalysisModal, setCompetitorAnalysisModal] =
    React.useState(false);

  const {
    conversation,
    updateConversation,
    fetchSegmentsWithCallback,
    fetchBudgetAllocationForDifferentChannelsWithCallback,
    fetchPastCampaignAnalysisWithCallback,
  } = useCampaignAnalysisQueryChat();

  const campaignQueryChatScrollController = useAppSelector(
    (state) => state.campaignQueryChatScrollController
  );

  const scrollRefs = {
    marketingBrief: useRef<HTMLDivElement>(null),
    pastCampaignAnalysis: useRef<HTMLDivElement>(null),
    audienceSegmentation: useRef<HTMLDivElement>(null),
    competitorAnalysis: useRef<HTMLDivElement>(null),
    marketingSentiments: useRef<HTMLDivElement>(null),
    recommendations: useRef<HTMLDivElement>(null),
    content: useRef<HTMLDivElement>(null),
  };

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const loadingRef = useRef<HTMLDivElement>(null);
  const { history } = useAppSelector((state) => state.erikoAiChatAsistant);

  const httpService = new HttpService();

  const [
    isPostSchedulesCalendarListViewModal,
    setIsPostSchedulesCalendarListViewModal,
  ] = useState(false);

  const [isApprovalModalOpen, setIsApprovalModalOpen] =
    React.useState<boolean>(false);
  const [isApprovalSubmittedModalOpen, setIsApprovalSubmittedModalOpen] =
    React.useState<boolean>(false);

  const handleCreateContent = async () => {
    setLoading(true);
    dispatch(
      fetchPosts({
        campaignPlanId: selectedCampaignRef.current?.id as string,
      })
    )
      .then(() => {
        setLoading(false);
        handleScroll("content");
        setActiveAnalysisPhase(6);
        setIsPostSchedulesCalendarListViewModal(true);
        updateConversation([
          {
            type: MessageType.ANSWER,

            content: (
              <div className={styles["pin-generate-row"]}>
                <Button
                  className={classNames(
                    styles["card"],
                    styles["continue-button"]
                  )}
                  onClick={() => {
                    setIsApprovalModalOpen(true);
                  }}
                >
                  <TextElement>Submit for approval 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Happy with all the content generated? Submit it for approval now!"
              />
            ),
          },
        ]);
      })
      .catch(() => {
        setLoading(false);
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Something went wrong while generating content."
              />
            ),
          },
        ]);
      });
  };

  const handleCreateRecommendation = async () => {
    setLoading(true);
    fetchBudgetAllocationForDifferentChannelsWithCallback(
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <Button
                className={classNames(
                  styles["card"],
                  styles["continue-button"]
                )}
                onClick={() => {
                  selectedCampaignRef?.current &&
                    dispatch(
                      updateCampaign({
                        ...selectedCampaignRef?.current,
                        activeContentStep: true,
                      })
                    );
                  handleCreateContent();
                }}
              >
                <TextElement>View content?</TextElement>
              </Button>
            ),
          },
          {
            type: MessageType.QUESTION,
            content: (
              <ContentDeliveryPlans
                executionPlans={[
                  {
                    id: "6061eda6-56a4-4544-9203-81756d8cde6c",
                    theme: "Cybersecurity Solutions for Australian Businesses",
                    campaignId: "c6b8875f-54ec-4614-984f-aadf0ef752b2",
                    channelId: "0d39275a-37d2-494a-a7fe-82add2e08385",
                    contentTypeId: "ffd21c4d-41cb-4866-8431-30879d418269",
                    startDate: "2024-08-15T00:00:00+00:00",
                    endDate: "2024-09-12T00:00:00+00:00",
                    numberOfPostsPerDay: 1,
                    daysOfTheWeek: ["Tuesday", "Wednesday", "Thursday"],
                    numberOfWeeks: 4,
                  },
                  {
                    id: "6bebb00f-951c-448c-a79e-3df2046c30e4",
                    theme: "Digital Transformation with Cloud Computing",
                    campaignId: "c6b8875f-54ec-4614-984f-aadf0ef752b2",
                    channelId: "0d39275a-37d2-494a-a7fe-82add2e08385",
                    contentTypeId: "540ec025-ace8-48fb-9f04-432f40e08ceb",
                    startDate: "2024-08-15T00:00:00+00:00",
                    endDate: "2024-09-26T00:00:00+00:00",
                    numberOfPostsPerDay: 1,
                    daysOfTheWeek: ["Tuesday", "Wednesday", "Thursday"],
                    numberOfWeeks: 6,
                  },
                ]}
                className={styles["card"]}
              />
            ),
          },
          // {
          //   type: MessageType.ANSWER,
          //   content: (
          //     <ProjectedROICard className={styles["card"]} cardType="pinable" />
          //   ),
          // },
          // {
          //   type: MessageType.ANSWER,
          //   content: (
          //     <BudgetAllocationAndBreakdown
          //       className={styles["card"]}
          //       cardType="pinable"
          //     />
          //   ),
          // },
          {
            type: MessageType.ANSWER,
            content: (
              <RecommendedMarketingChannels
                className={styles["card"]}
                cardType="pinable"
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <RecommendedProductOfferingsCard
                className={styles["card"]}
                cardType="pinable"
              />
            ),
          },
          // {
          //   type: MessageType.ANSWER,
          //   content: (
          //     <TargetAudienceAndEstimatedNumbersCard
          //       className={styles["card"]}
          //       cardType="pinable"
          //     />
          //   ),
          // },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.recommendations}
                message="Drawing from our previous insights, I've crafted a focused plan to ensure the success of the campaign. This strategy, tailored to meet the needs of our target audience, leverages specific marketing channels to drive engagement and maximize ROI. Here's how I intend to make the campaign a resounding success. 👇🏻"
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(5);
        handleScroll("recommendations");
        setLoading(false);
      },
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: <GenerateContentButton />,
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Something went wrong while generating recommendations."
              />
            ),
          },
        ]);
        setLoading(false);
        handleScroll("recommendations");
      }
    );
  };

  const handleCreateCompetitorAnalysis = async () => {
    setLoading(true);
    dispatch(
      fetchCompetitors({
        companyName: "Infotech",
        campaignPlanId: selectedCampaignRef.current?.id as string,
        industry: "salesforce",
      })
    )
      .then(() => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div className={styles["pin-generate-row"]}>
                <Button
                  className={classNames(
                    styles["card"],
                    styles["continue-button"]
                  )}
                  onClick={handleCreateRecommendation}
                >
                  <TextElement>Generate Recommendation 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(styles["card"], styles["comparison-row"])}
              >
                <PinAllAndContinueCompetitorButton
                  className={classNames(styles["continue-button"])}
                />
                <Button
                  className={styles["comparison-details-btn"]}
                  onClick={() => {
                    setCompetitorAnalysisModal(true);
                  }}
                >
                  See Comparison Details
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <CompetitorStrategies
                className={styles["card"]}
                cardType="pinable"
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.competitorAnalysis}
                message="I found these businesses to have similar offerings as yours, and here’s what they’ve been doing 👇🏻"
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(4);
        setLoading(false);
        handleScroll("competitorAnalysis");
      })
      .catch(() => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div className={styles["pin-generate-row"]}>
                <Button
                  className={classNames(
                    styles["card"],
                    styles["continue-button"]
                  )}
                  onClick={handleCreateRecommendation}
                >
                  <TextElement>Generate Recommendation 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Something Went wrong while generating competitor analysis."
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(4);
        setLoading(false);
        handleScroll("competitorAnalysis");
      });
  };

  const handleCreateMarketSentiments = async () => {
    updateConversation([
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs?.marketingSentiments}
            message="As we gear up to launch our campaign, understanding current market sentiments and past campaign performance is pivotal for crafting a targeted strategy that resonates with businesses and consumers alike. Here are some market sentiments that I found relevant to your campaign 👇🏻"
          />
        ),
      },
    ]);
    setLoading(true);
    selectedCampaignRef &&
      dispatch(
        fetchMarketSentiments({
          campaignPlanId: selectedCampaignRef.current?.id as string,
          industry: "Salesforce",
        })
      )
        .then((response) => {
          const responsePayload =
            response.payload as FetchMarketSentimentsResponse;

          const generatedResponse = responsePayload?.sentiments?.map(
            (key, index) => ({
              id: index + 1,
              ...key,
            })
          );

          dispatch(
            updateErikoChatHistory([
              "Assistant",
              JSON.stringify(generatedResponse),
            ])
          );
          updateConversation([
            {
              type: MessageType.ANSWER,
              content: (
                <div
                  className={classNames(
                    styles["card"],
                    styles["pin-generate-row"]
                  )}
                >
                  <PinAllMarketSentiments
                    className={classNames(styles["continue-button"])}
                  />
                  <Button
                    className={styles["continue-button"]}
                    onClick={handleCreateCompetitorAnalysis}
                  >
                    <TextElement>Generate Competitor Analysis 👍🏻</TextElement>
                  </Button>
                </div>
              ),
            },
            ...generatedResponse.reverse().map((ms) => ({
              type: MessageType.QUESTION,
              content: (
                <MarketSentimentCard
                  className={styles["card"]}
                  marketSentiment={ms}
                  cardType="pinable"
                />
              ),
            })),
          ]);
          setLoading(false);
          setActiveAnalysisPhase(3);
          handleScroll("marketingSentiments");
        })
        .catch((error) => {
          console.log(error);
          updateConversation([
            {
              type: MessageType.ANSWER,
              content: (
                <div
                  className={classNames(
                    styles["card"],
                    styles["pin-generate-row"]
                  )}
                >
                  <Button
                    className={styles["continue-button"]}
                    onClick={handleCreateCompetitorAnalysis}
                  >
                    <TextElement>Generate Competitor Analysis 👍🏻</TextElement>
                  </Button>
                </div>
              ),
            },
            {
              type: MessageType.ANSWER,
              content: (
                <ChatCard
                  messageClass={styles["chat-message"]}
                  ref={scrollRefs?.marketingSentiments}
                  message="Something went wrong while generating market sentiments."
                />
              ),
            },
          ]);
          setLoading(false);
          handleScroll("marketingSentiments");
        });
  };

  const handleCreateAudienceSegment = async () => {
    updateConversation([
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            ref={scrollRefs?.audienceSegmentation}
            message="Based on your past campaign performance, we've identified key audience segments that we believe will be particularly relevant for your upcoming campaign. Understanding the unique needs and preferences of these segments will be crucial in crafting targeted messaging and communication strategies. 👇🏻"
          />
        ),
      },
    ]);
    setLoading(true);
    fetchSegmentsWithCallback(
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateMarketSentiments}
                >
                  <TextElement>Generate Market Sentiments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(styles["card"], styles["comparison-row"])}
              >
                <PinAllAndContinueSegmentsButton
                  className={classNames(styles["continue-button"])}
                />
                {/* ˝ */}
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <AudienceSegmentation
                className={styles["card"]}
                cardType="pinable"
              />
            ),
          },
        ]);
        setLoading(false);
        setActiveAnalysisPhase(2);
        handleScroll("audienceSegmentation");
      },
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateMarketSentiments}
                >
                  <TextElement>Generate Market Sentiments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.audienceSegmentation}
                message="Something went wrong while generating audience segments."
              />
            ),
          },
        ]);
        setLoading(false);
        setActiveAnalysisPhase(2);
        handleScroll("audienceSegmentation");
      }
    );
  };

  const showPerformanceInsights = () => {
    setLoading(true);
    fetchPastCampaignAnalysisWithCallback(
      (res) => {
        dispatch(
          updateErikoChatHistory(["Assistant", JSON.stringify(res?.analysis)])
        );
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <PinAllPerformanceInsights
                  className={classNames(styles["continue-button"])}
                />
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateAudienceSegment}
                >
                  <TextElement>Generate Audience Segments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="Need more insights? Feel free to ask in the text box below 👇🏻 or click to continue for further guidance on your marketing plan. "
              />
            ),
          },
          ...res?.analysis?.map((insight, index) => ({
            type: MessageType.ANSWER,
            content: (
              <PerformanceMetricsCard
                className={styles["card"]}
                title={insight.metric}
                rate={insight.value}
                description={insight.reason}
                id={index}
                cardType="pinable"
                key={index}
              />
            ),
          })),
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.pastCampaignAnalysis}
                message="Based on the campaign brief, here are the industry benchmarks to guide our strategy for the upcoming campaign. These benchmarks will help us set realistic targets and optimize our approach for better results."
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(1);
        handleScroll("pastCampaignAnalysis");
        setLoading(false);
      },
      () => {
        updateConversation([
          {
            type: MessageType.ANSWER,
            content: (
              <div
                className={classNames(
                  styles["card"],
                  styles["pin-generate-row"]
                )}
              >
                <Button
                  className={classNames(styles["continue-button"])}
                  onClick={handleCreateAudienceSegment}
                >
                  <TextElement>Generate Audience Segments 👍🏻</TextElement>
                </Button>
              </div>
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                ref={scrollRefs?.pastCampaignAnalysis}
                message="Something went wrong while generating past campaign analysis."
              />
            ),
          },
        ]);
        setActiveAnalysisPhase(1);
        handleScroll("pastCampaignAnalysis");
        setLoading(false);
      }
    );
  };

  const handleCreateCampaign = async () => {
    updateConversation([
      {
        type: MessageType.QUESTION,
        content: (
          <ChatCard
            messageClass={styles["chat-message"]}
            message="Thanks for submitting your campaign details. Let me whip up some insights for you..."
          />
        ),
      },
    ]);
    showPerformanceInsights();
    // handleCreateRecommendation();
    // handleCreateMarketSentiments();
    // handleCreateAudienceSegment();
    // handleCreateContent();
    setSubmittedFormData(true);
    setShowInput(true);
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleScroll = (id: keyof typeof scrollRefs) => {
    const ref = scrollRefs[id];
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
        dispatch(
          updateChatScrolPosition({ id, isActive: true, isAvailable: true })
        );
      }
    }, 100);
  };

  useEffect(() => {
    if (campaignQueryChatScrollController?.pastCampaignAnalysis?.isActive) {
      scrollToRef(scrollRefs?.pastCampaignAnalysis);
    } else if (campaignQueryChatScrollController?.marketingBrief?.isActive) {
      scrollToRef(scrollRefs?.marketingBrief);
    } else if (
      campaignQueryChatScrollController?.audienceSegmentation?.isActive
    ) {
      scrollToRef(scrollRefs?.audienceSegmentation);
    } else if (
      campaignQueryChatScrollController?.competitorAnalysis?.isActive
    ) {
      scrollToRef(scrollRefs?.competitorAnalysis);
    } else if (
      campaignQueryChatScrollController?.marketingSentiments?.isActive
    ) {
      scrollToRef(scrollRefs?.marketingSentiments);
    } else if (campaignQueryChatScrollController?.recommendations?.isActive) {
      scrollToRef(scrollRefs?.recommendations);
    } else if (campaignQueryChatScrollController?.content?.isActive) {
      scrollToRef(scrollRefs?.content);
    }
  }, [
    campaignQueryChatScrollController?.pastCampaignAnalysis?.isActive,
    campaignQueryChatScrollController?.marketingBrief?.isActive,
    campaignQueryChatScrollController?.audienceSegmentation?.isActive,
    campaignQueryChatScrollController?.competitorAnalysis?.isActive,
    campaignQueryChatScrollController?.marketingSentiments?.isActive,
    campaignQueryChatScrollController?.recommendations?.isActive,
    campaignQueryChatScrollController?.content?.isActive,
  ]);

  useEffect(() => {
    if (!selectedCampaign && !submittedFormData) {
      updateConversation(
        [
          {
            type: MessageType.QUESTION,
            content: (
              <MarketingBriefFormCard
                className={styles["card"]}
                onSubmit={() => {
                  handleCreateCampaign();
                }}
                onErrorMessage={(message) => {
                  toast.error(message);
                }}
              />
            ),
          },
          {
            type: MessageType.ANSWER,
            content: (
              <ChatCard
                messageClass={styles["chat-message"]}
                message="To start off with your campaign, please let me know details about your campaign below. 👇🏻"
                ref={scrollRefs?.marketingBrief}
              />
            ),
          },
        ],
        true
      );
      handleScroll("marketingBrief");
    } else {
      setShowInput(true);
    }
    selectedCampaignRef.current = selectedCampaign;
  }, [selectedCampaign]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  return (
    <>
      <CompetitorAnalysisModal
        isOpen={competitorAnalysisModal}
        closeModal={() => setCompetitorAnalysisModal(false)}
      />
      <ApprovalSubmittedCampaignModal
        isOpen={isApprovalSubmittedModalOpen}
        closeModal={() => {
          setIsApprovalSubmittedModalOpen(false);
        }}
      />
      <ApprovalCampaignModal
        isOpen={isApprovalModalOpen}
        closeModal={() => {
          setIsApprovalModalOpen(false);
        }}
        onOk={() => {
          setIsApprovalModalOpen(false);
          setIsApprovalSubmittedModalOpen(true);
        }}
      />
      <PostSchedulesCalendarListViewModal
        isOpen={isPostSchedulesCalendarListViewModal}
        closeModal={() => setIsPostSchedulesCalendarListViewModal(false)}
        onOk={() => {}}
      />
      <div className={styles["container"]} ref={chatContainerRef}>
        <div className={styles["chat"]}>
          {loading && (
            <ChatCard
              messageClass={classNames(
                styles["chat-message"],
                styles["loading-chat"]
              )}
              ref={loadingRef}
              message={<SyncLoader color="#eb2701" />}
            />
          )}

          {conversation?.map((item: Question, index: number) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              {item.content}
            </div>
          ))}
        </div>
      </div>

      {showInput && (
        <div className={styles["input-container"]}>
          <input
            placeholder="Send a message"
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target?.value);
            }}
          />
          <SendIcon
            onClick={() => {
              if (!userInput) return null;
              setLoading(true);
              dispatch(updateErikoChatHistory(["Client", userInput]));
              updateConversation([
                {
                  type: MessageType.QUESTION,
                  content: (
                    <ChatCard
                      messageClass={styles["chat-message"]}
                      message={userInput}
                      iconType="user"
                    />
                  ),
                },
              ]);
              userInput &&
                dispatch(
                  fetchErikoAiAssistantResponse({
                    message: userInput,
                    history: [],
                    campaignPlanId: selectedCampaignRef.current?.id as string,
                  })
                )
                  .then((response) => {
                    updateConversation([
                      {
                        type: MessageType.ANSWER,
                        content: (
                          <ChatCard
                            messageClass={styles["chat-message"]}
                            message={(response.payload as string[])[1]}
                          />
                        ),
                      },
                    ]);
                    setLoading(false);
                    setUserInput("");
                  })
                  .catch((error) => {
                    console.log(error);
                    updateConversation([
                      {
                        type: MessageType.ANSWER,
                        content: (
                          <ChatCard
                            messageClass={styles["chat-message"]}
                            message="Something went wrong while fetching the response."
                          />
                        ),
                      },
                    ]);
                    setLoading(false);
                  });
            }}
          />
        </div>
      )}
    </>
  );
};

export default CampaignQueryChat;
