import React, { useEffect } from "react";

import styles from "./Card.module.scss";
import classNames from "classnames";
import Ellipses from "../Ellipses/Ellipses";

import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export type CardType = "pinable" | "ellipses" | "default";

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onPin?: () => void;
  unPin?: () => void;
  loading?: boolean;
  type: CardType;
  pinIconClassName?: string;
  ellipsesIconClassName?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const {
    children,
    className,
    onPin,
    unPin,
    type = "pinable",
    loading,
    pinIconClassName,
    ellipsesIconClassName,
  } = props;

  const getContainerClassName = (type: CardType) => {
    switch (type) {
      case "pinable":
        return classNames(styles["card"], className);
      case "ellipses":
        return classNames(styles["card"], styles["ellipses-card"], className);
      default:
        return classNames(styles["card"], className);
    }
  };

  const getHeaderElement = (type: CardType) => {
    switch (type) {
      case "pinable":
        return (
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(styles["pin-icon"], pinIconClassName)}
            onClick={() => onPin && onPin()}
          >
            <path
              d="M8 7.2L9.6 8.8V10.4H5.6V15.2L4.8 16L4 15.2V10.4H0V8.8L1.6 7.2V1.6H0.8V0H8.8V1.6H8V7.2Z"
              fill="#B2BBCA"
            />
          </svg>
        );
      case "ellipses":
        return (
          <Ellipses
            className={classNames(styles["ellipses"], ellipsesIconClassName)}
            onUnpin={() => unPin && unPin()}
          />
        );
    }
  };

  return (
    <div className={getContainerClassName(type)}>
      {getHeaderElement(type)}
      {children}
      {(type === "pinable" || type === "default") && loading ? (
        <Skeleton className={styles["skeleton"]} />
      ) : null}
    </div>
  );
};

export default Card;
