import React from "react";
import CommentCard from "../CommentCard/CommentCard";
import { addComment, CommentPoint } from "../../redux/campaignCommentsSlice";
import styles from "./CommentsSideBar.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Button from "../Button/Button";

interface DisplayCommentPointProps {
  commentPoint: CommentPoint;
}

const DisplayCommentPoint: React.FC<DisplayCommentPointProps> = (props) => {
  const { commentPoint } = props;
  const dispatch = useAppDispatch();
  const { loggedInUser } = useAppSelector((state) => state.auth);
  const [commentText, setCommentText] = React.useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  return (
    <div className={styles["comment-point"]}>
      {commentPoint.comments.map((comment, index) => (
        <CommentCard
          key={index}
          comment={comment.text}
          profileImage={comment.profileImage}
          username={comment.userName}
          time={new Date(comment.timeStamp).toDateString()}
          onReply={(text) => {
            loggedInUser &&
              selectedCampaign &&
              dispatch(
                addComment({
                  text,
                  profileImage:
                    "https://www.w3schools.com/howto/img_avatar.png",
                  user: loggedInUser?.userid,
                  commentPoint: commentPoint.id,
                  campaignPlan: selectedCampaign?.id,
                })
              );
          }}
        />
      ))}
      <div className={styles["reply-container"]}>
        <textarea
          className={styles["comment-input"]}
          placeholder="Write a comment..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        ></textarea>

        <footer>
          <img
            src="https://randomuser.me/api/portraits/women/48.jpg"
            alt="profile"
          />
          <Button
            className={styles["action-btn"]}
            onClick={() => {
              loggedInUser &&
                commentText &&
                selectedCampaign &&
                dispatch(
                  addComment({
                    text: commentText,
                    profileImage:
                      "https://www.w3schools.com/howto/img_avatar.png",
                    user: loggedInUser?.userid,
                    commentPoint: commentPoint.id,
                    campaignPlan: selectedCampaign?.id,
                  })
                );
            }}
          >
            Send
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default DisplayCommentPoint;
