import React from "react";

const SleepingNinja: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 119.3 100.8"
      {...props}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-1{fill:#eb2701;}.cls-2,.cls-5{fill:#02285e;}.cls-2{stroke:#02285e;stroke-width:20.57px;}.cls-2,.cls-3{stroke-miterlimit:10;}.cls-3{fill:#fcf4f6;stroke:#eb2701;stroke-width:5.11px;}.cls-4{fill:#fff;}",
          }}
        />
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M87.53,27.92a44.91,44.91,0,0,1-13.34,5.52A28.41,28.41,0,0,1,77.44,40c1-.38,2.09-.73,3.16-1a5.87,5.87,0,0,1,1.07-.19c8.55-3.12,18.39-6.33,32.23-2.14a37.65,37.65,0,0,0,5.4-17C106.65,18.29,98,21.53,87.53,27.92Z"
          />
          <path
            className="cls-1"
            d="M103.87,0C89,7,83,21.81,73.55,32.6A26.25,26.25,0,0,1,77.44,40c1-.38,2.09-.73,3.16-1a5.68,5.68,0,0,1,1.71-.19,41.11,41.11,0,0,0,3.62-5.53c6-11.08,18.37-17.24,29.78-18.73A37.34,37.34,0,0,0,103.87,0Z"
          />
          <circle className="cls-2" cx="49.16" cy="51.64" r="38.87" />
          <path
            className="cls-3"
            d="M75.61,73.32C68.8,71.7,63.14,67,48.33,67c-10.81,0-18,4.92-24.08,6.28C16.48,75.07,9.83,63.8,9.83,52.05h0c0-11.74,6.45-21.26,14.42-21.26H75.61c8,0,14.41,9.52,14.41,21.26h0C90,63.8,83.36,75.16,75.61,73.32Z"
          />
          <path
            className="cls-4"
            d="M72,92.12s4.52-3.06,6.47-4.71c.63-.53,2-1.91,2.55-2.47,1.72-1.92,2.9-3.78,3.46-3,.28.41-.73,1.44-1.44,2.34C79.08,89.22,72,92.12,72,92.12Z"
          />
          <path
            className="cls-4"
            d="M13.76,22.64a66.61,66.61,0,0,1,6.44-5.41c2.71-1.66,5.66-3.12,4.86-3.94-.32-.33-1.42.39-2.35.87C17.61,16.78,13.76,22.64,13.76,22.64Z"
          />
          <path
            className="cls-4"
            d="M95,9.33a50.11,50.11,0,0,1,5.23-3.82c2.17-1.14,4.51-2.11,3.94-2.78-.23-.27-1.11.22-1.85.54C98.27,5,95,9.33,95,9.33Z"
          />
          <path
            className="cls-4"
            d="M109,22.53a32.28,32.28,0,0,1,4.17-.3c1.58.12,3.19.39,3.11-.17,0-.23-.69-.26-1.2-.34A14.13,14.13,0,0,0,109,22.53Z"
          />
          <path
            className="cls-5"
            d="M81,50c.75,3.81-1.75,8.06-5.4,9.6a9.79,9.79,0,0,1-13.25-5.76A8.54,8.54,0,0,1,62,50a.83.83,0,0,1,1.65,0,8.79,8.79,0,0,0,2.82,5.14,7.9,7.9,0,0,0,10.33,0,9.1,9.1,0,0,0,3-5.2A.61.61,0,0,1,81,50Z"
          />
          <path
            className="cls-5"
            d="M39.68,50a9.13,9.13,0,0,1-5.43,9.66A9.75,9.75,0,0,1,21,53.87,8.33,8.33,0,0,1,20.74,50a.75.75,0,0,1,.86-.6.73.73,0,0,1,.59.6,9.1,9.1,0,0,0,2.93,5.12,7.93,7.93,0,0,0,10.28,0,9.12,9.12,0,0,0,3-5.15.66.66,0,0,1,1.3,0Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SleepingNinja;
