import React from "react";

const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7886 22.2469L20.8628 0.735873L0.00641509 8.77519L5.74863 14.0212L17.0137 4.95936L9.03357 17.0149L14.7886 22.2469Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default SendIcon;
