import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Login.module.scss";
import InputField from "../../componenets/Input/input";
import AuthLayout from "../../componenets/AuthLayout/AuthLayout";
import OneTimePin from "../../componenets/OneTimePin/OneTimePin";
import { Button, Modal, TextElement } from "../../componenets";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import LoginValidationLoading from "../../componenets/LoginValidation/LoginValidationLoading";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  login,
  sendOtp,
  updateUserData,
  User,
  UserRoleType,
  validateOtp,
} from "../../redux/authSlice";

type Props = {};

const Login: React.FC<Props> = () => {
  const navigate = useNavigate();

  const [oneTimePinVisibility, setOneTimePinVisibility] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const dispatch = useAppDispatch();
  const { loggedInUser, authError } = useAppSelector((state) => state.auth);

  const [loginValidationVisibility, setLoginValidationVisibility] =
    useState(false);

  const [validPinSubmitted, setValidPinSubmitted] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate(ROUTE_DEFINITIONS.LOGIN.path);
    } else {
      const userData: User = JSON.parse(user);
      if (userData?.role === UserRoleType.CMO) {
        navigate(ROUTE_DEFINITIONS.CMO_HOME.path);
      } else if (userData?.role === UserRoleType.MARKETING) {
        navigate(ROUTE_DEFINITIONS.HOME.path);
      } else if (
        userData?.role === UserRoleType.PRIMARY_APPROVER ||
        userData?.role === UserRoleType.SECONDARY_APPROVER
      ) {
        navigate(ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path);
      }
      dispatch(updateUserData(userData))
    }
  }, []);

  useEffect(() => {
    if (loggedInUser && validPinSubmitted) {
      if (loggedInUser.role === UserRoleType.CMO) {
        navigate(ROUTE_DEFINITIONS.CMO_HOME.path);
      } else if (loggedInUser.role === UserRoleType.MARKETING) {
        navigate(ROUTE_DEFINITIONS.HOME.path);
      } else if (
        loggedInUser.role === UserRoleType.PRIMARY_APPROVER ||
        loggedInUser.role === UserRoleType.SECONDARY_APPROVER
      ) {
        navigate(ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path);
      }
    }
  }, [loggedInUser, validPinSubmitted]);

  useEffect(() => {
    if (loggedInUser && !validPinSubmitted) {
      setOneTimePinVisibility(true);
    }
  }, [loggedInUser]);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    dispatch(sendOtp({ username, password })).then((result) => {
      if (sendOtp.fulfilled.match(result) && result.payload.success) {
        setOneTimePinVisibility(true);
      }
    });
  };

  const handleModalCloseOneTimePin = () => {
    setOneTimePinVisibility(false);
  };

  const handleModalCloseLoginValidation = () => {
    setLoginValidationVisibility(false);
  };

  const handlePinSubmit = (pin: string) => {
    setOneTimePinVisibility(false);
    setLoginValidationVisibility(true);

    dispatch(
      validateOtp({
        otp: pin,
      })
    ).then((result) => {
      if (validateOtp.fulfilled.match(result) && result.payload) {
        setLoginValidationVisibility(false);
        setValidPinSubmitted(true);
        const loggedUerRole = result.payload.data?.userdata?.role;

        if (loggedUerRole === UserRoleType.CMO) {
          navigate(ROUTE_DEFINITIONS.CMO_HOME.path);
        } else if (loggedUerRole === UserRoleType.MARKETING) {
          navigate(ROUTE_DEFINITIONS.HOME.path);
        } else if (
          loggedUerRole === UserRoleType.PRIMARY_APPROVER ||
          loggedUerRole === UserRoleType.SECONDARY_APPROVER
        ) {
          navigate(ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path);
        }
      } else {
        // The inputted PIN is wrong, try again
        // Show error modal

        setLoginValidationVisibility(false);
        setOneTimePinVisibility(true);
      }
    });
  };

  return (
    <AuthLayout>
      <div className={styles["form-container"]}>
        <Modal isOpen={oneTimePinVisibility} closeModal={() => {}}>
          <OneTimePin onPinSubmit={handlePinSubmit} />
        </Modal>

        <Modal
          isOpen={loginValidationVisibility}
          closeModal={handleModalCloseLoginValidation}
        >
          <LoginValidationLoading />
        </Modal>

        <form className={styles["form"]} onSubmit={handleSubmitForm}>
          <InputField
            label="Username"
            required
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <InputField
            label="Password"
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <Button className={styles["submit"]}>Login</Button>
          {authError && (
            <TextElement className={styles["error"]}>{authError}</TextElement>
          )}
          <TextElement
            className={styles["underline-text"]}
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.FORGOT_PASSWORD.path);
             }}
          >
            Forgot your password?
          </TextElement>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
