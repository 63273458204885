import React, { useState } from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";

import styles from "./CompetitorAnalysisModal.module.scss";
import CompetitorAnalysisCard from "../CompetitorAnalysisCard/CompetitorAnalysisCard";
import { useAppSelector } from "../../redux/hooks";
import Button from "../Button/Button";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
}

const SLIDE_LENGTH = 3;

const CompetitorAnalysisModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen } = props;
  const { competitors } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < competitors.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentCompetitors = competitors.slice(
    currentIndex,
    currentIndex + SLIDE_LENGTH
  );

  return (
    <Modal
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      <div className={styles["header"]}>
        <TextElement>Comparison Details</TextElement>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={closeModal}
        >
          <path
            d="M6.575 7.975L1.675 12.875C1.49167 13.0583 1.25833 13.15 0.975 13.15C0.691667 13.15 0.458333 13.0583 0.275 12.875C0.0916663 12.6917 0 12.4583 0 12.175C0 11.8917 0.0916663 11.6583 0.275 11.475L5.175 6.575L0.275 1.675C0.0916663 1.49167 0 1.25833 0 0.975C0 0.691667 0.0916663 0.458333 0.275 0.275C0.458333 0.0916663 0.691667 0 0.975 0C1.25833 0 1.49167 0.0916663 1.675 0.275L6.575 5.175L11.475 0.275C11.6583 0.0916663 11.8917 0 12.175 0C12.4583 0 12.6917 0.0916663 12.875 0.275C13.0583 0.458333 13.15 0.691667 13.15 0.975C13.15 1.25833 13.0583 1.49167 12.875 1.675L7.975 6.575L12.875 11.475C13.0583 11.6583 13.15 11.8917 13.15 12.175C13.15 12.4583 13.0583 12.6917 12.875 12.875C12.6917 13.0583 12.4583 13.15 12.175 13.15C11.8917 13.15 11.6583 13.0583 11.475 12.875L6.575 7.975Z"
            fill="black"
          />
        </svg>
      </div>
      <div className={styles["competitors"]}>
        {currentCompetitors.map((c, index) => (
          <CompetitorAnalysisCard
            className={styles["card"]}
            id={currentIndex + index}
            cardType="default"
            key={currentIndex + index}
          />
        ))}
      </div>
      <div className={styles["navigation"]}>
        <Button onClick={handleBack} disabled={currentIndex === 0}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33325 4.26676H10.6666M1.33325 4.26676L3.99992 6.93343M1.33325 4.26676L3.99992 1.6001"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
        <Button
          onClick={handleNext}
          disabled={currentIndex >= competitors.length - SLIDE_LENGTH}
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.19995 4.26676H10.5333H1.19995Z" fill="#D9D9D9" />
            <path
              d="M7.86662 6.93343L10.5333 4.26676L7.86662 6.93343Z"
              fill="#D9D9D9"
            />
            <path
              d="M7.86662 1.6001L10.5333 4.26676L7.86662 1.6001Z"
              fill="#D9D9D9"
            />
            <path
              d="M1.19995 4.26676H10.5333M10.5333 4.26676L7.86662 6.93343M10.5333 4.26676L7.86662 1.6001"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </div>
    </Modal>
  );
};

export default CompetitorAnalysisModal;
