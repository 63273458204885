import React from "react";

import TextElement from "../TextElement/TextElement";

import styles from "./Tag.module.scss";
import classNames from "classnames";
import { CampaignStatusType } from "../../redux/campaignPinningSlice";

interface TagProps {
  className?: string;
  text: string;
  backgroundColor: string;
}

const Tag: React.FC<TagProps> = ({ className, text, backgroundColor }) => {
 

  return (
    <TextElement
      className={classNames(styles["container"], className)}
      style={{ backgroundColor }}
    >
      {text}
    </TextElement>
  );
};

export default Tag;
