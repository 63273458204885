// const initialPostSchedules = [
//   {
//     post_schedule_id: "156a2581-d01f-48be-95c1-820a3f94dd2f",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-07-09 23:00:00+00",
//     post_number: 1,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "3d5e839c-4147-46b8-9c4d-69c4d469f89f",
//     post_date: "2024-07-09 23:00:00+00",
//     data: '{"text": "At Infotech Consultancy, we’re committed to driving innovation and supporting our community. Our technology solutions are designed to make a real impact, from enhancing business operations to supporting philanthropic initiatives. 🌍 Did you know? 72% of businesses consider innovation a top priority for growth. #TechForGood #BusinessTransformation"}',
//     status: "pending",
//   },
//   {
//     post_schedule_id: "94064056-305e-4103-968f-299dfc97f145",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-07-09 07:00:00+00",
//     post_number: 2,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "8a537a19-3c6b-45e0-b8b9-5c843acbce83",
//     post_date: "2024-07-09 07:00:00+00",
//     data: '{"text": "Partnering with industry leaders like Optus and Westpac, Infotech Consultancy is at the forefront of technological advancement. Our mission is to empower businesses with solutions that drive progress and efficiency. 🚀 According to a recent study, 70% of companies report increased efficiency through strategic tech partnerships. #ClientSuccess #TechInnovation"}',
//     status: "pending",
//   },
//   {
//     post_schedule_id: "c5226928-9c99-40d2-915b-85e062e3c657",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-09-11 23:00:00+00",
//     post_number: 1,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "c826e0f1-9e4d-4b3c-9ba8-2a018b49f011",
//     post_date: "2024-09-11 23:00:00+00",
//     data: '{"text": "Infotech Consultancy supports your cloud journey with expertise in leading platforms. Effective cloud strategies can lead to impressive outcomes. 📊 Studies show that cloud adoption can increase business agility by up to 50%. #CloudTech #BusinessGrowth"}',
//     status: "posted",
//   },
//   {
//     post_schedule_id: "f39bb492-f682-4f50-b5f9-791340739e20",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-09-12 07:00:00+00",
//     post_number: 2,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "fe94434c-30ea-493d-9fc7-9ae9e65ac8e6",
//     post_date: "2024-09-12 07:00:00+00",
//     data: '{"text": "Infotech Consultancy integrates advanced technologies to help businesses thrive. From cloud solutions to data analytics, we’re dedicated to enabling your success in a rapidly evolving landscape. 🌐 Research shows that digital transformation can boost revenue by up to 40%. #TechSolutions #FutureReady"}',
//     status: "posted",
//   },
//   {
//     post_schedule_id: "8f77dbea-149f-44a5-a188-3b95430925d9",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-09-13 23:00:00+00",
//     post_number: 1,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "0ff60e61-3e90-42b4-b74a-685d21c11d8c",
//     post_date: "2024-09-13 23:00:00+00",
//     data: '{"text": "Our dedication extends beyond technology. Infotech Consultancy actively supports various charitable causes, blending business success with community impact. 💡 A recent survey revealed that companies with strong CSR programs see a 20% increase in employee satisfaction. #Innovation #Philanthropy"}',
//     status: "posted",
//   },
//   {
//     post_schedule_id: "7c292267-5661-4832-9c62-3eae94b2133f",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-09-14 07:00:00+00",
//     post_number: 2,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "41ed7347-93ed-4d3a-9e8b-7806bbde8500",
//     post_date: "2024-09-14 07:00:00+00",
//     data: '{"text": "Salesforce is a powerful tool for enhancing customer relationships and streamlining operations. Infotech Consultancy’s managed services help businesses optimize their Salesforce environment for maximum efficiency. 📈 Did you know? Companies that leverage Salesforce see a 25% increase in sales productivity. #Salesforce #CRM"}',
//     status: "posted",
//   },
//   {
//     post_schedule_id: "5503711e-2ef6-4fa5-b5ae-4b6673fe3092",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-010-16 23:00:00+00",
//     post_number: 1,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "9e57b675-f145-49b7-9e5d-2fa2f4d46889",
//     post_date: "2024-09-16 23:00:00+00",
//     data: '{"text": "Maximizing the potential of Salesforce can transform your CRM strategy. Infotech Consultancy provides expert consulting to ensure you get the most out of your Salesforce investment. 🌟 According to Salesforce, 91% of users say their Salesforce implementation met or exceeded their expectations. #SalesforceConsulting #TechSolutions"}',
//     status: "pending",
//   },
//   {
//     post_schedule_id: "39a07b99-90e5-4768-9ba9-24cc3af5f3c6",
//     recommendation_plan_id: "39ad84d7-71a9-4019-b5cf-14c40e0716fe",
//     theme: "Info-Tech Update",
//     published_time: "2024-11-17 07:00:00+00",
//     post_number: 2,
//     content_type_id: "ffd21c4d-41cb-4866-8431-30879d418269",
//     channel_id: "0d39275a-37d2-494a-a7fe-82add2e08385",
//     post_id: "6703b5cf-02c2-4ab5-91a5-14069b227d79",
//     post_date: "2024-09-17 07:00:00+00",
//     data: '{"text": "Salesforce isn’t just about sales; it’s a comprehensive platform that enhances various business processes. Infotech Consultancy’s tailored solutions help you harness the full power of Salesforce. 🔍 Did you know? Salesforce customers report a 45% increase in customer satisfaction. #Salesforce #BusinessOptimization"}',
//     status: "pending",
//   },
// ];

import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchPosts } from "../../redux/contentGenerationSlice";
import { toast } from "react-toastify";

export type PostSchedule = {
  post_schedule_id: string;
  recommendation_plan_id: string;
  theme: string;
  published_time: string;
  post_number: number;
  content_type_id: string;
  channel_id: string;
  post_id: string;
  post_date: string;
  data: {
    text?: string;
    imageSrc?: string;
  };
  status: string;
};

const usePostSchedulesCalendarListView = () => {
  const [postSchedulesWithData, setPostSchedulesWithData] = useState<
    PostSchedule[]
  >([]);

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const dispatch = useAppDispatch();

  const { posts } = useAppSelector((state) => state.contentGeneration);

  useEffect(() => {
    if (selectedCampaign) {
      dispatch(
        fetchPosts({
          campaignPlanId: selectedCampaign.id,
        })
      ).catch(()=>{
        toast.error("Failed to fetch posts");
      })
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (posts.length > 0) {
      setPostSchedulesWithData(posts);
    }
  }, [posts]);

  const [dateRange, setDateRange] = useState<{
    startDateRange: Date | null;
    endDateRange: Date | null;
  }>({
    startDateRange: null,
    endDateRange: null,
  });

  useEffect(() => {
    setDateRange(getDateRange(postSchedulesWithData));
  }, [postSchedulesWithData]);

  const getDateRange = (
    postSchedules: PostSchedule[]
  ): { startDateRange: Date | null; endDateRange: Date | null } => {
    if (postSchedules.length === 0) {
      return { startDateRange: null, endDateRange: null };
    }

    let startDateRange: Date = new Date(postSchedules[0].published_time);
    let endDateRange: Date = new Date(postSchedules[0].published_time);

    postSchedules.forEach((post) => {
      const postDate = new Date(post.published_time);
      if (postDate < startDateRange) {
        startDateRange = postDate;
      }
      if (postDate > endDateRange) {
        endDateRange = postDate;
      }
    });

    return {
      startDateRange,
      endDateRange,
    };
  };

  const filterPostsByDate = (
    posts: PostSchedule[],
    targetDate: Date
  ): PostSchedule[] => {
    return posts.filter((post) => {
      const postDate = new Date(post.published_time);

      const postLocalDate = new Date(
        postDate.getUTCFullYear(),
        postDate.getUTCMonth(),
        postDate.getUTCDate(),
        postDate.getUTCHours(),
        postDate.getUTCMinutes(),
        postDate.getUTCSeconds()
      );

      // Get local date and month
      const localDay = postLocalDate.getDate();
      const localMonth = postLocalDate.getMonth();

      // Get target date and month
      const targetDay = targetDate.getDate();
      const targetMonth = targetDate.getMonth();

      return localMonth === targetMonth && localDay === targetDay;
    });
  };

  const groupPostsByDayInMonth = (
    posts: PostSchedule[],
    targetMonth: number,
    targetYear: number
  ) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Filter posts for the target month and year
    const filteredPosts = posts.filter((post) => {
      const postDate = new Date(post.published_time);
      return (
        postDate.getMonth() === targetMonth &&
        postDate.getFullYear() === targetYear
      );
    });

    // Group posts by day with string keys
    const groupedPosts = filteredPosts.reduce((acc, post) => {
      const postDate = new Date(post.published_time);

      const postLocalDate = new Date(
        postDate.getUTCFullYear(),
        postDate.getUTCMonth(),
        postDate.getUTCDate(),
        postDate.getUTCHours(),
        postDate.getUTCMinutes(),
        postDate.getUTCSeconds()
      );

      // Get local date and month
      const day = postLocalDate.getDate();
      const targetMonth = postLocalDate.getMonth();

      const monthName = monthNames[targetMonth];

      const key = `${monthName} ${day}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(post);

      return acc;
    }, {} as Record<string, PostSchedule[]>);

    return groupedPosts;
  };

  return {
    postSchedulesWithData,
    dateRange,
    filterPostsByDate,
    groupPostsByDayInMonth,
  };
};

export default usePostSchedulesCalendarListView;
