import React from "react";

const PdfIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 17.7778V2.22222C20 1 19 0 17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778ZM6.11111 11.6667L8.88889 15.0111L12.7778 10L17.7778 16.6667H2.22222L6.11111 11.6667Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default PdfIcon;
