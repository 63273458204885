import React, { useState } from "react";

import styles from "./ContentGeneration.module.scss";
import TextArea from "../../../../componenets/MarketingBriefFormCard/components/TextArea/TextArea";
import { TextElement } from "../../../../componenets";
import Input from "../../../../componenets/MarketingBriefFormCard/components/Input/Input";
import { PlusIcon } from "../../../../icons";
import { AiOutlineMinus } from "react-icons/ai";
import { TiMinus } from "react-icons/ti";
interface ContentGenerationProps {}

const ContentGeneration: React.FC<ContentGenerationProps> = (props) => {
  const [promptTemplates, setPromptTemplates] = useState<
    {
      prompt: string;
      marketingAction: string;
      id: number;
    }[]
  >([]);

  return (
    <div className={styles["container"]}>
      <TextElement className={styles["sub-header-text"]}>
        Prompt Templates
      </TextElement>
      {promptTemplates.map((template) => (
        <div className={styles["prompt-input-row"]} key={template.id}>
          <Input
            className={styles["action-input"]}
            label="Marketing action*"
            value={""}
            onChange={() => {}}
            placeholder="marketing action"
          />
          <Input
            className={styles["prompt-input"]}
            label="Prompt Template*"
            value={""}
            onChange={() => {}}
            placeholder="prompt template"
          />
          <span
            className={styles["remove-icon"]}
            onClick={() => {
              setPromptTemplates(
                promptTemplates.filter((t) => t.id !== template.id)
              );
            }}
          >
            <svg
              width="20"
              height="4"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 3.42855H11.4286H8.57143H0V0.571411H8.57143H11.4286H20V3.42855Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
      ))}
      <div className={styles["prompt-input-row"]}>
        <Input
          className={styles["action-input"]}
          label="Marketing action*"
          value={""}
          onChange={() => {}}
          placeholder="marketing action"
        />
        <Input
          className={styles["prompt-input"]}
          label="Prompt Template*"
          value={""}
          onChange={() => {}}
          placeholder="prompt template"
        />
        <span
          className={styles["add-icon"]}
          onClick={() => {
            setPromptTemplates([
              ...promptTemplates,
              {
                prompt: "",
                marketingAction: "",
                id: promptTemplates.length + 1,
              },
            ]);
          }}
        >
          <PlusIcon />
        </span>
       
      </div>
      <TextElement className={styles["header-text"]}>
        Brand parameters
      </TextElement>
      <div className={styles["equal-columns"]}>
        <TextArea
          label="Mission description*"
          value=""
          placeholder="mission description"
          onChange={() => {}}
        />
        <TextArea
          label="Style Description*"
          value=""
          placeholder="style description"
          onChange={() => {}}
        />
        <TextArea
          label="Values description*"
          value=""
          placeholder="values description"
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default ContentGeneration;
