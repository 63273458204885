import React from "react";

const Download: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 8.82353H14.2857V0H5.71429V8.82353H0L10 19.1176L20 8.82353ZM0 22.0588V25H20V22.0588H0Z"
        fill="#02285E"
      />
    </svg>
  );
};

export default Download;
