import { Dropzone, FileMosaic, ExtFile } from "@files-ui/react";
import React from "react";
import File from "../File/File";

import styles from "./BrandAssests.module.scss";
import classNames from "classnames";

 interface BrandAssetsProps {
  className?: string;
}

const BrandAssets: React.FC<BrandAssetsProps> = ({ className }) => {
  const [files, setFiles] = React.useState<ExtFile[]>([]);

  const updateFiles = (incomingFiles: ExtFile[]) => {
    // Do something with the files 
    console.log("incoming files", incomingFiles);
    setFiles(incomingFiles);
    // Even your own upload implementation
  };

  const removeFile = (id: string) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  return (
    <div className={classNames(styles["container"], className)}>
      <Dropzone onChange={updateFiles} value={files}>
        {files.map((file) => (
          <FileMosaic
            key={file.id}
            {...file}
            onDelete={() => removeFile(file.id as string)}
            info
          />
        ))}
      </Dropzone>
      <div className={styles["file-list"]}>
        <File fileName="IMG-1" fileUrl="brandAssetsImages/1.png" />
        <File fileName="IMG-2" fileUrl="brandAssetsImages/2.png" />
        <File fileName="IMG-3" fileUrl="brandAssetsImages/3.png" />
        <File fileName="IMG-4" fileUrl="brandAssetsImages/4.png" />
        <File fileName="IMG-5" fileUrl="brandAssetsImages/5.png" />
        <File fileName="IMG-6" fileUrl="brandAssetsImages/6.png" />
      </div>
    </div>
  );
};

export default BrandAssets;
