import React from "react";
import { CSSProperties } from "react";

import classNames from "classnames";

import styles from "./TextElement.module.scss";

interface TextElementProps extends React.HTMLProps<HTMLParagraphElement> {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

const TextElement: React.FC<TextElementProps> = ({ children, className, style, ...rest }) => {
  return (
    <p
      className={classNames(styles["text"], className)}
      style={style}
      {...rest}
    >
      {children}
    </p>
  );
}

export default TextElement;