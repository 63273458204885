import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import { CampaignAnalysisQueryChatProvider } from "./context/CampaignAnalysisQueryChatContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <Provider store={store}>
      <CampaignAnalysisQueryChatProvider>
        <App />
        <ToastContainer />
      </CampaignAnalysisQueryChatProvider>
    </Provider>
  </>
);
