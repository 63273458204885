import React from "react";

interface NotificationIconProps extends React.SVGProps<SVGSVGElement> {
  unreadCount?: boolean;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({
  unreadCount = 0,
  ...props
}) => { 
  if (unreadCount === 0)
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.85 11.2861V11.18V8.00015C13.8486 6.6187 13.3584 5.28231 12.4661 4.22766C11.5738 3.17301 10.3371 2.46814 8.975 2.2379L8.85 2.21677V2.09V1C8.85 0.774566 8.76045 0.558365 8.60104 0.398959C8.44164 0.239553 8.22543 0.15 8 0.15C7.77457 0.15 7.55837 0.239553 7.39896 0.398959C7.23955 0.558365 7.15 0.774566 7.15 1V2.09V2.21677L7.025 2.2379C5.66287 2.46814 4.42616 3.17301 3.5339 4.22766C2.64167 5.28227 2.15144 6.6186 2.15 8M13.85 11.2861L2.15 8M13.85 11.2861L13.95 11.3214C14.5044 11.5174 14.9846 11.8801 15.3247 12.3597C15.6649 12.8393 15.8483 13.4124 15.85 14.0004V16C15.85 16.2254 15.7604 16.4416 15.601 16.601C15.4416 16.7604 15.2254 16.85 15 16.85H11.86H11.7453L11.7153 16.9607C11.4936 17.7763 11.0097 18.4963 10.3383 19.0096C9.66687 19.5229 8.84517 19.801 8 19.801C7.15483 19.801 6.33313 19.5229 5.6617 19.0096C4.99026 18.4963 4.5064 17.7763 4.28475 16.9607L4.25468 16.85H4.14H1C0.774566 16.85 0.558365 16.7604 0.398959 16.601C0.239554 16.4416 0.15 16.2254 0.15 16V14.0002C0.151696 13.4123 0.335174 12.8393 0.675291 12.3597C1.01545 11.8801 1.49563 11.5174 2.05 11.3214L2.15 11.2861V11.18L2.15 8.00015M13.85 11.2861L2.15 8.00015M2.15 8C2.15 8.00005 2.15 8.0001 2.15 8.00015M2.15 8L2.15 8.00015M12 11.15H12.15V11V8C12.15 6.89935 11.7128 5.84378 10.9345 5.06551C10.1562 4.28723 9.10065 3.85 8 3.85C6.89935 3.85 5.84378 4.28723 5.06551 5.06551C4.28723 5.84378 3.85 6.89935 3.85 8V11V11.15H4H12ZM6.28 16.85H6.0202L6.15009 17.075C6.33769 17.3999 6.60707 17.6701 6.93144 17.8587C7.25581 18.0473 7.62389 18.1477 7.99909 18.15L8.00091 18.15C8.37611 18.1477 8.74419 18.0473 9.06856 17.8587C9.39293 17.6701 9.66231 17.3999 9.84991 17.075L9.9798 16.85H9.72H6.28ZM14 15.15H14.15V15V14C14.15 13.695 14.0288 13.4025 13.8132 13.1868C13.5975 12.9712 13.305 12.85 13 12.85H3C2.695 12.85 2.40249 12.9712 2.18683 13.1868C1.97116 13.4025 1.85 13.695 1.85 14V15V15.15H2H14Z"
          fill="#EB2701"
          stroke="white"
          stroke-width="0.3"
        />
        <rect x="10.5" y="9.5" width="9" height="9" rx="4.5" fill="#EB2701" />
        <rect x="10.5" y="9.5" width="9" height="9" rx="4.5" stroke="white" />
        {/* <path
          d="M14.9891 16.5597C14.6709 16.5597 14.3875 16.505 14.1389 16.3956C13.8918 16.2848 13.6965 16.1328 13.553 15.9396C13.411 15.745 13.3378 15.5206 13.3335 15.2663H14.2625C14.2682 15.3729 14.303 15.4666 14.3669 15.5476C14.4323 15.6271 14.5189 15.6889 14.6269 15.733C14.7348 15.777 14.8563 15.799 14.9912 15.799C15.1318 15.799 15.2561 15.7741 15.3641 15.7244C15.472 15.6747 15.5566 15.6058 15.6176 15.5178C15.6787 15.4297 15.7093 15.3281 15.7093 15.2131C15.7093 15.0966 15.6766 14.9936 15.6112 14.9041C15.5473 14.8132 15.455 14.7422 15.3343 14.6911C15.2149 14.6399 15.0729 14.6143 14.9081 14.6143H14.5012V13.9368H14.9081C15.0473 13.9368 15.1702 13.9126 15.2767 13.8643C15.3847 13.8161 15.4685 13.7493 15.5281 13.6641C15.5878 13.5774 15.6176 13.4766 15.6176 13.3615C15.6176 13.2521 15.5914 13.1562 15.5388 13.0739C15.4877 12.9901 15.4152 12.9247 15.3215 12.8778C15.2291 12.831 15.1212 12.8075 14.9976 12.8075C14.8726 12.8075 14.7583 12.8303 14.6546 12.8757C14.5509 12.9197 14.4678 12.983 14.4053 13.0653C14.3428 13.1477 14.3094 13.2443 14.3051 13.3551H13.4209C13.4252 13.1037 13.4969 12.8821 13.6361 12.6903C13.7753 12.4986 13.9628 12.3487 14.1986 12.2408C14.4358 12.1314 14.7036 12.0767 15.0019 12.0767C15.303 12.0767 15.5665 12.1314 15.7923 12.2408C16.0182 12.3501 16.1936 12.4979 16.3186 12.6839C16.445 12.8686 16.5075 13.076 16.5061 13.3061C16.5075 13.5504 16.4316 13.7543 16.2781 13.9176C16.1262 14.081 15.928 14.1847 15.6837 14.2287V14.2628C16.0047 14.304 16.249 14.4155 16.4166 14.5973C16.5857 14.7777 16.6695 15.0036 16.6681 15.2749C16.6695 15.5234 16.5977 15.7443 16.4529 15.9375C16.3094 16.1307 16.1112 16.2827 15.8584 16.3935C15.6056 16.5043 15.3158 16.5597 14.9891 16.5597Z"
          fill="white"
        /> */}
        <span>10</span>
      </svg>
    );

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.8">
        <path
          d="M17.85 13.2861V13.18V10.0002C17.8486 8.6187 17.3584 7.28231 16.4661 6.22766C15.5738 5.17301 14.3371 4.46814 12.975 4.2379L12.85 4.21677V4.09V3C12.85 2.77457 12.7604 2.55837 12.601 2.39896C12.4416 2.23955 12.2254 2.15 12 2.15C11.7746 2.15 11.5584 2.23955 11.399 2.39896C11.2396 2.55837 11.15 2.77457 11.15 3V4.09V4.21677L11.025 4.2379C9.66287 4.46814 8.42616 5.17301 7.5339 6.22766C6.64167 7.28227 6.15144 8.6186 6.15 10M17.85 13.2861L6.15 10M17.85 13.2861L17.95 13.3214C18.5044 13.5174 18.9846 13.8801 19.3247 14.3597C19.6649 14.8393 19.8483 15.4124 19.85 16.0004V18C19.85 18.2254 19.7604 18.4416 19.601 18.601C19.4416 18.7604 19.2254 18.85 19 18.85H15.86H15.7453L15.7153 18.9607C15.4936 19.7763 15.0097 20.4963 14.3383 21.0096C13.6669 21.5229 12.8452 21.801 12 21.801C11.1548 21.801 10.3331 21.5229 9.6617 21.0096C8.99026 20.4963 8.5064 19.7763 8.28475 18.9607L8.25468 18.85H8.14H5C4.77457 18.85 4.55837 18.7604 4.39896 18.601C4.23955 18.4416 4.15 18.2254 4.15 18V16.0002C4.1517 15.4123 4.33517 14.8393 4.67529 14.3597C5.01545 13.8801 5.49563 13.5174 6.05 13.3214L6.15 13.2861V13.18L6.15 10.0002M17.85 13.2861L6.15 10.0002M6.15 10C6.15 10.0001 6.15 10.0001 6.15 10.0002M6.15 10L6.15 10.0002M16 13.15H16.15V13V10C16.15 8.89935 15.7128 7.84378 14.9345 7.06551C14.1562 6.28723 13.1006 5.85 12 5.85C10.8994 5.85 9.84378 6.28723 9.06551 7.06551C8.28723 7.84378 7.85 8.89935 7.85 10V13V13.15H8H16ZM10.28 18.85H10.0202L10.1501 19.075C10.3377 19.3999 10.6071 19.6701 10.9314 19.8587C11.2558 20.0473 11.6239 20.1477 11.9991 20.15L12.0009 20.15C12.3761 20.1477 12.7442 20.0473 13.0686 19.8587C13.3929 19.6701 13.6623 19.3999 13.8499 19.075L13.9798 18.85H13.72H10.28ZM18 17.15H18.15V17V16C18.15 15.695 18.0288 15.4025 17.8132 15.1868C17.5975 14.9712 17.305 14.85 17 14.85H7C6.695 14.85 6.40249 14.9712 6.18683 15.1868C5.97116 15.4025 5.85 15.695 5.85 16V17V17.15H6H18Z"
          fill="#EB2701"
          stroke="white"
          strokeWidth="0.3"
        />
        <circle cx="19" cy="16" r="4.5" fill="#F72020" stroke="white" />
      </g>
    </svg>
  );
};

export default NotificationIcon;
