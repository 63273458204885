import React from "react";

const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.5 0C18.144 0 0 18.144 0 40.5C0 62.856 18.144 81 40.5 81C62.856 81 81 62.856 81 40.5C81 18.144 62.856 0 40.5 0ZM40.5 12.15C47.223 12.15 52.65 17.577 52.65 24.3C52.65 31.023 47.223 36.45 40.5 36.45C33.777 36.45 28.35 31.023 28.35 24.3C28.35 17.577 33.777 12.15 40.5 12.15ZM40.5 69.66C30.375 69.66 21.4245 64.476 16.2 56.619C16.3215 48.5595 32.4 44.145 40.5 44.145C48.5595 44.145 64.6785 48.5595 64.8 56.619C59.5755 64.476 50.625 69.66 40.5 69.66Z"
        fill="#17224D"
      />
    </svg>
  );
};

export default UserIcon;
