import React, { useEffect } from "react";
import styles from "./CampaignBuilder.module.scss";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import Button from "../Button/Button";
import ShowCampaignAnalysisData from "../ShowCampaignAnalysisData/ShowCampaignAnalysisData";
import ShowCampaignContentData from "../ShowCampaignContentData/ShowCampaignContentData";
import { useAppSelector } from "../../redux/hooks";
import ApprovalCampaignModal from "../ApprovalCampaignModal/ApprovalCampaignModal";
import ApprovalSubmittedCampaignModal from "../ApprovalSubmittedCampaignModal/ApprovalSubmittedCampaignModal";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";

interface Props {
  open: boolean;
  onClose: () => void;
  showWhichOperation: "content" | "analysis";
}

const CampaignBuilder: React.FC<Props> = ({
  open,
  onClose,
  showWhichOperation,
}: Props) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const [isApprovalModalOpen, setIsApprovalModalOpen] =
    React.useState<boolean>(false);
  const [isApprovalSubmittedModalOpen, setIsApprovalSubmittedModalOpen] =
    React.useState<boolean>(false);

  return (
    <div className={styles["container"]}>
      <ApprovalCampaignModal
        isOpen={isApprovalModalOpen}
        closeModal={() => {
          setIsApprovalModalOpen(false);
        }}
        onOk={() => { 
          setIsApprovalModalOpen(false);
          setIsApprovalSubmittedModalOpen(true);
        }}
      />
      <ApprovalSubmittedCampaignModal
        isOpen={isApprovalSubmittedModalOpen}
        closeModal={() => {
          setIsApprovalSubmittedModalOpen(false);
        }}
      />
      <button 
        className={classNames(
          styles["collapse-btn"],
          open ? styles["open"] : styles["closed"]
        )}
        onClick={onClose}
      >
        <svg
          width="18"
          height="30"
          viewBox="0 0 18 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <path
            d="M17.655 2.655L15 0L0 15L15 30L17.655 27.345L5.31 15L17.655 2.655Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        className={classNames(
          styles["campaign-builder"],
          open ? styles["open"] : styles["closed"]
        )}
      >
        <div className={styles["header"]}>
          <TextElement className={styles["header-text"]}>
            {location.pathname === 
            ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path
              ? "Your Campaign Plan"
              : "Your Campaign Assets"}
          </TextElement>
          <Button
            className={styles["submit-btn"]}
            disabled={
              !selectedCampaign?.activeContentStep 
            }
            onClick={() => setIsApprovalModalOpen(true)}
          >
            <TextElement>Submit for approval ✅</TextElement>
          </Button>
        </div>
        <div className={styles["campaign-builder-content"]}>
        <ShowCampaignAnalysisData />
        {/* <ShowCampaignContentData /> */}
          {/* {showWhichOperation === "analysis" && }
          {showWhichOperation === "content" && } */}
        </div>
      </div>
    </div>
  );
};

export default CampaignBuilder;
