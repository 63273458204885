import React from "react";

const TargetAudienceIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 0C17.92 0 0 17.92 0 40C0 62.08 17.92 80 40 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 40 0ZM54.44 25.36C58.72 25.36 62.16 28.8 62.16 33.08C62.16 37.36 58.72 40.8 54.44 40.8C50.16 40.8 46.72 37.36 46.72 33.08C46.68 28.8 50.16 25.36 54.44 25.36ZM30.44 19.04C35.64 19.04 39.88 23.28 39.88 28.48C39.88 33.68 35.64 37.92 30.44 37.92C25.24 37.92 21 33.68 21 28.48C21 23.24 25.2 19.04 30.44 19.04ZM30.44 55.56V70.56C20.84 67.56 13.24 60.16 9.88 50.72C14.08 46.24 24.56 43.96 30.44 43.96C32.56 43.96 35.24 44.28 38.04 44.84C31.48 48.32 30.44 52.92 30.44 55.56ZM40 72C38.92 72 37.88 71.96 36.84 71.84V55.56C36.84 49.88 48.6 47.04 54.44 47.04C58.72 47.04 66.12 48.6 69.8 51.64C65.12 63.52 53.56 72 40 72Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default TargetAudienceIcon;
