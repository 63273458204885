import React from "react";

const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 9.14286H9.64286V16H7.35714V9.14286H0.5V6.85714H7.35714V0H9.64286V6.85714H16.5V9.14286Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
