import React from "react";

import { LoadingThreeCircles, ThoughtNinja } from "../../icons";
import styles from "./LoginValidationLoading.module.scss";

interface LoginValidationLoadingProps {}

const LoginValidationLoading: React.FC<LoginValidationLoadingProps> = ({}) => {
  return (
    <div className={styles["validate-container"]}>
      <ThoughtNinja />

      <div className={styles["loading-text"]}>Validating... logging you in</div>

      <LoadingThreeCircles className={styles["loading-three-circles"]} />
    </div>
  );
};

export default LoginValidationLoading;
