import React from "react";

const DevelopStrategyIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="148"
      height="144"
      viewBox="0 0 148 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.25">
        <path
          d="M92.6606 129.492C119.338 129.492 140.964 127.012 140.964 123.953C140.964 120.893 119.338 118.413 92.6606 118.413C65.9836 118.413 44.3577 120.893 44.3577 123.953C44.3577 127.012 65.9836 129.492 92.6606 129.492Z"
          fill="#EB2701"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M44.2432 143.634C44.194 143.634 44.1449 143.628 44.0971 143.617L21.805 138.215L0.699415 140.964C0.571739 140.981 0.44205 140.957 0.328386 140.897C0.214723 140.836 0.122737 140.742 0.0652239 140.626C0.00771035 140.511 -0.0124714 140.381 0.00748579 140.254C0.027443 140.127 0.0865469 140.009 0.176576 139.917L8.86664 131.035L5.47929 123.225C5.43515 123.123 5.41907 123.011 5.43274 122.901C5.44642 122.791 5.48933 122.687 5.55702 122.599C5.62471 122.512 5.71469 122.444 5.81758 122.403C5.92047 122.361 6.0325 122.349 6.142 122.366L22.6189 124.915L41.6641 121.38C41.7814 121.359 41.9024 121.372 42.0126 121.417C42.1228 121.463 42.2175 121.539 42.2853 121.637C42.3531 121.735 42.3911 121.851 42.3948 121.97C42.3985 122.09 42.3677 122.207 42.3061 122.309L36.9833 131.057L44.7578 142.671C44.8202 142.764 44.856 142.873 44.8615 142.985C44.867 143.097 44.8419 143.209 44.7889 143.308C44.7358 143.407 44.6569 143.489 44.5605 143.547C44.4641 143.605 44.3539 143.635 44.2416 143.635L44.2432 143.634ZM21.839 136.967C21.8881 136.967 21.9369 136.973 21.9846 136.984L42.8445 142.039L35.7343 131.418C35.6686 131.32 35.6323 131.205 35.6298 131.087C35.6272 130.969 35.6585 130.853 35.7198 130.752L40.526 122.854L22.7417 126.153C22.6735 126.166 22.6038 126.167 22.5353 126.156L7.06432 123.762L10.1678 130.924C10.2168 131.037 10.2311 131.162 10.2087 131.283C10.1864 131.404 10.1285 131.516 10.0424 131.604L2.31284 139.505L21.759 136.972C21.7856 136.968 21.8123 136.967 21.839 136.967Z"
          fill="#EB2701"
        />
        <path
          d="M22.7357 133.558C19.2059 133.558 13.2322 133.001 13.2322 130.916C13.2322 128.83 19.2059 128.273 22.7357 128.273C26.2655 128.273 32.2386 128.83 32.2386 130.916C32.2386 133.002 26.265 133.558 22.7357 133.558ZM14.4915 130.916C14.8859 131.442 17.8361 132.319 22.7357 132.319C27.6353 132.319 30.5855 131.441 30.9798 130.916C30.5855 130.389 27.6353 129.512 22.7357 129.512C17.8361 129.512 14.8859 130.389 14.4915 130.916Z"
          fill="#EB2701"
        />
      </g>
      <path
        d="M36.0676 126.547L46.2167 80.6663L44.5718 79.9747L43.1241 82.936L40.2203 79.251L38.9135 81.0277L42.3205 86.0862L33.9375 125.511L36.0676 126.547Z"
        fill="#AF5528"
      />
      <path
        d="M59.6893 112.86C59.6893 112.86 55.791 116.858 53.3234 118.536C50.8558 120.214 51.9716 123.92 51.9716 123.92L78.7871 124.226C79.8235 122.4 79.2805 113.107 79.2805 113.107C69.3781 113.403 59.6893 112.86 59.6893 112.86Z"
        fill="#02285E"
      />
      <path
        d="M127.487 123.43C127.487 123.43 134.4 131.506 136.878 125.998C138.384 122.65 139.447 110.077 138.621 106.085C138.256 104.321 131.465 105.259 131.465 105.259C131.465 105.259 133.849 118.903 127.487 123.43Z"
        fill="#02285E"
      />
      <path
        d="M39.4066 89.3442C37.852 90.3559 37.7782 93.0947 38.1235 94.8714C38.4688 96.6481 40.7388 96.3518 40.7388 96.3518C39.9491 96.8458 40.2226 98.2276 41.5284 98.6467C42.7088 99.0256 48.2701 97.9974 49.3421 97.7935C49.0324 95.5017 47.897 88.826 47.897 88.826C45.8711 88.2582 40.9607 88.3325 39.4066 89.3442Z"
        fill="#F2C5A7"
      />
      <path
        d="M49.3498 97.8521C49.3498 97.834 49.3446 97.8127 49.342 97.7939C49.3446 97.8127 49.3478 97.8334 49.3498 97.8521Z"
        fill="#3E4049"
      />
      <path
        d="M139.912 61.6173C135.767 56.024 110.175 53.1406 110.175 53.1406C107.055 58.2792 105.771 58.6462 95.2185 63.9684C81.8818 70.6958 70.1693 67.9146 70.1693 67.9146C66.2673 68.0571 58.2575 78.7094 55.4451 82.0655C52.6327 85.4217 47.8967 88.7361 47.8967 88.7361C47.8967 88.7361 49.0322 95.5011 49.3419 97.7944L49.5225 97.7593V98.2038C56.0366 97.6117 65.857 93.8111 65.857 93.8111L65.5607 95.3411C65.5607 95.3411 61.218 98.7478 59.9349 103.287C58.6518 107.826 59.6882 112.862 59.6882 112.862C59.6882 112.862 69.377 113.404 79.2794 113.108L80.6121 111.727C87.0766 113.256 98.625 110.789 98.625 110.789C98.625 110.789 100.896 115.607 106.964 118.568C113.032 121.53 116.966 122.147 116.966 122.147C117.52 121.992 121.878 121.712 123.388 121.964C125.016 122.235 127.14 123.915 127.976 123.249C133.849 118.568 131.463 105.262 131.463 105.262C131.463 105.262 125.928 103.639 124.237 102.991C122.201 102.211 118.8 99.4127 118.8 96.7285C118.8 83.9222 114.763 67.9146 114.763 67.9146C122.196 66.3547 132.197 72.9618 132.197 72.9618L133.39 70.5414L139.452 62.2754L139.912 61.6173Z"
        fill="#02285E"
      />
      <path
        d="M145.11 66.8802C142.742 64.3138 139.452 62.2744 139.452 62.2744L133.39 70.5404L133.736 70.9849C135.117 73.7722 137.116 76.2654 137.979 76.1668C138.843 76.0682 138.67 71.8237 138.843 71.8237C139.015 71.8237 140.965 71.6843 140.965 71.6843C140.965 71.6843 143.531 74.9083 144.518 74.7767C145.505 74.6451 145.045 72.6712 145.045 72.6712C145.045 72.6712 146.163 74.3163 147.479 73.5926C148.794 72.8689 147.479 69.4467 145.11 66.8802Z"
        fill="#F2C5A7"
      />
      <path
        d="M88.4754 72.3329C88.4754 72.3329 83.7575 68.9777 83.2899 68.5999C82.8223 68.222 81.9944 67.5335 81.9944 67.5335L82.3521 66.9842C82.3521 66.9842 86.8863 66.5047 89.6775 65.8559C92.4687 65.2071 94.3928 64.6104 94.3928 64.6104C94.3738 64.8584 94.3738 65.1076 94.3928 65.3557C94.4248 65.7687 89.9887 73.5113 89.9887 73.5113L88.8878 72.7293L88.4754 72.3329Z"
        fill="#F2C5A7"
      />
      <path
        d="M66.8934 91.0992C66.8934 91.0992 76.944 96.4256 91.4194 94.1224C105.895 91.8193 112.652 89.5445 117.493 81.999C117.493 81.999 118.387 88.124 118.525 91.0145C118.525 91.0145 111.908 99.6749 86.9378 100.168C75.8839 100.563 66.8088 97.6459 65.5443 96.3941C65.5443 96.3941 65.5117 93.423 66.8934 91.0992Z"
        fill="#EB2701"
      />
      <path
        d="M65.7954 78.367C65.7981 78.3532 65.8054 78.3407 65.8159 78.3314C65.8265 78.3221 65.8399 78.3165 65.8539 78.3156C65.868 78.3146 65.8819 78.3184 65.8937 78.3262C65.9054 78.334 65.9142 78.3454 65.9188 78.3588C66.4782 80.1581 66.7657 81.9611 67.1998 83.7786C67.6277 85.5641 68.9717 86.949 69.5038 88.6988C69.5745 88.9269 68.9531 90.3965 68.9531 90.3965L70.2377 92.4612L66.8659 91.1047L67.2302 89.5211C66.9902 88.5966 66.7714 87.6912 66.582 86.7688"
        fill="#00284C"
      />
      <path
        d="M105.723 87.5349C105.671 87.5346 105.619 87.5291 105.569 87.5183C88.7572 83.9392 74.9621 68.8792 75.1474 68.8999C80.5383 69.4961 82.6271 67.9981 82.9012 68.3032C83.023 68.4395 98.8253 81.5281 115.154 85.0046C115.555 85.0897 112.263 87.6427 111.85 88.0557C111.597 88.3081 106.065 87.5349 105.723 87.5349Z"
        fill="#00284C"
      />
      <path
        d="M89.8661 73.9672C89.7326 73.967 89.6016 73.9309 89.4868 73.8626C89.3721 73.7943 89.2779 73.6963 89.2141 73.579C89.1503 73.4617 89.1193 73.3294 89.1243 73.196C89.1293 73.0626 89.1702 72.9329 89.2426 72.8208L94.7383 64.3163C94.7913 64.2343 94.8599 64.1635 94.9402 64.108C95.0205 64.0526 95.111 64.0135 95.2064 63.9929C95.3018 63.9724 95.4003 63.9709 95.4964 63.9885C95.5924 64.006 95.684 64.0424 95.7659 64.0953C96.1107 64.3183 102.231 61.2811 102.011 61.626L94.1659 76.1248C94.0209 76.3457 90.1102 73.9672 89.8661 73.9672Z"
        fill="#00284C"
      />
      <path
        d="M81.1313 94.7834C81.1313 94.7834 82.6488 93.2147 85.0405 93.0088C87.4322 92.8028 88.975 94.4752 88.975 94.4752C88.975 94.4752 91.3925 95.3496 93.4756 98.2815C95.5587 101.213 96.433 104.737 97.4106 105.663C98.3881 106.588 99.5964 107.077 99.5964 107.077C99.5964 107.077 98.4392 113.839 93.5788 114.948C93.5788 114.948 91.5468 113.559 90.7752 110.061C90.0036 106.563 89.1035 102.129 87.535 101.156C87.535 101.156 85.9659 103.272 83.9602 102.343C83.9602 102.343 80.6426 104.581 77.8653 101.827C77.8653 101.827 77.8906 104.656 79.0482 107.306C80.2059 109.955 82.5971 113.488 82.5971 113.488L76.5533 115.742C76.5533 115.742 73.4369 113.109 72.192 106.616C70.9471 100.122 74.6524 94.8624 74.6524 94.8624C74.6524 94.8624 77.2908 91.4665 81.1313 94.7834Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M97.6197 105.854C97.6197 105.854 97.0003 109.214 95.327 109.163C93.6537 109.111 93.1665 106.977 92.3696 104.507C91.5727 102.038 90.7696 101.421 90.6194 100.469C90.4692 99.5175 91.3936 97.5633 90.9549 95.8398C90.9549 95.8398 90.6529 95.335 89.9262 94.9535C89.1995 94.5721 88.5683 94.3233 88.5136 94.1793C88.4589 94.0352 87.8942 93.6631 87.8942 93.6631C87.8942 93.6631 88.0744 97.0595 86.4284 98.1399C84.7825 99.2202 83.7709 98.8429 83.7027 98.7397C83.6346 98.6364 82.4852 96.5965 82.4852 96.5965C82.4852 96.5965 82.5709 100.403 80.4966 100.266C78.4222 100.129 75.3064 97.8018 75.3064 97.8018L74.735 96.8515C74.735 96.8515 74.4439 98.3608 74.8723 99.7152C75.3007 101.07 75.8839 101.979 75.9696 102.348C76.0553 102.717 75.9009 103.916 76.9812 107.329C78.0615 110.741 78.3701 111.958 77.2393 112.61C76.1084 113.261 74.479 112.816 74.479 112.816C74.479 112.816 75.9495 115.652 76.4289 115.634C76.9084 115.616 82.3804 113.576 82.3804 113.576L82.4738 113.31C82.4738 113.31 79.9314 109.571 78.8341 106.787C77.7368 104.003 77.9938 102.648 78.0083 102.562C78.0227 102.477 78.0599 102.141 78.0599 102.141C78.0599 102.141 79.3286 103.247 81.1288 103.205C82.9291 103.163 84.112 102.348 84.112 102.348C84.112 102.348 85.2413 102.921 86.1415 102.301C87.0416 101.682 87.4674 101.241 87.4674 101.241L87.8684 101.429C87.8684 101.429 89.1159 102.889 89.887 106.215C90.6581 109.541 90.7128 111.175 91.92 113.018C93.1273 114.861 93.6341 114.937 93.6341 114.937C93.6341 114.937 95.9603 114.341 97.4602 112.198C98.96 110.055 99.7714 107.358 99.5763 107.172C99.3812 106.986 98.5012 106.506 98.383 106.428C98.2648 106.349 97.6197 105.854 97.6197 105.854Z"
          fill="#C91102"
        />
      </g>
      <path
        d="M43.0299 95.0728L46.217 80.6663L44.5721 79.9747L43.1244 82.936L40.2207 79.251L38.9138 81.0277L42.3208 86.0862L40.136 96.3612L43.0299 95.0728Z"
        fill="#AF5528"
      />
      <path
        d="M108.244 17.2762C108.244 17.2762 109.261 22.9401 116.002 23.5735C122.743 24.2068 126.977 19.9241 126.977 19.9241L125.296 11.4521C125.296 11.4521 120.831 14.8589 117.891 15.2584C114.952 15.6579 107.823 12.2842 107.823 12.2842"
        fill="#EB2701"
      />
      <path
        d="M81.3696 68.6148C90.2723 66.8758 103.993 61.1922 107.442 57.1841C112.025 51.8593 114.988 43.8962 115.202 38.8305C115.408 33.9552 114.809 15.8664 99.5326 5.79061C85.257 -3.62546 57.4526 -2.26792 48.343 26.4541C46.4188 35.1166 51.0217 53.3639 58.6263 61.0425C63.5636 66.0278 72.7265 70.3038 81.3696 68.6148Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M119.766 37.552C119.706 37.4839 119.647 37.4152 119.586 37.3456L119.569 37.249C119.639 37.3507 119.701 37.4514 119.766 37.552Z"
          fill="white"
        />
      </g>
      <path
        d="M96.4697 24.922C99.6552 24.5607 102.621 26.2687 103.772 29.103C105.531 33.4275 106.029 40.542 103.12 44.6043C99.1313 50.1764 90.8614 44.5764 80.2725 48.2376C69.3104 52.0284 69.5933 55.349 62.5512 55.0181C58.2906 54.8178 52.097 39.9112 55.2547 36.8183C59.9902 32.1804 65.088 36.6015 75.8054 33.3831C82.96 31.2333 91.1886 25.5213 96.4697 24.922Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M107.698 12.8476C108.339 7.73747 112.616 6.8161 115.993 5.87924C119.37 4.94238 120.529 0 120.529 0C123.192 1.34206 125.273 9.85585 125.273 9.85585C125.273 9.85585 116.992 11.888 115.122 12.876C113.252 13.864 110.432 17.9056 110.432 17.9056L110.083 17.9541"
        fill="#EB2701"
      />
      <path
        d="M48.4063 35.3497C48.4063 35.3497 84.9384 30.429 111.168 17.7182C111.168 17.7182 113.099 16.8376 111.382 13.4855C109.664 10.1335 105.548 12.0377 105.548 12.0377C105.548 12.0377 82.7614 20.9195 74.2705 23.2475C65.7797 25.5754 48.5394 28.6838 48.0403 28.9496C46.957 29.5278 47.469 33.1782 48.4063 35.3497Z"
        fill="#EB2701"
      />
      <path
        d="M78.3711 37.4574C81.9639 39.4416 86.1239 39.8478 89.8875 38.5832C93.2077 37.2411 94.4542 35.7587 95.1345 32.1073C95.3703 28.2096 94.8145 27.4209 94.8145 27.4209L78.3711 37.4574Z"
        fill="#EB2701"
      />
      <path
        d="M61.8112 44.01C64.061 45.5415 66.9178 45.5864 69.0968 44.1251C71.5294 42.7629 73.4839 40.6642 74.7196 38.0952L58.397 36.9307C58.397 36.9307 60.5451 42.9854 61.8112 44.01Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default DevelopStrategyIcon;
