import React, { useState, useRef, useEffect } from "react";

import styles from "./CampaignConnectingDataSourcesQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import Button from "../Button/Button";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

enum MessageType {
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
}

type Question = {
  type: MessageType;
  content?: React.ReactNode;
  query?: string;
};

interface CampaignConnectingDataSourcesQueryChatProps {
  className?: string;
}

const CampaignConnectingDataSourcesQueryChat: React.FC<
  CampaignConnectingDataSourcesQueryChatProps
> = (props) => {
  const { className } = props;

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleConnectChannels = async () => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <Button
            className={classNames(styles["card"], styles["continue-button"])}
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path);
            }}
          >
            <TextElement>Let's go</TextElement>
          </Button>
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={classNames(
              styles["chat-message"],
              styles["white-background"]
            )}
            message="Ready to publish?"
          />
        ),
      },

      {
        type: MessageType.ANSWER,
        content: (
          <div
            className={classNames(
              styles["card"],
              styles["connect-channels-container"]
            )}
          >
            <Button
              className={styles["continue-button"]}
              onClick={() => {
                showConnectedSuccessMessage(
                  "Your Google account is connected successfully!"
                );
              }}
            >
              <TextElement>Connect Google Account</TextElement>
            </Button>
            <Button
              className={styles["continue-button"]}
              onClick={() => {
                showConnectedSuccessMessage(
                  "Your Facebook account is connected successfully!"
                );
              }}
            >
              <TextElement>Connect Facebook Business</TextElement>
            </Button>
            <Button
              className={styles["continue-button"]}
              onClick={() => {
                showConnectedSuccessMessage(
                  "Your Hubspot account is connected successfully!"
                );
              }}
            >
              <TextElement>Connect Email Platform</TextElement>
            </Button>
          </div>
        ),
      },
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={classNames(styles["chat-message"])}
            message="Woohoo! 🎉 All your marketing content is now approved! Ready to publish? Let’s first check if all the needed marketing tools are integrated."
          />
        ),
      },

      ...pC,
    ]);
  };

  const showConnectedSuccessMessage = (message: string) => {
    setConversation((pC) => [
      {
        type: MessageType.ANSWER,
        content: (
          <ChatCard
            messageClass={classNames(
              styles["chat-message"],
              styles["white-background"]
            )}
            message={message}
          />
        ),
      },
      ...pC,
    ]);
  };

  const [conversation, setConversation] = useState<Question[]>([]);
  useEffect(() => {
    handleConnectChannels();
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["chat"]}>
          {conversation?.map((item: Question, index: number) => (
            <div key={index}>{item.content}</div>
          ))}
        </div>
      </div>
      <div className={styles["input-container"]}>
        <input />
        <SendIcon />
      </div>
    </>
  );
};

export default CampaignConnectingDataSourcesQueryChat;
