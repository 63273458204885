import React from "react";

const NinjaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.38 101.71" {...props}>
      <defs>
        <style>
          {`
              .cls-1 { fill: #eb2701; }
              .cls-2, .cls-5 { fill: #02285e; }
              .cls-2, .cls-6 { stroke: #02285e; }
              .cls-2, .cls-3 { stroke-miterlimit: 10; }
              .cls-2 { stroke-width: 20.76px; }
              .cls-3 { fill: #fcf4f6; stroke: #eb2701; stroke-width: 5.11px; }
              .cls-4 { fill: #fff; }
              .cls-6 { fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3.04px; }
            `}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M88.32,28.17a45.34,45.34,0,0,1-13.46,5.57,28.8,28.8,0,0,1,3.28,6.65c1-.39,2.1-.74,3.19-1a5.91,5.91,0,0,1,1.07-.2C91,36,101,32.76,114.93,37a37.9,37.9,0,0,0,5.45-17.13C107.62,18.45,98.83,21.72,88.32,28.17Z"
          />
          <path
            className="cls-1"
            d="M104.81,0c-15.06,7.08-21,22-30.59,32.89a26.65,26.65,0,0,1,3.92,7.5c1-.39,2.1-.74,3.19-1a5.52,5.52,0,0,1,1.73-.2,41.33,41.33,0,0,0,3.64-5.57c6.07-11.19,18.54-17.4,30.06-18.91A37.76,37.76,0,0,0,104.81,0Z"
          />
          <circle className="cls-2" cx="49.6" cy="52.1" r="39.22" />
          <path
            className="cls-3"
            d="M75,73.53c-6.81-1.61-12.46-6.27-27.28-6.27-10.81,0-18,4.91-24.08,6.27C15.85,75.28,9.2,64,9.2,52.27h0C9.2,40.52,15.66,31,23.62,31H75c8,0,14.42,9.52,14.42,21.27h0C89.4,64,82.73,75.38,75,73.53Z"
          />
          <path
            className="cls-4"
            d="M72.6,93s4.56-3.08,6.53-4.75c.63-.53,2.06-1.93,2.57-2.5,1.74-1.93,2.93-3.81,3.49-3,.29.41-.73,1.46-1.45,2.36C79.8,90,72.6,93,72.6,93Z"
          />
          <path
            className="cls-4"
            d="M13.88,22.85a66.06,66.06,0,0,1,6.51-5.46c2.73-1.68,5.7-3.15,4.9-4-.32-.33-1.44.39-2.37.88C17.77,16.93,13.88,22.85,13.88,22.85Z"
          />
          <path
            className="cls-4"
            d="M95.86,9.41a51.71,51.71,0,0,1,5.28-3.85c2.19-1.15,4.55-2.13,4-2.81-.23-.27-1.12.23-1.87.55C99.15,5.07,95.86,9.41,95.86,9.41Z"
          />
          <path
            className="cls-4"
            d="M110,22.73a33.07,33.07,0,0,1,4.21-.3c1.59.12,3.22.39,3.14-.18,0-.22-.7-.25-1.22-.33A14.19,14.19,0,0,0,110,22.73Z"
          />
          <path
            className="cls-5"
            d="M41.47,49A6.59,6.59,0,0,1,28.3,49a6.38,6.38,0,0,1,.57-2.66h12A6.7,6.7,0,0,1,41.47,49Z"
          />
          <path
            className="cls-5"
            d="M72,49a6.59,6.59,0,1,1-12.61-2.66h12A6.38,6.38,0,0,1,72,49Z"
          />
          <polyline
            className="cls-6"
            points="24.19 46.31 28.87 46.31 40.91 46.31 42.7 46.31"
          />
          <polyline
            className="cls-6"
            points="75.06 46.31 73.27 46.31 71.4 46.31 61.23 46.31 59.36 46.31 56.56 46.31"
          />
        </g>
      </g>
    </svg>
  );
};

export default NinjaIcon;
