import React from "react";

import styles from "./CommentCard.module.scss";
import TextElement from "../TextElement/TextElement";
import { MdReply } from "react-icons/md";
import Button from "../Button/Button";

interface CommentCardProps {
  onReply?: (text: string) => void;
  profileImage?: string;
  username: string;
  time: string;
  comment: string;
}

const CommentCard: React.FC<CommentCardProps> = (props) => {
  const { profileImage, username, time, comment, onReply } = props;

  const [commentText, setCommentText] = React.useState("");

  return (
    <div className={styles["container"]}>
      <div className={styles["meta-info"]}>
        <img
          src={profileImage}
          alt="profile"
          className={styles["profile-image"]}
        />
        <TextElement className={styles["username"]}>{username}</TextElement>
        <TextElement className={styles["time"]}>{time}</TextElement>
      </div>
      <TextElement className={styles["comment"]}>{comment}</TextElement>
      
    </div>
  );
};

export default CommentCard;
