import React, { useState } from "react";
import classNames from "classnames";

import styles from "./TextArea.module.scss";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  value: string;
  textAreaClassName?: string;
  className?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  value,
  textAreaClassName,
  className,
  ...rest
}) => {
  return (
    <div className={classNames(styles["container"], className)}>
      <label>{label}</label>
      <textarea
        value={value}
        className={classNames(styles["textArea"], textAreaClassName)}
        {...rest}
      ></textarea>
    </div>
  );
};

export default TextArea;
