import React, { useEffect } from "react";
import styles from "../ShowCampaignAnalysisData.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchSegments } from "../../../redux/campaignPlanAnalysisQueryChat";
import AudienceSegmentation from "../../AudienceSegmentationCards/AudienceSegmentation";
import AudienceSegmentationModal from "../../AudienceSegmentationModal/AudienceSegmentationModal";
import classNames from "classnames";
import { toast } from "react-toastify";

interface ShowAudienceSegmentsProps {}

const ShowAudienceSegments: React.FC<ShowAudienceSegmentsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { audienceSegments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [audienceSegmentationModalOpen, setAudienceSegmentationModalOpen] =
    React.useState(false);

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchSegments({
          campaignPlanId: selectedCampaign?.id,
        })
      ).catch(()=>{
        toast.error("Error fetching segments")
        console.log("Error fetching segments")
      })
  }, []);

  return (
    <>
      <AudienceSegmentationModal
        isOpen={audienceSegmentationModalOpen}
        closeModal={() => setAudienceSegmentationModalOpen(false)}
      />
      <div
        className={classNames(
          styles["campaign-data"],
          styles["audience-segmentation"]
        )}
      >
        {selectedCampaign &&
          selectedCampaign?.includedData?.audienceSegmentation?.map(
            (id, index) => (
              <AudienceSegmentation
                cardType="ellipses"
                segmentationId={id}
                key={index}
                className={styles["audience-segmentation-card"]}
              />
            )
          )}
      </div>
      <footer className={styles["disclosure-footer"]}>
        <a
          className={styles["underline-text"]}
          onClick={() => setAudienceSegmentationModalOpen(true)}
        >
          See more audience details
        </a>
      </footer>
    </>
  );
};

export default ShowAudienceSegments;
