import { useCallback, useEffect } from "react";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function useCampaignQueryChat() {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const pinWholeCompetiorSection = () => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            competitorIds: competitors.map((c) => c.id),
            competitorStrategies: true,
          },
        })
      );
  };

  useEffect(() => {}, []);

  const pinAllPastInsights = useCallback(() => {
    // selectedCampaign &&
    //   dispatch(
    //     updateCampaign({
    //       ...selectedCampaign,
    //       includedData: {
    //         ...selectedCampaign?.includedData,
    //         pastInsights: {
    //           averageChannelPerformance: true,
    //           averageRoi: true,
    //           feedbackAnalysis: true,
    //           retentionRate: true,
    //           lifeTimeValueAnalysis: true,
    //           segmentationAnalysis: true,
    //         },
    //       },
    //     })
    //   );
  }, [selectedCampaign]);

  // const audienceSegments = [
  //   {
  //     listHeading: "Segment 1 - Tech Enthusiasts",
  //     list: [
  //       {
  //         heading: "Age Range",
  //         text: "25-40",
  //       },
  //       {
  //         heading: "Location",
  //         text: "Suburbs close to CBDs (Central Business Districts) such as Surry Hills (Sydney), Collingwood (Melbourne), and Fortitude Valley (Brisbane)",
  //       },
  //       {
  //         heading: "Income Range",
  //         text: "$60,000 - $100,000",
  //       },
  //       {
  //         heading: "Traits",
  //         text: "Tech-savvy, early adopters",
  //       },
  //       {
  //         heading: "Preferred Blogs/Pages/Influencers",
  //         text: "TechCrunch, Wired, Marques Brownlee (YouTube)",
  //       },
  //       {
  //         heading: "Communication Frequencies",
  //         text: "Active on Twitter, Reddit",
  //       },
  //       {
  //         heading: "Psychography",
  //         text: "Seek innovation, value convenience and efficiency.",
  //       },
  //     ],
  //   },
  //   {
  //     listHeading: "Segment 2 - Innovators and Creators",
  //     list: [
  //       {
  //         heading: "Age Range",
  //         text: "25-45",
  //       },
  //       {
  //         heading: "Location",
  //         text: "Urban hubs, creative neighborhoods like Surry Hills (Sydney), Fitzroy (Melbourne), and West End (Brisbane)",
  //       },
  //       {
  //         heading: "Income Range",
  //         text: "$70,000 - $150,000",
  //       },
  //       {
  //         heading: "Traits",
  //         text: "Creative, entrepreneurial",
  //       },
  //       {
  //         heading: "Preferred Blogs/Pages/Influencers",
  //         text: "Fast Company, Entrepreneur, Gary Vaynerchuk (LinkedIn)",
  //       },
  //       {
  //         heading: "Communication Frequencies",
  //         text: "Actively engage on LinkedIn, attend networking events",
  //       },
  //       {
  //         heading: "Psychography",
  //         text: "Driven by innovation, seek tools for success.",
  //       },
  //     ],
  //   },
  //   {
  //     listHeading: "Segment 3 - Lifestyle Seekers",
  //     list: [
  //       {
  //         heading: "Age Range",
  //         text: "25-45",
  //       },
  //       {
  //         heading: "Location",
  //         text: "Trendy urban areas, coastal suburbs like Bondi (Sydney), St Kilda (Melbourne), and South Bank (Brisbane)",
  //       },
  //       {
  //         heading: "Income Range",
  //         text: "$60,000 - $120,000",
  //       },
  //       {
  //         heading: "Traits",
  //         text: "Social, adventurous",
  //       },
  //       {
  //         heading: "Preferred Blogs/Pages/Influencers",
  //         text: "Travel + Leisure, Refinery29, Chris Burkard (Instagram)",
  //       },
  //       {
  //         heading: "Communication Frequencies",
  //         text: "Active on Instagram, Pinterest",
  //       },
  //       {
  //         heading: "Psychography",
  //         text: "Value experiences, seek lifestyle enhancements.",
  //       },
  //     ],
  //   },

  //   {
  //     listHeading: "Segment 4 - Community Builders",
  //     list: [
  //       {
  //         heading: "Age Range",
  //         text: "30-50",
  //       },
  //       {
  //         heading: "Location",
  //         text: "Suburban areas with strong community engagement like Newtown (Sydney), Brunswick (Melbourne), and Paddington (Brisbane)",
  //       },
  //       {
  //         heading: "Income Range",
  //         text: "$70,000 - $130,000",
  //       },
  //       {
  //         heading: "Traits",
  //         text: "Community-minded, socially engaged",
  //       },
  //       {
  //         heading: "Preferred Blogs/Pages/Influencers",
  //         text: "TED Talks, HuffPost, Brene Brown (Twitter)",
  //       },
  //       {
  //         heading: "Communication Frequencies",
  //         text: "Participate in online forums, community groups",
  //       },
  //       {
  //         heading: "Psychography",
  //         text: "Value connection, seek positive impact.",
  //       },
  //     ],
  //   },
  // ];

  const marketSentiments = [
    {
      id: 1,
      heading: "Emergence of Industry Use Cases",
      description:
        "Industries such as manufacturing, healthcare, and logistics are increasingly leveraging 5G technology to drive innovation and efficiency.",
      imageUrl: "sentimentImages/1.png",
    },
    {
      id: 2,
      heading: "Increased Data Consumption",
      description:
        "The demand for high-speed data continues to surge, driven by the proliferation of data-intensive applications and IoT devices.",
      imageUrl: "sentimentImages/2.png",
    },
    {
      id: 3,
      heading: "Business Demand for 5G",
      description:
        "  Business interest in 5G technology remains high, with a significant portion of enterprises expressing intentions to invest in 5G infrastructure.",
      imageUrl: "sentimentImages/3.png",
    },
    {
      id: 4,
      heading: "Rapid Adoption Rate",
      description:
        "From past campaigns, we've observed a steady increase in the adoption of 5G technology.",
      imageUrl: "sentimentImages/4.png",
    },
  ];

  const competitors = [
    {
      id: 1,
      heading: "Competitor 1",
      companyName: "Telstra",
      companyImageUrl: "competitorImages/image1.png",
      items: [
        {
          heading: "Offerings",
          text: "Extensive 5G coverage with speeds up to 2 Gbps, starting from $75/month. Offers 5G-enabled smartphones, tablets, and IoT devices. Business solutions include 5G network slicing for customized services.",
        },
        {
          heading: "Marketing",
          text: "Multi-channel approach, TV ads, digital campaigns, social media promotions.",
        },
        {
          heading: "How to Beat Them",
          text: "Highlight faster deployment, wider coverage, exceptional service.",
        },
      ],
    },
    {
      id: 2,
      heading: "Competitor 2",
      companyName: "Vodafone",
      companyImageUrl: "competitorImages/image2.png",
      items: [
        {
          heading: "Offerings",
          text: "Major metropolitan 5G coverage with speeds up to 1.5 Gbps, starting from $70/month. Offers 5G-enabled smartphones, wearables, and smart home devices. Focuses on innovative solutions like 5G-powered IoT for businesses.",
        },
        {
          heading: "Marketing",
          text: "Digital campaigns, tech influencer partnerships, focus on innovation.",
        },
        {
          heading: "How to Beat Them",
          text: "Specialized business solutions, superior customer service, network reliability.",
        },
      ],
    },
    {
      id: 3,
      heading: "Competitor 3",
      companyName: "TPG Telecom",
      companyImageUrl: "competitorImages/image3.png",
      items: [
        {
          heading: "Offerings",
          text: "Select urban 5G coverage with speeds up to 1.2 Gbps, starting from $60/month. Offers 5G home broadband, mobile plans, and business solutions. Focuses on reliability and affordability.",
        },
        {
          heading: "Marketing",
          text: "Targeted digital campaigns, tech influencer partnerships.",
        },
        {
          heading: "How to Beat Them",
          text: "Flexible pricing plans, value-added services, reliability focus.",
        },
      ],
    },
  ];

  const competitorStrategies = [
    {
      id: 1,
      icon: "pricing-tag",
      text: "Offer competitive pricing, bundle deals, or unique content partnerships to attract customers.",
    },
    {
      id: 2,
      icon: "focus",
      text: "Focus on exceptional service, personalized experiences, and network reliability to differentiate.",
    },
    {
      id: 3,
      icon: "partnership",
      text: "Showcase innovative solutions, partnerships, or technologies to demonstrate leadership in the 5G space.",
    },
    {
      id: 4,
      icon: "target-board",
      text: "Utilize targeted digital campaigns and partnerships to effectively reach specific customer segments.",
    },
  ];

  // const pastInsights = [
  //   {
  //     id: "averageRoi",
  //     title: "Average ROI",
  //     rate: "350%",
  //     description:
  //       "Across all past campaigns indicating a strong return on investment in marketing efforts.",
  //   },
  //   {
  //     id: "feedbackAnalysis",
  //     title: "Feedback Analysis",
  //     rate: "60%",
  //     description:
  //       "expressed a preference for informative content, such as case studies and real-world examples.",
  //   },
  //   {
  //     id: "retentionRate",
  //     title: "Retention Analysis",
  //     rate: "40%",
  //     description:
  //       "of customers made repeat purchases within 3 months post-campaign, with an average order value of $120.",
  //   },
  //   {
  //     id: "lifeTimeValueAnalysis",
  //     title: "Lifetime Value Analysis",
  //     rate: "$800",
  //     description:
  //       "average estimated lifetime value of customers acquired in the past campaigns, reflecting their potential long-term impact on revenue generation.",
  //   },
  //   {
  //     id: "segmentationAnalysis",
  //     title: "Segmentation Analysis",
  //     rate: "15%",
  //     description:
  //       "Small businesses in the technology sector consistently showed higher-than-average conversion rates, averaging 15% compared to other segments with an average of 10%.",
  //   },
  // ];

  return {
    // audienceSegments,
    marketSentiments,
    competitors,
    competitorStrategies,
    // pastInsights,
    pinAllPastInsights,
  };
}

export default useCampaignQueryChat;
