import React from "react";

const LoadingThreeCircles: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = (props) => {
  // return <img src={"loadingThreeCircles.png"} {...props} />;
  return <img src={"loadingThreeCircles.svg"} {...props} />;
};

export default LoadingThreeCircles;
