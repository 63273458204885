import React, { useState } from "react";
import styles from "../ErikoChatQuery.module.scss";
import Button from "../../Button/Button";
import TextElement from "../../../../TextElement/TextElement";

interface CampaignRangeSelectorProps {
  onGenerate: (range: number) => void;
}

const CampaignRangeSelector: React.FC<CampaignRangeSelectorProps> = React.memo(
  (props) => {
    const { onGenerate } = props;
    const [range, setRange] = useState<number | undefined>(1);

    return (
      <div className={styles["propensity-container"]}>
        <TextElement>Propensity Threshold: {range}% </TextElement>
        <div className={styles["actions"]}>
          <input
            type="range"
            value={range}
            onChange={(e) => setRange(parseInt(e.target.value))}
          />
          <Button
            children={"Generate"}
            onClick={() => {
              if (typeof range === "number") {
                onGenerate(range);
              }
            }}
          />
        </div>
      </div>
    );
  }
);

export default CampaignRangeSelector;
