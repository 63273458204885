import React, { useState, useEffect } from "react";

import styles from "./MarketingPipelineConfiguration.module.scss";

import Tabs from "../../componenets/Tabs/Tabs";
import { TextElement } from "../../componenets";

import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { Disclosure } from "@headlessui/react";
import DisclosureComponent from "../../componenets/Disclosure/Disclosure";
import MarketingSentiments from "./componenets/MarketingSentiments/MarketingSentiments";
import ContentGeneration from "./componenets/ContentGeneration/ContentGeneration";

type Props = {};

const MarketingPipelineConfiguration: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [campaignActiveTab, setCampaingActiveTab] = useState(1);
  const [commentSideBarOpen, setCommentSideBarOpen] = useState(false);

  useEffect(() => {
    setCampaingActiveTab(
      parseInt(new URLSearchParams(window.location.search).get("tab") || "1")
    );
  }, [window.location.search]);

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Marketing Sentiments",
      content: (
        <div>
          <DisclosureComponent
            header={
              <TextElement className={styles["header-text"]}>
                Marketing Sentiments
              </TextElement>
            }
            content={<MarketingSentiments type="Marketing Sentiments" />}
            headerClassName={styles["disclosure-header"]}
            openHeaderClassName={styles["disclosure-header-open"]}
          />
          <DisclosureComponent
            header={
              <TextElement className={styles["header-text"]}>
                Marketing Sentiments Generation
              </TextElement>
            }
            content={
              <MarketingSentiments type="Marketing Sentiments Generation" />
            }
            headerClassName={styles["disclosure-header"]}
            openHeaderClassName={styles["disclosure-header-open"]}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: "Competitor Analysis",
      content: (
        <div>
          <DisclosureComponent
            header={
              <TextElement className={styles["header-text"]}>
                Competitor Analysis
              </TextElement>
            }
            content={<MarketingSentiments type="Marketing Sentiments" />}
            headerClassName={styles["disclosure-header"]}
            openHeaderClassName={styles["disclosure-header-open"]}
          />
          <DisclosureComponent
            header={
              <TextElement className={styles["header-text"]}>
                Competitor Analysis Generation
              </TextElement>
            }
            content={
              <MarketingSentiments type="Marketing Sentiments Generation" />
            }
            headerClassName={styles["disclosure-header"]}
            openHeaderClassName={styles["disclosure-header-open"]}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: "Content Generation",
      content: <ContentGeneration />,
    },
  ]);

  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <TextElement>Marketing Pipeline Configuration</TextElement>
      </div>
      <div className={styles["tabs-container"]}>
        <Tabs
          className={styles["tabs"]}
          tabs={tabs}
          basePath={ROUTE_DEFINITIONS.MARKETING_PIPELINE_CONFIGURATION.path}
          tabContentClassName={styles["tab-content"]}
        />
      </div>
    </div>
  );
};

export default MarketingPipelineConfiguration;
