import React, { useState, useEffect } from "react";
import styles from "./ResetPassword.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { checkPasswordStrength } from "../../lib/helpers";
import AuthLayout from "../../componenets/AuthLayout/AuthLayout";
import { Button, TextElement } from "../../componenets";
import InputField from "../../componenets/Input/input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetPassword, ResetPasswordResponse } from "../../redux/authSlice";

type Props = {};

const ResetPassword: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const dispatch = useAppDispatch();
  const { authError } = useAppSelector((state) => state.auth);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const strengthError = checkPasswordStrength(newPassword);
    setPasswordError(strengthError);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (passwordError) {
      setConfirmPasswordError("");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    setConfirmPasswordError("");
    dispatch(
      resetPassword({
        password,
        token: token as string,
      })
    ).then((response) => {
      if (
        response?.payload &&
        (response.payload as ResetPasswordResponse).success
      ) {
        navigate(ROUTE_DEFINITIONS.LOGIN.path);
      }
    });
  };

  return (
    <AuthLayout>
      <div className={styles["form-container"]}>
        <form className={styles["form"]} onSubmit={handleSubmit}>
          <TextElement className={"text-center"}>
            Add your new password below. Strong passwords include numbers,
            letters and punctuation marks.
          </TextElement>
          <InputField
            label="Enter New Password"
            required
            onChange={handlePasswordChange}
            type="password"
          />
          {passwordError && (
            <TextElement className={styles["error-text"]}>
              {passwordError}
            </TextElement>
          )}
          <InputField
            label="Confirm New Password"
            required
            onChange={handleConfirmPasswordChange}
            type="password"
          />
          {confirmPasswordError && (
            <TextElement className={styles["error-text"]}>
              {confirmPasswordError}
            </TextElement>
          )}
          {authError && (
            <TextElement className={styles["error-text"]}>
              {authError}
            </TextElement>
          )}
          <Button className={styles["submit"]} type="submit">
            Reset Password
          </Button>
          <TextElement
            className={styles["underline-text"]}
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.LOGIN.path);
            }}
          >
            Back to login
          </TextElement>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;














