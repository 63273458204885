import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";
import classNames from "classnames";

import TeamTable, { TeamMemberData } from "../../componenets/Table/TeamTable";
import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import { Button, TextElement } from "../../componenets";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import styles from "./MyTeamMember.module.scss";
import SearchInput from "../../componenets/SearchInput/SearchInput";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchTeamMembers } from "../../redux/teamMemberSlice";

type MyTeamMemberProps = {};

const MyTeamMember: React.FC<MyTeamMemberProps> = () => {
  const navigate = useNavigate();

  const [memberData, setMemberData] = useState<TeamMemberData[]>([]);
  const dispatch = useAppDispatch();

  const { teamMembers } = useAppSelector((state) => state.teamMember);

  useEffect(() => {
    // setMemberData([
    //   {
    //     id: 1,
    //     name: "Touqeerul Bari",
    //     role: "Secondary Approver",
    //     dateAdded: new Date("April 2, 2024"),
    //   },
    //   {
    //     id: 2,
    //     name: "Alvina Khan",
    //     role: "Marketer",
    //     dateAdded: new Date("April 10, 2024"),
    //   },
    //   {
    //     id: 3,
    //     name: "Sabeh Hassan",
    //     role: "Primary Approver",
    //     dateAdded: new Date("January 5, 2024"),
    //   },
    // ]);
    dispatch(fetchTeamMembers());
  }, []);

  const handleBtnAddMember = () => {
    navigate(ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path);
  };

  return (
    <CMOViewLayout
      header={
        <>
          <TextElement className={styles["header-text"]}>My Team</TextElement>
        </>
      }
    >
      <div>
        <div className={classNames(styles["actions-container"])}>
          <SearchInput
            placeholder="Search Team Member"
            className={classNames(styles["search-input"])}
          />
          <Button
            variant="outline"
            icon={<FaPlusSquare />}
            className={classNames(styles["add-member-btn"])}
            onClick={handleBtnAddMember}
          >
            Add New Member
          </Button>
        </div>

        <TeamTable
          memberData={teamMembers.map((member, index) => ({
            id: member.id,
            name: member.firstName,
            role: member.role,
            dateAdded: new Date(member.dateJoined),
          }))}
        />
      </div>
    </CMOViewLayout>
  );
};

export default MyTeamMember;
