import React from "react";

import styles from "./CampaignPlanEllipses.module.scss";
import classNames from "classnames";
import { Menu } from "@headlessui/react";

interface CampaignPlanEllipsesProps {
  className?: string;
  onStartCampaign?: () => void;
  onDeleteCampaign?: () => void;
}

const CampaignPlanEllipses: React.FC<CampaignPlanEllipsesProps> = (props) => {
  const { className, onDeleteCampaign, onStartCampaign } = props;
  return (
    <div className={styles["container"]}>
      <Menu>
        <Menu.Button className={classNames(styles["ellipses"], className)}>
          ...
        </Menu.Button>
        <Menu.Items className={styles["items"]}>
          <Menu.Item>
            {({ active }) => (
              <button className={styles["item"]} onClick={onStartCampaign}>
                Start Campaign
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button className={styles["item"]} onClick={onDeleteCampaign}>
                Delete
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default CampaignPlanEllipses;
