import React, { ReactNode, useState, useEffect, memo } from "react";

import styles from "./DisplayResponse.module.scss";
import classNames from "classnames";
import Ellipses from "../Ellipses/Ellipses";

import { FastNinja, UserIcon, NinjaIcon } from "../../icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type BackgroundColor = "white" | "theme";
type DisplayDirection = "left" | "right";

type DisplayResponseProps = {
  direction?: DisplayDirection;
  className?: string;
  query: string;
  customInsights?: string | null | ReactNode;
  modelResponseId: number;
  showEllipses?: boolean;
  iconClassName?: string;
};

const DisplayResponse: React.FC<DisplayResponseProps> = ({
  className,
  customInsights,
  direction,
  showEllipses = true,
  iconClassName,
}) => {
  const initialStyle = {
    shape: {
      backgroundColor: "#FFFFFF",
      shadowColor: "#f2f2f2",
    },
    ninjaType: "ninja",
    ninjaDirection: "left",
    text: {
      color: "#000000",
      fontSize: "24px",
      fontWeight: "400",
      fontFamily: "Inter,sans-serif",
      textAlign: "center",
    },
  };

  const [insights, setInsights] = useState<
    string | ReactNode | null | undefined
  >(null);
  const [insightsCssModalUpdateVisible, setInsightsCssModalUpdateVisible] =
    useState(false);

  const [customStyles, setCustomStyles] = useState(initialStyle);

  useEffect(() => {
    const handleInsights = async () => {
      setInsights(insights);
    };

    if (
      customInsights &&
      typeof customInsights === "string" &&
      customInsights.length > 0
    ) {
      setInsights(customInsights);
    } else if (customInsights) {
      setInsights(customInsights);
    } else {
      handleInsights();
    }
  }, []);

  const bgColor = styles["white-background"];

  const getDirection = (): "left" | "right" => {
    return (
      direction || (customStyles?.ninjaDirection as DisplayDirection) || "right"
    );
    // console.log(direction, "====", direction || customStyles?.ninjaDirection);
    // if (direction) return direction;
    // if (customStyles?.ninjaDirection === "left") {
    //   return "right";
    // } else if (customStyles?.ninjaDirection === "right") {
    //   return "left";
    // } else {
    //   return "right";
    // }
  };

  const directionClass =
    getDirection() === "left"
      ? styles["left-direction"]
      : styles["right-direction"];

  const getIcon = (ninjaType: "ninja" | "User" | "fast-ninja") => {
    if (ninjaType === "ninja") {
      return (
        <NinjaIcon
          className={classNames(styles["ninja-icon"], iconClassName)}
        />
      );
    } else if (ninjaType === "User") {
      return (
        <UserIcon className={classNames(styles["user-icon"], iconClassName)} />
      );
    } else {
      return (
        <FastNinja
          className={classNames(styles["fast-ninja-icon"], iconClassName)}
        />
      );
    }
  };

  const containerWrapper = (children: ReactNode) => {
    return (
      <div
        className={classNames(styles["response-wrapper"], className, {
          [styles["icon-left"]]:
            (direction || customStyles?.ninjaDirection) === "right",
          [styles["icon-right"]]:
            (direction || customStyles?.ninjaDirection) === "left",
          [styles["icon-none"]]:
            (direction || customStyles?.ninjaDirection) === "none",
        })}
      >
        {children}
        {getIcon(customStyles?.ninjaType as any)}
      </div>
    );
  };

  return containerWrapper(
    <div className={styles["container"]}>
      <div
        className={classNames(
          styles["response-content"],
          directionClass,
          className
        )}
      >
        <div
          className={classNames(
            styles["content-container"],
            bgColor,
            directionClass
          )}
          style={{
            backgroundColor: customStyles?.shape?.backgroundColor,
          }}
        >
          <span
            className={styles["insight-response"]}
            // style={{
            //   color: customStyles?.text?.color,
            //   fontSize: customStyles?.text?.fontSize,
            //   fontWeight: customStyles?.text?.fontWeight,
            //   fontFamily: customStyles?.text?.fontFamily,
            // }}
          >
            {insights
              ? insights
              : "Sorry, I could not find any insights for your query. Please try again."}
          </span>
          {showEllipses && (
            <Ellipses
              className={styles["ellipses"]}
              onUnpin={() => {}}
              onEdit={() => setInsightsCssModalUpdateVisible(true)}
            />
          )}
        </div>
        <svg
          width="88"
          height="71"
          viewBox="0 0 88 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(
            styles["svg"],
            styles["theme-svg"],
            directionClass === "left" ? styles["left-svg"] : styles["right-svg"]
          )}
          style={
            getDirection() === "left"
              ? { transform: "scale(-1, 1)" }
              : { transform: "scale(1, 1)" }
          }
        >
          <path
            d="M87.9031 37.1328L78.15 39.2448C46.1107 46.1827 13.5697 29.8415 0 0V68.6328C25.8927 74.297 52.9401 67.4138 72.9753 50.0615L87.9031 37.1328Z"
            fill={customStyles?.shape?.backgroundColor || "#ffffff"}
          />
        </svg>
      </div>
      <div
        className={classNames(
          styles["response-content-shadow"],
          directionClass,
          className
        )}
      >
        <div
          className={classNames(
            styles["content-container"],
            bgColor,
            directionClass
          )}
          style={
            customStyles?.shape?.shadowColor
              ? {
                  backgroundColor: customStyles?.shape?.shadowColor,
                }
              : {}
          }
        ></div>
        <svg
          width="88"
          height="71"
          viewBox="0 0 88 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(
            styles["svg"],
            styles["theme-svg"],
            directionClass === "left" ? styles["left-svg"] : styles["right-svg"]
          )}
          style={
            getDirection() === "left"
              ? { transform: "scale(-1, 1)" }
              : { transform: "scale(1, 1)" }
          }
        >
          <path
            d="M87.9031 37.1328L78.15 39.2448C46.1107 46.1827 13.5697 29.8415 0 0V68.6328C25.8927 74.297 52.9401 67.4138 72.9753 50.0615L87.9031 37.1328Z"
            fill={customStyles?.shape?.shadowColor || "#f2f2f2"}
          />
        </svg>
      </div>
    </div>
  );
};

export default DisplayResponse;
// export default memo(DisplayResponse, (prevProps, nextProps) => {
//   return (
//     prevProps.query === nextProps.query &&
//     prevProps.modelResponseId === nextProps.modelResponseId &&
//     prevProps.customInsights === nextProps.customInsights &&
//     prevProps.direction === nextProps.direction
//   );
// });
