import React from "react";

import styles from "./MarketValueSteps.module.scss";
import DisplayResponse from "../../componenets/DisplayResponse/DisplayResponse";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import SuggestedPrompt from "./components/SuggestedPrompt/SuggestedPrompt";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { useAppDispatch } from "../../redux/hooks"; 
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import { TextElement } from "../../componenets";

const RealizeValueStep: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCampaignNavigation = () => {
    // dispatch(clearConversation());
    // dispatch(setSelectedCampaign(-99));
    // navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
  };

  return (
    <MarkerterViewLayout className={styles["container-wrapper"]}>
      <div className={styles["container"]}>
        <DisplayResponse
          customInsights={
            <TextElement className={styles["custom-insights"]}>
              Woohoo! We're in the home stretch! There are{" "}
              <span>34 Sales Qualified Opportunities</span> ready to close. We
              had <span>20 losses</span>, but they can still be nurtured into
              future wins. We've <span>won 5 deals</span> so far—great job!
              What's our next move?
            </TextElement>
          }
          query=""
          modelResponseId={-1}
          direction="left"
          showEllipses={false}
          iconClassName={styles["ninja-icon"]}
        />

        <div className={styles["suggested-actions"]}>
          <div className={styles["actions"]}>
            <SuggestedPrompt
              title="Craft A Closing Strategy"
              description={
                "We’re almost there! Please help me create a plan to close these deals and handle objections."
              }
              onClick={handleCampaignNavigation}
            />
            <SuggestedPrompt
              title="Get Insights About My Won and Loss Deals"
              description="That's it! I want to review my won deals to gain a better understanding of their customer journey and touchpoints."
              onClick={handleCampaignNavigation}
            />
          </div>
        </div>
      </div>
    </MarkerterViewLayout>
  );
};

export default RealizeValueStep;
