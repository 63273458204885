import React from "react";

import Card, { CardType } from "../Card/Card";

import styles from "./ListingCard.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";

interface ListItem {
  heading: string;
  text: string | React.ReactNode;
}

interface Props {
  list: ListItem[];
  listHeading: string;
  description?: string;
  className?: string;
  onPin?: () => void;
  unPin?: () => void;
  cardType: CardType;
}

const ListingCard: React.FC<Props> = ({
  list,
  listHeading,
  className,
  onPin,
  unPin,
  cardType,
  description,
}) => {
  return (
    <Card
      onPin={onPin}
      unPin={unPin}
      className={classNames(styles["listing-card"], className)}
      type={cardType}
    >
      <TextElement className={styles["list-heading"]}>
        {listHeading}
      </TextElement>
      <TextElement>{description}</TextElement>
      <ul>
        {list?.map((item, index) => (
          <li key={index}>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>{item.heading}: </span>
              {item.text}
            </TextElement>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default ListingCard;
