import React from "react";

const BinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.42857 22.2222C1.42857 23.75 2.71429 25 4.28571 25H15.7143C17.2857 25 18.5714 23.75 18.5714 22.2222V5.55556H1.42857V22.2222ZM20 1.38889H15L13.5714 0H6.42857L5 1.38889H0V4.16667H20V1.38889Z"
        fill="#02285E"
      />
    </svg>
  );
};

export default BinIcon;
