import React, { useState, useEffect, useRef } from "react";
import styles from "./SideBar.module.scss";
import classNames from "classnames";
import ErikoText from "../../icons/ErikoText";
import { NinjaIcon, PlusIcon } from "../../icons";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";
import CampaignsIcon from "../../icons/CampaignsIcon";
import NotificationIcon from "../../icons/NotificationIcon";
import BrandingDropdown from "./BrandingDropdown/BrandingDropdown";
import BrandingList from "../BrandingList/BrandingList";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { SidebarItem } from "../../enums";
import MyTeamIcon from "../../icons/MyTeamIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout, UserRoleType } from "../../redux/authSlice";
import { MdDashboard, MdOutlineBrokenImage } from "react-icons/md";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import InsightsIcon from "../../icons/InsightsIcon";
import StrategyIcon from "../../icons/StrategyIcon";
import { CiSettings } from "react-icons/ci";
import Notifications from "../../pages/Notifications/Notifications";
import NotificationsSideIcon from "./NotificationsSideIcon";
import ConnectingChannelIcon from "../../icons/ConnectingChannelIcon";

export type ActiveMarketerSideTabType =
  | "campaigns"
  | "notifications"
  | "brand-identity"
  | "style-guide"
  | "assets";

interface SideBarProps {
  className?: string;
  activeSideTab?: ActiveMarketerSideTabType;
}

const SideBar: React.FC<SideBarProps> = (props) => {
  const { className, activeSideTab = "campaigns" } = props;

  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<SidebarItem[]>([]);

  const [activeItem, setActiveItem] = useState<SidebarItem | null>(null);

  const dispatch = useAppDispatch();

  const { loggedInUser } = useAppSelector((state) => state.auth);
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const [homeLink, setHomeLink] = useState<string | null>();

  useEffect(() => {
    if (loggedInUser?.role == UserRoleType.MARKETING) {
      setHomeLink(ROUTE_DEFINITIONS.HOME.path);
    } else if (
      loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
      loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
    ) {
      setHomeLink(ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path);
    } else if (loggedInUser?.role == UserRoleType.CMO) {
      setHomeLink(ROUTE_DEFINITIONS.CMO_HOME.path);
    }
  }, [loggedInUser]);

  useEffect(() => {
    // You can also use location.pathname + location.search + location.hash if needed

    // setActiveItem(SidebarItem.NOTIFICATIONS);

    const interestStageItems = [
      SidebarItem.INTEREST_STAGE_REPORT,
      SidebarItem.INTEREST_NURTURE_STRATEGY,
      SidebarItem.INTEREST_STAGE_SETTINGS,
      SidebarItem.INTEREST_NURTURE_CONTENT,
    ];

    const shortlistStageItems = [
      SidebarItem.SHORTLIST_STAGE_REPORT,
      SidebarItem.SHORTLIST_NURTURE_STRATEGY,
      SidebarItem.SHORTLIST_STAGE_SETTINGS,
      SidebarItem.SHORTLIST_NURTURE_CONTENT,
    ];

    const campaignDataSourceConnectingItems = [
      SidebarItem.CAMPAIGN_ANALYSIS_BUILDER,
      SidebarItem.CAMPAIGN_CONTENT_BUILDER,
      SidebarItem.CONNECTING_DATA_SOURCES,
    ];

    const approverItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.CAMPAIGN_PLANS_LIST,
      SidebarItem.NOTIFICATIONS,
      SidebarItem.BRAND_IDENTITY,
      SidebarItem.BRAND_STYLE_GUIDE,
      SidebarItem.BRAND_ASSETS,
    ];

    const marketerItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.ADD_CAMPAIGN,
      SidebarItem.CAMPAIGN_PLANS_LIST,
      SidebarItem.NOTIFICATIONS,
      SidebarItem.BRAND_IDENTITY,
      SidebarItem.BRAND_STYLE_GUIDE,
      SidebarItem.BRAND_ASSETS,
      SidebarItem.MARKETING_CHANNEL_CONNECTIONS,
    ];

    const cmoItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.ADD_TEAM_MEMBER,
      SidebarItem.CAMPAIGN_PLANS_LIST,
      SidebarItem.NOTIFICATIONS,
      SidebarItem.MY_TEAM,
      SidebarItem.BRAND_IDENTITY,
      SidebarItem.BRAND_STYLE_GUIDE,
      SidebarItem.BRAND_ASSETS,
    ];

    // We can include roles here as well
    switch (location.pathname) {
      case ROUTE_DEFINITIONS.LOGIN.path:
        setItems([]);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path:
        setItems(interestStageItems);
        setActiveItem(SidebarItem.INTEREST_STAGE_REPORT);
        break;

      case ROUTE_DEFINITIONS.MARKETING_NURTURE_STRATEGY_BUILDER.path:
        setItems(interestStageItems);
        setActiveItem(SidebarItem.INTEREST_NURTURE_STRATEGY);
        break;

      case ROUTE_DEFINITIONS.MARKETING_SHORTLIST_STAGE.path:
        setItems(shortlistStageItems);
        setActiveItem(SidebarItem.SHORTLIST_STAGE_REPORT);
        break;

      case ROUTE_DEFINITIONS.HOME.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path:
        setItems(campaignDataSourceConnectingItems);
        setActiveItem(SidebarItem.CAMPAIGN_ANALYSIS_BUILDER);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_CONTENT_BUILDER.path:
        setItems(campaignDataSourceConnectingItems);
        setActiveItem(SidebarItem.CAMPAIGN_CONTENT_BUILDER);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CONNECTING_DATA_SOURCES.path:
        setItems(campaignDataSourceConnectingItems);
        setActiveItem(SidebarItem.CONNECTING_DATA_SOURCES);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGINS_LIST.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.NOTIFICATIONS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MY_BRANDING.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_DATA.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_CREATE_VALUE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_IDENTIFY_VALUE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_REALIZE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.CMO_HOME.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.CAMPAIGN_PLANS_LIST);
        break;

      case ROUTE_DEFINITIONS.MY_TEAM_MEMBER.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.MY_TEAM);
        break;

      case ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.ADD_TEAM_MEMBER);
        break;

      case ROUTE_DEFINITIONS.CMO_NOTIFICATION.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        break;

      case ROUTE_DEFINITIONS.CMO_BRANDING.path:
        setItems(cmoItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path:
        setItems(approverItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_BRANDING.path:
        setItems(approverItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAGIN_DATA.path:
        setItems(approverItems);
        setActiveItem(null);
        break;
      case ROUTE_DEFINITIONS.APPROVER_NOTIFICATION.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        break;

      case ROUTE_DEFINITIONS.MARKETER_ACCOUNT.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.APPROVER_ACCOUNT.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.CMO_ACCOUNT.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.CAMPAIGN_PLANS_LIST);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAIGN_PLAN_LIST.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.CAMPAIGN_PLANS_LIST);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.MARKETING_CHANNEL_CONNECTIONS);
        break;

      default:
        throw `The current path '${location.pathname}' for the sidebar is not handled!`;
    }
  }, [location.pathname, selectedCampaign]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
      setIsExpanded(false);
    }
  };

  const needsDivider =
    [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.ADD_CAMPAIGN,
      SidebarItem.ADD_TEAM_MEMBER,
      SidebarItem.CAMPAIGN_PLANS_LIST,
      SidebarItem.MY_TEAM,
      SidebarItem.NOTIFICATIONS,
    ].some((item) => items.includes(item)) &&
    [
      SidebarItem.BRAND_IDENTITY,
      SidebarItem.BRAND_STYLE_GUIDE,
      SidebarItem.BRAND_ASSETS,
    ].some((item) => items.includes(item));

  if (!items.length) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        styles["container"],
        styles[isExpanded ? "expand" : "collapse"],
        className
      )}
      ref={sidebarRef}
    >
      {isExpanded && (
        <div className={styles["expand-sidebar"]}>
          {items.includes(SidebarItem.LOGO) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["logo-container"]
              )}
              onClick={() => {
                if (homeLink) navigate(homeLink);
              }}
            >
              <NinjaIcon className={styles["logo"]} />
              <ErikoText className={styles["text-icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.AVATAR) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["user-info"]
              )}
              onClick={() => {
                if (loggedInUser?.role == UserRoleType.MARKETING) {
                  navigate(ROUTE_DEFINITIONS.MARKETER_ACCOUNT.path);
                } else if (
                  loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                  loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                ) {
                  navigate(ROUTE_DEFINITIONS.APPROVER_ACCOUNT.path);
                } else if (loggedInUser?.role == UserRoleType.CMO) {
                  navigate(ROUTE_DEFINITIONS.CMO_ACCOUNT.path);
                }
              }}
            >
              <img
                className={styles["user-img"]}
                src="/avatar.png"
                alt="profile"
              />
              <div className={styles["meta-data"]}>
                <TextElement className={styles["user-name"]}>
                  Sabeh Hassan
                </TextElement>
                <TextElement className={styles["user-email"]}>
                  {loggedInUser?.email}
                </TextElement>
              </div>
            </div>
          )}

          {items.includes(SidebarItem.ADD_CAMPAIGN) && (
            <div className={styles["sidebar-item"]}>
              <Button
                className={styles["campaign-btn"]}
                onClick={() => {
                  dispatch(setSelectedCampaign(""));
                  navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
                }}
              >
                <PlusIcon className={styles["plus-icon"]} />
                <TextElement>Create New Campaign</TextElement>
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.ADD_TEAM_MEMBER) && (
            <div
              className={styles["sidebar-item"]}
              onClick={() => navigate(ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path)}
            >
              <Button className={styles["team-member-btn"]}>
                <PlusIcon className={styles["icon"]} />
                <TextElement>Add Team Member</TextElement>
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.CAMPAIGN_PLANS_LIST) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["sidebar-item-hover"],
                activeItem === SidebarItem.CAMPAIGN_PLANS_LIST &&
                  styles["sidebar-item-active"]
              )}
              onClick={() => {
                if (loggedInUser?.role == UserRoleType.MARKETING) {
                  navigate(ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path);
                } else if (
                  loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                  loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                ) {
                  navigate(ROUTE_DEFINITIONS.APPROVER_CAMPAIGN_PLAN_LIST.path);
                } else if (loggedInUser?.role == UserRoleType.CMO) {
                  navigate(ROUTE_DEFINITIONS.CMO_HOME.path);
                }
              }}
            >
              <CampaignsIcon className={styles["icon"]} />
              <TextElement>My Campaigns Plans</TextElement>
            </div>
          )}

          {items.includes(SidebarItem.NOTIFICATIONS) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["sidebar-item-hover"],
                activeItem === SidebarItem.NOTIFICATIONS &&
                  styles["sidebar-item-active"]
              )}
              onClick={() => {
                if (loggedInUser?.role == UserRoleType.MARKETING) {
                  navigate(ROUTE_DEFINITIONS.NOTIFICATIONS.path);
                } else if (loggedInUser?.role == UserRoleType.CMO) {
                  navigate(ROUTE_DEFINITIONS.CMO_NOTIFICATION.path);
                } else if (
                  loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                  loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                ) {
                  navigate(ROUTE_DEFINITIONS.APPROVER_NOTIFICATION.path);
                }
              }}
            >
              <NotificationsSideIcon />
            </div>
          )}

          {items.includes(SidebarItem.MY_TEAM) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["sidebar-item-hover"],
                activeItem === SidebarItem.MY_TEAM &&
                  styles["sidebar-item-active"]
              )}
              onClick={() => navigate(ROUTE_DEFINITIONS.MY_TEAM_MEMBER.path)}
            >
              <MyTeamIcon className={styles["icon"]} />
              <TextElement>My Team</TextElement>
            </div>
          )}

          {items.includes(SidebarItem.MARKETING_CHANNEL_CONNECTIONS) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["sidebar-item-hover"],
                activeItem === SidebarItem.MARKETING_CHANNEL_CONNECTIONS &&
                  styles["sidebar-item-active"]
              )}
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path);
              }}
            >
              <ConnectingChannelIcon className={styles["icon"]} />
              <TextElement>Marketing Channels</TextElement>
            </div>
          )}

          {needsDivider && <div className={styles["divider"]}></div>}

          {items.includes(SidebarItem.BRAND_IDENTITY) && <BrandingDropdown />}
          <button
            className={classNames(styles["sidebar-item"], styles["logout-btn"])}
            onClick={() => {
              dispatch(logout());
              navigate(ROUTE_DEFINITIONS.LOGIN.path);
            }}
          >
            <LogoutIcon className={styles["icon"]} />
            <TextElement>Logout</TextElement>
          </button>
        </div>
      )}

      {!isExpanded && (
        <div className={styles["collapse-sidebar"]}>
          {items.includes(SidebarItem.INTEREST_STAGE_REPORT) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.INTEREST_STAGE_REPORT &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                navigate(ROUTE_DEFINITIONS.MARKETING_INTEREST_STAGE.path);
              }}
            >
              <InsightsIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.INTEREST_NURTURE_STRATEGY) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.INTEREST_NURTURE_STRATEGY &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  ROUTE_DEFINITIONS.MARKETING_NURTURE_STRATEGY_BUILDER.path
                );
              }}
            >
              <StrategyIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.INTEREST_NURTURE_CONTENT) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.INTEREST_NURTURE_CONTENT &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MdOutlineBrokenImage className={styles["outsource-svg"]} />
            </div>
          )}

          {items.includes(SidebarItem.INTEREST_NURTURE_CONTENT) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.INTEREST_NURTURE_CONTENT &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CiSettings className={styles["outsource-svg"]} />
            </div>
          )}

          {items.includes(SidebarItem.SHORTLIST_STAGE_REPORT) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.SHORTLIST_STAGE_REPORT &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                navigate(ROUTE_DEFINITIONS.MARKETING_SHORTLIST_STAGE.path);
              }}
            >
              <InsightsIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.SHORTLIST_NURTURE_STRATEGY) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.SHORTLIST_NURTURE_STRATEGY &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <StrategyIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.SHORTLIST_NURTURE_CONTENT) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.SHORTLIST_NURTURE_CONTENT &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MdOutlineBrokenImage className={styles["outsource-svg"]} />
            </div>
          )}

          {items.includes(SidebarItem.SHORTLIST_STAGE_SETTINGS) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.SHORTLIST_STAGE_SETTINGS &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CiSettings className={styles["outsource-svg"]} />
            </div>
          )}

          {items.includes(SidebarItem.CAMPAIGN_ANALYSIS_BUILDER) && (
            <div
              className={classNames(
                styles["campaign-builder-item"],
                activeItem === SidebarItem.CAMPAIGN_ANALYSIS_BUILDER &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
              }}
            >
              <CampaignsIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.CAMPAIGN_CONTENT_BUILDER) &&
            selectedCampaign?.activeContentStep && (
              <div
                className={classNames(
                  styles["campaign-builder-item"],
                  activeItem === SidebarItem.CAMPAIGN_CONTENT_BUILDER &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_CONTENT_BUILDER.path
                  );
                }}
              >
                <MdDashboard className={styles["outsource-svg"]} />
              </div>
            )}

          {items.includes(SidebarItem.CONNECTING_DATA_SOURCES) &&
            selectedCampaign?.activeConnectingDataSourcesStep && (
              <div
                className={classNames(
                  styles["campaign-builder-item"],
                  activeItem === SidebarItem.CONNECTING_DATA_SOURCES &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    ROUTE_DEFINITIONS.MARKETING_CONNECTING_DATA_SOURCES.path
                  );
                }}
              >
                <MdOutlineBrokenImage className={styles["outsource-svg"]} />
              </div>
            )}

          {items.includes(SidebarItem.LOGO) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["logo-container"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <NinjaIcon className={styles["logo"]} />
            </div>
          )}

          {items.includes(SidebarItem.AVATAR) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["user-info"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <img
                className={styles["user-img"]}
                src="/avatar.png"
                alt="profile"
              />
            </div>
          )}

          {items.includes(SidebarItem.ADD_CAMPAIGN) && (
            <div
              className={styles["sidebar-item"]}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <Button className={styles["campaign-btn"]}>
                <PlusIcon className={styles["plus-icon"]} />
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.ADD_TEAM_MEMBER) && (
            <div
              className={styles["sidebar-item"]}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);

                navigate(ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path);
              }}
            >
              <Button className={styles["team-member-btn"]}>
                <PlusIcon />
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.CAMPAIGN_PLANS_LIST) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                activeItem === SidebarItem.CAMPAIGN_PLANS_LIST &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <CampaignsIcon className={styles["icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.NOTIFICATIONS) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                activeItem === SidebarItem.NOTIFICATIONS &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <NotificationsSideIcon renderType="icon" />
            </div>
          )}

          {items.includes(SidebarItem.MY_TEAM) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                activeItem === SidebarItem.MY_TEAM &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <MyTeamIcon
                className={styles["icon"]}
                style={
                  activeItem === SidebarItem.MY_TEAM
                    ? { fill: "#EB2701" }
                    : { fill: "#000000" }
                }
              />
            </div>
          )}

          {items.includes(SidebarItem.MARKETING_CHANNEL_CONNECTIONS) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["sidebar-item-hover"],
                activeItem === SidebarItem.MARKETING_CHANNEL_CONNECTIONS &&
                  styles["sidebar-item-active"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <ConnectingChannelIcon className={styles["icon"]} />
            </div>
          )}

          {needsDivider && <div className={styles["divider"]}></div>}

          {items.includes(SidebarItem.BRAND_IDENTITY) && (
            <BrandingList
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            />
          )}

          <button
            className={classNames(styles["sidebar-item"], styles["logout-btn"])}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(true);
            }}
          >
            <LogoutIcon className={styles["icon"]} />
          </button>
        </div>
      )}
    </div>
  );
};

export default SideBar;
