import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import styles from "./CampaignReport.module.scss";
import classNames from "classnames";
import { R } from "@storybook/react/dist/types-0a347bb9";

interface DrapAndDropGridProps {
  items: {
    id: string;
    content: React.ReactNode;
    wrapperClass?: string;
  }[];
}

function DrapAndDropGrid(props: DrapAndDropGridProps) {
  const [items, setItems] = useState<
    {
      id: string;
      content: React.ReactNode;
      wrapperClass?: string;
    }[]
  >(props.items);

  const onDragEnd = (result: any) => {
    if (!result?.destination) return null;
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result?.destination.index, 0, removed);
    setItems(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={styles["grid"]}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    //@ts-ignore
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={index}
                    className={classNames(
                      styles["grid-item"],
                      item?.wrapperClass
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
export default DrapAndDropGrid;
