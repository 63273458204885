import React from "react";

import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";

import styles from "./ProjectedROICard.module.scss";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface Props {
  className?: string;
  cardType: CardType;
}

const ProjectedROICard: React.FC<Props> = (props) => {
  const { className, cardType } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { recommendation } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              projectedRoi: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      type={cardType}
    >
      <TextElement className={styles["heading"]}>Projected ROI</TextElement>
      <ul>
        <li>
          <span>Anticipated ROI of {recommendation?.anticipatedROI}%</span>{" "}
          based on historical data and industry benchmarks.
        </li>
        <li>
          Total marketing budget: $
          {recommendation?.totalMarketingBudget.toLocaleString()}.
        </li>
        <li>
          Expected return:{" "}
          <span>${recommendation?.expectedReturn} in revenue</span> from new
          acquisitions and upselling existing customers.
        </li>
      </ul>
    </Card>
  );
};

export default ProjectedROICard;
