import React from "react";
import Button from "../../Button/Button";
import TextElement from "../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useCampaignQueryChat from "../useCampaignQueryChat";
import { updateCampaign } from "../../../redux/campaignPinningSlice";

interface PinAllAndContinueCompetitorButtonProps {
  className?: string;
}

const PinAllAndContinueCompetitorButton: React.FC<
  PinAllAndContinueCompetitorButtonProps
> = ({ className }) => {
  const { competitors } = useCampaignQueryChat();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();

  const pinWholeCompetiorSection = () => {
    selectedCampaign &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign?.includedData,
            competitorIds: competitors.map((c) => c.id),
            competitorStrategies: true,
          },
        })
      );
  };

  return (
    <Button
      className={className}
      onClick={() => {
        pinWholeCompetiorSection();
      }}
    >
      <TextElement>Pin All Competitor Analysis 👍🏻</TextElement>
    </Button>
  );
};

export default PinAllAndContinueCompetitorButton;
