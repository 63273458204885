import React from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";

import styles from "./NinjaResponseModal.module.scss";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  heading: string;
  message: string;
}

const NinjaResponseModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen, heading, message } = props;

  return (
    <Modal
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      <svg
        width="112"
        height="92"
        viewBox="0 0 112 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.0444 91.7685C77.3856 91.7685 97.9287 71.2254 97.9287 45.8843C97.9287 20.5431 77.3856 0 52.0444 0C26.7032 0 6.16016 20.5431 6.16016 45.8843C6.16016 71.2254 26.7032 91.7685 52.0444 91.7685Z"
          fill="#FFE5E0"
        />
        <path
          d="M80.5063 81.7322L61.8658 73.4127C47.7041 72.3551 30.6068 68.896 30.6068 68.896L22.4336 81.0862C23.0893 81.6414 31.3956 89.188 42.2929 91.4856C71.688 94.7085 80.5063 81.7322 80.5063 81.7322Z"
          fill="#02285E"
        />
        <path
          d="M69.4934 81.0918C69.4934 81.0918 64.5793 77.5719 58.9583 74.6371C53.3373 71.7024 47.8618 69.1316 47.8618 69.1316L47.5859 68.4266C47.5859 68.4266 55.4921 67.4201 61.4788 67.7726C67.4655 68.1252 72.1118 69.8305 72.1118 69.8305L72.89 70.5567C72.89 70.5567 71.1688 76.3257 70.5783 80.7331C70.5783 80.7358 70.514 81.647 69.4934 81.0918Z"
          fill="#F2C5A7"
        />
        <path
          d="M70.4141 82.3511C70.3813 82.351 70.3486 82.3484 70.3162 82.3431C70.2348 82.3306 70.1566 82.302 70.0862 82.2592C70.0157 82.2164 69.9544 82.1601 69.9058 82.0935C69.8572 82.0269 69.8222 81.9514 69.8028 81.8713C69.7834 81.7912 69.78 81.7081 69.7927 81.6266C69.8024 81.5641 70.7895 75.2892 72.3565 70.1547C72.3805 70.0759 72.4199 70.0026 72.4723 69.939C72.5247 69.8754 72.5891 69.8227 72.6619 69.784C72.7346 69.7453 72.8143 69.7213 72.8963 69.7134C72.9783 69.7054 73.0611 69.7137 73.1399 69.7378C73.2188 69.7619 73.2921 69.8013 73.3557 69.8537C73.4193 69.9061 73.4719 69.9705 73.5106 70.0432C73.5493 70.116 73.5733 70.1956 73.5813 70.2777C73.5892 70.3597 73.5809 70.4425 73.5568 70.5213C72.0163 75.5685 71.0424 81.7579 71.0327 81.8196C71.0099 81.9674 70.9349 82.1021 70.8215 82.1996C70.7081 82.297 70.5636 82.3507 70.4141 82.3511Z"
          fill="#0F0F14"
        />
        <path
          d="M74.2137 85.1014H74.1943C74.028 85.0964 73.8706 85.0255 73.7565 84.9043C73.6425 84.7832 73.5812 84.6217 73.5862 84.4554C73.5915 84.2536 73.7493 79.4391 75.229 73.5563C75.2709 73.3964 75.3742 73.2595 75.5165 73.1753C75.6588 73.0911 75.8285 73.0665 75.9889 73.1068C76.1492 73.1471 76.2872 73.249 76.3728 73.3905C76.4584 73.5319 76.4847 73.7014 76.4461 73.8622C75.0016 79.6056 74.8421 84.4449 74.8412 84.4925C74.8362 84.6556 74.7679 84.8103 74.6508 84.924C74.5336 85.0377 74.3769 85.1013 74.2137 85.1014Z"
          fill="#0F0F14"
        />
        <path
          d="M20.8773 38.5843C20.8773 38.5843 22.6329 44.3128 16.6885 48.1157C10.7441 51.9185 4.78115 49.9602 4.78115 49.9602L2.30469 41.3102C2.30469 41.3102 8.0649 42.3413 10.9785 41.3102C13.8921 40.2791 18.89 33.7539 18.89 33.7539"
          fill="#EB2701"
        />
        <path
          d="M70.2224 73.354C61.145 75.9829 45.7222 77.2493 40.6212 75.1783C33.8395 72.4268 27.3029 66.4595 24.6925 61.8679C22.1799 57.449 14.123 40.4037 23.4798 23.7955C32.225 8.27222 58.6359 -3.71 80.7523 18.565C86.657 25.6745 91.0829 44.7732 87.6917 55.5093C85.4911 62.4786 79.0355 70.8026 70.2224 73.354Z"
          fill="#02285E"
        />
        <g opacity="0.5">
          <path
            d="M19.8574 62.8585C19.8795 62.7704 19.9015 62.6752 19.9262 62.5827C19.9262 62.5827 19.9147 62.5474 19.8962 62.4849C19.8812 62.61 19.8689 62.7343 19.8574 62.8585Z"
            fill="white"
          />
        </g>
        <path
          d="M35.4269 40.0615C32.3054 41.2451 30.3709 44.2416 30.652 47.4143C31.0821 52.2614 34.008 59.0872 38.6375 61.4667C44.9829 64.7275 49.9834 55.6025 61.5382 53.9536C73.5011 52.2456 75.0645 55.8519 81.1913 51.797C85.5212 48.9301 83.6854 32.8216 79.2868 31.46C72.6876 29.4154 70.0684 35.9388 58.6053 38.0601C50.9529 39.4764 40.6081 38.1015 35.4269 40.0615Z"
          fill="#FFF6EB"
          stroke="#EB2701"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M19.2761 34.2213C16.2479 29.7884 11.8476 30.9737 8.27212 31.7131C4.69666 32.4526 1.26927 28.4268 1.26927 28.4268C-0.55856 30.9402 1.56627 39.8176 1.56627 39.8176C1.56627 39.8176 10.2031 37.7571 12.4063 37.7827C14.6096 37.8083 19.1465 40.2098 19.1465 40.2098L19.4938 40.0882"
          fill="#EB2701"
        />
        <path
          d="M84.9305 26.8373C84.9305 26.8373 48.7353 39.6735 18.3779 40.3865C18.3779 40.3865 16.1685 40.4914 16.1641 36.5678C16.1597 32.6442 20.8861 32.4451 20.8861 32.4451C20.8861 32.4451 46.2263 29.8258 55.2024 27.9372C64.1784 26.0486 81.6336 20.7237 82.2249 20.7334C83.5019 20.7528 84.7657 24.3785 84.9305 26.8373Z"
          fill="#EB2701"
        />
        <path
          d="M58.1649 43.0579C55.78 46.6069 52.1191 48.9626 48.0299 49.584C44.3143 49.9215 42.4538 49.1433 40.0849 46.0825C38.0103 42.5838 38.1504 41.5879 38.1504 41.5879L58.1649 43.0579Z"
          fill="#EB2701"
        />
        <path
          d="M76.633 41.2451C75.2767 43.7348 72.6513 45.137 69.9351 44.8206C67.0058 44.7076 64.1831 43.6918 61.8535 41.9123L76.4259 33.0596C76.4259 33.0596 77.3178 39.6932 76.633 41.2451Z"
          fill="#EB2701"
        />
        <path
          d="M91.8795 65.1285C90.9206 64.0251 83.7327 65.1285 83.7327 65.1285C83.7327 65.1285 82.8514 60.5695 80.9583 60.3175C79.0653 60.0654 77.6094 70.493 77.6094 70.493C77.6094 70.493 79.5676 77.7541 83.5344 79.6701C83.5344 79.6701 86.6762 78.579 89.1263 76.6111C91.5763 74.6431 92.9644 72.4742 91.0475 68.5912C91.0475 68.5859 92.8374 66.2266 91.8795 65.1285Z"
          fill="#F4D2B0"
        />
        <path
          d="M80.2335 80.873L79.6836 80.3345L80.2247 80.8932L80.2335 80.873Z"
          fill="#3E4049"
        />
        <path
          d="M77.6311 70.4409C77.6311 70.4409 73.0078 71.7505 71.462 72.1621C68.5175 72.9473 66.9268 73.447 59.3749 71.7655C51.823 70.084 42.9209 73.6101 41.1319 77.1089C39.475 80.3547 41.1107 88.4046 43.091 89.3687C48.3965 91.9492 62.8322 90.911 69.7434 88.4944C76.496 86.1361 79.9904 84.0765 83.6469 79.6038L82.6863 78.89C79.5321 76.4567 77.6311 70.4409 77.6311 70.4409Z"
          fill="#012A56"
        />
        <path
          d="M99.0875 47.0425C99.0875 47.0425 96.6489 51.2974 95.601 52.6255C94.606 53.8849 90.916 56.3843 90.916 56.3843C90.916 56.3843 94.8819 58.8802 95.9818 59.98C97.034 61.0314 99.5228 64.9911 99.5228 64.9911C99.5228 64.9911 101.865 60.6066 102.764 59.7033C103.822 58.6405 107.367 57.1475 107.367 57.1475C107.367 57.1475 103.792 54.1581 102.873 52.9252C101.722 51.3803 99.0875 47.0425 99.0875 47.0425Z"
          fill="#EB2701"
        />
        <path
          d="M106.647 38.8901C106.647 38.8901 105.226 41.371 104.615 42.1448C104.035 42.8789 101.883 44.3357 101.883 44.3357C101.883 44.3357 104.194 45.7908 104.835 46.4315C105.452 47.0484 106.899 49.353 106.899 49.353C106.899 49.353 108.265 46.7972 108.789 46.2684C109.406 45.6515 111.472 44.7764 111.472 44.7764C111.472 44.7764 109.388 43.034 108.852 42.3149C108.183 41.4212 106.647 38.8901 106.647 38.8901Z"
          fill="#EB2701"
        />
        <path
          d="M96.7759 34.8882C96.7759 34.8882 95.7289 36.7169 95.2776 37.2871C94.8502 37.8282 93.2656 38.9016 93.2656 38.9016C93.2656 38.9016 94.9692 39.9733 95.4416 40.4457C95.8937 40.8978 96.9627 42.5987 96.9627 42.5987C96.9627 42.5987 97.9692 40.7154 98.3552 40.3294C98.8108 39.8728 100.332 39.2295 100.332 39.2295C100.332 39.2295 98.7958 37.9454 98.4019 37.4158C97.911 36.7504 96.7759 34.8882 96.7759 34.8882Z"
          fill="#EB2701"
        />
      </svg>
      <div className={styles['body']}>
        <TextElement className={styles["heading"]}>{heading}</TextElement>
        <TextElement className={styles["message"]}>{message}</TextElement>
      </div>
    </Modal>
  );
};

export default NinjaResponseModal;
