import React from "react";

const Dynamics: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="19"
      height="31"
      viewBox="0 0 19 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.11256 29.2727L1.019 29.2177C1.06854 29.0416 1.11807 28.8599 1.17861 28.6673C2.77464 23.9562 4.35967 19.2122 5.97222 14.5011C6.12082 14.0718 6.08229 13.8517 5.66953 13.6095C4.01845 12.6244 2.36738 11.5952 0.716306 10.5826C0.573213 10.5 0.419113 10.4395 0.226488 10.3459V30.5C0.43012 30.4119 0.589724 30.3569 0.732817 30.2799C6.63265 27.0988 12.5325 23.9214 18.4323 20.7477C18.5905 20.6824 18.7235 20.5679 18.8117 20.4213C18.8999 20.2746 18.9386 20.1035 18.9221 19.9331C18.9221 17.1813 18.9221 14.4296 18.9221 11.7108C18.9221 11.5512 18.8946 11.3916 18.8671 11.1604L1.11256 29.2727ZM18.3112 10.3239L0.138431 0.5C0.10967 0.588104 0.0875926 0.678251 0.0723877 0.769675C0.0723877 3.32884 0.0723877 5.89351 0.0723877 8.47468C0.092012 8.58916 0.136732 8.69788 0.20334 8.79303C0.269947 8.88819 0.35679 8.96742 0.457638 9.02504C4.2441 10.8596 8.03973 12.6941 11.8445 14.5286C11.926 14.5681 12.0154 14.5886 12.106 14.5886C12.1965 14.5886 12.2859 14.5681 12.3674 14.5286C14.3432 13.1582 16.2804 11.7658 18.3112 10.3239Z"
        fill="white"
      />
      <path
        d="M1.11257 29.2725L18.8616 11.1382C18.8616 11.3914 18.9166 11.551 18.9166 11.6886C18.9166 14.4404 18.9166 17.1922 18.9166 19.9109C18.9331 20.0813 18.8944 20.2524 18.8062 20.3991C18.718 20.5457 18.585 20.6602 18.4268 20.7255C12.5307 23.9102 6.63449 27.0949 0.738324 30.2797C0.595231 30.3567 0.435627 30.4117 0.231995 30.4998V10.3457C0.42462 10.4393 0.57872 10.4998 0.721813 10.5824C2.37289 11.595 4.02396 12.6242 5.67503 13.6093C6.0878 13.8515 6.12633 14.0716 5.97773 14.5009C4.35968 19.212 2.77465 23.956 1.17311 28.6836C1.11257 28.8597 1.06303 29.0414 1.0135 29.234L1.11257 29.2725Z"
        fill="white"
      />
      <path
        d="M18.3112 10.3239C16.2804 11.7658 14.3432 13.1582 12.3839 14.5286C12.3024 14.5681 12.213 14.5886 12.1225 14.5886C12.0319 14.5886 11.9425 14.5681 11.8611 14.5286C8.05624 12.6941 4.26061 10.8596 0.474149 9.02504C0.373301 8.96742 0.286458 8.88819 0.21985 8.79303C0.153243 8.69788 0.108523 8.58916 0.0888984 8.47468C0.0723877 5.89351 0.0723877 3.32884 0.0723877 0.769675C0.0875926 0.678251 0.10967 0.588104 0.138431 0.5L18.3112 10.3239Z"
        fill="white"
      />
    </svg>
  );
};

export default Dynamics;
