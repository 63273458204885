import React, { useEffect, useRef, useState } from "react";
import styles from "./ApproverCommentChat.module.scss";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addComment, Comment } from "../../redux/campaignCommentsSlice";

interface CommentProps {
  comments: Comment[];
  style?: React.CSSProperties;
  commentPointId: string;
}

const ApproverCommentChat: React.FC<CommentProps> = ({
  comments,
  style,
  commentPointId,
}) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loggedInUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && comment.trim() !== "") {
        handleAddComment();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleAddComment = () => {
    if (loggedInUser === null) return;
    selectedCampaign &&
      dispatch(
        addComment({
          text: comment,
          profileImage: "https://www.w3schools.com/howto/img_avatar.png",
          user: loggedInUser.userid,
          commentPoint: commentPointId,
          campaignPlan: selectedCampaign?.id,
        })
      );
    setComment("");
  };

  return (
    <div
      className={classNames(
        styles.container,
        open ? styles.openContainer : styles.closeContainer
      )}
      style={style}
      onClick={() => {
        setOpen(true);
      }}
    >
      {!open ? (
        <div className={styles.profileImages}>
          {Array.from(new Set(comments.map((c) => c.profileImage))).map((c) => (
            <img src={c} alt={c} />
          ))}
        </div>
      ) : (
        <div className={styles["comment-list"]}>
          <div className={styles["header"]}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["close-icon"]}
              onClick={(e) => {
                setOpen(false);
                e.stopPropagation();
              }}
            >
              <path
                d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                fill="#EB2701"
              />
            </svg>
            <span>
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.885 0.370117L8 10.2551L2.615 4.88512L0.5 7.00012L8 14.5001L20 2.50012L17.885 0.370117Z"
                  fill="#FFF6EB"
                />
              </svg>
            </span>
          </div>
          <div className={styles["divider"]}></div>
          <div className={styles["comments"]}>
            {comments.map((c, index) => (
              <div key={index} className={styles["comment"]}>
                <img src={c.profileImage} alt={c.userName} />
                <div className={styles["comment-content"]}>
                  <div className={styles["comment-header"]}>
                    <h4>{c.userName}</h4>
                    <span>{c.createdAt}</span>
                  </div>
                  <TextElement className={styles["text"]}>{c.text}</TextElement>
                </div>
              </div>
            ))}
          </div>
          <div className={styles["input-container"]}>
            <img
              src="https://www.w3schools.com/howto/img_avatar.png"
              alt="profile"
            />
            <div className={styles["input"]}>
              <input
                type="text"
                placeholder="Add a comment"
                className={styles["input"]}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleAddComment}
              >
                <path
                  d="M15 21C15.425 21 15.7815 20.856 16.0695 20.568C16.3565 20.281 16.5 19.925 16.5 19.5V14.7L17.85 16.05C18.125 16.325 18.475 16.4625 18.9 16.4625C19.325 16.4625 19.675 16.325 19.95 16.05C20.225 15.775 20.3625 15.425 20.3625 15C20.3625 14.575 20.225 14.225 19.95 13.95L16.05 10.05C15.9 9.9 15.7375 9.7935 15.5625 9.7305C15.3875 9.6685 15.2 9.6375 15 9.6375C14.8 9.6375 14.6125 9.6685 14.4375 9.7305C14.2625 9.7935 14.1 9.9 13.95 10.05L10.05 13.95C9.775 14.225 9.6375 14.575 9.6375 15C9.6375 15.425 9.775 15.775 10.05 16.05C10.325 16.325 10.675 16.4625 11.1 16.4625C11.525 16.4625 11.875 16.325 12.15 16.05L13.5 14.7V19.5C13.5 19.925 13.644 20.281 13.932 20.568C14.219 20.856 14.575 21 15 21ZM15 30C12.925 30 10.975 29.606 9.15 28.818C7.325 28.031 5.7375 26.9625 4.3875 25.6125C3.0375 24.2625 1.969 22.675 1.182 20.85C0.394 19.025 0 17.075 0 15C0 12.925 0.394 10.975 1.182 9.15C1.969 7.325 3.0375 5.7375 4.3875 4.3875C5.7375 3.0375 7.325 1.9685 9.15 1.1805C10.975 0.3935 12.925 0 15 0C17.075 0 19.025 0.3935 20.85 1.1805C22.675 1.9685 24.2625 3.0375 25.6125 4.3875C26.9625 5.7375 28.031 7.325 28.818 9.15C29.606 10.975 30 12.925 30 15C30 17.075 29.606 19.025 28.818 20.85C28.031 22.675 26.9625 24.2625 25.6125 25.6125C24.2625 26.9625 22.675 28.031 20.85 28.818C19.025 29.606 17.075 30 15 30Z"
                  fill="#778BA8"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproverCommentChat;
