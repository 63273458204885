import React, { useEffect } from "react";

import styles from "./Metric.module.scss";

import classNames from "classnames";
import TextElement from "../../../TextElement/TextElement";

type Type = "prominent" | "normal";

interface MetricProps {
  value: string | number | null | undefined;
  label: string;
  className?: string;
  type?: Type;
  icon?: React.ReactNode;
}

const Metric: React.FC<MetricProps> = ({
  value,
  label,
  className,
  icon,
  type = "normal",
}) => {
  return (
    <div
      className={classNames(
        styles["container"],
        className,
        type === "normal" ? "" : styles["prominent"]
      )}
    >
      <span className={styles["icon-container"]}>{icon}</span>
      <TextElement className={styles["value"]}>{value}</TextElement>
      <TextElement className={styles["label"]}>{label}</TextElement>
    </div>
  );
};

export default Metric;
