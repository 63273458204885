import React, { ReactNode } from "react";

import classNames from "classnames";

import styles from "./IconicContainer.module.scss";

import ContainerBox from "../ContainerBox/ContainerBox";
import { NinjaIcon, UserIcon } from "../../../../icons";


type IconicContainerProps = {
  children: ReactNode;
  className?: string;
  iconType?: "ninja" | "user";
  showIcon?: boolean;
  contentClass?: string;
};

const IconicContainer = (props: IconicContainerProps) => {
  const {
    children,
    className,
    iconType = "ninja",
    showIcon = true,
    contentClass,
  } = props;
  return (
    <div className={classNames(styles["container"], className)}>
      {iconType === "ninja" ? (
        <NinjaIcon
          style={{ visibility: showIcon ? "visible" : "hidden" }}
          className={styles["ninja-icon"]}
        />
      ) : (
        <UserIcon
          style={{ visibility: showIcon ? "visible" : "hidden" }}
          className={styles["user-icon"]}
        />
      )}
      <ContainerBox className={classNames(styles["content"], contentClass)}>
        {children}
      </ContainerBox>
    </div>
  );
};

export default IconicContainer;
