import React from "react";

const NegotiateTermsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="106"
      height="113"
      viewBox="0 0 106 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.25">
        <path
          d="M54.8425 112.345C77.4208 112.345 95.7241 110.018 95.7241 107.148C95.7241 104.278 77.4208 101.951 54.8425 101.951C32.2643 101.951 13.9609 104.278 13.9609 107.148C13.9609 110.018 32.2643 112.345 54.8425 112.345Z"
          fill="#EB2701"
        />
      </g>
      <path
        d="M12.0615 84.1387C12.0566 84.1506 12.0522 84.1625 12.0469 84.1745C12.0509 84.1625 12.0566 84.1506 12.0615 84.1387Z"
        fill="#3E4049"
      />
      <path
        d="M13.8807 77.8028C13.8954 73.833 12.6506 70.7312 12.6506 70.7312C12.6506 70.7312 10.8314 68.4934 8.20833 68.1453C5.58528 67.7972 5.06576 68.8686 5.12065 69.8327C5.17555 70.7968 7.33398 70.556 7.47056 71.8683C7.60713 73.1805 5.55136 73.1805 5.11708 72.27C4.68281 71.3596 5.42103 70.1006 4.84929 69.4846C4.27755 68.8686 2.30791 69.4309 1.78884 71.4399C1.78884 71.4399 -0.451716 71.627 0.203935 74.6271C0.203935 74.6271 -0.506614 75.6449 0.750684 77.4392C0.750684 77.4392 0.231161 78.9124 3.07157 81.3497C5.91199 83.7871 8.28689 83.6029 8.28689 83.6029L12.0164 83.9081C12.1052 83.7317 13.8718 79.5469 13.8807 77.8028Z"
        fill="#F4D2B0"
      />
      <path
        d="M94.1743 84.5618C90.365 77.464 93.4535 70.3203 93.4535 70.3203C91.978 71.0032 88.9278 64.8258 84.8841 58.1293C80.8404 51.4329 72.2678 49.7158 72.2678 49.7158C66.5276 51.8518 52.7487 54.2947 52.7487 54.2947C52.7487 54.2947 38.6122 52.1952 32.872 50.0592C32.872 50.0592 26.3235 53.8947 22.2794 60.5903C18.329 67.1313 14.0393 71.1595 12.4785 70.5323C12.691 71.0732 14.8061 77.0309 12.1313 83.3359C12.1264 83.3479 12.1219 83.3595 12.1166 83.3715C12.0367 83.5591 11.951 83.7467 11.8621 83.9347C11.8456 83.9695 11.8314 84.0042 11.8144 84.0394C11.7305 84.2137 11.6408 84.388 11.5488 84.5627C11.589 84.7923 31.812 79.0205 32.6837 67.1159L31.5728 83.3702C31.5728 83.3702 31.3746 88.5213 31.4933 96.1527L52.8598 100.712L78.5757 95.4273C79.5282 77.9517 76.4932 64.0819 76.4932 64.0819C77.6483 68.9325 78.8815 70.0619 81.7893 74.2124C85.4786 79.4777 94.1743 84.5618 94.1743 84.5618Z"
        fill="#02285E"
      />
      <path
        d="M105.8 74.7306C106.456 71.7305 104.215 71.5433 104.215 71.5433C103.696 69.5344 101.728 68.972 101.155 69.588C100.581 70.204 101.319 71.4626 100.887 72.3735C100.455 73.2844 98.3969 73.2839 98.5335 71.9717C98.6701 70.6595 100.828 70.8986 100.883 69.9362C100.938 68.9738 100.419 67.9006 97.7957 68.2487C95.1726 68.5969 93.1914 71.2888 93.1914 71.2888C93.1914 71.2888 90.297 78.2659 94.1068 85.3637C94.1068 85.3637 100.091 83.8905 102.932 81.4532C105.774 79.0159 105.253 77.5426 105.253 77.5426C106.51 75.7483 105.8 74.7306 105.8 74.7306Z"
        fill="#F4D2B0"
      />
      <path
        d="M51.4678 61.6882C51.4678 61.6882 49.375 59.6389 48.2003 58.3306C47.0255 57.0222 46.1387 55.9791 46.1387 55.9791L46.2462 55.4434C46.2462 55.4434 50.9251 55.6683 52.6073 55.6983C54.2895 55.7284 58.4814 55.4434 58.4814 55.4434L58.4872 55.8155C57.6292 57.0685 56.6914 58.2693 55.6793 59.411C54.0685 61.2139 52.5243 62.263 52.5243 62.263L51.8963 62.1037L51.4678 61.6882Z"
        fill="#F4D2B0"
      />
      <path
        d="M78.4263 79.8224C78.4263 79.8224 68.4621 83.5891 54.2971 83.7763C40.1321 83.5891 31.8496 79.7842 31.8496 79.7842C31.4925 83.39 31.572 85.2787 31.572 85.2787C40.0102 88.6166 48.1463 90.1027 53.2705 90.195V90.2118C53.2705 90.2118 53.6374 90.2152 54.2971 90.2092C54.9572 90.2152 55.3236 90.2118 55.3236 90.2118V90.195C60.4474 90.1027 70.3844 89.5704 78.8227 86.2325C78.8231 86.2325 78.7713 83.3728 78.4263 79.8224Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M32.6367 49.5586C32.6367 49.5586 38.8821 58.2447 52.5736 58.2447C66.2651 58.2447 71.9607 50.1943 71.9607 50.1943C71.9607 50.1943 59.9063 54.1328 52.4116 54.1328C44.9169 54.1328 33.6646 50.5927 32.6367 49.5586Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M57.8332 90.6465C57.8332 90.6465 61.8006 101.167 61.7091 104.558L56.126 104.131L52.1836 91.22"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M54.4014 90.9951L57.6515 101.641L61.3658 101.924C61.5926 102.983 61.7269 103.904 61.7091 104.558L56.126 104.132L52.1836 91.2209L54.4014 90.9951Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M52.5782 83.7131C52.5782 83.7131 53.8948 80.5031 58.213 81.4754C62.5312 82.4477 61.2409 89.005 58.8214 90.859C56.4018 92.713 53.7583 90.8127 53.7583 90.8127C53.7583 90.8127 50.2158 92.288 47.0045 90.058C43.7932 87.828 43.5678 82.4468 46.4814 81.8317C49.395 81.2166 51.3793 82.7168 52.5782 83.7131Z"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M44.3805 84.377C44.3805 84.377 47.1111 88.0205 50.4273 88.4167C53.7435 88.8129 54.4358 87.3985 54.4358 87.3985L54.4519 86.8585C54.4519 86.8585 57.4811 88.9709 58.8705 88.3965C60.2599 87.8222 60.5495 86.9263 60.5495 86.9263L61.024 86.1614C61.024 86.1614 60.7647 90.5458 58.1443 91.2812C55.5239 92.0165 54.2166 91.0816 54.2166 91.0816L53.2347 90.754C53.2347 90.754 49.3722 92.1384 46.4952 89.6496C43.6182 87.1607 44.3805 84.377 44.3805 84.377Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M47.7712 90.4951C47.7712 90.4951 43.2419 100.797 43.1504 104.189L48.7486 104.05L53.3815 91.3584"
        fill="#EB2701"
      />
      <g opacity="0.25">
        <path
          d="M51.1793 91.0186L47.3597 101.482L43.6351 101.574C43.3512 102.619 43.1678 103.532 43.1504 104.187L48.7486 104.048L53.3815 91.3577L51.1793 91.0186Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M50.9359 84.1857C51.8794 84.9283 52.2427 85.8525 51.9945 86.1066C51.7464 86.3607 50.9814 85.8491 50.0383 85.1056C49.0952 84.3621 48.3307 83.671 48.5784 83.4169C48.8261 83.1628 49.9923 83.4426 50.9359 84.1857Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M58.0269 82.8601C58.9334 83.2104 59.4167 83.9208 59.2726 84.2337C59.1284 84.5467 58.4121 84.3441 57.5056 83.9938C56.5991 83.6435 55.8457 83.2786 55.9881 82.9636C56.1304 82.6485 57.1204 82.5098 58.0269 82.8601Z"
          fill="white"
        />
      </g>
      <path
        d="M50.1169 100.096C50.1012 97.6528 49.5183 94.7802 47.8161 92.5407C44.4534 91.5693 33.5524 88.0442 31.693 87.7227C28.359 87.1462 20.9107 85.6803 18.4577 90.8614C13.3995 101.545 22.0876 104.808 27.265 107.097C32.612 109.461 48.153 108.585 48.153 108.585C48.153 108.585 50.1454 106.575 50.1169 100.096Z"
        fill="#02285E"
      />
      <path
        d="M58.8405 95.2159C56.6816 93.5749 50.1997 93.0267 47.7958 92.5605C49.5056 94.8086 47.4717 107.652 46.8438 108.586C47.3035 108.707 54.4273 111.453 58.6968 107.112C62.9664 102.772 60.9998 96.857 58.8405 95.2159Z"
        fill="#EAC8A9"
      />
      <path
        d="M61.575 97.0161C60.8381 97.1582 60.1248 97.1741 59.3848 97.1243C58.6404 97.0329 57.8593 97.0535 57.2728 96.4714C57.2661 96.4644 57.2612 96.4559 57.2584 96.4468C57.2556 96.4376 57.2551 96.4279 57.2569 96.4185C57.2587 96.4091 57.2627 96.4002 57.2686 96.3926C57.2746 96.3849 57.2823 96.3787 57.2911 96.3744C58.016 96.0567 58.6444 96.1713 59.3714 96.0615C59.9597 95.9979 60.8242 95.8859 61.3964 95.8267C62.1521 95.7661 62.3114 96.8517 61.575 97.0161Z"
        fill="#0F0F14"
      />
      <path
        d="M61.3824 100.744C60.8166 100.754 60.252 100.689 59.7046 100.55C59.2235 100.414 58.654 100.316 58.3108 99.9065C58.2661 99.8322 58.0997 99.6524 58.2215 99.5876C58.5594 99.485 58.8049 99.4588 59.0642 99.479C59.839 99.5159 60.6424 99.4742 61.415 99.5412C62.159 99.6215 62.1291 100.705 61.3833 100.743L61.3824 100.744Z"
        fill="#0F0F14"
      />
      <path
        d="M59.9014 103.841C59.1587 103.858 58.4629 103.66 57.8037 103.339C57.6776 103.283 57.5646 103.203 57.4719 103.104C57.3792 103.004 57.3086 102.888 57.2645 102.761C57.258 102.744 57.2582 102.726 57.2649 102.709C57.2717 102.692 57.2845 102.678 57.3011 102.67C57.8264 102.416 58.1715 102.575 58.6722 102.567C59.04 102.587 59.5979 102.597 59.9487 102.641C60.6794 102.739 60.6329 103.802 59.9019 103.843L59.9014 103.841Z"
        fill="#0F0F14"
      />
      <path
        d="M57.5633 106.036C57.0638 105.997 56.6126 105.867 56.1533 105.677C55.815 105.52 55.4365 105.366 55.1879 105.06C55.0915 104.934 55.0369 104.784 55.0313 104.628C55.031 104.609 55.0378 104.592 55.0505 104.578C55.0632 104.565 55.0807 104.556 55.0995 104.555C55.6588 104.461 55.94 104.603 56.4416 104.651C56.8179 104.704 57.3736 104.762 57.736 104.846C58.4559 105.018 58.2997 106.075 57.5655 106.036H57.5633Z"
        fill="#0F0F14"
      />
      <path
        d="M55.178 100.007C55.1937 97.5643 55.7766 94.6913 57.4793 92.4518C60.841 91.4808 71.7425 87.9553 73.6023 87.6338C76.9364 87.0573 84.3842 85.5914 86.8372 90.773C91.8959 101.457 83.2073 104.719 78.0299 107.008C72.6834 109.373 57.1419 108.497 57.1419 108.497C57.1419 108.497 55.1495 106.486 55.178 100.007Z"
        fill="#02285E"
      />
      <path
        d="M63.0925 88.1816C63.0108 88.2825 62.9327 88.3842 62.8555 88.486C62.9318 88.3842 63.0108 88.2825 63.0925 88.1816Z"
        fill="#3E4049"
      />
      <path
        d="M46.454 95.1271C48.6129 93.486 55.0949 92.9379 57.4988 92.4717C55.7893 94.7197 57.8228 107.563 58.4508 108.498C57.9911 108.618 50.8677 111.365 46.5977 107.024C42.3277 102.682 44.2947 96.7681 46.454 95.1271Z"
        fill="#F4D2B0"
      />
      <path
        d="M24.9909 22.0713C24.9909 22.0713 25.7372 26.803 20.3938 29.1111C15.0504 31.4193 10.3921 29.179 10.3921 29.179L9.41016 22.0588C9.41016 22.0588 14.0153 23.5428 16.5322 23.0706C19.049 22.5984 23.9447 18.0264 23.9447 18.0264"
        fill="#EB2701"
      />
      <path
        d="M61.2927 55.2873C53.5208 56.3042 40.705 55.5058 36.7644 53.2745C31.5295 50.31 26.8859 44.8344 25.2992 40.9041C23.7724 37.121 19.2243 22.7198 28.923 10.6941C37.9865 -0.543934 61.1267 -6.92531 76.5856 13.2465C80.5739 19.5506 81.8888 35.1494 77.8023 43.2324C75.1493 48.4797 68.8392 54.3004 61.2927 55.2873Z"
        fill="#02285E"
      />
      <g opacity="0.5">
        <path
          d="M21.207 41.1222C21.2369 41.0522 21.2659 40.9827 21.2963 40.9127C21.2963 40.9127 21.2914 40.8831 21.2838 40.832C21.257 40.9282 21.2316 41.0256 21.207 41.1222Z"
          fill="white"
        />
      </g>
      <path
        d="M35.2123 25.3938C32.5036 25.9625 30.5518 28.1024 30.3978 30.641C30.1631 34.5172 31.7368 40.2513 35.2498 42.6702C40.0701 45.9883 43.1092 39.3649 52.798 39.4078C62.8283 39.455 64.1449 44.0799 69.8177 41.9327C73.25 40.6338 75.7637 27.4958 72.3171 25.9071C67.1482 23.5243 64.1556 26.178 54.4855 26.5163C48.0299 26.7429 39.7024 24.4507 35.2123 25.3938Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M24.2032 18.4387C22.2545 14.5853 18.4974 15.0077 15.4713 15.1729C12.4452 15.3382 10.1199 11.7607 10.1199 11.7607C8.31403 13.5319 8.98217 20.7915 8.98217 20.7915C8.98217 20.7915 16.3273 20.1721 18.1318 20.4481C19.9363 20.7241 23.3726 23.1524 23.3726 23.1524L23.6721 23.0971"
        fill="#EB2701"
      />
      <path
        d="M79.6961 21.9022C79.6961 21.9022 47.7329 26.1832 22.7177 23.2045C22.7177 23.2045 20.8909 23.029 21.3631 19.9306C21.8353 16.8322 25.7402 17.2262 25.7402 17.2262C25.7402 17.2262 46.7461 19.1403 54.3452 18.6982C61.9444 18.256 77.6666 16.1037 78.1495 16.1797C79.1966 16.3432 79.8585 19.9413 79.6961 21.9022Z"
        fill="#EB2701"
      />
      <path
        d="M51.735 31.5568C49.3467 34.0812 46.0541 35.5162 42.621 35.5287C39.5289 35.3613 38.0953 34.5285 36.5215 31.8371C35.2424 28.8322 35.478 28.0596 35.478 28.0596L51.735 31.5568Z"
        fill="#EB2701"
      />
      <path
        d="M67.082 34.0423C65.4516 35.6735 63.0427 36.2024 60.9383 35.3872C58.6366 34.697 56.6054 33.3268 55.1035 31.4466L68.7066 27.6953C68.7066 27.6953 67.9679 32.9911 67.082 34.0423Z"
        fill="#EB2701"
      />
      <path
        d="M33.5566 80.4717C34.5024 80.8628 38.7005 82.0145 39.7454 82.3411C41.6075 81.213 59.5493 62.9781 67.0806 53.5713C65.6845 53.7001 60.2808 55.6133 58.5901 55.5935C57.2212 56.9899 44.314 74.2234 33.5566 80.4717Z"
        fill="#012551"
      />
      <path
        d="M49.5458 66.5448C45.6744 63.8023 39.0612 54.5255 39.2705 54.3731C39.4798 54.2207 44.796 54.6075 45.7574 54.926C46.9669 55.3269 49.519 59.8041 53.3543 62.1951C53.5743 62.332 49.6324 66.6049 49.5458 66.5448Z"
        fill="#012551"
      />
    </svg>
  );
};

export default NegotiateTermsIcon;
