import React, { useEffect } from "react";

import CampaignHeader from "../CampaignHeader/CampaignHeader";
import SideBar from "../SideBar/SideBar";

import styles from "./ShortlistStageLayout.module.scss";

import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import InsightsFromKnownContacts from "../CampaignReports/InsightsFromKnownContacts";
import MQLInsights from "../CampaignReports/MQLInsights";

interface Props {
  children?: React.ReactNode;
  operation: "report" | "content" | "strategy";
}

const ShortlistStageLayout: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  useEffect(() => {
    dispatch(setSelectedCampaign(""));
  }, []);

  return (
    <ProtectedRoleRoute
      requiredRole={[UserRoleType.MARKETING]}
      className={styles["campaign-builder-layout"]}
    >
      <div className={styles["container"]}>
        <CampaignHeader className={styles["header"]} />
        <div className={styles["content"]}>
          <SideBar className={styles["side-bar"]} />
          <MQLInsights className={styles["mql-insights"]} />
        </div>
      </div>
    </ProtectedRoleRoute>
  );
};

export default ShortlistStageLayout;
