import React from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";

import styles from "./ApprovalCampaignModal.module.scss";
import HttpService from "../../lib/api";
import { useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { CampaignStatusType } from "../../redux/campaignPinningSlice";
import { isHttpResponseError } from "../../lib/helpers";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  onOk: () => void;
}

const ApprovalCampaignModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen, onOk } = props;
  const [email, setEmail] = React.useState("");
  const httpService = new HttpService();

  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const [loading, setLoading] = React.useState(false);

  return (
    <Modal
      className={classNames(className, styles["container"])}
      isOpen={isOpen}
      closeModal={closeModal}
      contentClassName={styles["content"]}
    >
      <div className={styles["header"]}>
        <TextElement>Send for approval?</TextElement>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={closeModal}
        >
          <path
            d="M6.575 7.975L1.675 12.875C1.49167 13.0583 1.25833 13.15 0.975 13.15C0.691667 13.15 0.458333 13.0583 0.275 12.875C0.0916663 12.6917 0 12.4583 0 12.175C0 11.8917 0.0916663 11.6583 0.275 11.475L5.175 6.575L0.275 1.675C0.0916663 1.49167 0 1.25833 0 0.975C0 0.691667 0.0916663 0.458333 0.275 0.275C0.458333 0.0916663 0.691667 0 0.975 0C1.25833 0 1.49167 0.0916663 1.675 0.275L6.575 5.175L11.475 0.275C11.6583 0.0916663 11.8917 0 12.175 0C12.4583 0 12.6917 0.0916663 12.875 0.275C13.0583 0.458333 13.15 0.691667 13.15 0.975C13.15 1.25833 13.0583 1.49167 12.875 1.675L7.975 6.575L12.875 11.475C13.0583 11.6583 13.15 11.8917 13.15 12.175C13.15 12.4583 13.0583 12.6917 12.875 12.875C12.6917 13.0583 12.4583 13.15 12.175 13.15C11.8917 13.15 11.6583 13.0583 11.475 12.875L6.575 7.975Z"
            fill="black"
          />
        </svg>
      </div>
      <TextElement className={styles["subjective-text"]}>
        Add the email address or the username of the approver below
      </TextElement>
      <form
        className={styles["form"]}
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          const response = await httpService
            .update<
              {
                status: string;
              },
              { status: string; approver_email: string }
            >(`api/campaign/update-status/${selectedCampaign?.id}/`, {
              status: CampaignStatusType.NEEDS_APPROVAL,
              approver_email: email,
            })
            .then((response) => {
              toast.success("Campaign Plan sent for approval");
              onOk();
            })
            .catch((error) => {
              console.log(error);
              if (isHttpResponseError(error)) {
                toast.error(error.data.message);
              } else {
                toast.error("Failed to send the campaign plan for approval");
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <input
          className={styles["input"]}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
        />
        <Button
          className={styles["action-btn"]}
          type="submit"
          disabled={loading}
        >
          {!loading ? "Send" : "Sending for approval"}
        </Button>
      </form>
    </Modal>
  );
};

export default ApprovalCampaignModal;
