import React from "react";

import styles from "./GraphPlaceholder.module.scss";
import TextElement from "../../../TextElement/TextElement";
import classNames from "classnames";

interface GraphPlaceholderProps {
  title: string;
  imageUrl: string;
  className?: string;
}

const GraphPlaceholder: React.FC<GraphPlaceholderProps> = (props) => {
  const { title, imageUrl, className } = props;
  return (
    <div className={classNames(styles["container"], className)}>
      <TextElement className={styles["title"]}>{title}</TextElement>
      <img className={styles["img"]} src={imageUrl} alt="placeholder graph" />
    </div>  
  );
};

export default GraphPlaceholder;
