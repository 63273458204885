import React from "react";

import styles from "./ProductDemoInvitations.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";

interface ProductDemoInvitationsProps {
  className?: string;
  cardType: CardType;
}

const ProductDemoInvitations: React.FC<ProductDemoInvitationsProps> = (
  props
) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector((state) => state.interestStage);

  const handlePinned = (isPinned: boolean) => {
    nurtureStrategyData &&
      dispatch(
        setNurtureStrategyData({
          ...nurtureStrategyData,
         productDemoInvitation: isPinned,
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Week 3-4: Product Demo invitations
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Action: Schedule Demos
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Topics</TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>"Exclusive Demo:</span> Our
              5G Deployment Capabilities": A live demonstration showcasing our
              cutting-edge 5G solutions and their practical applications.
            </li>
            <li>
              <span className={styles["highlight"]}>"Live Demonstration:</span>{" "}
              Advanced IoT Integration Solutions": A webinar highlighting the
              seamless integration of IoT devices within telecom networks and
              the resulting efficiencies.
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Channels
        </TextElement>
        <div className={styles["content"]}>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>Email</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>Subject Line:</span> "Join
                Our Exclusive 5G Demo"
              </li>
              <li>
                "Dear [Lead Name], experience the power of our 5G solutions
                first-hand. Join our exclusive demo and see how we can elevate
                your telecom capabilities.
                <span className={styles["sub-heading-text"]}>
                  [Register Now]
                </span>
                "
              </li>
              <li>
                <span className={styles["highlight"]}>Call to Action:</span>
                "Don't miss our live demonstration on advanced IoT integration.
                Reserve your spot today! [Sign Up]"
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Social Media
            </TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>
                  Zoom/WebEx Invitations:{" "}
                </span>{" "}
                Automated invites with calendar integration to ensure easy
                scheduling for the leads.
              </li>
            </ul>
          </div>
        </div>
        <TextElement className={styles["content-heading"]}>
          Metrics to Track
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>Registration rates:</span>{" "}
              Target 40%
            </li>
            <li>
              <span className={styles["highlight"]}>Attendance rates:</span>{" "}
              Target 70%
            </li>
            <li>
              <span className={styles["highlight"]}>
                Post-demo engagement (questions asked, demo feedback):
              </span>{" "}
              Target 50% follow-up engagement
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Expected Results
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Achieve a{" "}
            <span className={styles["highlight"]}>
              25% conversion rate from demo attendees to SQLs
            </span>{" "}
            by demonstrating product value directly relevant to their interests.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default ProductDemoInvitations;
