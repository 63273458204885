import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";

type ButtonProps = {
  variant?: "primary" | "secondary" | "outline";
  children: React.ReactNode;
  className?: string;
  focus?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  variant = "primary",
  onClick,
  children,
  className,
  focus,
  disabled,
  icon,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        styles["button"],
        styles[variant],
        className,
        focus && styles.focus
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
};

export default Button;
