import React from "react";
import { CSSProperties } from "react";
import classNames from "classnames";

import styles from "./TeamTable.module.scss";

export interface TeamMemberData {
  id: number;
  name: string;
  role: string;
  dateAdded: Date;
}

interface TeamTableProps extends React.HTMLProps<HTMLParagraphElement> {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  memberData: TeamMemberData[];
}

const TeamTable: React.FC<TeamTableProps> = ({
  id,
  children,
  className,
  style,
  memberData,
  ..._rest
}) => {
  return (
    <div className={classNames(styles["container"], className)}>
      <table className={classNames(styles["table"], className)}>
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Role</th>
            <th>Date Added</th>
          </tr>
        </thead>
        <tbody>
          {memberData.map((member) => (
            <tr key={member.id}>
              <td>{member.name}</td>
              <td>{member.role}</td>
              <td>
                {member.dateAdded.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamTable;
