import React from "react";
import { Button } from "../../../componenets";

import styles from "../ConnectingMarketingChannels.module.scss";
import classNames from "classnames";

interface ConnectWithFacebookProps {}

const ConnectWithFacebook: React.FC<ConnectWithFacebookProps> = () => {
  return (
    <div
      className={classNames(
        styles["channel-card"],
        styles["disabled-channel-card"]
      )}
    >
      <svg
        width="101"
        height="100"
        viewBox="0 0 101 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100.5 50.0002C100.5 22.3858 78.1143 0 50.5 0C22.8857 0 0.5 22.3858 0.5 50.0002C0.5 74.9567 18.7843 95.642 42.6875 99.3929V64.4534H29.9922V50.0002H42.6875V38.9845C42.6875 26.4532 50.1521 19.5313 61.5732 19.5313C67.0437 19.5313 72.7657 20.5079 72.7657 20.5079V32.8126H66.4608C60.2495 32.8126 58.3126 36.6669 58.3126 40.621V50.0002H72.1797L69.9629 64.4534H58.3125V99.3929C82.2157 95.642 100.5 74.9567 100.5 50.0002Z"
          fill="#778BA8"
        />
      </svg>
      <Button
        disabled
      >Connect Facebook Profile</Button>
      <Button disabled>Connect Facebook Page</Button>
      <Button disabled>Connect Facebook Group</Button>
    </div>
  );
};

export default ConnectWithFacebook;
