import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Section {
  id: string;
  isActive: boolean;
  isAvailable: boolean;
}

interface State {
  marketingBrief: Section;
  pastCampaignAnalysis: Section;
  audienceSegmentation: Section;
  competitorAnalysis: Section;
  marketingSentiments: Section;
  recommendations: Section;
  content: Section;
}

const initialState: State = {
  marketingBrief: { id: "marketingBrief", isActive: false, isAvailable: false },
  pastCampaignAnalysis: {
    id: "pastCampaignAnalysis",
    isActive: false,
    isAvailable: false,
  },
  audienceSegmentation: {
    id: "audienceSegmentation",
    isActive: false,
    isAvailable: false,
  },
  competitorAnalysis: {
    id: "competitorAnalysis",
    isActive: false,
    isAvailable: false,
  },
  marketingSentiments: {
    id: "marketingSentiments",
    isActive: false,
    isAvailable: false,
  },
  recommendations: {
    id: "recommendations",
    isActive: false,
    isAvailable: false,
  },
  content: { id: "content", isActive: false, isAvailable: false },
};

const CampaignQueryChatScrollControllerSlice = createSlice({
  name: "CampaignQueryChatScrollController",
  initialState,
  reducers: {
    updateChatScrolPosition: (
      state,
      action: PayloadAction<{
        id: keyof State;
        isActive: boolean;
        isAvailable: boolean;
      }>
    ) => {
      Object.keys(state).forEach((key) => {
        const sectionKey = key as keyof State;
        // Set the specified section to active and available based on the action payload
        if (sectionKey === action.payload.id) {
          state[sectionKey].isActive = action.payload.isActive;
          state[sectionKey].isAvailable = action.payload.isAvailable;
        } else {
          // Set all other sections to inactive
          state[sectionKey].isActive = false;
        }
      });
    },
  },
  extraReducers: (builder) => {},
});

export const { updateChatScrolPosition } =
  CampaignQueryChatScrollControllerSlice.actions;
export default CampaignQueryChatScrollControllerSlice.reducer;
