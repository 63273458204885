import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { Menu } from "@headlessui/react";
import { IoMdFingerPrint } from "react-icons/io";
import { LuPencilRuler } from "react-icons/lu";
import { PiImageSquareBold } from "react-icons/pi";

import styles from "./BrandingDropdown.module.scss";
import TextElement from "../../TextElement/TextElement";
import { PlusIcon } from "../../../icons";
import { SidebarItem } from "../../../enums";
import { ROUTE_DEFINITIONS } from "../../../pages/AppRoutes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { UserRoleType } from "../../../redux/authSlice";

export enum BrandingDropdownIds {
  IDENTITY = "identity",
  STYLE_GUIDE = "style-guide",
  ASSETS = "assets",
}

interface BrandingDropdownProps {
  className?: string;
}

const BrandingDropdown: React.FC<BrandingDropdownProps> = (props) => {
  const { className } = props;
  const [open, setOpen] = useState(true);
  const { loggedInUser } = useAppSelector((state) => state.auth);
  const [basePath, setBasePath] = useState<string>("");

  const [activeSideTab, setActiveSideTab] = useState<SidebarItem>();

  useEffect(() => {
    const activeSection = new URLSearchParams(window.location.search).get(
      "section"
    );

    switch (activeSection) {
      case BrandingDropdownIds.IDENTITY:
        setActiveSideTab(SidebarItem.BRAND_IDENTITY);
        break;
      case BrandingDropdownIds.STYLE_GUIDE:
        setActiveSideTab(SidebarItem.BRAND_STYLE_GUIDE);
        break;
      case BrandingDropdownIds.ASSETS:
        setActiveSideTab(SidebarItem.BRAND_ASSETS);
        break;
      default:
        setActiveSideTab(undefined);
        break;
    }
  }, [window.location.search]);

  useEffect(() => {
    if (loggedInUser?.role === UserRoleType.MARKETING) {
      setBasePath(ROUTE_DEFINITIONS.MY_BRANDING.path);
    } else if (loggedInUser?.role === UserRoleType.PRIMARY_APPROVER || loggedInUser?.role === UserRoleType.SECONDARY_APPROVER) {
      setBasePath(ROUTE_DEFINITIONS.APPROVER_BRANDING.path);
    } else if (loggedInUser?.role === UserRoleType.CMO) {
      setBasePath(ROUTE_DEFINITIONS.CMO_BRANDING.path);
    }
  }, []);

  

  const [items, _setItems] = useState<
    {
      id: BrandingDropdownIds;
      label: string;
      sidebarItem: SidebarItem;
      icon: ReactNode;
    }[]
  >([
    {
      id: BrandingDropdownIds.IDENTITY,
      label: "Identity",
      sidebarItem: SidebarItem.BRAND_IDENTITY,
      icon: <IoMdFingerPrint />,
    },
    {
      id: BrandingDropdownIds.STYLE_GUIDE,
      label: "Style Guide",
      sidebarItem: SidebarItem.BRAND_STYLE_GUIDE,
      icon: <LuPencilRuler />,
    },
    {
      id: BrandingDropdownIds.ASSETS,
      label: "Assets",
      sidebarItem: SidebarItem.BRAND_ASSETS,
      icon: <PiImageSquareBold />,
    },
  ]);

  const navigate = useNavigate();

  return (
    <div className={classNames(styles.container, className)}>
      <PlusIcon className={styles["plus-icon"]} />
      <Menu>
        <Menu.Button
          className={styles["dropdown-btn"]}
          onClick={() => setOpen(!open)}
        >
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={
              open
                ? { transform: "rotate(0deg)" }
                : { transform: "rotate(180deg)" }
            }
          >
            <path
              d="M11 1L6 6L1 1"
              stroke="#333333"
              strokeOpacity="0.9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <TextElement>My Brand</TextElement>
        </Menu.Button>
        {open && (
          <Menu.Items className={styles["container"]} static>
            {items.map((item) => (
              <Menu.Item key={item.label}>
                <div
                  className={classNames(
                    styles["brand"],
                    activeSideTab === item.sidebarItem
                      ? styles["active-branding-step"]
                      : styles["brand-hover"]
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${basePath}?section=${item.id}`);
                  }}
                >
                  {item.icon}
                  <TextElement>{item.label}</TextElement>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        )}
      </Menu>
    </div>
  );
};

export default BrandingDropdown;
