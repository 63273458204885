import React, { useEffect } from "react";

import CampaignHeader from "../CampaignHeader/CampaignHeader";
import SideBar from "../SideBar/SideBar";

import styles from "./CampaignBuilderLayout.module.scss";
import CampaignBuilder from "../CampaignBuilder/CampaignBuilder";
import CampaignAnalysisFlow from "../CampaignAnalysisFlow/CampaignAnalysisFlow";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import CampaignQueryChatSideBar from "../CampaignQueryChat/componenets/CampaignQueryChatSideBar/CampaignQueryChatSideBar";

interface Props {
  children?: React.ReactNode;
  operation: "analysis" | "content" | "connecting-data-sources";
}

const CampaignBuilderLayout: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { selectedCampaign, campaigns } = useAppSelector(
    (state) => state.campaignData
  );

  useEffect(() => {
    dispatch(setSelectedCampaign(""));
  }, []);

  return (
    <ProtectedRoleRoute
      requiredRole={[UserRoleType.MARKETING]}
      className={styles["campaign-builder-layout"]}
    >
      <div className={styles["container"]}>
        <CampaignHeader className={styles["header"]} />
        <div className={styles["content"]}>
          <CampaignQueryChatSideBar />
          <CampaignAnalysisFlow showWhichOperation={props.operation} />
          <CampaignBuilder
            showWhichOperation={
              props.operation === "analysis" ? "analysis" : "content"
            }
            open={open}
            onClose={() => setOpen(!open)}
          />
        </div>
      </div>
    </ProtectedRoleRoute>
  );
};

export default CampaignBuilderLayout;
