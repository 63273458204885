import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Modal from "../Modal/Modal";

import styles from "./AddApproverCommentModal.module.scss";
import { on } from "events";
import { Dialog } from "@headlessui/react";

interface Props {
  className?: string;
  isOpen: boolean;
  closeModal: () => void;
  onOk: (comment: string, style: React.CSSProperties) => void;
  style?: React.CSSProperties;
}

const AddApproverCommentModal: React.FC<Props> = (props) => {
  const { className, closeModal, isOpen, onOk, style } = props;
  const [commentInput, setCommentInput] = useState("");


  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onOk(commentInput, style || {});
        setCommentInput("");
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [style, commentInput]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className={classNames(styles["modal"], className)}
      style={style}
    >
      <Dialog.Panel className={classNames(styles["content"])}>
        <input
          placeholder="Add a comment"
          value={commentInput}
          onChange={(e) => setCommentInput(e.target.value)}
        />
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            onOk(commentInput, style || {});
            setCommentInput("");
          }}
        >
          <path
            d="M15 21.0005C15.425 21.0005 15.7815 20.8565 16.0695 20.5685C16.3565 20.2815 16.5 19.9255 16.5 19.5005V14.7005L17.85 16.0505C18.125 16.3255 18.475 16.463 18.9 16.463C19.325 16.463 19.675 16.3255 19.95 16.0505C20.225 15.7755 20.3625 15.4255 20.3625 15.0005C20.3625 14.5755 20.225 14.2255 19.95 13.9505L16.05 10.0505C15.9 9.90049 15.7375 9.79399 15.5625 9.73099C15.3875 9.66899 15.2 9.63799 15 9.63799C14.8 9.63799 14.6125 9.66899 14.4375 9.73099C14.2625 9.79399 14.1 9.90049 13.95 10.0505L10.05 13.9505C9.775 14.2255 9.6375 14.5755 9.6375 15.0005C9.6375 15.4255 9.775 15.7755 10.05 16.0505C10.325 16.3255 10.675 16.463 11.1 16.463C11.525 16.463 11.875 16.3255 12.15 16.0505L13.5 14.7005V19.5005C13.5 19.9255 13.644 20.2815 13.932 20.5685C14.219 20.8565 14.575 21.0005 15 21.0005ZM15 30.0005C12.925 30.0005 10.975 29.6065 9.15 28.8185C7.325 28.0315 5.7375 26.963 4.3875 25.613C3.0375 24.263 1.969 22.6755 1.182 20.8505C0.394 19.0255 0 17.0755 0 15.0005C0 12.9255 0.394 10.9755 1.182 9.15049C1.969 7.32549 3.0375 5.73799 4.3875 4.38799C5.7375 3.03799 7.325 1.96899 9.15 1.18099C10.975 0.393988 12.925 0.000488281 15 0.000488281C17.075 0.000488281 19.025 0.393988 20.85 1.18099C22.675 1.96899 24.2625 3.03799 25.6125 4.38799C26.9625 5.73799 28.031 7.32549 28.818 9.15049C29.606 10.9755 30 12.9255 30 15.0005C30 17.0755 29.606 19.0255 28.818 20.8505C28.031 22.6755 26.9625 24.263 25.6125 25.613C24.2625 26.963 22.675 28.0315 20.85 28.8185C19.025 29.6065 17.075 30.0005 15 30.0005Z"
            fill="#778BA8"
          />
        </svg>
      </Dialog.Panel>
    </Dialog>
  );
};

export default AddApproverCommentModal;
