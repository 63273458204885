import React from "react";

import styles from "./Header.module.scss";
import classNames from "classnames";

interface HeaderProps {
  className?: string;
  titleContent?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { titleContent, className } = props;
  return (
    <header className={classNames(styles["header"], className)}>
      {titleContent}
    </header>
  );
};

export default Header;
