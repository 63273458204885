import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

const initialState = {};

const httpService = new HttpService();

export const fetchMarketingBriefs = createAsyncThunk(
  "marketingBrief/fetchMarketingBriefs",
  async (): Promise<any[]> => {
    const response = await httpService.get("marketing-api/get-marketing-brief");
    return response.data as any;
  }
)

const marketingBriefSlice = createSlice({
  name: "marketingBrief",
  initialState,
  reducers: {
    setSelectedMarketingBriefId: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarketingBriefs.fulfilled, (state, action) => {});
  },
})

export const { setSelectedMarketingBriefId } = marketingBriefSlice.actions;
export default marketingBriefSlice.reducer;