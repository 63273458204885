import React, { InputHTMLAttributes } from "react";
import { IoSearchOutline } from "react-icons/io5";
import classNames from "classnames";

import styles from "./SearchInput.module.scss";

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <div className={styles["container"]}>
      <input
        className={classNames(styles["input"], className)}
        type="text"
        {...restProps}
      />
      <IoSearchOutline className={styles["icon"]} />
    </div>
  );
};

export default SearchInput;
