import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InterestStageState {
  nurtureStrategyData: {
    customerTestimonialsDistribution: boolean;
    educationalContentDistribution: boolean;
    overallExpectedResults: boolean;
    productDemoInvitation: boolean;
  };
}

const initialState = {
  nurtureStrategyData: {
    customerTestimonialsDistribution: false,
    educationalContentDistribution: false,
    overallExpectedResults: false,
    productDemoInvitation: false,
  },
};

const interestStageSlice = createSlice({
  name: "interestStageSlice",
  initialState,
  reducers: {
    setNurtureStrategyData: (
      state,
      action: PayloadAction<InterestStageState["nurtureStrategyData"]>
    ) => {
      state.nurtureStrategyData = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setNurtureStrategyData } = interestStageSlice.actions;

export default interestStageSlice.reducer;
