import React, { useEffect, useState } from "react";

import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import TargetAudienceIcon from "../../icons/TargetAudienceIcon";

import styles from "./TargetAudienceAndEstimatedNumbersCard.module.scss";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";

interface TargetAudienceAndEstimatedNumbersCardProps {
  className?: string;
  cardType: CardType;
}

const TargetAudienceAndEstimatedNumbersCard: React.FC<
  TargetAudienceAndEstimatedNumbersCardProps
> = (props) => {
  const { className, cardType } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const { loadedStateForAnalysis } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const { audienceSegments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const [aggregatedNumbers, setAggregatedNumbers] = useState({
    totalNumberOfCompanies: 0,
    companiesUsingSalesforce: 0,
    companiesHiring: 0,
    averageRelevance: 0,
  });

  useEffect(() => {
    if (audienceSegments) {
      const updateAggregatedNumbers = {
        totalNumberOfCompanies: 0,
        companiesUsingSalesforce: 0,
        companiesHiring: 0,
        totalRelevance: 0,
      };

      audienceSegments?.forEach((segment) => {
        const {
          "Number of All Companies": companiesLength,
          "Number of Companies Using Salesforce": salesForceUsers,
          "Number of Companies Hiring for Salesforce Related Positions":
            hiringPositions,
          Relevance: relevance,
        } = segment;

        // Accumulate the totals
        updateAggregatedNumbers.totalNumberOfCompanies +=
          parseInt(companiesLength);
        updateAggregatedNumbers.companiesUsingSalesforce +=
          parseInt(salesForceUsers);
        updateAggregatedNumbers.companiesHiring += parseInt(hiringPositions);

        // Convert relevance to a number and accumulate it
        updateAggregatedNumbers.totalRelevance += parseFloat(relevance);
        console.log(updateAggregatedNumbers);
      });

      // Calculate average relevance
      const averageRelevance =
        updateAggregatedNumbers.totalRelevance / audienceSegments.length;

      // Update the aggregated numbers state or use them as needed
      const finalAggregatedNumbers = {
        totalNumberOfCompanies: updateAggregatedNumbers.totalNumberOfCompanies,
        companiesUsingSalesforce:
          updateAggregatedNumbers.companiesUsingSalesforce,
        companiesHiring: updateAggregatedNumbers.companiesHiring,
        averageRelevance: averageRelevance,
      };
      setAggregatedNumbers(finalAggregatedNumbers);
    }
  }, [audienceSegments]);

  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              targetAudienceAndEstimatedNumbers: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      type={cardType}
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
    >
      <TextElement className={styles["heading"]}>
        Target Audience and Estimated Numbers
      </TextElement>
      <div className={styles["audience-info"]}>
        <TargetAudienceIcon />
        <ul>
          <li>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>Total Companies : </span>
              {aggregatedNumbers?.totalNumberOfCompanies}
            </TextElement>
          </li>
          <li>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>
                Companies using SalesForce product:{" "}
              </span>
              {aggregatedNumbers?.companiesUsingSalesforce}
            </TextElement>
          </li>
          <li>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>
                Companies Hiring for Salesforce :{" "}
              </span>
              {aggregatedNumbers?.companiesHiring}
            </TextElement>
          </li>
          <li>
            <TextElement className={styles["text"]}>
              <span className={styles["heading"]}>Relevance : </span>
              {aggregatedNumbers?.averageRelevance}%
            </TextElement>
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default TargetAudienceAndEstimatedNumbersCard;
