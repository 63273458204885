import React from "react";

import styles from "./File.module.scss";
import classNames from "classnames";
import { Menu } from "@headlessui/react";
import TextElement from "../TextElement/TextElement";
import PdfIcon from "../../icons/PdfIcon";
import { MdBrokenImage } from "react-icons/md";

interface FileProps {
  className?: string;
  onFileInfo?: () => void;
  fileUrl: string;
  fileName: string;
}

const File: React.FC<FileProps> = (props) => {
  const { className, onFileInfo, fileName, fileUrl } = props;
  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <PdfIcon className={styles["icon"]} />
        <TextElement className={styles["name"]}>{fileName}</TextElement>
        <Menu>
          <Menu.Button
            className={classNames(styles["ellipses"], className)}
            style={{
              transform: "rotate(90deg)",
              display: "inline-block",
            }}
          >
            ...
          </Menu.Button>
          <Menu.Items className={styles["items"]}>
            <Menu.Item>
              {({ active }) => (
                <button className={styles["item"]}>Rename</button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button className={styles["item"]}>Archive</button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button className={styles["item"]} onClick={onFileInfo}>
                  File Information
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button className={styles["item"]}>Delete</button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
      <img src={fileUrl} alt={fileName} />
    </div>
  );
};

export default File;
