import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import classNames from "classnames";

import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import styles from "./AddTeamMember.module.scss";
import InputField from "../../componenets/Input/input";
import { Button, Modal, TextElement } from "../../componenets";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Select from "../../componenets/Select/Select";
import { useAppDispatch } from "../../redux/hooks";
import { addTeamMember } from "../../redux/teamMemberSlice";
import { UserRoleType } from "../../redux/authSlice";

type AddTeamMemberProps = {};

const AddTeamMember: React.FC<AddTeamMemberProps> = () => {
  const navigate = useNavigate();

  const [messageBoxVisibility, setMessageBoxVisibility] = useState(false);
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
  });

  const handleClickBackMyTeam = () => {
    navigate(ROUTE_DEFINITIONS.MY_TEAM_MEMBER.path);
  };

  const handleCloseMessageBox = () => {
    setMessageBoxVisibility(false);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      addTeamMember({
        name: formData.name,
        email: formData.email,
        role: formData.role as UserRoleType,
      })
    ).then((result)=>{
      if(result.payload){
        setMessageBoxVisibility(true);
      }
    })
    // setMessageBoxVisibility(true);  
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  return (
    <CMOViewLayout
      header={
        <>
          <a
            className={styles["back-to-my-team-link"]}
            onClick={handleClickBackMyTeam}
            href="#"
          >
            Back to My Team
          </a>

          <TextElement className={styles["header-text"]}>
            Add Team Member
          </TextElement>
        </>
      }
    >
      <Modal
        isOpen={messageBoxVisibility}
        closeModal={handleCloseMessageBox}
        className={styles["modal"]}
      >
        <h1>
          <div className={styles["modal-header"]}>
            <FaCheckCircle />
            New team member added!
          </div>

          <RxCross1
            className={styles["modal-close"]}
            onClick={handleCloseMessageBox}
          />
        </h1>

        <div className={styles["modal-message"]}>
          {formData.name} has been added to your team successfully. They should
          receive an email with their username and password for login.
        </div>

        <Button
          className={styles["back-to-my-team-btn"]}
          onClick={handleClickBackMyTeam}
        >
          Back to My Team 👉🏻
        </Button>
      </Modal>

      <div className={styles["form-container"]}>
        <form className={styles["form"]} onSubmit={handleFormSubmit}>
          <div className={classNames(styles["element-group"])}>
            <InputField
              className={classNames(styles["input-half"])}
              label="Name"
              placeholder="Name of the new team member"
              value={formData.name}
              onChange={(e) => handleChangeInput(e, "name")}
              required
            />
            <InputField
              className={classNames(styles["input-half"])}
              label="Email Address"
              type="email"
              placeholder="Email address of the new team member"
              value={formData.email}
              onChange={(e) => handleChangeInput(e, "email")}
              required
            />
          </div>

          <div className={classNames(styles["element-group"], styles['select-group'])}>
            <TextElement className={styles['select-role']}>Role</TextElement>
            <Select
              value={formData.role}
              onChange={(value) => {
                setFormData({ ...formData, role: value as string });
              }}
              width="100%"
              placeholderText="Select Role"
              className={styles["select-role"]}
              options={[
                { label: "Marketer", value: UserRoleType.MARKETING },
                { label: "Primary Approver", value: UserRoleType.PRIMARY_APPROVER },
                { label: "Secondary Approver", value: UserRoleType.SECONDARY_APPROVER },
              ]}
            />
          </div>

          <Button className={styles["submit"]}>Save</Button>
        </form>
      </div>
    </CMOViewLayout>
  );
};

export default AddTeamMember;
