import React from "react";
import Button from "../../Button/Button";
import TextElement from "../../TextElement/TextElement";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateCampaign } from "../../../redux/campaignPinningSlice";
import useCampaignQueryChat from "../useCampaignQueryChat";

interface PinAllMarketSentimentsProps {
  className?: string;
}

const PinAllMarketSentiments: React.FC<PinAllMarketSentimentsProps> = ({
  className,
}) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const dispatch = useAppDispatch();
  const { marketSentiments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  return (
    <Button
      className={className}
      onClick={() => {
        selectedCampaign &&
          dispatch(
            updateCampaign({
              ...selectedCampaign,
              includedData: {
                ...selectedCampaign?.includedData,
                marketSentimentIds: marketSentiments.map(
                  (marketSentiment) => marketSentiment.id
                ),
              },
            })
          );
      }}
    >
      <TextElement>Pin all market sentiments</TextElement>
    </Button>
  );
};

export default PinAllMarketSentiments;
