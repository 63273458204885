import React from "react";

import styles from "./CustomerTestimonialsDistribution.module.scss";

import classNames from "classnames";
import Card, { CardType } from "../../../Card/Card";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TextElement from "../../../TextElement/TextElement";
import { setNurtureStrategyData } from "../../../../redux/interestStageSlice";

interface CustomerTestimonialsDistributionProps {
  className?: string;
  cardType: CardType;
}

const CustomerTestimonialsDistribution: React.FC<
  CustomerTestimonialsDistributionProps
> = (props) => {
  const { className, cardType } = props;

  const dispatch = useAppDispatch();
  const { nurtureStrategyData } = useAppSelector(
    (state) => state.interestStage
  );

  const handlePinned = (isPinned: boolean) => {
    nurtureStrategyData &&
      dispatch(
        setNurtureStrategyData({
          ...nurtureStrategyData,
          customerTestimonialsDistribution: isPinned,
        })
      );
  };

  return (
    <Card
      type={cardType}
      onPin={() => handlePinned(true)}
      unPin={() => handlePinned(false)}
      className={classNames(styles["container"], className)}
    >
      <div>
        <TextElement className={styles["heading"]}>
          Week 5-6: Customer Testimonials Distribution
        </TextElement>
        <TextElement className={styles["sub-heading-text"]}>
          Action: Distribute Content
        </TextElement>
      </div>
      <div className={styles["content-container"]}>
        <TextElement className={styles["content-heading"]}>Topics</TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>"Case Study:</span>{" "}
              Successful 5G Rollout for Urban Areas": Detailed story of a
              client's success with our 5G deployment, highlighting the
              challenges faced, solutions implemented, and the positive impact
              on their operations.
            </li>
            <li>
              <span className={styles["highlight"]}>"Customer Story:</span>{" "}
              Enhancing Network Efficiency with IoT": A testimonial from a
              satisfied customer who improved their network management through
              our IoT solutions, showcasing the ROI and operational efficiencies
              gained.
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Channels
        </TextElement>
        <div className={styles["content"]}>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>Email</TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>Subject Line:</span> "See
                How Our Clients Are Succeeding with 5G"
              </li>
              <li>
                <span className={styles["highlight"]}>Body:</span>
                "Dear [Lead Name], discover how our clients are achieving
                success with our 5G and IoT solutions. Read our latest case
                studies and learn how you can benefit too. [Read Case Study]"
                <span className={styles["sub-heading-text"]}>[Read More]</span>"
              </li>
              <li>
                <span className={styles["highlight"]}>Call to Action:</span>
                "Interested in similar results? Let's discuss how we can help.
                [Contact Us]"
                <span className={styles["sub-heading-text"]}>
                  [Download Now]
                </span>
                "
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Website Content
            </TextElement>
            <ul>
              <li>
                Feature these case studies on the homepage and relevant service
                pages to build credibility and trust.
              </li>
            </ul>
          </div>
          <div className={styles["ul-with-heading"]}>
            <TextElement className={styles["highlight"]}>
              Social Media
            </TextElement>
            <ul>
              <li>
                <span className={styles["highlight"]}>LinkedIn Post:</span>
                "Our 5G solutions are transforming businesses. Read our latest
                success story. [Read Now] "
              </li>
              <li>
                <span className={styles["highlight"]}>Twitter Post:</span>
                "See how IoT is making a difference in network management.
                #TelecomSuccess [Read Story]" "
              </li>
            </ul>
          </div>
        </div>
        <TextElement className={styles["content-heading"]}>
          Metrics to Track
        </TextElement>
        <div className={styles["content"]}>
          <ul>
            <li>
              <span className={styles["highlight"]}>
                Email engagement (open rates, CTR):
              </span>{" "}
              Target 25%
            </li>
            <li>
              <span className={styles["highlight"]}>
                Website metrics (time on page, bounce rate):
              </span>{" "}
              Target 30% increase in engagement time
            </li>
            <li>
              <span className={styles["highlight"]}>
                Social media metrics (engagement, shares):
              </span>{" "}
              Target 20% increase
            </li>
          </ul>
        </div>
        <TextElement className={styles["content-heading"]}>
          Expected Results
        </TextElement>
        <div className={styles["content"]}>
          <TextElement>
            Improve SQL conversion rates by 30% by showcasing real-world
            applications and ROI, increasing trust and credibility.
          </TextElement>
        </div>
      </div>
    </Card>
  );
};

export default CustomerTestimonialsDistribution;
