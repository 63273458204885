export const formatNumberWithCommas = (num: number): string => {
  if (typeof num !== "number") return num;

  if (!num) return "0";
  const numString = num.toString();

  const groups = numString.split(/(?=(?:\d{3})+(?!\d))/);

  return groups.join(",");
};

export const formatNumber = (value: number): string => {
  if (value < 1000) {
    return value.toFixed(1);
  } else if (value < 1000000) {
    return (value / 1000).toFixed(1) + "K";
  } else if (value < 1000000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value < 1000000000000) {
    return (value / 1000000000).toFixed(1) + "B";
  } else {
    return (value / 1000000000000).toFixed(1) + "T";
  }
};

export const transformTotalNumber = (num: number): number => {
  if (num >= 1 && num <= 50) {
    return (Math.floor(Math.random() * 100) + 51) * 7; // Return a number between 51 to 100
  } else if (num > 50 && num <= 100) {
    return (Math.floor(Math.random() * 50) + 1) * 234; // Return a number between 1 to 50
  } else {
    throw new Error("Number must be between 1 and 100");
  }
};

export function checkPasswordStrength(password: string): string {
  const lengthRegex = /.{8,}/;
  const letterRegex = /[A-Za-z]/;
  const numberRegex = /\d/;
  const punctuationRegex = /[^\w\s]/;

  if (!lengthRegex.test(password)) {
    return "Password must be at least 8 characters long.";
  }
  if (!letterRegex.test(password)) {
    return "Password must include letters.";
  }
  if (!numberRegex.test(password)) {
    return "Password must include numbers.";
  }
  if (!punctuationRegex.test(password)) {
    return "Password must include punctuation marks.";
  }

  return "";
}

export function isObject(value: any): value is object {
  return value !== null && typeof value === "object";
}

export function isHttpResponseError(
  error: any
): error is { status: number; data: any } {
  return error && typeof error.status === "number" && isObject(error.data);
}

