import { useEffect, useState } from "react";
import axios from "axios";
import HttpService from "../../../../lib/api";

type CampaignsResponseType = {
  total: number;
  rows: {
    name: string;
  }[];
};

type FetchInsightsResponse = {
  response: string;
};

const KPI_LIST = [
  {
    title: "Lead Conversion Rate",
    key: "lead conversion rate",
    value: "4.1%",
  },
  {
    title: "Campaign ROI",
    key: "ROI",
    value: "8X",
  },
  {
    title: "Customer Acquisition Cost",
    key: "lead conversion rate",
    value: "$1,483.5",
  },
  {
    title: "Average Deal Size",
    key: "average deal size",
    value: "$68,445.1",
  },
  {
    title: "Opportunity Win Rate",
    key: "opportunity win rate",
    value: "8.9%",
  },
];

const useErikoChatQuery = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const abortController = new AbortController();
  const [fetchInsightsLoading, setFetchInsightsLoading] =
    useState<boolean>(false);
  const [fetchVisualizationsLoading, setFetchVisualizationsLoading] =
    useState<boolean>(false);

  const httpService = new HttpService();

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const campaignsData = (
        await httpService.get(
          "sql-api/dynamic/campaign/?page=1&pagesize=10&sort_by=id&sort_order=asc"
        )
      ).data as CampaignsResponseType;

      setLoading(false);
      return campaignsData?.rows;
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchKPI = async (key: string) => {
    const kpi = KPI_LIST.filter((kpi) => kpi.key === key);
    if (kpi.length) return kpi[0];
    return null;
  };

  const fetchInsights = async (query: string) => {
    try {
      setFetchInsightsLoading(true);
      setLoading(true);
      const response = await httpService.post(
        "model/get-recommendation/",
        {
          input: query,
        },
        {
          signal: (abortController as AbortController).signal,
        }
      );
      setLoading(false);
      setFetchInsightsLoading(false);
      return (response.data as FetchInsightsResponse)?.response;
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
      setFetchInsightsLoading(false);
    }
  };

  const fetchVisualizations = async (query: string) => {
    return "/image3.png";
  };

  return {
    loading,
    error,
    fetchInsights,
    fetchVisualizations,
    fetchKPI,
    fetchInsightsLoading,
    fetchVisualizationsLoading,
    fetchCampaigns,
    setLoading,
    KPI_LIST,
  };
};

export default useErikoChatQuery;
