import React from "react";

import styles from "./Ellipses.module.scss";
import classNames from "classnames";
import { Menu } from "@headlessui/react";

interface EllipsesProps {
  className?: string;
  onUnpin?: () => void;
  onEdit?: () => void;
}

const Ellipses: React.FC<EllipsesProps> = (props) => {
  const { className, onUnpin, onEdit } = props;
  return (
    <div className={styles["container"]}>
      <Menu>
        <Menu.Button className={classNames(styles["ellipses"], className)}>
          ...
        </Menu.Button>
        <Menu.Items className={styles["items"]}>
          <Menu.Item>
            {({ active }) => (
              <button className={styles["item"]} onClick={onUnpin}>
                Unpin
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default Ellipses;
