import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import HttpService from "../lib/api";

interface GenerateSegmentationInput {
  campaignPlanId: string;
}

export interface GenerateSegmentationResponse {
  segments: {
    [key: string]: string;
  }[];
}

interface FetchPastCampaignAnalysisInput {
  campaignPlanId: string;
  industry: string;
}

export interface FetchPastCampaignAnalysisResponse {
  analysis: {
    metric: string;
    value: string;
    reason: string;
    industry: string;
    geography: string;
  }[];
}

interface FetchBudgetAllocationForChannelInput {
  campaignPlanId: string;
}

interface BudgetAllocationForChannel {
  title: string;
  description: string;
  "budget allocation": {
    channel: string;
    spend: number;
    "projected ROI": number;
  }[];
}

const contentFieldsForGoogleAds = ["Ad a Title", "Description"] as const;
const contentFieldsForBlogPosts = [
  "Title",
  "Introduction",
  "Conclusion",
  "Call to Action",
] as const;
const contentFieldsForEmailMarketing = ["Subject", "Email Body"] as const;
const contentFieldsForFacebookAds = [
  "Ad a Headline",
  "Description",
  "Image Url",
] as const;

type GoogleAdsFields = (typeof contentFieldsForGoogleAds)[number];
type BlogPostsFields = (typeof contentFieldsForBlogPosts)[number];
type EmailMarketingFields = (typeof contentFieldsForEmailMarketing)[number];
type FacebookAdsFields = (typeof contentFieldsForFacebookAds)[number];

interface ContentGenerationInput {
  marketSentiments: string;
  companyName: string;
  shortCompanyDescription: string;
  marketingGoal: string;
  contentToGenerate: string;
  fieldsList:
    | GoogleAdsFields[]
    | BlogPostsFields[]
    | EmailMarketingFields[]
    | FacebookAdsFields[];
}

type ContentFields =
  | GoogleAdsFields
  | BlogPostsFields
  | EmailMarketingFields
  | FacebookAdsFields;

type ContentOption = {
  [K in ContentFields]?: string;
};

interface ContentGenerationResponse {
  content: {
    "Option 1": ContentOption;
    "Option 2": ContentOption;
    "Option 3": ContentOption;
  };
}

interface FetchBudgetAllocationForChannelResponse {
  "Marketing channels": BudgetAllocationForChannel[];
  "Anticipated ROI": number;
  "Total marketing budget": number;
  "Expected return": number;
  recommended_product_offerings: {
    [key: string]: string;
  };
  marketing_channels_with_reason: {
    [key: string]: string;
  };
  execution_plans: {
    id: string;
    theme: string;
    campaign_id: string;
    channel_id: string;
    content_type_id: string;
    start_date: string;
    end_date: string;
    number_of_posts_per_day: number;
    days_of_the_week: string[];
    number_of_weeks: number;
    number_of_posts: number;
  }[];
}

interface FetchMarketSentimentsInput {
  campaignPlanId: string;
  industry: string;
}

export interface FetchMarketSentimentsResponse {
  sentiments: {
    topic: string;
    purposes: string;
    relevance: string;
    information: string;
  }[];
}

interface FetchCompetitorsInput {
  companyName: string;
  industry: string;
  campaignPlanId: string;
}

interface FetchCompetitorResponse {
  analysises: Competitor[];
}

export interface Competitor {
  offerings: string;
  competitorName: string;
  howToBeat: string;
  marketing: string;
}

interface StateType {
  audienceSegments: {
    [key: string]: string;
  }[];
  pastCampaignAnalysis: {
    [key: string]: string;
  }[];
  recommendation: {
    budgetAllocations: BudgetAllocationForChannel[];
    anticipatedROI: number;
    totalMarketingBudget: number;
    expectedReturn: number;
    recommendedProductOfferings: {
      [key: string]: string;
    };
    marketingChannelsWithReason: {
      [key: string]: string;
    };
    execution_plans: {
      id: string;
      theme: string;
      campaign_id: string;
      channel_id: string;
      content_type_id: string;
      start_date: string;
      end_date: string;
      number_of_posts_per_day: number;
      days_of_the_week: string[];
      number_of_weeks: number;
      number_of_posts: number;
    }[];
  };
  contentForGoogleAds: ContentGenerationResponse;
  contentForBlogPosts: ContentGenerationResponse;
  contentForEmailMarketing: ContentGenerationResponse;
  contentForFacebookAds: ContentGenerationResponse;

  marketSentiments: {
    id: number;
    topic: string;
    purposes: string;
    relevance: string;
    information: string;
  }[];
  loading?: boolean;
  error?: string | null;
  competitors: Competitor[];
  loadedStateForAnalysis: {
    audienceSegments: boolean;
    pastCampaignAnalysis: boolean;
    recommendation: boolean;
    competitors: boolean;
    marketSentiments: boolean;
  };
}

const initialState: StateType = {
  audienceSegments: [],
  loading: false,
  error: null,
  pastCampaignAnalysis: [],
  marketSentiments: [],
  recommendation: {
    budgetAllocations: [],
    anticipatedROI: 0,
    totalMarketingBudget: 0,
    expectedReturn: 0,
    recommendedProductOfferings: {},
    marketingChannelsWithReason: {},
    execution_plans: [],
  },
  competitors: [],
  contentForGoogleAds: {
    content: {
      "Option 1": {},
      "Option 2": {},
      "Option 3": {},
    },
  },
  contentForBlogPosts: {
    content: {
      "Option 1": {},
      "Option 2": {},
      "Option 3": {},
    },
  },
  contentForEmailMarketing: {
    content: {
      "Option 1": {},
      "Option 2": {},
      "Option 3": {},
    },
  },
  contentForFacebookAds: {
    content: {
      "Option 1": {},
      "Option 2": {},
      "Option 3": {},
    },
  },
  loadedStateForAnalysis: {
    audienceSegments: false,
    pastCampaignAnalysis: false,
    recommendation: false,
    competitors: false,
    marketSentiments: false,
  },
};

export const fetchCompetitors = createAsyncThunk<
  FetchCompetitorResponse,
  FetchCompetitorsInput
>("campaignAnalysisQueryChat/fetchCompetitors", async (input) => {
  try {
    const httpService = new HttpService(process.env.REACT_APP_PGRAG_API_URL);
    const response = await httpService.post<
      FetchCompetitorResponse,
      FetchCompetitorsInput
    >("/industry_competitor_analysis", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchBudgetAllocationForDifferentChannels = createAsyncThunk<
  FetchBudgetAllocationForChannelResponse,
  FetchBudgetAllocationForChannelInput
>(
  "campaignAnalysisQueryChat/fetchBudgetAllocationForDifferentChannels",
  async (input) => {
    try {
      const httpService = new HttpService(
        process.env.REACT_APP_RECOMMENDATION_API_URL
      );
      const response = await httpService.post<
        FetchBudgetAllocationForChannelResponse,
        FetchBudgetAllocationForChannelInput
      >("/recommendation_plan/", input);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPastCampaignAnalysis = createAsyncThunk<
  FetchPastCampaignAnalysisResponse,
  FetchPastCampaignAnalysisInput
>("campaignAnalysisQueryChat/fetchPastCampaignAnalysis", async (input) => {
  try {
    const httpService = new HttpService(
      process.env.REACT_APP_PAST_CAMPAIGN_ANALYSIS_API_URL
    );
    const response = await httpService.post<
      FetchPastCampaignAnalysisResponse,
      FetchPastCampaignAnalysisInput
    >("/past_campaign_analysis", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchSegments = createAsyncThunk<
  GenerateSegmentationResponse,
  GenerateSegmentationInput
>("campaignAnalysisQueryChat/fetchSegments", async (input) => {
  try {
    const httpService = new HttpService(
      process.env.REACT_APP_SEGMNETATION_API_URL
    );
    const response = await httpService.post<
      GenerateSegmentationResponse,
      GenerateSegmentationInput
    >("/generate_segments/", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchMarketingContent = createAsyncThunk(
  "campaignAnalysisQueryChat/fetchContent",
  async () => {
    const input: ContentGenerationInput = {
      marketSentiments:
        "Recent trends in the IT industry have generated considerable interest, reflecting a mix of enthusiasm and caution.Positive Sentiments:Technological Innovation: Infotech IT is at the forefront of driving advancements in areas such as cloud computing, AI, and cybersecurity. The company is dedicated to providing innovative solutions that empower businesses to stay ahead in a rapidly evolving digital landscape.Economic Growth: As digital transformation accelerates across industries, Infotech IT anticipates a surge in demand for its services. The company is well-positioned to capitalize on this growth, offering tailored solutions that address the unique challenges of various sectors.Negative Sentiments:Security Concerns: With the increasing reliance on digital platforms, there are heightened concerns about data privacy and cybersecurity. Infotech IT is committed to delivering robust security solutions that safeguard clients’ sensitive information and ensure compliance with global standards.Cost Management: The rapid pace of technological change presents challenges in terms of managing costs and resources. Infotech IT views these challenges as opportunities to innovate, streamline operations, and deliver cost-effective solutions without compromising on quality.Overall: Infotech IT is optimistic about the future of the IT industry and is well-prepared to navigate the complexities of the digital era, offering cutting-edge solutions that drive growth and success for its clients.",
      companyName: "Infotech",
      shortCompanyDescription: "B2B IT company",
      marketingGoal: "generate new leads",
      contentToGenerate: "Content Marketing (Blog Post)",
      fieldsList: ["Title", "Introduction", "Conclusion", "Call to Action"],
    };
    try {
      const httpService = new HttpService(
        // "http://0.0.0.0:8005/"
        process.env.REACT_APP_CONTENT_API_URL
      );
      const promises = [
        httpService.post<ContentGenerationResponse, ContentGenerationInput>(
          "generate_content",
          {
            ...input,
            contentToGenerate: "Google Ads",
            fieldsList: ["Ad a Title", "Description"],
          }
        ),
        httpService.post<ContentGenerationResponse, ContentGenerationInput>(
          "generate_content",
          {
            ...input,
            contentToGenerate: "Blog Posts",
            fieldsList: [
              "Title",
              "Introduction",
              "Conclusion",
              "Call to Action",
            ],
          }
        ),
        httpService.post<ContentGenerationResponse, ContentGenerationInput>(
          "generate_content",
          {
            ...input,
            contentToGenerate: "Email Marketing",
            fieldsList: ["Subject", "Email Body"],
          }
        ),
        httpService.post<ContentGenerationResponse, ContentGenerationInput>(
          "generate_content",
          {
            ...input,
            contentToGenerate: "Facebook Ads",
            fieldsList: ["Ad a Headline", "Description", "Image Url"],
          }
        ),
      ];

      // Execute all the API calls in parallel
      const [
        contentForGoogleAds,
        contentForBlogPosts,
        contentForEmailMarketing,
        contentForFacebookAds,
      ] = await Promise.all(promises);

      // Return the combined content
      return {
        contentForGoogleAds: contentForGoogleAds.data,
        contentForBlogPosts: contentForBlogPosts.data,
        contentForEmailMarketing: contentForEmailMarketing.data,
        contentForFacebookAds: contentForFacebookAds.data,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMarketSentiments = createAsyncThunk<
  FetchMarketSentimentsResponse,
  FetchMarketSentimentsInput
>("campaignAnalysisQueryChat/fetchMarketSentiments", async (input) => {
  try {
    const httpService = new HttpService(process.env.REACT_APP_PGRAG_API_URL);
    const response = await httpService.post<
      FetchMarketSentimentsResponse,
      FetchMarketSentimentsInput
    >("market_sentiments", input);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const campaignAnalysisQueryChatSlice = createSlice({
  name: "campaignAnalysisQueryChat",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLoadedStateForAnalysis: (
      state,
      action: PayloadAction<{
        audienceSegments: boolean;
        pastCampaignAnalysis: boolean;
        recommendation: boolean;
        competitors: boolean;
        marketSentiments: boolean;
      }>
    ) => {
      state.loadedStateForAnalysis = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Segments
    builder
      .addCase(fetchSegments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.loading = false;
        state.audienceSegments = action.payload.segments;
      })
      .addCase(fetchSegments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching audience segments");
      });
    // fetch past campaign analaysis

    builder
      .addCase(fetchPastCampaignAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPastCampaignAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.pastCampaignAnalysis = action.payload.analysis;
      })
      .addCase(fetchPastCampaignAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching past campaign analysis");
      });

    // fetch budget allocation for different channels
    builder
      .addCase(fetchBudgetAllocationForDifferentChannels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBudgetAllocationForDifferentChannels.fulfilled,
        (state, action) => {
          state.loading = false;
          const {
            "Marketing channels": budgetAllocations,
            "Anticipated ROI": anticipatedROI,
            "Total marketing budget": totalMarketingBudget,
            "Expected return": expectedReturn,
            recommended_product_offerings,
            marketing_channels_with_reason,
            execution_plans,
          } = action.payload;
          state.recommendation = {
            budgetAllocations,
            anticipatedROI,
            totalMarketingBudget,
            expectedReturn,
            marketingChannelsWithReason: marketing_channels_with_reason,
            recommendedProductOfferings: recommended_product_offerings,
            execution_plans,
          };
        }
      )
      .addCase(
        fetchBudgetAllocationForDifferentChannels.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          console.error("Error fetching budget allocation", action.error);
          throw new Error("Error fetching budget allocation");
        }
      );

    // fetch marketing content
    builder
      .addCase(fetchMarketingContent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMarketingContent.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action.payload", action.payload);
        state.contentForGoogleAds = action.payload.contentForGoogleAds;
        state.contentForBlogPosts = action.payload.contentForBlogPosts;
        state.contentForEmailMarketing =
          action.payload.contentForEmailMarketing;
        state.contentForFacebookAds = action.payload.contentForFacebookAds;
      })
      .addCase(fetchMarketingContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching marketing content");
      });

    // fetching market sentiments
    builder
      .addCase(fetchMarketSentiments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMarketSentiments.fulfilled, (state, action) => {
        state.loading = false;
        state.marketSentiments = action.payload.sentiments.map(
          (key, index) => ({
            id: index + 1,
            ...key,
          })
        );
      })
      .addCase(fetchMarketSentiments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching market sentiments");
      });

    // fetching competitors
    builder
      .addCase(fetchCompetitors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompetitors.fulfilled, (state, action) => {
        state.loading = false;
        state.competitors = action.payload.analysises;
      })
      .addCase(fetchCompetitors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        throw new Error("Error fetching competitors");
      });
  },
});

export const { setLoading, updateLoadedStateForAnalysis } =
  campaignAnalysisQueryChatSlice.actions;

export default campaignAnalysisQueryChatSlice.reducer;
