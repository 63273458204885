import React, { useRef, useState } from "react";

import { Button } from "../../componenets";
import styles from "./OneTimePin.module.scss";
import { useAppSelector } from "../../redux/hooks";

interface OneTimePinProps {
  onPinSubmit: (pin: string) => void;
}

const OneTimePin: React.FC<OneTimePinProps> = ({ onPinSubmit }) => {
  const [pin, setPin] = useState<(number | null)[]>(Array(6).fill(null));

  const [pinProvided, setPinProvided] = useState(false);

  const pinDigitInputRef = useRef<(HTMLInputElement | null)[]>([]);
  const { authError } = useAppSelector((state) => state.auth);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();

    const strPin = pin.join("");
    const isPinProvided = strPin.trim().length === 6;

    if (!isPinProvided) {
      // Show error => You need to fill PIN digit inputs
      return;
    }

    onPinSubmit(strPin);
  };

  const handlePinDigitKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const oldPinDigit = pin[index];
    const newPinDigit = e.key;

    const deleteFound = ["Backspace", "Delete"].includes(newPinDigit);

    let newPin = [...pin];

    if (Number(newPinDigit) >= 0 || deleteFound) {
      newPin[index] = deleteFound ? null : Number(newPinDigit);

      setPin(newPin);

      if (index < 5 && !deleteFound) {
        pinDigitInputRef.current[index + 1]?.focus();
      }

      if (oldPinDigit === null && deleteFound && index > 0) {
        pinDigitInputRef.current[index - 1]?.focus();
      }
    }

    const strPin = newPin.join("");
    const isPinProvided = strPin.trim().length === 6;

    setPinProvided(isPinProvided);
  };

  return (
    <div className={styles["form"]}>
      <h1 className={styles["pin-title"]}>One-Time PIN</h1>

      <div className={styles["pin-body"]}>
        To continue with your log in, we have sent a one-time PIN to your
        registered email address. Please input in the fields below. 👇🏻
      </div>
      <span className={styles["error"]}>{authError || ""}</span>

      <div className={styles["pin-digit-input"]}>
        {Array.from(Array(6).keys()).map((pinIndex) => (
          <input
            key={pinIndex}
            type="text"
            ref={(input) => (pinDigitInputRef.current[pinIndex] = input)}
            maxLength={1}
            value={pin[pinIndex] ?? ""}
            onKeyDown={(e) => handlePinDigitKeyDown(e, pinIndex)}
            onChange={() => {
              /** Do nothing! */
            }}
          />
        ))}
      </div>

      <Button
        className={styles["submit"]}
        onClick={handleSubmitForm}
        disabled={!pinProvided}
      >
        Validate
      </Button>
    </div>
  );
};

export default OneTimePin;
