import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const headers = {
  "X-ACCESS-KEY": "f170ad04-4789-47be-87a8-78d165b36862", // API access key
  "Content-Type": "application/json", // Content-Type header
};

interface Channel {
  id: string;
  channelName: string;
  platform: string;
  isEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  channelTypeId: string;
  createdById: string;
  updatedById: string;
}

interface ContentType {
  id: string;
  name: string;
}

const dummyChannels: Channel[] = [
  {
    id: "0d39275a-37d2-494a-a7fe-82add2e08385",
    channelName: "LinkedIn Posts",
    platform: "linkedIn-api",
    isEnabled: true,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "f08703b6-a426-48ba-a589-732624812d09",
    channelName: "Website Posts",
    platform: "website",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "b114eb77-79fd-4d84-b345-eac113559799",
    channelName: "Facebook Posts",
    platform: "facebook-graph-api",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "ca05bea5-f037-44bb-9f22-183d86462cf4",
    channelName: "Email",
    platform: "azure-email-action",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "6abaf224-0a25-42a6-bd8f-e875cb672451",
    channelName: "Whatsapp",
    platform: "whatsapp-business-app",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "e9dabfea-02ce-40d0-9d35-06bcd5e05367",
    channelName: "SMS",
    platform: "twilio",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "f2d46c28-6595-4701-a26b-b8330d8a3cfe",
    channelName: "Google Posts",
    platform: "google-business-profile",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "4c6f7846-dbbd-439a-bb17-202b0aec2065",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "315c9397-d27d-4410-9c1b-087deb13f8ec",
    channelName: "Google Ads",
    platform: "google-ads",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "2ef1869f-5e9f-442c-a9fa-58320e2fadaa",
    channelName: "Facebook Ads",
    platform: "facebook-marketing-api",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "b0a4958a-75aa-4010-9230-30c4c3648cbf",
    channelName: "Email (Paid)",
    platform: "azure-email-action",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "cded3ddb-6113-4f47-b87b-0d43c33f027a",
    channelName: "Whatsapp (Paid)",
    platform: "whatsapp-business-api-via-twilio",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "ccc9ae11-e0e2-4208-87b3-67d55c065aeb",
    channelName: "SMS (Paid)",
    platform: "twilio",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
  {
    id: "aec2efe6-1c0e-4d46-aa52-028da2067343",
    channelName: "LinkedIn Ads",
    platform: "linkedIn-marketing-api",
    isEnabled: false,
    createdAt: "2024-09-07T10:30:00Z",
    updatedAt: "2024-09-07T10:30:00Z",
    channelTypeId: "d54a84ca-f642-4c19-9f09-62b20fd2684f",
    createdById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
    updatedById: "4c83e41b-d9e8-4c7f-b4d4-f8c9b8d40c38",
  },
];

const useContentDeliveryPlans = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [contentTypes, setContentTypes] = useState<ContentType[]>([]);

  const executeQuery = async (query: string) => {
    try {
      const response = await axios.post(
        "https://dev-api.eriko.ai/api/execute-query/",
        {
          query: query,
        },
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error executing query:", error);
      setError("Error fetching data");
      toast.error("error fetching data");
      //   throw error;
    }
  };

  const fetchChannels = async () => {
    setLoading(true);
    // const query = "select * from marketing_channel;";
    // const data = await executeQuery(query);
    setChannels(dummyChannels);
  };

  const fetchContentTypes = async () => {
    setLoading(true);
    // const query = "select * from marketing_content_type;";
    // const data = await executeQuery(query);
    setContentTypes([
      {
        id: "ffd21c4d-41cb-4866-8431-30879d418269",
        name: "text",
      },
      {
        id: "540ec025-ace8-48fb-9f04-432f40e08ceb",
        name: "single-media-post",
      },
    ]);
  };

  useEffect(() => {
    fetchChannels().finally(() => setLoading(false));
    fetchContentTypes().finally(() => setLoading(false));
  }, []);

  return { channels, contentTypes, loading, error };
};

export default useContentDeliveryPlans;
