import React from "react";

const SendButton: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.344455 20L23.6667 10L0.344455 0L0.333344 7.77778L17 10L0.333344 12.2222L0.344455 20Z"
        fill="white"
      />
    </svg>
  );
};

export default SendButton;
