import React from "react";

const InsightsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 18.4194L7.90306 10.5058L12.118 14.7208L19.589 6.32244L21.0748 7.80822L12.118 17.882L7.90306 13.667L1.58061 20L0 18.4194ZM1.58061 13.6776L7.90306 7.34457L12.118 11.5595L21.0748 1.48577L19.589 0L12.118 8.39831L7.90306 4.18335L0 12.0969L1.58061 13.6776Z"
        fill="#EB2701"
      />
    </svg>
  );
};

export default InsightsIcon;
