import React from "react";

import styles from "./MarketValueSteps.module.scss";
import DisplayResponse from "../../componenets/DisplayResponse/DisplayResponse";

import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import SuggestedPrompt from "./components/SuggestedPrompt/SuggestedPrompt";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import { TextElement } from "../../componenets";

const CreateValueStep: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCampaignNavigation = () => {
    // dispatch(clearConversation());
    // dispatch(setSelectedCampaign(-99));
    // navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
  };

  return (
    <MarkerterViewLayout className={styles["container-wrapper"]}>
      <div className={styles["container"]}>
        <DisplayResponse
          customInsights={
            <TextElement className={styles["custom-insights"]}>
              After some automated BANT assessment, I reckon we’ve got{" "}
              <span>3,456 prospects who are now Sales Qualified.</span> Time to
              create value for those customers! There are also{" "}
              <span>250 SQLs that aren’t quite ready for negotiation</span> yet
              but can still be nurtured for future sales. Let me know how you’d
              like to move forward!
            </TextElement>
          }
          query=""
          modelResponseId={-1}
          direction="left"
          showEllipses={false}
          iconClassName={styles["ninja-icon"]}
        />

        <div className={styles["suggested-actions"]}>
          <div className={styles["actions"]}>
            <SuggestedPrompt
              title="Start a Tailored Outreach Plan"
              description={
                "I want to craft a tailored Outreach plan specific to my sales qualified leads segments. "
              }
              onClick={handleCampaignNavigation}
            />
            <SuggestedPrompt
              title="Nurture Contacts that are still not negotiation-ready"
              description="I don’t want these contacts to go to waste, so let’s craft a strategy to nurture and ultimately win them!"
              onClick={handleCampaignNavigation}
            />
          </div>
        </div>
      </div>
    </MarkerterViewLayout>
  );
};

export default CreateValueStep;
