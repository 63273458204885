import React from "react";

const BulbIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5417 0V2.875H12.4583V0H10.5417ZM4.04671 2.69157L2.69157 4.04671L4.72428 6.07943L6.07943 4.72428L4.04671 2.69157ZM18.9533 2.69157L16.9206 4.72428L18.2757 6.07943L20.3084 4.04671L18.9533 2.69157ZM11.5 4.79167C7.80083 4.79167 4.79167 7.80083 4.79167 11.5C4.79167 14.169 6.36237 16.4723 8.625 17.5513V20.125C8.625 21.182 9.48462 22.0417 10.5417 22.0417H12.4583C13.5154 22.0417 14.375 21.182 14.375 20.125V17.5513C16.6376 16.4723 18.2083 14.169 18.2083 11.5C18.2083 7.80083 15.1992 4.79167 11.5 4.79167ZM0 10.5417V12.4583H2.875V10.5417H0ZM20.125 10.5417V12.4583H23V10.5417H20.125ZM4.72428 16.9206L2.69157 18.9533L4.04671 20.3084L6.07943 18.2757L4.72428 16.9206ZM18.2757 16.9206L16.9206 18.2757L18.9533 20.3084L20.3084 18.9533L18.2757 16.9206Z"
        fill="white"
      />
    </svg>
  );
};

export default BulbIcon;
