import React from "react";

const ApprovedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.925 8.225L8.5 6.8C8.31667 6.61667 8.08733 6.52067 7.812 6.512C7.53733 6.504 7.3 6.6 7.1 6.8C6.91667 6.98333 6.825 7.21667 6.825 7.5C6.825 7.78333 6.91667 8.01667 7.1 8.2L9.225 10.35C9.425 10.55 9.65833 10.65 9.925 10.65C10.1917 10.65 10.425 10.55 10.625 10.35L15.275 6.1C15.275 5.83333 15.2583 5.575 15.225 5.325C15.1917 5.075 15.0833 4.85833 14.9 4.675C14.7 4.475 14.4627 4.375 14.188 4.375C13.9127 4.375 13.675 4.475 13.475 4.675L9.925 8.225ZM0 18V16H21C21.2833 16 21.5207 16.096 21.712 16.288C21.904 16.4793 22 16.7167 22 17C22 17.2833 21.904 17.5207 21.712 17.712C21.5207 17.904 21.2833 18 21 18H0ZM3 15C2.45 15 1.97933 14.8043 1.588 14.413C1.196 14.021 1 13.55 1 13V2C1 1.45 1.196 0.979 1.588 0.587C1.97933 0.195667 2.45 0 3 0H19C19.55 0 20.021 0.195667 20.413 0.587C20.8043 0.979 21 1.45 21 2V13C21 13.55 20.8043 14.021 20.413 14.413C20.021 14.8043 19.55 15 19 15H3Z"
        fill="white"
      />
    </svg>
  );
};

export default ApprovedIcon;
