import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./BrandingList.module.scss";
import TextElement from "../TextElement/TextElement";
import { IoMdFingerPrint } from "react-icons/io";
import { LuPencilRuler } from "react-icons/lu";
import { PiImageSquareBold } from "react-icons/pi";
import { SidebarItem } from "../../enums/SidebarItem.enum";
import { BrandingDropdownIds } from "../SideBar/BrandingDropdown/BrandingDropdown";

interface BrandingListProps {
  className?: string;
  onClick?: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>
  ) => void;
}

const BrandingList: React.FC<BrandingListProps> = (props) => {
  const { className, onClick } = props;
  const [items, setItems] = useState<
    {
      label: string;
      sidebarItem: SidebarItem;
      icon: ReactNode;
    }[]
  >([
    {
      label: "Identity",
      sidebarItem: SidebarItem.BRAND_IDENTITY,
      icon: <IoMdFingerPrint />,
    },
    {
      label: "Style Guide",
      sidebarItem: SidebarItem.BRAND_STYLE_GUIDE,
      icon: <LuPencilRuler />,
    },
    {
      label: "Assets",
      sidebarItem: SidebarItem.BRAND_ASSETS,
      icon: <PiImageSquareBold />,
    },
  ]);

  const [activeSideTab, setActiveSideTab] = useState<SidebarItem>();

  useEffect(() => {
    const activeSection = new URLSearchParams(window.location.search).get(
      "section"
    );

    switch (activeSection) {
      case BrandingDropdownIds.IDENTITY:
        setActiveSideTab(SidebarItem.BRAND_IDENTITY);
        break;
      case BrandingDropdownIds.STYLE_GUIDE:
        setActiveSideTab(SidebarItem.BRAND_STYLE_GUIDE);
        break;
      case BrandingDropdownIds.ASSETS:
        setActiveSideTab(SidebarItem.BRAND_ASSETS);
        break;
      default:
        setActiveSideTab(undefined);
        break;
    }
  }, [window.location.search]);

  useEffect(() => {}, [activeSideTab]);

  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={(e) => {
        onClick && onClick(e);
      }}
    >
      <TextElement className={styles["title"]}>Brand</TextElement>
      <div className={styles["brands"]}>
        {items.map((item, index) => (
          <span
            key={index}
            className={classNames(
              styles["brand"],
              activeSideTab === item.sidebarItem &&
                styles["active-branding-step"]
            )}
          >
            {item.icon}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BrandingList;
