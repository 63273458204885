import React, { useEffect, useState } from "react";

import { Popover } from "@headlessui/react";

import styles from "./ErikoChatQuery.module.scss";
import Input from "../Input/Input";
import { SyncLoader } from "react-spinners";
import useErikoChatQuery from "./useErikoChatQuery";
import classNames from "classnames";


import CampaignRangeSelector from "./components/CampaignRangeSelector";
import TextElement from "../../../TextElement/TextElement";
import Button from "../Button/Button";
import { transformTotalNumber } from "../../../../lib/helpers";
import Download from "../../../../icons/Download";
import SendButton from "../../../../icons/SendButton";
import Adobe from "../../../../icons/Adobe";
import Dynamics from "../../../../icons/Dynamics";
import Marketo from "../../../../icons/Marketo";
import Saleforce from "../../../../icons/Saleforce";
import { PinIcon, SleepingNinjaIcon } from "../../../../icons/index copy";
import Copy from "../../../../icons/Copy";
import IconicContainer from "../IconicContainer/IconicContainer";
// import DisplayResponseContainer from "../DisplayResponseContainer/DisplayResponseContainer";

enum Sender {
  USER = "user",
  NINJA = "ninja",
}

type Question = {
  sender: Sender;
  content?: React.ReactNode;
  contentAsString?: string;
  type?: "KPI" | "INSIGHT" | "VISUALIZATION" | null;
  query?: string;
};

export const formatTextAsHTML = (text: string): string => {
  // Split the text into paragraphs
  text = text.replace(/^['"]|['"]$/g, "");

  // Split the text into sentences based on ".", "!", or "?" followed by a space
  const sentences: string[] = text.split(/(?<=[.!?])\s+/);

  // Convert each sentence to an HTML element
  const htmlSentences: string[] = sentences.map(
    (sentence: string, index: number) => {
      // Detect if the sentence is a list item
      if (sentence.includes(":")) {
        const listItemStyle = `
        padding-left: 20px;
      `;
        const listItemContent = sentence.replace(":", "").trim();
        return `\n<li style="${listItemStyle}">${listItemContent}</li>\n`;
      }
      // Default to a regular sentence
      return sentence.trim();
    }
  );

  // Remove empty strings and join the HTML elements with line breaks
  return htmlSentences.filter(Boolean).join("<br>");
};

interface ErikoChatQueryProps {
  className?: string;
  // responses: any[];
  // createResponse: (input: {
  //   query: string;
  //   type: string;
  //   sequence: string;
  // }) => void;
}

const ErikoChatQuery: React.FC<ErikoChatQueryProps> = (props) => {
  const {
    fetchInsights,
    loading,
    fetchVisualizations,
    fetchKPI,
    fetchCampaigns,
    setLoading,
    KPI_LIST,
  } = useErikoChatQuery();

  const { className } = props;

  const [userInput, setUserInput] = useState("");
  const [rangeValue, setRangeValue] = useState("");

  const [conversation, setConversation] = useState<Question[]>([
    {
      sender: Sender.NINJA,
      content: "Hi, I am Eriko. I am here to help you with your data.",
    },
  ]);

  const handleInsightsClick = async () => {
    if (!userInput.trim()) return;
    const query = userInput;
    setConversation((pC) => [
      {
        sender: Sender.USER,
        content: query,
      },
      ...pC,
    ]);
    const response = await fetchInsights(query);

    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <span
            className={styles["insight-response"]}
            dangerouslySetInnerHTML={{
              __html: response
                ? formatTextAsHTML(response)
                : "Sorry, I could not find any insights for your query. Please try again.",
            }}
          ></span>
        ),
        type: response ? "INSIGHT" : null,
        contentAsString: response,
        query,
      },
      ...pC,
    ]);
  };

  const handleChartIconClick = async () => {
    if (!userInput.trim()) return;
    const query = userInput;
    setConversation((pC) => [
      {
        sender: Sender.USER,
        content: query,
      },
      ...pC,
    ]);
    const url = await fetchVisualizations(query);
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: url ? (
          <img
            className={styles["visulization-img"]}
            src={url}
            alt="Visualization"
          />
        ) : (
          "Sorry, I could not find any visualizations for your query. Please try again."
        ),
        inludesCopyNReportButtons: true,
        type: url ? "VISUALIZATION" : null,
        query,
      },
      ...pC,
    ]);
  };

  const handleKPIClick = async () => {
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <div className={styles["propensity-container"]}>
            <TextElement>Which KPI would you like to generate ?</TextElement>
            <div className={styles["actions"]}>
              {KPI_LIST.map((kpi) => (
                <Button
                  children={kpi.title}
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                      setConversation((pC) => [
                        {
                          sender: Sender.NINJA,
                          content: (
                            <TextElement>
                              {kpi.title} :{kpi.value}
                            </TextElement>
                          ),
                          inludesCopyNReportButtons: true,
                          type: "KPI",
                          query: kpi.key,
                        },
                        ...pC,
                      ]);
                    }, 500);
                  }}
                />
              ))}
            </div>
          </div>
        ),
        inludesCopyNReportButtons: true,
        type: null,
      },
      ...pC,
    ]);
  };

  const handlePropensityClick = async () => {
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <div className={styles["propensity-container"]}>
            <TextElement>
              Which type of propensity model do you want to implement?
            </TextElement>
            <div className={styles["actions"]}>
              <Button
                children={"Propensity to Convert"}
                onClick={() => showCampaignList("convert")}
              />
              <Button
                children={"Propensity to Churn"}
                onClick={() => showCampaignList("churn")}
              />
              <Button
                children={"Propensity to Engage"}
                onClick={() => showCampaignList("engage")}
              />
            </div>
          </div>
        ),
        inludesCopyNReportButtons: true,
        type: null,
      },
      ...pC,
    ]);
  };

  const showCampaignList = async (type: string) => {
    const campaigns = await fetchCampaigns();
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <div className={styles["propensity-container"]}>
            <TextElement>Choose the campaign</TextElement>
            <div className={styles["actions"]}>
              {campaigns?.map((c, index) => {
                return (
                  <Button
                    key={index}
                    children={c?.name}
                    onClick={() => showRangeSelectorForCampaign(c, type)}
                  />
                );
              })}
            </div>
          </div>
        ),
        inludesCopyNReportButtons: true,
        type: null,
      },
      ...pC,
    ]);
  };

  const showRangeSelectorForCampaign = (
    campaign: { name: string },
    type: string
  ) => {
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <CampaignRangeSelector
            onGenerate={(range) => {
              showCampaignPropensityInfo(campaign, type, range);
            }}
          />
        ),
        type: null,
      },
      ...pC,
    ]);
  };

  const showCampaignPropensityInfo = (
    campaign: { name: string },
    type: string,
    range: number
  ) => {
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <div className={styles["propensity-container"]}>
            <TextElement>
              Based on the campaign data, here’s the lead segment with the
              highest propensity to {type} with the {campaign?.name} campaign.
            </TextElement>
            <table className={styles["table"]}>
              <tbody>
                <tr>
                  <td>
                    <TextElement>Total Count</TextElement>
                  </td>
                  <td>
                    <TextElement>{transformTotalNumber(range)}</TextElement>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextElement>Top State</TextElement>
                  </td>
                  <td>
                    <TextElement>Sydney</TextElement>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextElement>Average Age Range</TextElement>
                  </td>
                  <td>
                    <TextElement>25-26</TextElement>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextElement>Common Industry</TextElement>
                  </td>
                  <td>
                    <TextElement>
                      Professional, Scientific and Technical Services
                    </TextElement>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextElement>Average Income</TextElement>
                  </td>
                  <td>
                    <TextElement>&gt;$100,000</TextElement>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles["right-actions"]}>
              <Button
                icon={<Download className={styles["icon"]} />}
                children={"Export List"}
                onClick={() => dispalyMessage("Exported Successfully")}
              />
              <Button
                icon={<SendButton className={styles["icon"]} />}
                children={"Send to Marketing Automation Platform"}
                onClick={showMarketingList}
              />
            </div>
          </div>
        ),
        inludesCopyNReportButtons: true,
        type: null,
      },
      ...pC,
    ]);
  };

  const showMarketingList = () => {
    setConversation((pC) => [
      {
        sender: Sender.NINJA,
        content: (
          <div className={styles["propensity-container"]}>
            <TextElement>
              Choose which marketing automation platform you want to send this
              data?
            </TextElement>
            <div className={styles["actions"]}>
              <Button
                children={"Adobe Campaign"}
                icon={<Adobe className={styles["icon"]} />}
                onClick={() => dispalyMessage("Sent to Adobe Campaign")}
              />
              <Button
                children={"Dynamics 365"}
                icon={<Dynamics className={styles["icon"]} />}
                onClick={() => dispalyMessage("Sent to Dynamics 365")}
              />

              <Button
                children={"Marketo"}
                icon={<Marketo className={styles["icon"]} />}
                onClick={() => dispalyMessage("Sent to Marketo")}
              />
              <Button
                children={"Salesforce"}
                icon={<Saleforce className={styles["icon"]} />}
                onClick={() => dispalyMessage("Sent to Salesforce")}
              />
            </div>
          </div>
        ),
        inludesCopyNReportButtons: true,
        type: null,
      },
      ...pC,
    ]);
  };

  const dispalyMessage = (message: string) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setConversation((pC) => [
        {
          sender: Sender.NINJA,
          content: <TextElement>{message}</TextElement>,

          type: null,
        },
        ...pC,
      ]);
    }, 1000);
  };

  const renderQuestion = (item: Question, index: number) => {
    return (
      <div className={styles["message-wrapper"]} key={index}>
        <IconicContainer
          iconType={item.sender}
          className={styles[item.sender === Sender.NINJA ? "left" : "right"]}
          contentClass={styles["msg-content"]}
          children={
            <div className={styles["response-container"]}>
              <TextElement className={styles["msg-text"]}>
                {item.content}
              </TextElement>
              {item?.type && (
                <div className={styles["actions"]}>
                  <PinIcon
                    className={styles["action-icon"]}
                    onClick={() => {
                      // createResponse({
                      //   query: item.query as string,
                      //   type: item.type as string,
                      //   sequence: `${
                      //     responses.length > 0 ? responses[0].id + 1 : 0
                      //   }`,
                      // });
                    }}
                  />
                  {item.type == "VISUALIZATION" ? (
                    <Download
                      className={styles["action-icon"]}
                      onClick={() => {
                        if (item.content) {
                          const link = document.createElement("a");
                          link.href = (item as any)?.content.props.src;
                          link.download = "downloaded-image.jpg";
                          link.click();
                        }
                      }}
                    />
                  ) : (
                    <Copy
                      onClick={() => {
                        if (item?.contentAsString) {
                          navigator.clipboard.writeText(item.contentAsString);
                        }
                      }}
                      className={styles["action-icon"]}
                    />
                  )}
                </div>
              )}
            </div>
          }
        />
      </div>
    );
  };

  return (
    <Popover className={classNames(styles["popover"], className)}>
      {({ open }) => (
        <>
          <Popover.Button className={styles["popover-btn"]}>
            {open && (
              <span className={styles["close-button"]}>
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.9043 5.9901L15.6983 4.69482L10.9171 9.83L6.13589 4.69482L4.92989 5.9901L9.71112 11.1253L4.92989 16.2605L6.13589 17.5557L10.9171 12.4206L15.6983 17.5557L16.9043 16.2605L12.1231 11.1253L16.9043 5.9901Z"
                    fill="white"
                  />
                </svg>
              </span>
            )}
            <div className={styles["eriko-chat__button-container"]}>
              <SleepingNinjaIcon className={styles["icon"]} />
              <TextElement className={styles["text"]}>
                Got questions about your data? Click me to wake me up.
              </TextElement>
            </div>
          </Popover.Button>

          {open && (
            <Popover.Panel className={styles["chat-container"]} static>
              <div className={styles["container"]}>
                <div className={styles["chat"]}>
                  {loading && (
                    <IconicContainer
                      iconType={"ninja"}
                      className={styles["left"]}
                      contentClass={styles["msg-content"]}
                      children={<SyncLoader color="#eb2701" />}
                    />
                  )}
                  {conversation?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      {renderQuestion(item, index + 1)}
                    </React.Fragment>
                  ))}
                </div>
                <Input
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Send a message"
                  className={styles["input-container"]}
                  onChartIconClick={handleChartIconClick}
                  onSubmit={handleInsightsClick}
                  onPropensityClick={handlePropensityClick}
                  onKPIIconClick={handleKPIClick}
                />
              </div>
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export default ErikoChatQuery;
