import React from "react";

import styles from "./ForgotPassword.module.scss";
import InputField from "../../componenets/Input/input";
import AuthLayout from "../../componenets/AuthLayout/AuthLayout";
import { Button, TextElement } from "../../componenets";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { forgotPassword, ForgotPasswordResponse } from "../../redux/authSlice";

type Props = {};

const ForgotPassword: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authError } = useAppSelector((state) => state.auth);
  const [email, setEmail] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  return (
    <AuthLayout>
      <div className={styles["form-container"]}>
        {successMessage ? (
          <div>
            <TextElement>{successMessage}</TextElement>
            <TextElement
              className={styles["underline-text"]}
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.LOGIN.path);
              }}
            >
              Back to login
            </TextElement>
          </div>
        ) : (
          <form
            className={styles["form"]}
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(forgotPassword({ email })).then((response) => {
                if (response.payload) {
                  if ((response.payload as ForgotPasswordResponse).success) {
                    setSuccessMessage(
                      "A forgot password link has been sent to your email. Please check your inbox."
                    );
                  }
                }
              });
            }}
          >
            <TextElement className="text-center">
              Please input your registered email address below. If the account
              exists, you should receive a forgot password link in your inbox.
            </TextElement>
            <InputField
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address:"
              required
            />
            {authError && (
              <TextElement className={styles["error"]}>{authError}</TextElement>
            )}
            <Button className={styles["submit"]}>Forgot Password</Button>
            <TextElement
              className={styles["underline-text"]}
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.LOGIN.path);
              }}
            >
              Back to login
            </TextElement>
          </form>
        )}
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
