import React from "react";

const Copy: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7895 0H1.68421C0.757895 0 0 0.654545 0 1.45455V11.6364H1.68421V1.45455H11.7895V0ZM10.9474 2.90909L16 7.27273V14.5455C16 15.3455 15.2421 16 14.3158 16H5.04421C4.11789 16 3.36842 15.3455 3.36842 14.5455L3.37684 4.36364C3.37684 3.56364 4.12632 2.90909 5.05263 2.90909H10.9474ZM10.1053 8H14.7368L10.1053 4V8Z"
        fill="#B2BBCA"
      />
    </svg>
  );
};

export default Copy;
