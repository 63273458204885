import React from "react";
import styles from "./ApproverViewLayout.module.scss";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";
import { UserRoleType } from "../../redux/authSlice";

interface ApproverViewLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const ApproverViewLayout: React.FC<ApproverViewLayoutProps> = ({
  children,
  header,
}) => {
  return (
    <ProtectedRoleRoute requiredRole={[UserRoleType.PRIMARY_APPROVER]}>
      <div className={styles["container"]}>
        <SideBar className={styles["side-bar"]} />
        {header && (
          <Header className={styles["header"]} titleContent={header} />
        )}
        <div className={styles["content"]}>{children}</div>
      </div>
     </ProtectedRoleRoute>
  );
};

export default ApproverViewLayout;
