import React from "react";

const PresentAndProveValueIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      width="123"
      height="132"
      viewBox="0 0 123 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M105.731 24.0186C105.731 24.0186 105.047 29.3589 110.855 31.825C116.663 34.2911 121.623 31.6598 121.623 31.6598L122.504 23.6269C122.504 23.6269 117.584 25.4101 114.863 24.9372C112.141 24.4644 106.757 19.4434 106.757 19.4434"
        fill="#EB2701"
      />
      <g opacity="0.4">
        <path
          d="M39.6876 48.7299C38.1497 45.6074 38.4011 42.5632 39.0263 40.3164C38.4357 42.6473 38.2179 45.7205 39.6876 48.7299Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M41.5739 24.707C40.5786 31.2272 40.5978 36.6941 40.5978 36.6941C40.5978 36.6941 39.6366 38.1256 39.0262 40.3164C39.6227 37.9609 40.5997 36.3652 40.5997 36.3652C40.5997 36.3652 40.4349 31.077 41.5739 24.707Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M42.3107 16.7911C41.8868 16.7138 41.6657 16.6718 41.6657 16.6718C41.6609 16.6626 41.9867 16.7259 42.3107 16.7911Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M42.9509 16.9079C42.9254 16.9079 42.7485 16.8755 42.5342 16.833C42.6601 16.8557 42.7995 16.8799 42.9509 16.9079Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M63.2548 120.144C59.8973 120.476 57.6154 120.917 57.6154 120.917C57.6154 120.917 51.3066 123.853 50.9519 125.482C50.5972 127.111 51.7209 131.221 51.7209 131.221L75.6864 131.735C75.6864 131.735 75.4759 126.621 75.5187 120.196C71.4566 120.273 66.865 119.794 63.2548 120.144Z"
        fill="#02285E"
      />
      <path
        d="M97.3022 58.8379C74.0994 64.7438 58.6838 59.0098 58.6838 59.0098C54.5093 65.0084 54.4117 80.046 54.4117 80.046C52.993 95.0144 57.6476 120.949 57.6476 120.949C57.6476 120.949 61.4174 120.155 64.9205 120.144C68.4332 120.133 71.1793 120.08 75.5581 120.359C75.5581 120.14 75.521 109.623 75.523 109.395C75.571 102.42 76.5361 99.9254 76.5361 99.9254H78.2585C80.1329 102.573 81.0686 120.404 81.0686 120.404C81.0686 120.404 90.3749 120.345 99.1391 121.949C99.2405 116.247 100.891 89.7476 100.891 81.336C100.891 72.7477 97.3022 58.8379 97.3022 58.8379Z"
        fill="#02285E"
      />
      <path
        d="M104.807 127.747C104.018 126.322 99.0396 120.921 99.0396 120.921C90.2749 119.318 81.0974 120.371 81.0974 120.371C81.0974 120.371 80.8436 123.884 81.0974 125.768C81.3512 127.651 81.3536 131.74 81.3536 131.74L105.063 131.998C105.063 131.998 105.595 129.175 104.807 127.747Z"
        fill="#02285E"
      />
      <path
        d="M76.426 66.8644C76.426 66.8644 79.9694 63.6797 80.4947 63.1383C81.02 62.5968 81.3069 62.2993 81.4559 62.1433C81.6049 61.9873 81.8091 61.557 81.8091 61.557H81.6054C81.6054 61.557 75.4537 61.2991 75.199 61.2856C74.9443 61.272 71.6656 60.9281 71.3287 61.0136C70.9918 61.0991 70.7895 61.1242 70.6122 61.1242C70.4757 61.1289 70.34 61.1459 70.2065 61.1749V61.6096C70.2065 61.6096 71.4316 63.4338 72.2789 64.338C73.1262 65.2421 75.3956 67.317 75.3956 67.317L75.8839 67.4136L76.203 67.3687L76.426 66.8644Z"
        fill="#F2C5A7"
      />
      <path
        d="M100.891 85.3695C100.891 85.3695 93.654 90.0709 76.7754 90.0709C59.8968 90.0709 54.0912 86.7219 54.0912 86.7219C54.0912 86.7219 54.1392 83.4202 54.2195 81.5274C54.2195 81.5274 58.6732 85.3695 76.5193 85.3695C94.3653 85.3695 100.891 80.6475 100.891 80.6475C100.891 80.6475 101.001 84.5634 100.891 85.3695Z"
        fill="#FF2434"
      />
      <path
        d="M65.255 94.4803C70.9188 99.6877 79.462 100.896 85.5362 96.8599C86.4436 96.2451 87.3457 95.4805 88.1084 94.4991C88.1622 94.4175 88.2526 94.4223 88.3006 94.5146C88.3218 94.5576 88.3322 94.6051 88.331 94.653C88.3298 94.701 88.3171 94.7479 88.2939 94.7899C87.5533 95.962 86.6685 96.8087 85.7467 97.566C80.4034 101.731 73.0887 101.259 67.7411 97.3173C66.8126 96.6145 65.9148 95.8302 65.0978 94.8092C65.0692 94.767 65.0522 94.718 65.0485 94.6671C65.0448 94.6163 65.0545 94.5653 65.0767 94.5194C65.1204 94.4329 65.1983 94.418 65.2545 94.4793L65.255 94.4803Z"
        fill="#012551"
      />
      <path
        d="M57.2203 82.987C58.2392 83.4275 61.6596 84.0573 62.7847 84.4253C64.7893 83.156 80.6485 71.082 88.7582 60.498C87.2548 60.6429 83.7148 61.3505 81.8947 61.3283C80.4203 62.8989 68.8042 75.9567 57.2203 82.987Z"
        fill="#012551"
      />
      <path
        d="M72.8475 71.7012C72.7385 71.7012 72.6323 71.6659 72.5447 71.6007C68.376 68.515 62.6598 60.9709 62.4185 60.6516C62.3777 60.598 62.3479 60.5368 62.3306 60.4716C62.3134 60.4064 62.3091 60.3384 62.318 60.2715C62.3269 60.2046 62.3489 60.1402 62.3826 60.0818C62.4164 60.0235 62.4612 59.9724 62.5146 59.9315C62.7395 59.7595 68.7067 59.0138 68.8773 59.2394C68.9354 59.3162 71.9531 64.9347 76.0829 67.6253C76.3198 67.7794 73.4252 71.2631 73.2579 71.4892C73.2109 71.5544 73.1492 71.6076 73.0779 71.6445C73.0066 71.6813 72.9277 71.7007 72.8475 71.7012Z"
        fill="#012551"
      />
      <path
        d="M49.1141 75.8392C49.1141 75.8392 46.342 76.6569 39.3507 75.2316C32.3595 73.8063 29.6234 65.7618 29.6234 65.7618C31.8524 60.7722 35.8044 58.8799 35.8044 58.8799C38.7428 60.7543 44.4672 62.096 48.1558 60.365C51.8444 58.634 58.8553 59.0127 58.8553 59.0127C58.8553 59.0127 56.9449 64.7355 56.1212 66.5684C55.2975 68.4013 55.0105 72.9225 55.0105 72.9225"
        fill="#02285E"
      />
      <path
        d="M29.5863 66.0666C29.5863 66.0666 25.2667 65.3793 23.9749 64.8447C22.683 64.31 21.8977 63.1219 21.8977 63.1219C21.8977 63.1219 19.6178 63.3938 19.2631 61.9173C19.2631 61.9173 17.7939 61.4344 17.8213 60.2371C17.8213 60.2371 15.7946 59.4484 15.1617 58.3534C14.5287 57.2585 15.0785 57.1016 15.0785 57.1016L18.2024 58.6592C18.2024 58.6592 19.7221 58.0733 20.2286 59.117C20.2286 59.117 22.027 58.4554 22.559 59.6C22.559 59.6 23.9018 58.7345 24.3574 59.8801C24.813 61.0258 24.9404 61.458 24.9404 61.458C24.9404 61.458 29.4744 62.6293 30.2597 62.7819C31.045 62.9345 31.365 62.7964 31.2343 62.9751C31.1036 63.1538 29.5863 66.0666 29.5863 66.0666Z"
        fill="#F2C5A7"
      />
      <path
        d="M78.8026 90.1547C78.8026 90.1547 79.4783 92.5735 76.872 95.7385C74.2657 98.9034 71.7839 100.08 71.7839 100.08C71.7839 100.08 73.9163 103.206 75.5437 103.93C75.5437 103.93 80.0156 100.899 82.4008 95.3579C84.786 89.8167 79.7758 86.3262 79.7758 86.3262"
        fill="#FF2434"
      />
      <g opacity="0.25">
        <path
          d="M79.5868 88.4912C79.5868 88.4912 80.9508 90.8771 80.2116 93.6364C79.4725 96.3956 75.3638 100.218 75.2614 100.539C75.1591 100.859 76.3812 102.449 76.9849 102.457C77.5885 102.464 75.5431 103.93 75.5431 103.93C75.5431 103.93 72.3913 101.549 71.8463 100.17C71.8463 100.17 77.1925 96.3387 78.271 93.7948C79.3494 91.251 78.7996 90.1541 78.7996 90.1541L79.5868 88.4912Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M70.58 91.2969C70.58 91.2969 68.5692 99.7201 60.9709 100.036L58.9467 96.2093C58.9467 96.2093 62.4762 95.8379 64.1708 93.436C65.8653 91.0342 67.8968 87.6181 70.0273 87.6886"
        fill="#FF2434"
      />
      <g opacity="0.25">
        <path
          d="M60.8329 95.9087C60.8329 95.9087 61.6077 97.5025 62.7606 97.686C63.9136 97.8696 66.7251 95.7807 67.4955 93.9884C68.2659 92.196 69.8658 89.5029 69.8658 89.5029L70.2359 90.5143L70.5242 91.6445C70.5242 91.6445 69.3765 95.5745 66.665 97.7831C63.9535 99.9918 60.9699 100.036 60.9699 100.036L59.0653 96.1932L60.8329 95.9087Z"
          fill="#0F0F14"
        />
      </g>
      <path
        d="M75.0524 86.9228C75.0524 86.9228 75.757 84.1698 79.2615 84.5248C82.766 84.8798 82.4301 90.3601 80.7153 92.1182C79.0005 93.8762 76.7196 92.5944 76.7196 92.5944C76.7196 92.5944 74.0802 94.1573 71.3172 92.6644C68.5543 91.1715 67.8185 86.8044 70.0513 86.007C72.2842 85.2096 74.0033 86.2316 75.0524 86.9228Z"
        fill="#FF2434"
      />
      <g opacity="0.25">
        <path
          d="M68.6595 88.2968C68.6595 88.2968 71.1904 90.9918 73.8452 90.9783C76.5 90.9647 76.8989 89.7409 76.8989 89.7409L76.8542 89.3004C76.8542 89.3004 79.4615 90.716 80.4972 90.1065C81.5329 89.497 81.6674 88.7363 81.6674 88.7363L81.9625 88.0645C81.9625 88.0645 82.2129 91.6675 80.2237 92.533C78.2345 93.3985 77.108 92.7691 77.108 92.7691L76.2982 92.6016C76.2982 92.6016 73.3973 94.1234 70.8708 92.3852C68.3443 90.6469 68.6595 88.2968 68.6595 88.2968Z"
          fill="#0F0F14"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M73.8067 87.4752C74.6276 87.9852 75.0082 88.7025 74.841 88.9352C74.6737 89.168 74.0153 88.8271 73.1949 88.317C72.3745 87.807 71.6998 87.3202 71.869 87.0879C72.0381 86.8555 72.9863 86.9652 73.8067 87.4752Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M79.2592 85.6738C80.0103 85.867 80.465 86.3983 80.3837 86.6678C80.3025 86.9373 79.7172 86.8451 78.966 86.6514C78.2148 86.4577 77.5833 86.2365 77.6645 85.9665C77.7457 85.6965 78.5085 85.4802 79.2592 85.6738Z"
          fill="white"
        />
      </g>
      <path
        d="M67.4815 62.2559C75.8742 63.2122 89.6506 62.0052 93.8371 59.4005C99.3986 55.9399 104.261 49.6689 105.871 45.2091C107.42 40.9169 111.958 24.6077 101.217 11.3146C91.1794 -1.10805 66.1094 -7.72775 49.9705 15.3345C45.834 22.5217 44.8074 40.1002 49.4082 49.0947C52.3951 54.9329 59.3335 61.3281 67.4815 62.2559Z"
        fill="#02285E"
      />
      <path
        d="M93.1345 27.5235C96.0661 28.0982 98.2192 30.458 98.4479 33.3095C98.7978 37.6641 97.2465 44.1524 93.5247 46.958C88.4207 50.8064 82.6468 43.4806 72.2179 43.7646C61.4212 44.0587 60.7421 47.1015 54.5799 44.8228C50.8543 43.4444 49.5327 28.3991 53.202 26.528C58.7068 23.7239 61.9941 29.1043 72.4125 29.2516C79.3696 29.3496 88.2771 26.5705 93.1345 27.5235Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M106.489 19.9124C108.491 15.5308 112.544 15.9152 115.808 16.0297C119.071 16.1442 121.484 12.0625 121.484 12.0625C123.472 14.0113 122.934 22.193 122.934 22.193C122.934 22.193 115.011 21.6738 113.074 22.0283C111.136 22.3828 107.499 25.1957 107.499 25.1957L107.175 25.1406"
        fill="#EB2701"
      />
      <path
        d="M47.5339 23.2896C47.5339 23.2896 81.3526 29.1891 108.208 25.236C108.208 25.236 110.17 24.9945 109.584 21.5204C108.999 18.0463 104.805 18.5829 104.805 18.5829C104.805 18.5829 82.086 20.0913 73.8923 19.7759C65.6986 19.4605 49.5202 17.3789 49.0026 17.4759C47.8785 17.6865 47.3099 21.0881 47.5339 23.2896Z"
        fill="#EB2701"
      />
      <path
        d="M73.5515 33.6103C76.1857 36.3922 79.7656 37.9271 83.4624 37.8605C86.7867 37.5978 88.3092 36.627 89.9365 33.562C91.2385 30.1502 90.9655 29.29 90.9655 29.29L73.5515 33.6103Z"
        fill="#EB2701"
      />
      <path
        d="M57.0099 34.7946C58.5785 36.7946 61.1026 37.6393 63.447 36.9492C65.9889 36.4179 68.3155 35.0974 70.1302 33.1525L55.9622 27.5156C55.9622 27.5156 56.1727 33.5239 57.0099 34.7946Z"
        fill="#EB2701"
      />
      <path
        d="M105.423 58.0053C105.423 58.0053 105.312 56.9514 104.248 54.5076C103.184 52.0637 102.475 50.3332 100.752 50.6384C99.03 50.9437 99.587 55.3233 99.587 55.3233C99.587 55.3233 91.8864 55.3233 90.4585 56.9423C89.0306 58.5612 91.6331 60.4144 91.6331 60.4144C91.6331 60.4144 91.3293 60.822 90.8733 61.9184C90.4172 63.0148 91.7345 63.8769 91.7345 63.8769C91.7345 63.8769 91.9878 65.506 93.1023 67.4915C94.2168 69.477 97.4594 69.8334 97.4594 69.8334C109.111 70.1397 105.423 58.0053 105.423 58.0053Z"
        fill="#F2C5A7"
      />
      <path
        d="M119.346 68.5404C118.586 63.9584 104.479 55.2314 104.479 55.2314L105.423 58.0071C105.423 58.0071 109.111 70.1396 97.4594 69.8343C97.4594 69.8343 105.732 77.8107 111.912 77.5562C118.093 77.3016 120.106 73.1224 119.346 68.5404Z"
        fill="#02285E"
      />
      <path
        d="M39.341 14.0091C31.9071 12.4684 25.198 20.7679 23.9393 24.0854C21.4402 21.5773 11.934 16.7693 5.78134 21.2359C-0.371275 25.7025 -3.25775 35.2741 5.57469 48.2609C14.4071 61.2478 32.2628 63.1222 32.2628 63.1222C32.2628 63.1222 47.7861 54.0601 50.5481 38.5763C53.3101 23.0924 46.7754 15.5517 39.341 14.0091Z"
        fill="#EB2701"
      />
      <path
        d="M43.5978 15.6533C46.5415 19.6756 48.0131 25.9408 46.4305 34.8145C43.6685 50.2983 28.1451 59.3605 28.1451 59.3605C28.1451 59.3605 17.013 58.1897 7.97778 51.3034C17.2831 61.5474 32.2624 63.1219 32.2624 63.1219C32.2624 63.1219 47.7857 54.0598 50.5477 38.576C52.7614 26.1673 49.0041 18.8598 43.5978 15.6533Z"
        fill="#E21D03"
      />
      <g opacity="0.5">
        <path
          d="M10.4003 41.1451C10.8244 39.682 9.98792 38.1505 8.53204 37.7243C7.07616 37.2981 5.55216 38.1387 5.12808 39.6018C4.70401 41.0649 5.54045 42.5965 6.99633 43.0226C8.45221 43.4488 9.97622 42.6082 10.4003 41.1451Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M6.52006 34.3132C5.80397 34.7938 4.82836 34.3847 4.56691 33.5603C4.55201 33.512 4.53856 33.468 4.52558 33.4221C4.45309 33.1507 4.40483 32.8734 4.3814 32.5933C4.36846 32.3196 4.37344 32.0453 4.3963 31.7723C4.47054 30.667 4.69017 29.5764 5.04943 28.5291C5.21428 27.9915 5.45938 27.4815 5.69536 26.9647C5.96834 26.4677 6.23363 25.9591 6.57581 25.4989C7.20492 24.5672 8.00607 23.7558 8.88845 23.0714C9.91501 22.2745 11.4212 22.4092 12.2411 23.4192C12.6065 23.8684 12.7977 24.4354 12.7794 25.0154C12.761 25.5953 12.5343 26.149 12.1411 26.574L11.9825 26.7445C11.4452 27.324 10.8584 27.8312 10.3576 28.4378C10.0813 28.7189 9.85301 29.042 9.59253 29.3472C9.46854 29.5047 9.35704 29.6718 9.23593 29.835C9.17729 29.9172 9.11001 29.9954 9.05378 30.0804L8.88701 30.3383C8.41368 31.0417 7.99588 31.7814 7.63746 32.5504C7.46153 32.9721 7.24129 33.3738 6.98048 33.7486C6.88643 33.9787 6.7261 34.1753 6.52006 34.3132Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default PresentAndProveValueIcon;
