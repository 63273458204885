import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import axios from "axios";
import { ROUTE_DEFINITIONS } from "../AppRoutes";

const useConnectingMarketingChannels = () => {
  const [loading, setLoading] = useState(false);

  const httpService = new HttpService();

  const linkedInLogin = () => {
    setLoading(true);
    const redirectUrl = window.location.href;
    const response = httpService
      .get<{
        authorizationUrl: string;
      }>(`api/linkedin/connect/?redirect_uri=${redirectUrl}`)
      .then((res) => {
        window.location.href = res.data.authorizationUrl;
      });
  };

  const sendAuthCodeToLinkedIn = (code: string) => {
    setLoading(true);
    console.log();
    httpService
      .get(
        `api/linkedin/callback/?code=${code}&
        redirect_uri=${window.location.origin + window.location.pathname}
        `
      )
      .then(() => {
        window.location.href =
          ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path;
      })
      .catch(() => {});
  };

  return {
    linkedInLogin,
    sendAuthCodeToLinkedIn,
  };
};

export default useConnectingMarketingChannels;
