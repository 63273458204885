import React, { useEffect } from "react";

import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import styles from "./CMOViewLayout.module.scss";
import { UserRoleType } from "../../redux/authSlice";
import ProtectedRoleRoute from "../ProtectedRoleRoute/ProtectedRoleRoute";

interface CMOViewLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const CMOViewLayout: React.FC<CMOViewLayoutProps> = ({ children, header }) => {
  return (
    <ProtectedRoleRoute requiredRole={[UserRoleType.CMO]}
      className={styles["cmo-view-layout"]}
    >
      <div className={styles["container"]}>
      <SideBar className={styles["side-bar"]} />
        {header && (
          <Header className={styles["header"]} titleContent={header} />
        )}
        <div className={styles["content"]}>{children}</div>
      </div>
    </ProtectedRoleRoute>
  );
};

export default CMOViewLayout;
